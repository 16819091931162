/* jshint esversion: 8 */
define([
  "service/datatransform/ruleInfo",
  "supportedProducts",
  "util",
  "constants"
], function (
  RuleInfo,
  SupportedProducts,
  Util,
  CCWA_Constants) {

  class RuleManager {

    constructor (args) {
      if (! (args && typeof args === 'object' && args.dataService &&
             typeof args.dataService === 'object' && args.dataService.ui &&
             typeof args.dataService.ui.getRulesArrayByProduct === 'function')) {
        throw new TypeError("Invalid dataService argument");
      }
      this.dataService = args.dataService;
      if ( !args.product || typeof args.product !== 'string') {
        throw new TypeError("Invalid product argument");
      }
      this.product = args.product;
      this.entitledReleaseMap = new Map();
      this.entitledReleaseExists = false;
      this.showAll = true;
      this.platformCredentials = {};
      this._INITIALIZED = false;
      this._RULES_ARRAY = null;
      this.initializePlatformCredentials();
    }

    isInitialized () { return this._INITIALIZED; }

    getDataService () { return this.dataService; }

    setShowAll (showAll) { this.showAll = !!showAll; }
    getShowAll () { return this.showAll; }

    async initialize (showAll = true) {
      if (!this._INITIALIZED) {
        this.setShowAll(showAll);
        try {
          this._RULES_ARRAY = await this.getDataService().ui.getRulesArrayByProduct(this.product, null, null, false, this.getShowAll());
        } catch (error) {
          Util.consoleLogError('initialize', error.message);
          this._RULES_ARRAY = [];
        }
        RuleManager._FEATURE_HIGHLIGHT_SET = await this.getFeatureSet(false);
        this._INITIALIZED = true;
      }
    }

    initializePlatformCredentials () {
      const supportedPlatforms = Util.getSupportedCloudPlatforms();
      for (const platform of supportedPlatforms) {
        this.platformCredentials[platform] = {};
      }
    }

    static getEntitledReleaseMap () {
      return RuleManager._ENTITLED_RELEASE_MAP;
    }

    static isEntitledReleasePresent () {
      return RuleManager._ENTITLED_RELEASE_EXISTS;
    }

    static setEntitledReleasePresent (isPresent) {
      RuleManager._ENTITLED_RELEASE_EXISTS = (isPresent === true);
    }

    static hasEntitlementEntry (release) {
      let hasEntry = false;
      if (release && typeof release === 'string' && RuleManager.getEntitledReleaseMap().has(release)) {
        hasEntry = true;
      }
      return hasEntry;
    }

    static isEntitledToUseRelease (release) {
      // TODO: Fix backend
      return true;
    /*
      let isEntitled = false;
      if (release && typeof release === 'string' && RuleManager.hasEntitlementEntry(release)) {
        isEntitled = RuleManager.getEntitledReleaseMap().get(release);
      }
      return isEntitled;
    */
    }

    static loadEntitlementReleaseMap (rules) {
      // make a map of release to entitled.
      RuleManager.setEntitledReleasePresent(false);
      RuleManager.getEntitledReleaseMap().clear();
      for (let i = 0; i < rules.length; i++) {
        RuleManager.getEntitledReleaseMap().set(rules[i].release, rules[i].entitled);
        if (rules[i].entitled) {
          RuleManager.setEntitledReleasePresent(true);
        }
      }
    }

    async getRules (useCache = true) {
      if (!useCache || !this._RULES_ARRAY) {
        try {
          this._RULES_ARRAY = await this.getDataService().ui.getRulesArrayByProduct(this.getProduct(), null, null, false, this.getShowAll());
        } catch (error) {
          Util.consoleLogError('initialize', error.message);
          this._RULES_ARRAY = [];
        }
        RuleManager.loadEntitlementReleaseMap(this._RULES_ARRAY);
      }
      return this._RULES_ARRAY;
    }

    getProduct () {
      return this.product;
    }

    async getRulesWithDefaults (product, ruleID, credTypeID) {
      let result = null;
      if (!ruleID) {
        let defaultRulesArray = [];
        try {
          defaultRulesArray = await this.getDataService().workflow.defaultRuleByProduct(product, credTypeID);
        } catch (error) {
          Util.consoleLogError('getRulesWithDefaults', error);
        }
        if (defaultRulesArray && Array.isArray(defaultRulesArray) && defaultRulesArray.length > 0) {
          ruleID = defaultRulesArray[0].id;
        }
      }
      if (product && ruleID && credTypeID) {
        const rulesArray = await this.getDataService().ui.getRulesArrayByProduct(product, ruleID, credTypeID, true);
        if (rulesArray && Array.isArray(rulesArray) && rulesArray.length > 0) {
          result = rulesArray[0];
        }
      }
      return result;
    }

    async getFeatureSet (useCache = true) {
      if (!useCache || !RuleManager._FEATURE_HIGHLIGHT_SET) {
        RuleManager._FEATURE_HIGHLIGHT_SET = await this.initializeFeatureHighlightSet();
      }
      return RuleManager._FEATURE_HIGHLIGHT_SET;
    }

    filterRules (searchCriteriaRuleInfo, ruleInfo) {
      if (!(searchCriteriaRuleInfo && typeof searchCriteriaRuleInfo === 'object')) {
        throw new TypeError("Invalid searchCriteriaRuleInfo argument");
      }
      if (!(ruleInfo && typeof ruleInfo === 'object')) {
        throw new TypeError("Invalid ruleInfo argument");
      }
      let isMatch = true;
      if (searchCriteriaRuleInfo.id) {
        isMatch = (searchCriteriaRuleInfo.id === ruleInfo.id);
      }
      if (isMatch) {
        for (let c in searchCriteriaRuleInfo) {
          if (searchCriteriaRuleInfo[c]) {
            if (typeof searchCriteriaRuleInfo[c] === 'object' && typeof ruleInfo[c] === 'object') {
              isMatch = Object.keys(searchCriteriaRuleInfo[c])[0] === Object.keys(ruleInfo[c])[0];
            } else if (typeof searchCriteriaRuleInfo[c] === 'string' && typeof ruleInfo[c] === 'object') {
              isMatch = Object.keys(ruleInfo[c]).includes(searchCriteriaRuleInfo[c]);
            } else {
              isMatch = searchCriteriaRuleInfo[c] === ruleInfo[c];
            }
          }
          if (!isMatch) {
            break;
          }
        }
      }
      return isMatch;
    }

    async searchRules (searchCriteriaRuleInfo, getCountOnly, column, useCache = true) {
      let results = [];
      let resultSet = new Set();
      let rulesArray = await this.getRules(useCache);
      let foundRules = rulesArray.filter(ruleInfo => this.filterRules(searchCriteriaRuleInfo, ruleInfo));
      if (foundRules.length) {
        for (let rule of foundRules) {
          if (column && typeof column === 'string' && column in rule) {
            let columnValue = rule[column];
            // Set does not ensure unique objects so, do our own check.
            if (typeof columnValue === 'object' && Object.keys(columnValue).length === 1) {
              let keyValue = Object.keys(columnValue)[0];
              let foundKey = false;
              let keys = resultSet.keys();
              let item = keys.next();
              while (!item.done) {
                if (Object.keys(item.value)[0] === keyValue) {
                  foundKey = true;
                  break;
                }
                item = keys.next();
              }
              if (!foundKey) {
                resultSet.add(columnValue);
              }
            } else {
              resultSet.add(columnValue);
            }
          } else if (!column) {
            // Set ensures unique primitive values
            resultSet.add(rule);
          }
        }
      }
      let iter = resultSet.values();
      let item = iter.next();
      while (!item.done) {
        results.push(item.value);
        item = iter.next();
      }
      if (getCountOnly) {
        let count = results.length;
        results = count;
      }
      return results;
    }

    extractIndividualFeatures (featureArray) {
      let extractedFeatures = [];
      for (let r of featureArray) {
        if (r) {
          if (r.indexOf(',') > 0) {
            let tmp = r.split(',');
            for (let t of tmp) {
              if (t) {
                if (!extractedFeatures.includes(t)) {
                  extractedFeatures.push(t);
                }
              }
            }
          } else {
            if (!extractedFeatures.includes(r)) {
              extractedFeatures.push(r);
            }
          }
        }
      }
      return extractedFeatures;
    }

    async initializeFeatureHighlightSet () {
      let searchCriteria = new RuleInfo();
      let rawFeatureHighlights = [];
      try {
        rawFeatureHighlights = await this.searchRules(searchCriteria, false, "featureHighlights", false);
      } catch (error) {
        Util.consoleLogError('initializeFeatureHighlightSet', error.message);
      }
      let processedHighlights = this.extractIndividualFeatures(rawFeatureHighlights);
      let featureSet = new Set();
      for (let ph of processedHighlights) {
        if (!featureSet.has(ph)) {
          featureSet.add(ph);
        }
      }
      return featureSet;
    }

    async getLostFeatures (searchCriteria, useCache = true) {
      let lostFeatures = [];
      const completeFeatures = (await this.getFeatureSet(useCache)).values();
      let rawCurrentFeatures = await this.searchRules(searchCriteria, false, "featureHighlights");
      let currentFeatures = this.extractIndividualFeatures(rawCurrentFeatures);
      let val = completeFeatures.next();
      while (!val.done) {
        let feature = val.value;
        if (!currentFeatures.includes(feature)) {
          lostFeatures.push(feature);
        }
        val = completeFeatures.next();
      }
      return lostFeatures;
    }

    hasCredentialFor (cloudPlatformName) {
      let count = 0;
      if (cloudPlatformName in this.platformCredentials) {
        for(const credentialTypeId in this.platformCredentials[cloudPlatformName]) {
          count += this.platformCredentials[cloudPlatformName][credentialTypeId].count;
        }
      }
      return count;
    }

    storePlatformCredentialInfo (cloudPlatformName, credentialTypeId, credentials = []) {
      const count = credentials.length;
      if (cloudPlatformName in this.platformCredentials) {
        this.platformCredentials[cloudPlatformName][credentialTypeId] = {
          count: count,
          credentials: credentials,
          timestamp: new Date().getTime()
        };
      }
    }

    storeCredentialTypeStats (credentialTypeId, credentials = []) {
      switch(credentialTypeId) {
        case CCWA_Constants.AMAZON_AWS_CREATE_ROLE_CREDENTIAL_ID:
        case CCWA_Constants.ALL_TOGETHER_NOW_CREDENTIAL_TYPE_ID:
        case CCWA_Constants.AMAZON_AWS_CFE_DEMOS_CREDENTIAL_ID:
            this.storePlatformCredentialInfo('aws', credentialTypeId, credentials);
          break;
        case CCWA_Constants.MICROSOFT_AZURE_CREATE_REFRESH_TOKEN_CREDENTIAL_ID:
        case CCWA_Constants.MICROSOFT_GRAPH_CREATE_REFRESH_TOKEN_CREDENTIAL_ID:
          this.storePlatformCredentialInfo('azure', credentialTypeId, credentials);
          break;
      }
    }

    getCredentialData (platform, credentialTypeId) {
      let credData = null;
      const platformData = this.platformCredentials[platform];
      if (platformData) {
        credData = platformData[credentialTypeId];
        if (typeof credData === 'undefined') {
          credData = null;
        }
      }
      return credData;
    }

    async getCredentials (platform, credentialTypeId) {
      let credentials = [];
      try {
        let credData = this.getCredentialData(platform, credentialTypeId);
        const timestamp = credData.timestamp;
        const now = new Date().getTime();
        if (Util.clientCachingIsEnabled() && (now - timestamp < CCWA_Constants.REFRESH_CREDENTIAL_INFO_INTERVAL)) {
          credentials = credData.credentials;
        } else {
          await this.listCredentialsByPlatformAndType(platform, credentialTypeId);
          credData = this.getCredentialData(platform, credentialTypeId);
          credentials = credData.credentials;
        }
      } catch (error) {
        Util.consoleLogError(`getCredentials ${platform} ${credentialTypeId}`, error);
        credentials = [];
      }
      return credentials;
    }

    async loadCredentialDataByPlatformAndCredentialType (checkSubscriptions = true) {
      let searchCriteria = new RuleInfo();
      let rules = [];
      try {
        rules = await this.searchRules(searchCriteria, false, null, false);
      } catch (error) {
        Util.consoleLogError('loadCredentialDataByPlatformAndCredentialType', error.message);
      }
      const uniqCombos = new Set();
      for (let rule of rules) {
        if (rule instanceof RuleInfo) {
          const keys = Object.keys(rule.getCredentialType());
          const credentialTypeId = keys[0];
          const platform = rule.getCloudPlatform();
          // Ignore invalid platform::credentialTypeId combinations
          const combo = `${platform}::${credentialTypeId}`;
          if (!Util.getValidPlatformCredentialTypeIdCombos().includes(combo)) {
            Util.consoleLogWarning('loadCredentialDataByPlatformAndCredentialType', `Illegal Rule ID: ${rule.id}, Platform: ${platform}, CredentialTypeID: ${credentialTypeId}`);
            continue;
          }
          uniqCombos.add(combo);
        }
      }
      for (const combo of uniqCombos) {
        const [platform, credentialTypeId] = combo.split('::');
        await this.listCredentialsByPlatformAndType(platform, credentialTypeId, checkSubscriptions);
      }
    }

    async listCredentialsByPlatformAndType (platform, credentialTypeId, checkSubscriptions = true) {
      let credentials = [];
      try {
        credentials = await this.getDataService().ui.listCredentials(platform, credentialTypeId, checkSubscriptions);
      } catch (error) {
        Util.consoleLogError('listCredentialsByPlatformAndType', error);
        credentials = [];
      }
      this.storeCredentialTypeStats(credentialTypeId, credentials);
    }

    async hasCredentials (platform = 'any', checkSubscriptions = true) {
      await this.initialize();
      let atLeastOneCredentialExists = false;
      await this.loadCredentialDataByPlatformAndCredentialType(checkSubscriptions);
      if (platform === 'any') {
        let totalCount = 0;
        const platforms = Object.keys(this.platformCredentials);
        for (const p of platforms) {
          const credTypeIds = Object.keys(this.platformCredentials[p]);
          for (const credTypeId of credTypeIds) {
            totalCount += this.platformCredentials[p][credTypeId].count;
          }
        }
        atLeastOneCredentialExists = (totalCount > 0);
      } else {
        atLeastOneCredentialExists = (this.hasCredentialFor(platform) > 0);
      }
      return atLeastOneCredentialExists;
    }

    static initializeRuleManagerProductMap (dataService) {
      if (! (dataService && typeof dataService === 'object' && dataService.ui
             && typeof dataService.ui.getRulesArrayByProduct === 'function')) {
        throw new TypeError("Invalid dataService argument");
      }
      RuleManager._PRODUCTS_RULE_MANAGER_MAP.clear();
      const productNameList = SupportedProducts.getProductNameList();
      for (let product of productNameList) {
        const ruleManager = new RuleManager({
          dataService: dataService,
          product: product
        });
        RuleManager._PRODUCTS_RULE_MANAGER_MAP.set(product, ruleManager);
      }
    }

    static getRuleManagerForProduct (product) {
      if (!product || typeof product !== 'string' || !SupportedProducts.getProductNameList().includes(product)) {
        throw new TypeError(`Invalid product argument: ${product}`);
      }
      const ruleManager = RuleManager._PRODUCTS_RULE_MANAGER_MAP.get(product);
      return ruleManager;
    }

  }
  // class static members
  Object.defineProperty(RuleManager, "_FEATURE_HIGHLIGHT_SET", {
    value: null,
    writable: true,
    configurable: true,
    enumerable: false
  });
  Object.defineProperty(RuleManager, "_ENTITLED_RELEASE_MAP", {
    value: new Map(),
    writable: true,
    configurable: true,
    enumerable: false
  });
  Object.defineProperty(RuleManager, "_ENTITLED_RELEASE_EXISTS", {
    value: false,
    writable: true,
    configurable: true,
    enumerable: false
  });
  Object.defineProperty(RuleManager, "_PRODUCTS_RULE_MANAGER_MAP", {
    value: new Map(),
    writable: false,
    configurable: false,
    enumerable: true
  });

  return RuleManager;
});
