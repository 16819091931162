/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "bootstrap",
  "templates/inputPromptDialogTemplate",
  "util",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string"
], function(_, $, Bootstrap, InputPromptDialogTemplate, Util, I18NStringResource, DojoString) {

  class InputPromptDialog {

    constructor (args) {
      if (!this.validateArgs(args)) {
        throw new TypeError("Invalid args");
      }
      this.dialogTitle = args.dialogTitle;
      this.explanation = args.explanation;
      this.actionButtonLabel = args.actionButtonLabel;
      this.cancelButtonLabel = args.cancelButtonLabel;
      this.actionFn = args.actionFn;
      this.inputArray = args.inputArray;
      this.hiddenArray = args.hiddenArray;
      this.className = "";
      // istanbul ignore next
      if (args.className && typeof args.className === 'string') {
        this.className = args.className;
      }
      // istanbul ignore next
      this.dduxLogger = { logData: () => {} };
      if (args.dduxLogger && typeof args.dduxLogger === 'object' && args.dduxLogger.logData && typeof args.dduxLogger.logData === 'function') {
        this.dduxLogger = args.dduxLogger;
      }
    }

    getModalContainer () { return document.querySelector('#modalContainer'); }

    getDialogElement () { return document.querySelector('div#inputPromptDialog'); }

    getErrorContainer () {
      let errContainer = null;
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        errContainer = dialogEl.querySelector('div.errorTextContainer');
      }
      return errContainer;
    }

    getErrorText () {
      let errText = null;
      const errorContainer = this.getErrorContainer();
      if (errorContainer) {
        errText = errorContainer.querySelector('p.errorText');
      }
      return errText;
    }

    // istanbul ignore next
    getInputsForm () { return document.querySelector('form[name="inputPromptForm"]'); }

    getNonHiddenInputs () {
      let nonHiddenInputs = [];
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        nonHiddenInputs = dialogEl.querySelectorAll('input:not([type="hidden"])');
      }
      return this.getNonHiddenInputs;
    }

    getActiveElement() { return document.activeElement; }

    getActionButton () {
      let actionButton;
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        actionButton = dialogEl.querySelector('button#actionButton');
      }
      return actionButton;
    }

    getCancelButton () {
      let cancelButton;
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        cancelButton = dialogEl.querySelector('button#cancelButton');
      }
      return cancelButton;
    }

    getDDUXLogger () { return this.dduxLogger; }

    validateArgs (args) {
      let isValid = false;
      if (args && typeof args === 'object') {
          isValid = true;
      }
      return isValid;
    }

    getFieldValues () {
      const form = this.getInputsForm();
      let formData;
      if (form) {
        formData = new FormData(form);
        const keys = formData.keys();
        let key = keys.next();
        while (!key.done) {
          const value = formData.get(key.value);
          const escapedValue = _.escape(value);
          formData.set(key.value, escapedValue);
          key = keys.next();
        }
      }
      return formData;
    }

    dragNotAllowed (event) {
       event.preventDefault();
       event.originalEvent.dataTransfer.dropEffect = 'none';
    }

    async doAction (event) {
      if (event && event.currentTarget) {
        Util.logDDUXinfoFromClickEvent(event, this.getDDUXLogger());
      }
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        const actionButton = this.getActionButton();
        const errorContainer = this.getErrorContainer();
        const errorText = this.getErrorText();
        try {
          actionButton.disabled = true;
          actionButton.classList.add("disabled");
          await this.actionFn(this.getFieldValues());
          const modal = Bootstrap.Modal.getInstance(dialogEl);
          if (modal) {
            modal.hide();
          }
          if (this.className) {
            $.event.trigger(`${this.className}_DoAction:ccwa`);
          }
        } catch (error) {
          if (errorContainer && errorContainer.style) {
            errorContainer.style.visibility = "visible";
          }
          if (typeof error === 'string') {
            errorText.textContent = error;
          } else {
            errorText.textContent = I18NStringResource.inputPromptDialogActionError;
          }
        } finally {
          actionButton.classList.remove("disabled");
          actionButton.disabled = false;
        }
      }
    }

    clearError () {
      const errorContainer = this.getErrorContainer();
      if (errorContainer) {
        const errorText = this.getErrorText();
        if (errorText) {
          errorText.innerHTML = "";
        }
        if (errorContainer.style) {
           errorContainer.style.visibility = 'hidden';
        }
      }
    }

    setFocusHandler (input) {
      input.addEventListener('focus', this.clearError.bind(this));
    }

    setup () {
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        dialogEl.querySelectorAll('form input').forEach(this.setFocusHandler.bind(this));
        dialogEl.querySelectorAll('form textarea').forEach(this.setFocusHandler.bind(this));
        const actionButton = this.getActionButton();
        // istanbul ignore next
        actionButton.addEventListener('click', async function() {await this.doAction();}.bind(this));
        dialogEl.addEventListener('keydown', this.onKeydown.bind(this));
        const inputs = this.getNonHiddenInputs();
        if (inputs && inputs.length > 0) {
          const firstInput = inputs[0];
          firstInput.focus();
        }
      }
    }

    disposeOfModal() {
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        const modal = Bootstrap.Modal.getOrCreateInstance(dialogEl);
        modal.dispose();
      }
    }

    show () {
      const html = InputPromptDialogTemplate({
        dialogTitle: this.dialogTitle,
        explanation: this.explanation,
        actionButtonLabel: this.actionButtonLabel,
        cancelButtonLabel: this.cancelButtonLabel,
        hiddenArray: this.hiddenArray,
        inputArray: this.inputArray
      });
      const modalContainer = this.getModalContainer();
      if (modalContainer) {
        modalContainer.innerHTML = html;
        Util.clearAllBootstrapBackdrops();
        const dialogEl = this.getDialogElement();
        if (dialogEl) {
          if (this.className) {
            dialogEl.classList.add(this.className);
          }
          dialogEl.addEventListener('shown.bs.modal', this.setup.bind(this));
          dialogEl.addEventListener('hidden.bs.modal', this.disposeOfModal.bind(this));
          dialogEl.addEventListener('dragover', this.dragNotAllowed);
          const modal = new Bootstrap.Modal(dialogEl, {backdrop: 'static'});
          modal.show();
        }
      }
    }

    onKeydown (event) {
      if (event) {
        const code = event.keyCode || event.which;
        if (code == 13) { // Enter key
          if (event.preventDefault) {
            event.preventDefault();
          }
          let focusedElement = this.getActiveElement();
          if (focusedElement &&
              focusedElement.tagName.toUpperCase() === "BUTTON" && (
                focusedElement.className === 'close'
                || focusedElement.id === "cancelButton" ||
                focusedElement.id === "actionButton"
              )
          ) {
            focusedElement.click();
          }
        }
      }
    }

  }

  return InputPromptDialog;
});
