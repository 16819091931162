/* jshint esversion: 8 */
define([
    "util"
], function(
  Util
) {

  /**
   * OAuthService handles all oauth-specific interaction with Cloud Center server via DAO.
   */
  class OAuthService {

    constructor (args) {
        if (!args || typeof args !== "object" || !args.dao) {
          throw new TypeError("Invalid args argument");
        }
        this.dao = args.dao;
        this.azureNetworkData = {}
    }

    getDAO () { return this.dao; }

    /****
      API calls to OAuth service
    ****/
    async initPopupURL (credentialTypeId) {
      Util.consoleLogTrace("oauth.initPopupURL", "called");
      if (!credentialTypeId || typeof credentialTypeId !== "string") { // TODO: convert to isMD5 when changes available
        throw new TypeError("Invalid credentialTypeId argument");
      }
      return await this.getDAO().getCCAPI("oauth", "type", credentialTypeId, "url", undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
    }

    async initInlineURL (credentialTypeId) {
      Util.consoleLogTrace("oauth.initInlineURL", "called");
      if (!credentialTypeId || typeof credentialTypeId !== "string") { // TODO: convert to isMD5 when changes available
        throw new TypeError("Invalid credentialTypeId argument");
      }
      return await this.getDAO().getCCAPI("oauth", "type", credentialTypeId, "url", {redirect: `${window.location.href}`}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
    }

    async list (credentialTypeId) {
      Util.consoleLogTrace("oauth.list", "called");
      if (!credentialTypeId || typeof credentialTypeId !== "string") { // TODO: convert to isMD5 when changes available
        throw new TypeError("Invalid credentialTypeId argument");
      }
      return await this.getDAO().getCCAPI("oauth", "type", credentialTypeId, undefined, undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});

    }

    async editCredential (credentialId, description) {
      Util.consoleLogTrace("oauth.editCredential", "called");
      let credentialIdValid = (credentialId && typeof credentialId === 'string' && Util.isMD5(credentialId));
      if (!credentialIdValid) {
        throw new TypeError("Invalid credentialId argument");
      }
      if (typeof description !== 'string') {
        throw new TypeError("Invalid description argument");
      }
      let params = {"description": description};
      return await this.getDAO().putCCAPI("oauth", "resource", credentialId, null, params, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
    }

    async get (credentialId) {
      Util.consoleLogTrace("oauth.get", "called");
      let credentialIdValid = (credentialId && typeof credentialId === 'string' && Util.isMD5(credentialId));
      if (!credentialIdValid) {
        throw new TypeError("Invalid credentialId argument");
      }
      let credData = await this.getDAO().getCCAPI("oauth", "resource", `${credentialId}/metadata`, undefined, undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
      if (credData && credData.msg) {
        credData = credData.msg;
      }
      return credData;
    }

    async delete (credentialId) {
      Util.consoleLogTrace("oauth.delete", "called");
      let credentialIdValid = (credentialId && typeof credentialId === 'string' && Util.isMD5(credentialId));
      if (!credentialIdValid) {
        throw new TypeError("Invalid credentialId argument");
      }
      return await this.getDAO().deleteCCAPI("oauth", "resource", credentialId, undefined, undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
    }

    /* istanbul ignore next */
    openOAuthInline (url) {
      window.location.href = url;
    }

  }
  return OAuthService;
});
