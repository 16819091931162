/* jshint esversion: 8 */
define([
    "jquery",
    "util",
    "service/general/adminService",
    "dojo/i18n!nls/cloudCenterStringResource",
    "dojo/string"
], function( $, Util, AdminService,
            I18NStringResource, DojoString) {

    /**
     * IAMService handles AWS iam-specific interaction with Cloud Center server via DAO.
     */
    class IAMService {

        constructor (args) {
            if (!args || typeof args !== "object" || !args.dao) {
                throw new TypeError("Invalid args argument");
            }
            this.dao = args.dao;
            this.admin = new AdminService({dao: this.dao});
        }

        getDAO () { return this.dao; }
        getAdminService () { return this.admin; }

        async list (credentialTypeId) {
            Util.consoleLogTrace("IAMService.list", "called");
            if (!credentialTypeId || typeof credentialTypeId !== "string") { // TODO: convert to isMD5 when changes available
                throw new TypeError("Invalid credentialTypeId argument");
            }
            return await this.getDAO().getCCAPI("awsiam", "type", credentialTypeId, undefined, undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async createRole (credentialTypeId, accessKeyId, secretAccessKey, sessionToken) {
            Util.consoleLogTrace("IAMService.createRole", "called");
            if (!credentialTypeId || typeof credentialTypeId !== "string") { // TODO: convert to isMD5 when changes available
                throw new TypeError("Invalid credentialTypeId argument");
            }
            if (!accessKeyId || typeof accessKeyId !== 'string') {
                throw new TypeError("Invalid accessKeyId argument");
            }
            if (!secretAccessKey || typeof secretAccessKey !== 'string') {
                throw new TypeError("Invalid secretAccessKey argument");
            }
            let params = {"access-key-id":accessKeyId, "secret-access-key": secretAccessKey};
            if (sessionToken && typeof sessionToken !== 'string') {
                throw new TypeError("Invalid sessionToken argument");
            }
            if (sessionToken) {
                params["session-token"] = sessionToken;
            }
            return await this.getDAO().postCCAPI("awsiam", "type", credentialTypeId, null, params, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async getType (credentialTypeId) {
            Util.consoleLogTrace("IAMService.getType", "called");
            if (!credentialTypeId || typeof credentialTypeId !== "string") { // TODO: convert to isMD5 when changes available
                throw new TypeError("Invalid credentialTypeId argument");
            }
            return await this.getDAO().getCCAPI("awsiam", "type", credentialTypeId, "policy", undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async getExternalID (credentialTypeId) {
            Util.consoleLogTrace("IAMService.getExternalID", "called");
            if (!credentialTypeId || typeof credentialTypeId !== "string") { // TODO: convert to isMD5 when changes available
                throw new TypeError("Invalid credentialTypeId argument");
            }
            return await this.getDAO().getCCAPI("awsiam", "type", credentialTypeId, "externalid", undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async editCredential (credentialId, description) {
            Util.consoleLogTrace("IAMService.editCredential", "called");
            if (!credentialId || typeof credentialId !== "string") { // TODO: convert to isMD5 when changes available
                throw new TypeError("Invalid credentialId argument");
            }
            if (typeof description !== 'string') {
                throw new TypeError("Invalid description argument");
            }
            let params = {"description": description};
            return await this.getDAO().putCCAPI("awsiam", "resource", credentialId, null, params, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async importRole (credentialTypeId, roleARN, description = "") {
            Util.consoleLogTrace("IAMService.importRole", "called");
            if (!credentialTypeId || typeof credentialTypeId !== "string") { // TODO: convert to isMD5 when changes available
                throw new TypeError("Invalid credentialTypeId argument");
            }
            if (!roleARN || typeof roleARN !== 'string') {
                throw new TypeError("Invalid roleARN argument");
            }
            if (description && typeof description !== 'string') {
                throw new TypeError("Invalid description argument");
            }
            const slashIndex = roleARN.lastIndexOf("/") + 1;
            if (slashIndex > 0 && slashIndex < roleARN.length && (!description || !description.trim())) {
                description = roleARN.slice(slashIndex).trim();
                if (description.length > 50) {
                    description = description.substring(0,50);
                }
            }
            let params = {"role-arn":roleARN, "description":description};
            return await this.getDAO().putCCAPI("awsiam", "type", credentialTypeId, null, params, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async get (credentialId) {
            Util.consoleLogTrace("IAMService.get", "called");
            let credentialIdValid = (credentialId && typeof credentialId === 'string' && Util.isMD5(credentialId));
            if (!credentialIdValid) {
                throw new TypeError("Invalid credentialId argument");
            }
            return await this.getDAO().getCCAPI("awsiam", "resource", credentialId, undefined, undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async delete (credentialId) {
            Util.consoleLogTrace("IAMService.delete", "called");
            let credentialIdValid = (credentialId && typeof credentialId === 'string' && Util.isMD5(credentialId));
            if (!credentialIdValid) {
                throw new TypeError("Invalid credentialId argument");
            }
            return await this.getDAO().deleteCCAPI("awsiam", "resource", credentialId, undefined, undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        ccAccountInfo () {
            return Promise.resolve({accountId: this.getDAO().getAWSAccountID()});
        }

    }
    return IAMService;
});
