define ([
  "underscore",
  "jquery",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "util"
], function (_, $,I18NStringResource, DojoString, Util) {

  class Countdown {
    constructor(fnStop, countdownSelector, endDate, maxHours, refreshSeconds = 15) {
      if (!countdownSelector || typeof countdownSelector !== "string" ) {
        throw new TypeError("Invalid CountdownTemplate argument");
      }
      if (!endDate || Object.prototype.toString.call(endDate) !== "[object Date]" || isNaN(endDate)) {
        throw new TypeError("Invalid end date argument");
      }
      if (!maxHours || isNaN(maxHours) || maxHours < 0) {
        throw new TypeError("Invalid max hours argument");
      }
      if (!refreshSeconds || isNaN(refreshSeconds) || refreshSeconds < 0) {
        throw new TypeError("Invalid refreshSeconds argument");
      }

      this.setCountdown(countdownSelector);
      if (!this.getText()) {
        throw new TypeError("Invalid CountdownTemplate argument: no text");
      }

      this.maxHours = maxHours;
      this.refreshSeconds = refreshSeconds;
      this.fnStop = fnStop;

      // Calc the start date
      this.endDate = endDate;
      this.startDate = new Date(this.endDate.getTime());
      this.startDate.setHours(this.startDate.getHours() - this.maxHours);
      this.startTime = this.startDate.getTime();

      this.endTime = this.endDate.getTime();


      // Calc the total seconds
      this.totalSeconds = (this.getEndTime() - this.getStartTime()) / 1000;
      this.stopService = true;

    }

    start () {
      this.stopService = false;
      this.updateText();
    }

    stop () {
      this.stopService = true;
    }

    isStopped () {
      return this.stopService === true;
    }

    updateText (){
      let remaining = this.getRemainingSeconds();
      var date = new Date(0);
      date.setSeconds(remaining);
      if (this.getText()) {
        this.getText().textContent = `${date.toISOString().substr(11, 8)} ${I18NStringResource.terminationPolicyTimeoutRemaining}`;
      }

      // updated timers change dataset.timerId such that the old timer can stop
      if (this.getCountdown() && !this.stopService && remaining >0 ) {
        setTimeout(function() {this.updateText()}.bind(this), this.getRefreshSeconds()*1000);
      }
      if (remaining == 0 && !this.stopService) {
        this.stopService = true;
        this.stopMachine();
      }

    }

    setCountdown (countdownSelector) {
      this.countdown = countdownSelector;
    }
    getCountdown () {
      let countdown = document.querySelector(this.countdown);
      return countdown;
    }
    getText () {
      let textSpan = null;
      if (this.getCountdown()) {
        textSpan = this.getCountdown().querySelector('span.countdown');
      }
      return textSpan;
    }
    getMaxHours () {
      return this.maxHours;
    }
    getRefreshSeconds () {
      return this.refreshSeconds;
    }
    setRefreshSeconds (refreshSeconds) {
      this.refreshSeconds = refreshSeconds;
    }
    getStartTime () {
      return this.startTime;
    }
    getEndDate () {
      return this.endDate;
    }
    getEndTime () {
      return this.endTime;
    }
    getTotalSeconds () {
      return this.totalSeconds;
    }
    getRemainingSeconds () {
      let remaining = (this.getEndTime() - (new Date()).getTime()) / 1000;
      if (remaining < 0) {
        remaining = 0;
      }
      return remaining;
    }
    getElapsedSeconds () {
      return this.getTotalSeconds() - this.getRemainingSeconds();
    }
    stopMachine() {
      if (this.fnStop) {
        this.fnStop();
      }
    }
  }
  return Countdown;
});
