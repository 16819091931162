define([
  "underscore",
  "jquery",
  "bootstrap",
  "templates/warningDialogTemplate",
  "util"
], function(_, $, Bootstrap, WarningDialogTemplate, Util) {

  class WarningDialog {

    constructor (args) {
      if (!this.validateArgs(args)) {
        throw new TypeError("Invalid args");
      }
      this.dialogTitle = args.title;
      this.warningText = args.text;
      this.actionButtonLabel  = args.actionButtonLabel;
      this.closeButtonLabel = args.closeButtonLabel;
      this.actionFn = args.actionFn;
      // istanbul ignore next
      this.hideIcon = args.hideIcon || false;
      // istanbul ignore next
      this.showProceedPrompt = args.showProceedPrompt || false;
      // istanbul ignore next
      this.proceedPrompt = args.proceedPrompt || "";
      this.modalContainerId = `#${args.modalContainerId}`;
      this.classname = args.classname;
      this.dialogSelector = `${this.modalContainerId} div.modal.${this.classname}`;
    }

    getModalContainer () { return document.querySelector(this.modalContainerId); }

    getDialogElement () { return document.querySelector(this.dialogSelector); }

    getWarningActionButton () {
      let warningActionButton;
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        warningActionButton = dialogEl.querySelector('button#warningActionButton');
      }
      return warningActionButton;
    }

    validateArgs (args) {
      let isValid = false;
      if (args && typeof args === 'object') {
        if (
          (args.title && typeof args.title === 'string') &&
          (args.text && typeof args.text === 'string') &&
          (args.actionButtonLabel && typeof args.actionButtonLabel === 'string') &&
          (args.closeButtonLabel && typeof args.closeButtonLabel === 'string') &&
          (args.actionFn && typeof args.actionFn === 'function') &&
          (args.modalContainerId && typeof args.modalContainerId === 'string') &&
          (args.classname && typeof args.classname === 'string')
        ) {
          isValid = true;
        }
      }
      return isValid;
    }

    dragNotAllowed (event) {
       event.preventDefault();
       event.originalEvent.dataTransfer.dropEffect = 'none';
    }

    doAction () {
      $.event.trigger("logdduxevent:ccwa", {elementId: 'warningActionButton', elementType: 'button'});
      try {
        this.actionFn();
      } catch (error) {
        Util.consoleLogError("doAction", error);
      } finally {
        const modal = Bootstrap.Modal.getInstance(this.getDialogElement());
        if (modal) {
          modal.hide();
        }
      }
    }

    show () {
      const html = WarningDialogTemplate({
        warningHeader: this.dialogTitle,
        warningText: this.warningText,
        hideIcon: this.hideIcon,
        showProceedPrompt: this.showProceedPrompt,
        proceedPrompt: this.proceedPrompt,
        actionButtonLabel: this.actionButtonLabel,
        closeButtonLabel: this.closeButtonLabel,
        classList: `\"modal ${this.classname}\"`
      });
      const modalContainer = this.getModalContainer();
      if (modalContainer) {
        modalContainer.innerHTML = html;
        Util.clearAllBootstrapBackdrops();
        const dialogEl = this.getDialogElement();
        if (dialogEl) {
          // istanbul ignore next
          dialogEl.addEventListener('shown.bs.modal', function() {
            $("#warningActionButton").focus();
          });
          // istanbul ignore next
          dialogEl.addEventListener('hidden.bs.modal', function() {
            const dialogEl = this.getDialogElement();
            if (dialogEl) {
              const modal = Bootstrap.Modal.getOrCreateInstance(dialogEl);
              modal.dispose();
            }
          }.bind(this));
          dialogEl.addEventListener('dragover', this.dragNotAllowed);
          const warningActionButton = this.getWarningActionButton();
          warningActionButton.addEventListener('click', this.doAction.bind(this));
          dialogEl.addEventListener('keydown', this.onKeydown.bind(this));
          const modal = new Bootstrap.Modal(dialogEl, {backdrop: 'static'});
          modal.show();
        }
      }
    }

    getFocusedElement() { return document.activeElement; }

    onKeydown(event) {
      // istanbul ignore next
      const code = event.keyCode || event.which;
      if (event.stopPropagation) {
        event.stopPropagation();
      }
      if (code == 13) { // Enter key
        if (event.preventDefault) {
          event.preventDefault();
        }
        let focusedElement = this.getFocusedElement();
        if (focusedElement &&
             focusedElement.tagName.toUpperCase() === "BUTTON" && (
              focusedElement.className === 'close'
              || focusedElement.id === "warningCloseButton" ||
              focusedElement.id === "warningActionButton"
            )
        ) {
          focusedElement.click();
        }
      }
    }

  }

  return WarningDialog;
});
