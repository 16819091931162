/* Copyright 2020 The MathWorks, Inc. */
define([
    'mw-progress-indicator/enums/TypeEnum',
    'mw-progress-indicator/enums/SizeEnum'
], function (TypeEnum, SizeEnum) {
    'use strict';

    /**
         * ProgressIndicatorUtil is an internal class used for validation
         * specific to the progress indicator component.
         *
         */
    class ProgressIndicatorUtils {
        static _isHexaColor (hexaColor) {
            return /^#([0-9A-F]{3}){1,2}$/i.test(hexaColor);
        }

        static validate (value, property) {
            switch (property) {
                case 'size':
                    let sizes = Object.values(SizeEnum);
                    if ((typeof value !== 'string') || !sizes.includes(value.toLowerCase())) {
                        throw new Error('Value of ' + property + ' property should be xsmall/small/medium/large/xlarge');
                    }
                    break;
                case 'type':
                    if ((typeof value !== 'string') || !(value.toLowerCase() === TypeEnum.SPINNER)) {
                        throw new Error('Value of ' + property + ' property should be spinner');
                    }
                    break;
                case 'value':
                    if (isNaN(parseFloat(value)) || (parseFloat(value) < 0 || parseFloat(value) > 100)) {
                        throw new Error('Value of ' + property + ' property should be Number between 0-100');
                    }
                    break;
                case 'color':
                    let isValidColor = ProgressIndicatorUtils._isHexaColor(value);
                    if ((typeof value !== 'string') || !isValidColor) {
                        throw new Error('Value of ' + property + ' css should be in valid supported css color in hex code');
                    }
                    break;
                case 'customSize':
                    if (isNaN(value) || parseFloat(value) < 0 || value === null) {
                        throw new Error('Value of ' + property + '  should be a Number greater than 0');
                    }
                    break;
                case 'border':
                    if ((value === undefined || !(value.toString() === 'false' || value.toString() === 'true'))) {
                        throw new Error('Value of ' + property + '  should be a true or false value');
                    }
                    break;
            }
        }

        static addStyles (node, styleList) {
            if (node && styleList && Object.keys(styleList).length > 0) {
                Object.keys(styleList).forEach((style) => { node.style[style] = styleList[style]; });
            }
        }

        static removeStyles (node, styleList) {
            if (node && styleList && Array.isArray(styleList) && styleList.length > 0) {
                const currentStyle = (node.getAttribute('style') || '');
                let currentStyleList = currentStyle.split(';');
                styleList.forEach((style) => {
                    currentStyleList = currentStyleList.filter((currStyl) => currStyl.trim().startsWith(style) === false);
                });
                currentStyleList = currentStyleList.filter((currStyl) => currStyl.trim().length > 0);
                const newList = currentStyleList.join(';');
                node.setAttribute('style', newList);
            }
        }

        static addAttributes (node, attributeList) {
            if (node && attributeList && Object.keys(attributeList).length > 0) {
                Object.keys(attributeList).forEach((attr) => node.setAttribute(attr, attributeList[attr]));
            }
        }

        static removeAttributes (node, attributeList) {
            if (node && attributeList && Array.isArray(attributeList) && attributeList.length > 0) {
                attributeList.forEach((attr) => { node.hasAttribute(attr) && node.removeAttributes(attr); });
            }
        }
    }
    return ProgressIndicatorUtils;
});
