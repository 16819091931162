define([
    "dojo/_base/declare",
    "dojo/_base/lang",

    "mw-log/Log",

    "./EventTrigger",
    "./MessageTrigger",
    "./RemoteTrigger",
    "./TimeoutTrigger",
    "./Transition"
], function (declare, lang, Log, EventTrigger, MessageTrigger, RemoteTrigger, TimeoutTrigger,
    Transition) {

    var State;
    State = declare([], {

        constructor: function (args) {
            this.stateMachine = args.stateMachine || {
                _changeState: function () {
                },
                onEnterState: function () {
                },
                onExitState: function () {
                }
            };

            this.namespace = args.namespace || "";
            this.name = args.name || this.getDefaultName();
            this.context = args.context || {};
            this.data = args.data || {};
            this.composition = args.composition || "XOR";
            this.defaultSubstate = args.defaultSubstate || "";
            this.constructSubstates(args.substates);
            this.constructTriggersAndTransitions(args.transitions);
            this.active = args.active || false;
        },

        getDefaultName: function () {
            var nameParts, name;
            if (this.namespace.length > 0) {
                nameParts = this.namespace.split(".");
                name = nameParts.pop(); // last element
            } else {
                Log.warn("State: state name is not specified.");
            }

            return name || "";
        },

        constructSubstates: function (substates) {
            var newSubstates = {}, name, substate;

            for (name in substates) {
                if (substates.hasOwnProperty(name)) {
                    substate = substates[name];
                    if (substate.name && substate.name !== name) {
                        Log.error("State: error constructing substate: " + name +
                            ", the specified names don't match: " + substate.name);
                    }
                    substate.name = name;
                    substate.namespace =
                        substate.namespace || [this.namespace, this.name].join(".");
                    substate.stateMachine = substate.stateMachine || this.stateMachine;
                    substate.context = substate.context || this.context;

                    newSubstates[substate.name] = new State(substate);
                }
            }

            this.substates = newSubstates;
        },

        constructTriggersAndTransitions: function (transitions) {
            var triggerName, newTransitions = {},
                newTriggers = [], newTrigger;

            for (triggerName in transitions) {
                if (transitions.hasOwnProperty(triggerName)) {

                    newTrigger = this.constructTrigger(triggerName);
                    if (newTrigger) {
                        // only add new triggers if the trigger is required
                        newTriggers.push(newTrigger);
                    }

                    newTransitions[triggerName] =
                        this.constructTransition(triggerName, transitions[triggerName]);
                }
            }

            this.transitions = newTransitions;
            this.triggers = newTriggers;
        },

        constructTrigger: function (triggerName) {
            var nameParts, newTrigger, TriggerConstructor;

            nameParts = triggerName.split(" ");

            if (nameParts.length === 2) {
                if (nameParts[0] === "subscribe") {
                    TriggerConstructor = MessageTrigger;
                } else if (nameParts[0] === "response" || nameParts[0] === "fault") {
                    TriggerConstructor = RemoteTrigger;
                } else if (nameParts[0] === "timeout") {
                    TriggerConstructor = TimeoutTrigger;
                }

            } else if (triggerName !== "onEnterState" || triggerName !== "onExitState") {
                // onEnterState and onExitState are no-ops
                // these are internally generated so we don't need trigger objects for them
                TriggerConstructor = EventTrigger;
            }

            if (TriggerConstructor) {
                newTrigger = new TriggerConstructor({
                    name: triggerName,
                    context: this.context,
                    triggerHandler: lang.hitch(this, this.handleTrigger)
                });
            }
            return newTrigger;
        },

        constructTransition: function (triggerName, transition) {
            var action, nextState;

            if (lang.isFunction(transition) || lang.isString(transition) ||
                    lang.isArray(transition)) {

                if (triggerName === "onEnterState" || triggerName === "onExitState") {
                    action = transition;
                    transition = {};
                    transition.action = action;
                    transition.target = "";
                } else {
                    nextState = transition;
                    transition = {};
                    transition.target = nextState;
                }
            }
            transition.state = this;

            return new Transition(transition);
        },

        enter: function (nextState, otherArguments) {
            if (!this.active) {
                this.active = true;
                this.enableTriggers();
                this.stateMachine.onEnterState(this.name);
                this.handleTrigger.apply(this, ["onEnterState"].concat(otherArguments));
            }
            if (nextState) {
                this.changeSubstates(nextState, otherArguments);
            } else {
                this.changeToDefaultSubstate(otherArguments);
            }
        },

        exit: function () {
            var active = this.activeSubstates();

            this.active = false;
            // disable triggers before exiting substates to help prevent event loops
            this.disableTriggers();

            active.forEach(function (substate) {
                substate.exit();
            });

            this.handleTrigger("onExitState");

            this.stateMachine.onExitState(this.name);
        },

        enableTriggers: function () {
            this.triggers.forEach(function (trigger) {
                trigger.enable();
            });
        },

        disableTriggers: function () {
            this.triggers.forEach(function (trigger) {
                trigger.disable();
            });
        },

        handleTrigger: function (triggerName) {
            var nextState, otherArguments;
            if (this.transitions[triggerName]) {
                otherArguments = Array.prototype.slice.call(arguments, 1, arguments.length);
                nextState =
                    this.transitions[triggerName].handleTrigger(triggerName, otherArguments);

                // only change state if the transition specified a new state.
                // this prevents a loop in onExitState.
                if (nextState) {

                    // if the trigger function returns something other than a string,
                    // pull out the arguments property and use it as the arguments for the next
                    // state.
                    if (!lang.isString(nextState)) {
                        otherArguments = nextState.args;
                        nextState = nextState.target;
                    }

                    this.stateMachine._changeState(nextState, otherArguments);
                }
            }
        },

        changeSubstates: function (nextState, otherArguments) {
            var active = this.activeSubstates();

            if (this.substates[nextState]) {
                if (active.length >= 0 && active[0].name !== nextState) {
                    active.forEach(function (substate) {
                        substate.exit();
                    });
                }

                this.substates[nextState].enter(undefined, otherArguments);
            }
        },

        changeToDefaultSubstate: function (otherArguments) {
            if (this.activeSubstates().length === 0 && this.substates[this.defaultSubstate]) {
                this.substates[this.defaultSubstate].enter(undefined, otherArguments);
            }
        },

        activeSubstates: function () {
            var active = [], name;
            for (name in this.substates) {
                if (this.substates.hasOwnProperty(name) && this.substates[name].active) {
                    active.push(this.substates[name]);
                }
            }
            return active;
        }
    });

    return State;
});