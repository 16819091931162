/* jshint esversion: 8 */
define([
    "underscore",
    "jquery",
    "backbone",
    "authentication/loginui/mwLoginPageManager",
    "util",
    "dao/cloudCenterDao",
    "dao/cloudCenterFetchDao",
    "dao/mockDao"
  ], function( _, $, Backbone, LoginPageManager, Util, CloudCenterDAO, CloudCenterFetchDAO, MockDAO) {

    const _amdClosureArgs = {
      "CloudCenterDAO": CloudCenterDAO,
      "CloudCenterFetchDAO": CloudCenterFetchDAO,
      "MockDAO": MockDAO
    };

    class AuthManager {

      constructor (options, config) {
        // config should be provide and have a getMicroServiceURL method -- if not, throw exception
        if (!config || typeof config != 'object' || !config.getMicroServiceURL || !config.getDAOClassName || !config.getAWSAccountID) {
          throw new Error("Config parameter missing or incorrect. Unable to configure Cloud Center Microservice connection.");
        }
        this.config = config;
        this.logoutURL = this.config.getLogoutURL();
        this.authContainerId = options.authContainerId; // html element's id attribute value
        this.appContainerId = options.appContainerId;
        this.loginIframeId = options.loginIframeId; // html element's id attribute value
        this.loginTextContainerId = options.loginTextContainerId;
        this.loginFooterId = options.loginFooterId;
        this.loginLinkAreaId = options.loginLinkAreaId;
        // The embedded login manager
        this.loginManager = new LoginPageManager({iframeId: this.loginIframeId,
                                                  authContainerId: this.authContainerId,
                                                  appContainerId: this.appContainerId,
                                                  loginTextContainerId: this.loginTextContainerId,
                                                  loginFooterId: this.loginFooterId,
                                                  loginLinkAreaId: this.loginLinkAreaId},
                                                  this.config);
        this.sessionStatusListener = {...{}, ...Backbone.Events};

        /*
         * The  Data Access Object (DAO) used to communicate with the backend service
         */
        this.dao = null;
        this.dao = (function (classname, config) {
          const p = _amdClosureArgs[classname];
          if (!p) {
            throw new Error('Unable to instantiate DAO of class ' + classname);
          }
          const dao = new p(config);
          if (!dao) {
            throw new Error('Unable to instantiate DAO of class ' + classname);
          }
          return dao;
        })(this.config.getDAOClassName(), this.config);

        this.activeDAO = this.getDAO();
        // bind 'this' in the listed methods to the current 'this', even when called by other modules.
        _.bindAll(this, "authTokenExpiredHandler", "updateLoginInformation");
      }

      getDAO () {
        return this.dao;
      }
      setDAO (dao) {
        this.dao = dao;
      }
      getActiveDAO () {
        return this.activeDAO;
      }
      setActiveDAO (dao) {
        this.activeDAO = dao;
      }
      useHtmlLocalStorage () {
        return this.getDAO().useHtmlLocalStorage();
      }
      getLoginUIManager () {
        return this.loginManager;
      }
      authTokenExpiredHandler () {
        this.clearLoginInformation();
        $.event.trigger('exitApplication:ccwa');
      }

      start () {
        this.sessionStatusListener.bind("AUTH_TOKEN_EXPIRED", this.authTokenExpiredHandler);
        this.getDAO().setSessionListener(this.sessionStatusListener);
      }
      stop () {
        this.sessionStatusListener.unbind(); // all events
      }
      hide () {
        this.getLoginUIManager().hide();
      }
      isValidSession () {
        return (this.getSessionId());
      }
      isLoggedIn () {
        return (this.getSessionId());
      }
      getLogoutURL () { return this.logoutURL; }
      /**
       * Setter for the auth token received from the login service
       * We store it as a local member for our current browser session.
       */
      setAuthToken (token) {
        // in-memory only
        this.getDAO().setAuthToken(token);
      }

      /**
       * Getter for the auth token received from the login service.
       */
      getAuthToken () {
        return this.getDAO().getAuthToken();
      }
      /**
       * Delete stored value of auth token
       */
      removeAuthToken () {
        if (this.useHtmlLocalStorage()) {
          localStorage.removeItem("authToken");
        }
        this.getDAO().setAuthToken(null);
      }
      /**
       * Getter for the login user profile data received from the login service.
       */
      getLoginData () {
        return this.getDAO().getLoginData();
      }
      /**
       * Delete stored value of login data (profile)
       */
      removeLoginData () {
        if (this.useHtmlLocalStorage()) {
          localStorage.removeItem("loginData");
        }
        this.getDAO().setLoginData(null);
      }
      /**
       * Setter for the login profile data received from the login service
       */
      setLoginData (data) {
        this.getDAO().setLoginData(data);
      }

      /**
       * Setter for the session ID received from Cloud Center Microservice
       */
      setSessionId (sessionId) {
        this.getDAO().setSessionId(sessionId);
      }
      /**
       * Getter for the session ID received from Cloud Center Microservice
       */
      getSessionId () {
        return this.getDAO().getSessionId();
      }
      /**
       * Delete stored value of Session ID
       */
      removeSessionId () {
        if (this.useHtmlLocalStorage()) {
          localStorage.removeItem("sessionId");
        }
        this.getDAO().setSessionId(null);
      }
      /**
       * Store all session/user info received from MW Login
       */
       updateLoginInformation (sessionRenewalData) {
         this.getDAO().updateLoginInformation(sessionRenewalData);
       }
       clearLoginInformation () {
         this.getDAO().clearLoginInformation();
       }
      /**
       * Used by event listener callback for "loginmgr:loginsuccess" events.
       * Store the user profile data and the auth token
       */
      notifyAccountLoginSuccess (data) {
        if (data && data.token) {
          this.setLoginData(data);
          if (data.accessToken) {
            this.setAuthToken(data.accessToken); // V2 embeddedLoginWS
          } else {
            this.setAuthToken(data.token); // V1 embeddedLoginWS
          }
          // Login is done so, stop listening to the login manager
          this.validateLogin();
        } else {
          throw new Error("notifyAccountLoginSuccess called with invalid login data.");
        }
      }

      async validateLogin () {
        let result;
        try {
          result = await this.getDAO().validateLogin(this.getAuthToken()); //sometimes mwa_session cookie hasn't been set by first login, so trigger it early
          await this.validateLoginSuccess(result);
          return;
        } catch (error) {
          Util.consoleLogWarning('validateLogin first attempt', error);
        }
        this.clearLoginValidationError();
        try {
          result = await this.getDAO().validateLogin(this.getAuthToken());
          await this.validateLoginSuccess(result);
        } catch (error) {
          Util.consoleLogWarning('validateLogin 2nd attempt', error);
          await this.validateLoginFailure(error);
        }
      }

      async validateLoginSuccess (result) {
        if (result && typeof result === "object" && result.logged_in) {
          let loginData = {
            firstName: result.first_name,
            lastName: result.last_name
          };
          this.setLoginData(loginData);
          this.setSessionId(true);
        } else {
          await this.validateLoginFailure();
        }
      }

      async validateLoginFailure (error) {
        this.clearLoginInformation();
        this.setSessionId(false);
        await this.logout();
        throw error;
      }

      clearLoginValidationError () {
        $('#loginHandshakeErrorText').empty();
        $('#loginHandshakeErrorContainer').hide();
      }

      /**
       * Show the login page
       */
      startLogin (clientString, errorText) {
        const deferred = Util.generateDeferredPromise();
        const deferredPromise = deferred.promise;
        this.loginPromise = deferred;
        this.getLoginUIManager().start(clientString, errorText);
        return deferredPromise;
      }
      /**
       * Clear saved session ID and auth token.
       * Hide the app container
       * re-show the embedded login form
       */
      async logout (keepLoad) {
        await this.getDAO().logout();
        this.removeSessionId();
        this.removeAuthToken();
        this.removeLoginData();
        if (this.config.getDAOClassName() !== "MockDAO") {
          window.location.href = this.getLogoutURL();
        }
      }

    }
    return AuthManager;
  }); // require
