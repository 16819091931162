/* jshint esversion: 8 */
define ([
  "underscore",
  "jquery",
  "wizard/sequenceMapElement",
  "util"
], function(_, $, SequenceMapElement, Util) {

  class InputSequenceMap {

    constructor () {
      this.sequenceMap = new Map();
    }

    getMap () { return this.sequenceMap; }

    set (sequenceMapElement) {
      if (!SequenceMapElement.isValid(sequenceMapElement)) {
        throw new TypeError("Invalid sequenceMapElement argument");
      }
      this.getMap().set(sequenceMapElement.key, sequenceMapElement);
    }

    get (key) {
      if (!key || typeof key !== 'string') {
        throw new TypeError("Invalid key argument");
      }
      return this.getMap().get(key);
    }

    has (key) {
      if (!key || typeof key !== 'string') {
        throw new TypeError("Invalid key argument");
      }
      return this.getMap().has(key);
    }

    async enableNextSetOfInputs (targetId) {
      if (!targetId || typeof targetId !== 'string') {
        throw new TypeError("Invalid targetId argument");
      }
      const sequenceMap = this.getMap();
      if (targetId && sequenceMap.has(targetId)) {
        let selectors;
        selectors = await sequenceMap.get(targetId).adjacentSelectors();
        let enableDisableData = await sequenceMap.get(targetId).getAdjacentData();
        for (let sel of selectors) {
          let element = document.querySelector(sel);
          if (element) {
            let elementDisabled = false;
            let options = element.getElementsByTagName("option");
            let hasHiddenOption = true;
            if (options && options.length >= 1) {
              hasHiddenOption = options[0].hidden;
            }
            if ((hasHiddenOption && options.length===2) || options.length===1) {
              elementDisabled = true;
            } else if (sel in enableDisableData) {
              elementDisabled = enableDisableData[sel];
            }
            element.disabled = elementDisabled;
            if (elementDisabled) {
              element.classList.add("disabled");
            } else {
              element.classList.remove("disabled");
            }
          }
        }
      }
    }

    async disableAllInputsFollowingTargetId (targetId) {
      if (!targetId || typeof targetId !== 'string') {
        throw new TypeError("Invalid targetId argument");
      }
      const sequenceMap = this.getMap();
      if (targetId && sequenceMap.has(targetId)) {
        // disable the adjacent items temporarily, not setting defaults.
        let adjacentSelectors = await sequenceMap.get(targetId).adjacentSelectors();
        for (let adjSel of adjacentSelectors) {
          let element = document.querySelector(adjSel);
          if (element) {
            element.disabled = true;
            element.classList.add("disabled");
          }
        }
        // disable all following items, setting their defaults
        let id = sequenceMap.get(targetId).nextSequenceStep;
        while (id && sequenceMap.has(id)) {
          sequenceMap.get(id).setDefaults.bind(this)();
          let selectors = await sequenceMap.get(id).adjacentSelectors();
          for (let sel of selectors) {
            let element = document.querySelector(sel);
            if (element) {
              element.disabled = true;
              element.classList.add("disabled");
            }
          }
          id = sequenceMap.get(id).nextSequenceStep;
        }
        // signal the sequence is not done yet.
        $.event.trigger("sequenceincomplete:ccwa");
      }
    }

  }

  return InputSequenceMap;
});
