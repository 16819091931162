/* jshint esversion: 8 */
define([
    "util",
    "constants",
    "service/platform/platformDataService",
    "service/general/oauthService",
    "service/general/mathworksService",
    "service/datatransform/uiElementUtil",
    "dojo/i18n!nls/cloudCenterStringResource",
    "dojo/string"
], function(
    Util,
    CCWA_Constants,
    PlatformDataService,
    OAuthService,
    MathworksService,
    UIElementUtil,
    I18NStringResource,
    DojoString
) {

  /**
   * AzureDataService handles all Azure-specific interaction with Cloud Center server via DAO.
   */
  class AzureDataService extends PlatformDataService {

    constructor (args) {
        super(args);
        this.oauth = new OAuthService({dao: this.getDAO()});
        this.mathworksService = new MathworksService({dao: this.getDAO()});
        this.azureNetworkData = {}
    }

    getOAuthService () { return this.oauth; }
    setAzureNetworkData (index, value) { this.azureNetworkData[index] = value; }
    getAzureNetworkData (index) { return this.azureNetworkData[index]; }
    getMathworksService() { return this.mathworksService; }

    /*
     * Required API
    */

    async getDependentOptionFields (queryType, options = {}) {
        const optionFields = {
            display: undefined,
            value: undefined
        };
        if (UIElementUtil.QUERY_TYPES.includes(queryType)) {
            switch (queryType) {
                case "azure_resource_group":
                    optionFields.display = "";
                    optionFields.value = "";
                    break;
                case "azure_vnet":
                case "azure_network":
                    optionFields.display = ["Name"];
                    optionFields.value = "ID";
                    break;
                case "azure_subnet":
                    optionFields.display = ["Name", "Properties.AddressPrefix"];
                    optionFields.value = "Name";
                    break;
                case "azure_vmsize":
                    optionFields.display = "Name";
                    optionFields.value = "Name";
                    break;
                case "azure_region":
                    optionFields.display = "";
                    optionFields.value = "";
                    break;
                case "azure_mwa_license_entitlement":
                    optionFields.display = ["id", "label"];
                    optionFields.value = "id";
                    break;
                default:
            }
        }
        return optionFields;
      }
      
    async findMachineType (credId, rulesId, machineType) { 
        let accountId;
        let subscriptionId;
        ({accountId, subscriptionId} = Util.splitCredIdForAzure(credId));
        
        const accountIdValid = (accountId && typeof accountId === 'string');
        if (!accountIdValid) {
            throw new TypeError("Invalid accountId argument");
        }
        const subscriptionIdValid = (subscriptionId && typeof subscriptionId === 'string');
        if (!subscriptionIdValid) {
            throw new TypeError("Invalid subscriptionId argument");
        }
        const rulesIdValid = (rulesId && typeof rulesId === 'string');
        if (!rulesIdValid) {
            throw new TypeError("Invalid rulesId argument");
        }
        const machineTypeValid = (machineType && typeof machineType === 'string');
        if (!machineTypeValid) {
            throw new TypeError("Invalid machineTypeValid argument");
        }
        return this.getDAO().getCCAPI("workflow", "resource", rulesId, "machine-type-availability", {"credential_id":accountId, "subscription_id":subscriptionId, "desired_machine_type":machineType, refresh:"false"}, false);
    }

    async getDependentOptionData (queryType, credId, options = {}) {
        let optionData;
        if (UIElementUtil.QUERY_TYPES.includes(queryType)) {
            let accountId;
            let subscriptionId;

            switch (queryType) {
                case "azure_resource_group":
                    ({accountId, subscriptionId} = Util.splitCredIdForAzure(credId));
                    optionData = await this.getResourceGroup(accountId, subscriptionId);
                    break;
                case "azure_vnet":
                case "azure_network":
                    ({accountId, subscriptionId} = Util.splitCredIdForAzure(credId));
                    optionData = await this.getVirtualNetwork(accountId, subscriptionId);

                    // Store this data to retrieve subnet information
                    this.setAzureNetworkData(`${accountId} | ${subscriptionId}`, optionData.msg);
                    break;
                case "azure_subnet":
                    if (!("vpc" in options)) {
                        throw new TypeError("Invalid options.vpc argument");
                    }
                    ({accountId, subscriptionId} = Util.splitCredIdForAzure(credId));
                    // Subnet information already received along with virtual network data
                    // retrieve this from "azureNetworkData"
                    const azureNetworkList = this.getAzureNetworkData(`${accountId} | ${subscriptionId}`);
                    for (const vnet of Object.values(azureNetworkList)) {
                        if(vnet["ID"] === options.vpc) {
                            optionData = {}
                            optionData.msg = vnet.Properties.Subnets
                            break;
                        }
                    }
                    break;
                case "azure_vmsize":
                    ({accountId, subscriptionId} = Util.splitCredIdForAzure(credId));
                    optionData = await this.getVMSize(accountId, subscriptionId, options.location);
                    break;
                case "azure_region":
                    ({accountId, subscriptionId} = Util.splitCredIdForAzure(credId));
                    optionData = await this.getRegion(accountId, subscriptionId);
                    break;
                case "azure_mwa_license_entitlement":
                    if (options && options.release && options.product) {
                        optionData = await this.getMathworksService().getMWEntitlements(options.product, options.release);
                    }
                    break;
                default:
            }
        }
        return optionData;
    }

    async renderCredentialList (credentialId, popText, description, data = null) {
        let subscriptions;
        try {
            const subscriptionData = await this.getSubscriptions(credentialId);
            subscriptions = subscriptionData.msg;
        } catch (error) {
            Util.consoleLogWarning(`renderCredentialList`, error);
            subscriptions = [];
        }
        popText = `<h2 class="credPopoverh2">${I18NStringResource.credPopoverAzureAccount}</h2>
                <p class="credPopoverP">${description}</p>
                <h3 class="credPopoverh3">${I18NStringResource.credPopoverAzureSubscriptions}</h3>`;
        popText += `<ul class="credPopoverUl">`;
        if (!subscriptions.length) {
        popText += `              <li><div class="credPopoverWarningIcon"></div><span>${I18NStringResource.credPopoverAzureNoSubscriptions}</span></li>`;
        popText += `              <li>${I18NStringResource.credPopoverAzureAddSubscription}</li>`;
        }
        subscriptions.sort(Util.sortMultipleAttr("DisplayName"));
        for (const subscription of subscriptions) {
        popText += `              <li>${subscription.DisplayName}</li>`;
        }
        popText += `</ul>
                <p class="credPopoverP"><placeholder/></p=><p class="credPopoverP">
                <a href="https://portal.azure.com/#home" rel="noopener noreferrer" target="_blank">
                ${I18NStringResource.credPopoverAzureManageSubscriptions}
                </a>
                </p>`;
        return popText;
    }

    async listCredentials (credentialTypeId, includeSubscriptions = true) {
        let rawData = {};
        Util.consoleLogTrace("listCredentials", "getting server data");
        if (! (credentialTypeId && typeof credentialTypeId === 'string')) {
            throw new TypeError("Invalid credentialTypeId argument");
        }
        try {
          const accountRawData = await this.getOAuthService().list(credentialTypeId);
          // We now have a list of Azure accounts that the user has authorized.
          // In Azure, a single account can have multiple subscriptions.
          // Get list of the subscriptions for each account, and use that information in rawData

          //using accountId:subscriptionId as key
          for (const [accountId, data] of Object.entries(accountRawData)) {
            let subscriptions = {msg:[{SubscriptionID:"", DisplayName:data.account}]};
            if (includeSubscriptions) {
              try {
                subscriptions = await this.getSubscriptions(accountId)
                if(!(subscriptions && subscriptions.msg && subscriptions.msg.length > 0)) {
                  //TODO vn: handle no subscriptions case
                }
              } catch (error) {
                Util.consoleLogWarning('listCredentials', error);
                continue;
              }
            }
            for(const subscription of Object.values(subscriptions.msg)) {
              const account = `${accountId}:${subscription.SubscriptionID}`
              rawData[account] = {
                ...data,
                ...subscription,
                account,
                description: `${data.description} : ${subscription.DisplayName} : ${data.account}`,
                accountId
              }
            }
          }
        } catch (error) {
          throw new Error(DojoString.substitute(I18NStringResource.dataServiceErrorServerErrorWithInfo, [error.message]));
        }
        return rawData;
    }

    async deleteCredential (credentialId, credTypeId, configs = []) {
        const platform = Util.getPlatformFromCredentialTypeId(credTypeId);
        if (platform !== "azure") {
            throw new TypeError(I18NStringResource.credentialsPageErrorCannotFindCredentialTypeID);
        }
        // See if credential is in use by CC2 resources and fail if it is
        let names = await Util.collectExistingConfigNames(configs);
        if (names) {
          throw new Error(DojoString.substitute(I18NStringResource.credentialsPageUnableToDelete, [names]));
        }
        // Made it to here, do credential delete depending on what credential this is
        switch (credTypeId) {
          case CCWA_Constants.MICROSOFT_AZURE_CREATE_REFRESH_TOKEN_CREDENTIAL_ID:
          case CCWA_Constants.MICROSOFT_GRAPH_CREATE_REFRESH_TOKEN_CREDENTIAL_ID:
            return await this.getOAuthService().delete(credentialId);
          default:
            throw new TypeError(I18NStringResource.credentialsPageErrorCannotFindCredentialTypeID);
        }
    }

    async getCredential (credentialId) {
        if (!credentialId || typeof credentialId !== 'string') {
            throw new TypeError("Invalid credentialId argument");
        }
        return await this.getOAuthService().get(credentialId);
    }

    async updateCredentialDescription (credentialId, credentialTypeId, description) {
        if (!credentialId || typeof credentialId !== 'string') {
            throw new TypeError("Invalid credentialId argument");
        }
        if (!credentialTypeId || typeof credentialTypeId !== 'string') {
            throw new TypeError("Invalid credentialTypeId argument");
        }
        if (typeof description !== 'string') {
            throw new TypeError("Invalid description argument");
        }
        const platform = Util.getPlatformFromCredentialTypeId(credentialTypeId);
        if (platform !== "azure") {
            throw new TypeError(I18NStringResource.credentialsPageErrorCannotFindCredentialTypeID);
        }
        try {
            switch(credentialTypeId) {
            case CCWA_Constants.MICROSOFT_AZURE_CREATE_REFRESH_TOKEN_CREDENTIAL_ID:
            case CCWA_Constants.MICROSOFT_GRAPH_CREATE_REFRESH_TOKEN_CREDENTIAL_ID:
                await this.getOAuthService().editCredential(credentialId, description);
                break;
            default:
                throw new TypeError(I18NStringResource.credentialsPageErrorCannotFindCredentialTypeID);
            }
        } catch (error) {
            const errMsg = error.message || JSON.stringify(error);
            throw new Error(DojoString.substitute(I18NStringResource.credentialsPageErrorUpdateFailed, [errMsg]));
        }
    }

    async createCredential (createCredArgs) {
        let oauthURLResp = await this.getOAuthService().initInlineURL(CCWA_Constants.MICROSOFT_AZURE_CREATE_REFRESH_TOKEN_CREDENTIAL_ID);
        this.getOAuthService().openOAuthInline(oauthURLResp.url);
    }

    async importCredential (importCredArgs) {
        return; // do nothing
    }

  /****
      Private API calls to Azure service
    ****/

    async getSubscriptions (accountId) {
        const accountIdValid = (accountId && typeof accountId === 'string');
        if (!accountIdValid) {
            throw new TypeError("Invalid accountId argument");
        }
        return this.getDAO().getCCAPI("azure", "resource", accountId, "subscription", {refresh: false}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
    }

    getResourceGroup (accountId, subscriptionId) {
        const accountIdValid = (accountId && typeof accountId === 'string');
        if (!accountIdValid) {
            throw new TypeError("Invalid accountId argument");
        }
        const subscriptionIdValid = (subscriptionId && typeof subscriptionId === 'string');
        if (!subscriptionIdValid) {
            throw new TypeError("Invalid subscriptionId argument");
        }
        return this.getDAO().getCCAPI("azure", "resource", accountId, "resourcegroup", {"subscription_id":subscriptionId, refresh: false}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
    }

    createResourceGroup (accountId, subscriptionId, region, resourcegroupName) {
        const accountIdValid = (accountId && typeof accountId === 'string');
        if (!accountIdValid) {
            throw new TypeError("Invalid accountId argument");
        }
        const subscriptionIdValid = (subscriptionId && typeof subscriptionId === 'string');
        if (!subscriptionIdValid) {
            throw new TypeError("Invalid subscriptionId argument");
        }
        const regionValid = (region && typeof region === 'string');
        if (!regionValid) {
            throw new TypeError("Invalid region argument");
        }
        const resourcegroupNameValid = (resourcegroupName && typeof resourcegroupName === 'string');
        if (!resourcegroupNameValid) {
            throw new TypeError("Invalid resourcegroupName argument");
        }
        return this.getDAO().postCCAPI("azure", "resource", accountId, "resourcegroup", {"subscription_id":subscriptionId, "location": region, "resource_group_name": resourcegroupName}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
    }

    getRegion (accountId, subscriptionId) {
        // Azure name: location
        const accountIdValid = (accountId && typeof accountId === 'string');
        if (!accountIdValid) {
            throw new TypeError("Invalid accountId argument");
        }
        const subscriptionIdValid = (subscriptionId && typeof subscriptionId === 'string');
        if (!subscriptionIdValid) {
            throw new TypeError("Invalid subscriptionId argument");
        }
        return this.getDAO().getCCAPI("azure", "resource", accountId, "location", {"subscription_id":subscriptionId, refresh: false}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
    }

    getVMSize (accountId, subscriptionId, region) {
        // Azure name: vmsize
        const accountIdValid = (accountId && typeof accountId === 'string');
        if (!accountIdValid) {
            throw new TypeError("Invalid accountId argument");
        }
        const subscriptionIdValid = (subscriptionId && typeof subscriptionId === 'string');
        if (!subscriptionIdValid) {
            throw new TypeError("Invalid subscriptionId argument");
        }
        const regionValid = (region && typeof region === 'string');
        if (!regionValid) {
            throw new TypeError("Invalid region argument");
        }
        return this.getDAO().getCCAPI("azure", "resource", accountId, "vmsize", {"subscription_id":subscriptionId,"location": region, refresh: false}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
    }

    getVirtualNetwork (accountId, subscriptionId) {
        const accountIdValid = (accountId && typeof accountId === 'string');
        if (!accountIdValid) {
            throw new TypeError("Invalid accountId argument");
        }
        const subscriptionIdValid = (subscriptionId && typeof subscriptionId === 'string');
        if (!subscriptionIdValid) {
            throw new TypeError("Invalid subscriptionId argument");
        }
        return this.getDAO().getCCAPI("azure", "resource", accountId, "virtualnetwork", {"subscription_id":subscriptionId, refresh: false}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
    }

    getSubnet (accountId, subscriptionId, resourcegroupName, virtualNetworkName) {
        const accountIdValid = (accountId && typeof accountId === 'string');
        if (!accountIdValid) {
            throw new TypeError("Invalid accountId argument");
        }
        const subscriptionIdValid = (subscriptionId && typeof subscriptionId === 'string');
        if (!subscriptionIdValid) {
            throw new TypeError("Invalid subscriptionId argument");
        }
        const resourcegroupNameValid = (resourcegroupName && typeof resourcegroupName === 'string');
        if (!resourcegroupNameValid) {
            throw new TypeError("Invalid resourcegroupName argument");
        }
        const virtualNetworkNameValid = (virtualNetworkName && typeof virtualNetworkName === 'string');
        if (!virtualNetworkNameValid) {
            throw new TypeError("Invalid virtualNetworkName argument");
        }
        return this.getDAO().getCCAPI("azure", "resource", accountId, "subnet", {"subscription_id":subscriptionId, "resource_group_name": resourcegroupName, "virtual_network_name": virtualNetworkName, refresh: false}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
    }

    async getAccountIdFromConfig (config) {
        let accountId = "";
        if (config && typeof config === 'object') {
            if (config.params && typeof config.params === 'object') {
                accountId = config.params["mw-subscription-id"];
            }
        }
        return accountId;
    }

    async getCredentialDataFromAccountId (accountId, credentialTypeId, userCredentials) {
        const desiredCredentialData = {};
        let desiredCredentialFound = false;
        if (!accountId || typeof accountId !== 'string') {
            throw new TypeError("Invalid accountId argument");
        }
        if (!credentialTypeId || typeof credentialTypeId !== 'string') {
            throw new TypeError("Invalid credentialTypeId argument");
        }
        if (!userCredentials || typeof userCredentials !== 'object') {
            throw new TypeError("Invalid userCredentials argument");
        }
        for(const key in userCredentials) {
          if (desiredCredentialFound) {
            break;
          }
          const credentialData = userCredentials[key];
          if (Util.getPlatformFromCredentialPlatform(credentialData.platform) !== 'azure') {
            continue;
          }
          if ("account" in credentialData) {
            const azureAcct = credentialData.account;
            const subscriptionData = await this.getSubscriptions(key);
            if (subscriptionData && subscriptionData.msg) {
              const subscriptions = subscriptionData.msg;
              for (const subscription of subscriptions) {
                if (subscription.SubscriptionID === accountId && credentialData["credtype-id"] === credentialTypeId) {
                    desiredCredentialData.credentialId = key;
                    desiredCredentialData.subscriptionId = subscription.SubscriptionID;
                    desiredCredentialData.credentialTypeId = credentialData["credtype-id"];
                    desiredCredentialData.account = azureAcct;
                    desiredCredentialData.platform = Util.getPlatformFromCredentialPlatform(credentialData.platform);
                    desiredCredentialFound = true;
                    break;
                }
              }
            }
          }
        }
        return desiredCredentialData;
    }

  }
  return AzureDataService;
});