/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "backbone",
  "templates/createMatlabWizardSummary",
  "wizard/uiGenerator",
  "service/datatransform/dependentOptionInfo",
  "service/datatransform/credentialInfo",
  "util",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string"
], function (_, $, Backbone, SummaryTemplate, UIGenerator, DependentOptionInfo,
  CredentialInfo, Util, I18NStringResource, DojoString) {

  class CreateMATLABWizardSummary extends Backbone.View {

    constructor(args) {
      super(args);
      this.events = {
        "click div#summaryButtons button#saveConfigButton": "onSave",
        "click div#summaryButtons button#cancelSummaryButton": "onCloseSummary",
        "click div#summaryButtons button#launchConfigButton": "onLaunch",
        "click div.wizardSummary a.sectionExpander": "toggleSection",
        "click button#editButton": "onEdit"
      };
      this.template = SummaryTemplate;
      this.validateParams(args); // will throw if not valid
      this.configId = args.configId || "";
      this.productName = args.resourceType || 'matlab';
      this.closeParentMethod = args.closeParentMethod;
      this.saveParentMethod = args.saveParentMethod;
      this.startParentMethod = args.startParentMethod;
      this.editParentMethod = args.editParentMethod;
      this.configValuesMap = args.configValuesMap;
      this.autoComplete = (args.autoComplete === true);
      this.licenseRequired = (args.licenseRequired === true);
      this.uiResults = args.uiResults;
      this.dduxLogger = args.dduxLogger;
      this.setElement(Util.createWizardStepDisposableElement("stepSummaryWrapper"));
      _.bindAll(this, "onSave", "onCloseSummary", "onEdit");
    }

    /*
     * selector-based element getters
     */
    getNameContainer() { return this.el.querySelector('div#wizardCompleteSummary.wizardSummary dl.wizardCompleteSummaryList dd.configValue.mlNameInput'); }
    getSummaryContainer() { return this.el.querySelector("div#wizardCompleteSummary.wizardSummary"); }
    getSummaryList() { return this.el.querySelector('div#wizardCompleteSummary.wizardSummary dl.wizardCompleteSummaryList'); }
    getSaveConfigButton() { return this.el.querySelector('button#saveConfigButton'); }
    getStartConfigButton() { return this.el.querySelector('button#launchConfigButton'); }
    getAdditionalDetailContainer() { return this.el.querySelector("div.additionalDetailContainer > div.generatedCode"); }
    getShortAdditionalDetailContainer() { return this.el.querySelector("div.additionalDetailContainer > div.shortAddtionalDetail > p"); }

    /*
     * Getters and Setters
     */
    getName() {
      let name = "";
      const nameContainer = this.getNameContainer();
      if (!nameContainer) {
        Util.consoleLogWarning("onEdit", "Unable to find nameContainer selector \"div.wizardSummary > div.header > h1\"");
      }
      if (nameContainer && nameContainer.textContent) {
        name = nameContainer.textContent;
      }
      return name;
    }

    getDDUXLogger() { return this.dduxLogger; }

    getConfigId() { return this.configId; }

    getProductName() { return this.productName; }

    getValuesMap() { return this.configValuesMap; }

    getCloseParentMethod() { return this.closeParentMethod; }

    getSaveParentMethod() { return this.saveParentMethod; }

    getStartParentMethod() { return this.startParentMethod; }

    getEditParentMethod() { return this.editParentMethod; }

    isAutoCompleteOn() { return this.autoComplete; }
    setAutoComplete(value) { this.autoComplete = (value === true); }

    isLicenseRequired() { return this.licenseRequired; }

    getUIResults() { return this.uiResults; }

    /*
     * Validation
     */
    validateParams(args) {
      if (!(args && typeof args === 'object')) {
        throw new TypeError("Invalid args object");
      }
      if (!(("closeParentMethod" in args) && typeof args.closeParentMethod === 'function')) {
        throw new TypeError("Invalid closeParentMethod argument");
      }
      if (!(("saveParentMethod" in args) && typeof args.saveParentMethod === 'function')) {
        throw new TypeError("Invalid saveParentMethod argument");
      }
      if (!(("editParentMethod" in args) && typeof args.editParentMethod === 'function')) {
        throw new TypeError("Invalid editParentMethod argument");
      }
      if (!(("configValuesMap" in args) && args.configValuesMap instanceof Map)) {
        throw new TypeError("Invalid configValuesMap argument");
      }
    }

    disableSummaryButtons(disable) {
      try {
        let startBtn = document.getElementById('launchConfigButton');
        let saveBtn = document.getElementById('saveConfigButton');
        let editBtn = document.getElementById('editButton');
        let cancelBtn = document.getElementById('cancelSummaryButton');
        const btns = [startBtn, saveBtn, cancelBtn, editBtn];
        for (const btn of btns) {
          if (btn) {
            btn.disabled = disable;
            if (disable) {
              btn.classList.add("disabled");
            } else {
              btn.classList.remove("disabled");
            }
          }
        }
      } catch (error) {
        //page could be closing or buttons could be otherwise gone
      }
    }
    goToDetails(event) {
      if (event && event.preventDefault) { event.preventDefault(); }
      let btn;
      if (event && event.target) {
        btn = event.target;
        if (btn) {
          if (btn.disabled) {
            this.disableSummaryButtons(true);
            return;
          }
        }
      }
      this.disableSummaryButtons(true);
      Util.notify('NORMAL', `Go to details for ${this.configId}...`);
      $.event.trigger("changetoresourcedetailpage:ccwa", { path: `${Util.convertProductToUserFriendlyProduct(this.getProductName())}/${this.getConfigId()}` });
    }


    async onSave(event) {
      if (event && event.currentTarget) {
        Util.logDDUXinfoFromClickEvent(event, this.getDDUXLogger());
      }
      document.body.classList.remove('additionalDetailsShowing');
      if (this.isAutoCompleteOn()) {
        if (event && event.preventDefault) { event.preventDefault(); }
        if (event && event.target) {
          let btn = event.target;
          if (btn) {
            if (btn.disabled) {
              this.disableSummaryButtons(true);
              return;
            }
          }
        }
        this.disableSummaryButtons(true);
        try {
          this.configId = await this.getSaveParentMethod()();
          let data = (this.getConfigId()) ? { configId: this.getConfigId() } : null;
          this.getCloseParentMethod()(event, data);
          this.remove();
        } catch (err) {
          this.disableSummaryButtons(false);
          Util.notify("ERROR", DojoString.substitute(I18NStringResource.cmlWizardSummaryInvalidValues, [Util.convertWizardErrorJSONToString(err)]));
        }
      } else {
        this.goToDetails(event);
      }
    }

    async onLaunch(event) {
      document.body.classList.remove('additionalDetailsShowing');
      if (event && event.preventDefault) { event.preventDefault(); }
      let btn;
      if (event && event.currentTarget) {
        Util.logDDUXinfoFromClickEvent(event, this.getDDUXLogger());
      }
      if (event && event.target) {
        btn = event.target;
        if (btn) {
          if (btn.disabled) {
            this.disableSummaryButtons(true);
            return;
          }
        }
      }
      this.disableSummaryButtons(true);
      if (this.isAutoCompleteOn()) {
        try {
          this.configId = await this.getSaveParentMethod()();
          await this.getStartParentMethod()(this.configId);
          let values = this.getValuesMap();
          let configName = values.get("mlNameInput").displayValue;
          let data = (this.getConfigId()) ? { configId: this.getConfigId() } : null;
          this.getCloseParentMethod()(event, data);
          this.remove();
        } catch (err) {
          this.disableSummaryButtons(false);
          Util.notify("ERROR", DojoString.substitute(I18NStringResource.cmlWizardSummaryInvalidValues, [Util.convertWizardErrorJSONToString(err)]));
        }
      }
    }

    onCloseSummary(event) {
      if (event && event.currentTarget) {
        Util.logDDUXinfoFromClickEvent(event, this.getDDUXLogger());
      }
      document.body.classList.remove('additionalDetailsShowing');
      if (event && event.preventDefault) { event.preventDefault(); }
      let btn;
      if (event && event.target) {
        btn = event.target;
        if (btn) {
          if (btn.disabled) {
            this.disableSummaryButtons(true);
            return;
          }
        }
      }
      this.disableSummaryButtons(true);

      try {
        this.el.style.display = 'none';
        let data = (this.getConfigId()) ? { configId: this.getConfigId() } : null;
        this.getCloseParentMethod()(event, data);
        this.remove();
      } catch (error) {
        if (btn) {
          btn.disabled = false;
          btn.classList.remove("disabled");
        }
        Util.notify("ERROR", DojoString.substitute(I18NStringResource.cmlWizardSummaryInvalidValues, [Util.convertWizardErrorJSONToString(error)]));
      }
    }

    onEdit(event) {
      if (event && event.preventDefault) { event.preventDefault(); }
      if (event && event.currentTarget) {
        Util.logDDUXinfoFromClickEvent(event, this.getDDUXLogger());
      }
      this.setAutoComplete(false);
      const summaryContainer = this.getSummaryContainer();
      if (summaryContainer) {
        summaryContainer.style.display = 'none';
      } else {
        Util.consoleLogWarning("onEdit", "Unable to find summaryContainer selector \"div#wizardCompleteSummary.wizardSummary\"");
      }
      let values = this.getValuesMap();
      let cloudChoice = "";
      if (values && values.get("cloudChoice")) {
        cloudChoice = values.get("cloudChoice").displayValue;
      }
      this.getEditParentMethod()({
        name: "",
        cloudChoice: cloudChoice
      });
      this.remove();
    }

    notify(severity, message) {
      $.event.trigger("message:ccwa", { message: message, severity: severity });
    }

    async renderStep() {
      this.render();
      // async method
      await this.initializeStep();
    }

    showWizardSummary(configId) {
      const list = this.getSummaryList();
      if (list) {
        // cleanup any prior content
        while (list.lastChild) {
          list.lastChild.remove();
        }
        this.createSummaryListItems(list, [
          "mlNameInput",
          "releaseSelector",
          "cloudChoice",
          "credentialSelector",
          "osChoice",
          "cloudLocationSelector"
        ]);
      } else {
        Util.consoleLogWarning("showWizardSummary", "Unable to find list selector \"div#wizardCompleteSummary.wizardSummary dl.wizardCompleteSummaryList\"");
      }
      const saveConfigButton = this.getSaveConfigButton();
      if (!saveConfigButton) {
        Util.consoleLogWarning("showWizardSummary", "Unable to find saveConfigButton selector \"button#saveConfigButton\"");
      }
      if (saveConfigButton && (this.configId || this.isAutoCompleteOn())) {
        saveConfigButton.classList.remove("disabled");
        saveConfigButton.disabled = false;
      }
      const launchButton = this.getStartConfigButton();
      if (!launchButton) {
        Util.consoleLogWarning("showWizardSummary", "Unable to find launchButton selector \"button#launchConfigButton\"");
      }
      if (launchButton && (this.configId || this.isAutoCompleteOn())) {
        launchButton.classList.remove("disabled");
        launchButton.disabled = false;
      }
      const wizardSummary = this.getSummaryContainer();
      if (wizardSummary) {
        wizardSummary.style.display = 'block';
        window.scrollTo(0, 0);
      } else {
        Util.consoleLogWarning("showWizardSummary", "Unable to find summaryContainer selector \"div#wizardCompleteSummary.wizardSummary\"");
      }
    }

    createSummaryListItems(list, selectorList) {
      let values = this.getValuesMap();
      if (selectorList && Array.isArray(selectorList)) {
        selectorList.forEach(selector => {
          let dt = document.createElement('dt');
          dt.classList.add("col-sm-3", "configValueLabel");
          let dd = document.createElement('dd');
          dd.classList.add("col-sm-9", "configValue");
          dd.classList.add(selector);
          let value = values.get(selector).displayValue;
          dt.textContent = I18NStringResource["cmlWizardSummaryAttributeLabel_" + selector];
          dd.textContent = value;
          list.appendChild(dt);
          list.appendChild(dd);
        });
      }
    }

    createExtrasListItem(list, selectorList) {
      let parent = list.parentElement;
      let extrasLabel = document.createElement('span');
      extrasLabel.classList.add("configValueLabel");
      extrasLabel.textContent = I18NStringResource.cmlWizardSummaryAdditionalAttributes;
      parent.appendChild(extrasLabel);
      let extrasList = document.createElement('dl');
      extrasList.classList.add("dl-horizontal", "extras");
      parent.appendChild(extrasList);
      this.createSummaryListItems(extrasList, selectorList);
    }

    setDefaultFocus() {
      let launchButton = this.getStartConfigButton();
      if (launchButton) {
        launchButton.focus();
      } else {
        Util.consoleLogWarning("showWizardSummary", "Unable to find launchButton selector \"button#launchConfigButton\"");
      }
    }

    async render() {
      let values = this.getValuesMap();
      // let mlName = values.get("mlNameInput").displayValue;
      let templateParameters = {};  // new object
      // templateParameters.wizardCompleteTitle = mlName;
      templateParameters.wizardSummarySubTitle = DojoString.substitute(I18NStringResource.cmlWizardSummarySubTitle, [values.get("productName").displayValue]);
      if (this.isAutoCompleteOn()) {
        templateParameters.editLinkTitle = I18NStringResource.cmlWizardSummaryEditLinkText;
        templateParameters.editButtonTooltip = I18NStringResource.editButtonTooltip;
        templateParameters.releaseLicenseWarningText = I18NStringResource.releaseNeedsLicenseWarning;
        templateParameters.description = I18NStringResource.cmlWizardSummaryAutoCompleteDescription;
        templateParameters.saveConfigButtonText = I18NStringResource.cmlWizardSaveButtonText;
        templateParameters.closeSummaryButtonText = I18NStringResource.cmlWizardCancelButtonText;
      } else {
        templateParameters.description = DojoString.substitute(I18NStringResource.cmlWizardSummaryDescription, [`<strong>${values.get("cloudChoice").displayValue}</strong>`]);
        templateParameters.saveConfigButtonText = I18NStringResource.cmlWizardGoToDetailsButtonText;
        templateParameters.closeSummaryButtonText = I18NStringResource.cmlWizardCloseSummaryButtonText;
      }
      templateParameters.launchButtonText = I18NStringResource.cmlWizardLauchButtonText;
      templateParameters.launchButtonTooltip = I18NStringResource.cmlWizardSummaryStartButtonTooltip;
      templateParameters.saveConfigButtonTooltip = I18NStringResource.cmlWizardSummarySaveOnlyButtonTooltip;
      templateParameters.closeSummaryButtonTooltip = I18NStringResource.cmlWizardSummaryCancelButtonTooltip;
      templateParameters.additionDetailSectionTitle = I18NStringResource.computeResourceDetailPageSummarySubHeading;
      templateParameters.autoCompleteOn = this.isAutoCompleteOn();
      templateParameters.licenseRequired = this.isLicenseRequired();
      let htmlResult = this.template(templateParameters);
      this.$el.empty().html(htmlResult);
      this.generateAdditionalDetails(values);
    }

    generateAdditionalDetails(values) {
      if (values) {
        let uiSections = this.getUIResults().uiSections;
        let doNothingFn = function () { };
        const uiGeneratorArgs = {
          uiSections: uiSections,
          settingsMap: this.getUIResults().settingsMap,
          dependentOptionHandlerFn: doNothingFn,
          inEditMode: false,
          baseElement: null,
          fieldValidationHandlers: null,
          dduxLogger: null,
          dataGetters: null,
          // None of these methods exist.
          // {
            // getCredentialTypeId: this.getCredentialTypeId(),
            // getCloudLocation: this.getCloudLocationValueMethod(),
            // getCredentialId: this.getCredentialIdInputValueMethod(),
            // getRulesId: this.getRulesIdMethod(),
            // getCurrentLocation: this.getCurrentLocationMethod(),
            // getCurrentCredentialId: this.getCurrentCredentialIdMethod(),
            // getSavedStep1Values: this.getSavedStep1ValuesMethod, //method was bound in args to constructor
            // getSavedStep2ValueOverrides: this.getSavedStep2ValueOverridesMethod(),
            // getCurrentStep2Values: this.getCurrentStep2ValuesMethod()
          // },
          dataSetters: null,
          // None of these methods exist.
          // {
            // updateSavedStep1Value: this.updateSavedStep1ValueMethod, //method was bound in args to constructor
            // updateSavedStep2ValueOverride: this.updateSavedStep2ValueOverrideMethod(),
            // onStep1Change: this.onStep1ChangeMethod //method was bound in args to constructor
          // },
        };
        let uiGenerator = new UIGenerator(uiGeneratorArgs);
        let generatedHTML = uiGenerator.renderDetailsFromMap(values);
        const additionalDetailGeneratedCodeContainer = this.getAdditionalDetailContainer();
        if (additionalDetailGeneratedCodeContainer) {
          additionalDetailGeneratedCodeContainer.innerHTML = generatedHTML;
        } else {
          Util.consoleLogWarning("generateAdditionalDetails",
            "Unable to find additionalDetailGeneratedCodeContainer selector \"div.additionalDetailContainer > div.generatedCode\"");
        }
        let additionalDetailSectionNames = uiGenerator.getSectionTitles();
        if (additionalDetailSectionNames && Array.isArray(additionalDetailSectionNames) && additionalDetailSectionNames.length) {
          const shortAddtionalDetailContainer = this.getShortAdditionalDetailContainer();
          let titlesLength = additionalDetailSectionNames.length;
          let i = 0;
          if (shortAddtionalDetailContainer) {
            let titleSummary = "";
            for (let title of additionalDetailSectionNames) {
              i++;
              titleSummary += title
              if (i < titlesLength) {
                titleSummary += ", ";
              }
            }
            shortAddtionalDetailContainer.textContent = titleSummary;
          } else {
            Util.consoleLogWarning("generateAdditionalDetails",
              "Unable to find shortAddtionalDetailContainer selector \"div.additionalDetailContainer > div.shortAddtionalDetail > p\"");
          }
        }
      }
    }

    async initializeStep() {
      this.showWizardSummary(this.getConfigId());
    }

    toggleSection(event) {
      let sectionHeader = null;
      if (event) {
        if (event.preventDefault) {
          event.preventDefault();
        }
        if (event.currentTarget) {
          Util.logDDUXinfoFromClickEvent(event, this.getDDUXLogger());
        }
        if (event.target) {
          sectionHeader = event.target.closest('.additionalDetailHeader')
        }
      }
      Util.toggleSection(sectionHeader);
    }

  }

  return CreateMATLABWizardSummary;
});
