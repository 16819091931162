define([
  "cloudCenterApp"
], function( CloudCenterApplication ) {

  /**
   * This subclass of CloudCenterApplication uses
   */
  class CloudCenterWebApp extends CloudCenterApplication {
    constructor(opts) {
      super(opts);
    }

  }
  return CloudCenterWebApp;
}); // require
