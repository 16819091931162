define([
    "dojo/_base/declare",
    "dojo/_base/lang",

    "mw-log/Log"
], function (declare, lang, Log) {

    return declare([], {

        constructor: function (args) {
            this.state = {};
            this.target = "";
            this.condition = null;
            this.action = null;

            declare.safeMixin(this, args);
        },

        handleTrigger: function (trigger, otherArguments) {
            var nextState = "";
            if (this.checkCondition(trigger, otherArguments)) {
                this.runAction(trigger, otherArguments);
                nextState = this.getTarget(trigger, this.target, otherArguments);
            }
            return nextState;
        },

        checkCondition: function (trigger, otherArguments) {
            if (!this.condition) {
                // if condition is null or undefined then its assumed to be true
                return true;
            }
            // Create a function which will run in the state's context.
            // This will accept either a function object or a string.
            var conditionFunction = lang.hitch(this.state.context, this.condition);
            return conditionFunction.apply(null, [trigger, this.state.data].concat(otherArguments));
        },

        // run an action asynchronously
        runAction: function (trigger, otherArguments) {
            var actionFunction, that = this;
            if (this.action) {
                // Create a function which will run in the state's context.
                // This will accept either a function object or a string.
                actionFunction = lang.hitch(this.state.context, this.action);
                actionFunction.apply(null, [trigger, that.state.data].concat(otherArguments));
            }
        },

        getTarget: function (trigger, target, otherArguments) {
            var i, targetState, targetFunction, partialTargetState;
            if (lang.isString(target)) {
                targetState = target;
            } else if (lang.isFunction(target)) {
                targetFunction = lang.hitch(this.state.context, target);
                targetState = targetFunction.apply(null,
                    [trigger, this.state.data].concat(otherArguments));
            } else if (lang.isArray(target)) {
                for (i = 0; i < target.length; i += 1) {
                    targetState = this.getTarget(trigger, target[i], otherArguments);
                    // If no next state or target was specified, then we need to recurse
                    if (lang.isString(targetState)) {
                        targetState = {target: targetState};
                        lang.mixin(targetState, partialTargetState);
                        break; // found the next state, stop searching
                    }
                    if (targetState && targetState.target) {
                        lang.mixin(targetState, partialTargetState);
                        break; // found the next state, stop searching
                    }
                    // Keep track of the results of the getTarget call so that we can combine
                    // properties
                    partialTargetState = targetState || {};
                }
            } else {
                Log.warn("Transition: improper target: ", target);
            }
            return targetState;
        }
    });
});