/**
 * Abstract class that defines expected interface for DAO
 */
 /* jshint esversion: 8 */
define(function() {
  function abstractMethod() { throw new Error("Abstract method."); }

  class AbstractDAO {
    constructor() {
      if(this.constructor === AbstractDAO) {
        throw new Error("Cannot instantiate abstract class.");
      }
    }
    useHtmlLocalStorage() { abstractMethod(); }
    setAuthToken(authToken) { abstractMethod(); }
    getAuthToken() { abstractMethod(); }
    setSessionId(sessionId) { abstractMethod(); }
    getSessionId() { abstractMethod(); }
    setOriginId(originId) { abstractMethod(); }
    getOriginId() { abstractMethod(); }
    setLoginData(loginData) { abstractMethod(); }
    getLoginData() { abstractMethod(); }
    setUserId(userId) { abstractMethod(); }
    getUserId() { abstractMethod(); }
    setClientString(clientString) { abstractMethod(); }
    getClientString() { abstractMethod(); }
    updateLoginInformation(loginInfo) { abstractMethod(); }
    clearLoginInformation() { abstractMethod(); }
    setSessionListener(sessionListener) { abstractMethod(); }
    getSessionListener() { abstractMethod(); }
    validateLogin() { abstractMethod(); }
    startParallelServerCluster(clusterId, restParams) { abstractMethod(); }
    getWorkflowResourceDetails(id) {  abstractMethod(); }
    getCCAPI(service, type, id, action, params, restParams) { abstractMethod(); }
    postCCAPI(service, type, id, action, params, restParams) { abstractMethod(); }
    putCCAPI(service, type, id, action, params, restParams) { abstractMethod(); }
    deleteCCAPI(service, type, id, action, params, restParams) { abstractMethod(); }
    getLegacyParallelAPI(endpoint, id, action, params, restParams) { abstractMethod(); }
    postLegacyParallelAPI(endpoint, id, action, params, restParams) { abstractMethod(); }
    putLegacyParallelAPI(endpoint, id, action, params, restParams) { abstractMethod(); }
    deleteLegacyParallelAPI(endpoint, id, restParams) { abstractMethod(); }
    getAWSAccountID() { abstractMethod(); }
    
    logout() { abstractMethod(); }
  }

  return AbstractDAO;
});
