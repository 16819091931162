define([
  "config",
  "util",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
], function (
  Config,
  Util,
  I18NStringResource,
  DojoString
) {

  const HOUR = 3600;

  class CCInternalScripts {
    constructor(resourceId, secs = 60, force = false, product, collection) {
      this.resourceId = resourceId;
      this.secsBetweenAttempts = secs;
      this.startURL = '';
      this.statusURL = '';
      this.restartAttemptCount = 0;
      this.maxRestartAttempts = HOUR / this.secsBetweenAttempts;
      this.finsihedOrAborted = false;
      this.force = force;
      this.product = product;
      this.collection = collection;
      this.machineName = '';
      this.init();
    }

    init() {
      const baseURL = Config.getMicroServiceURL();
      this.startURL = `${baseURL}/workflow/resource/${this.resourceId}/resume`;
      this.statusURL = `${baseURL}/workflow/type/config?config_id=${this.resourceId}&show_all=true`;
      this.loggedInURL = `${baseURL}/user/resource/loggedin/`;
      this.finsihedOrAborted = false;
      const collectionItem = this.getCollectionRowItem();
      if (collectionItem) {
        this.machineName = collectionItem.getConfigName();
      }
    }

    getFetchArgs(method) {
      return {
        method: method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8'
        },
        credentials: 'include'
      };
    }

    getProduct() {
      return this.product;
    }

    getCollection() {
      return this.collection;
    }

    getName() {
      return this.machineName;
    }

    getCollectionRowItem() {
      let collectionItem = null;
      const product = this.getProduct();
      if (product) {
        const collection = this.getCollection();
        if (collection) {
          collectionItem = collection.getCollectionItem(this.resourceId);
        }
      }
      return collectionItem;
    }

    getRowIPAddressContainer() {
      let ipContainer = null;
      const collectionItem = this.getCollectionRowItem();
      if (collectionItem) {
        const rowElement = collectionItem.getElement();
        if (rowElement) {
          const container = rowElement.getElementsByClassName('ip-address')[0];
          if (container) {
            ipContainer = container;
          }
        }
      }
      return ipContainer;
    }

    disableActionButton() {
      try {
        const collectionItem = this.getCollectionRowItem();
        if (collectionItem) {
          const rowElement = collectionItem.getElement();
          if (rowElement) {
            const threeDots = rowElement.querySelector(`div.threeDotsVerticalIcon.smallIcon`);
            let btn = null;
            if (threeDots) {
              btn = threeDots.parentElement;
            }
            if (btn && btn.tagName === 'BUTTON') {
              btn.disabled = true;
              btn.classList.add('disabled');
            }
          }
        }
      } catch (error) {
        Util.consoleLogError('CCInternalScripts.disableActionButton', error);
      }
    }

    enableActionButton() {
      try {
        const collectionItem = this.getCollectionRowItem();
        if (collectionItem) {
          const rowElement = collectionItem.getElement();
          if (rowElement) {
            const threeDots = rowElement.querySelector(`div.threeDotsVerticalIcon.smallIcon`);
            let btn = null;
            if (threeDots) {
              btn = threeDots.parentElement;
            }
            if (btn && btn.tagName === 'BUTTON') {
              btn.disabled = false;
              btn.classList.remove('disabled');
            }
          }
        }
      } catch (error) {
        Util.consoleLogError('CCInternalScripts.enableActionButton', error);
      }
    }

    setRowToBusy() {
      try {
        const collectionItem = this.getCollectionRowItem();
        if (collectionItem) {
          this.disableActionButton();
          const spinner = Util.createSpinner();
          spinner.classList.add('ccInternalScripts');
          const container = this.getRowIPAddressContainer();
          if (container) {
            const hasSpinnerAlready = container.querySelectorAll('div.progressSpinnerContainer.ccInternalScripts').length;
            if (!hasSpinnerAlready) {
              container.children[0].style.display = 'none';
              container.appendChild(spinner);
            }
          }
        }
      } catch (error) {
        Util.consoleLogError('CCInternalScripts.setRowToBusy', error);
      }
    }

    removeBusyIndicator() {
      try {
        const container = this.getRowIPAddressContainer();
        if (container) {
          const spinners = container.querySelectorAll('div.progressSpinnerContainer.ccInternalScripts');
          const hasSpinnerAlready = spinners.length;
          if (hasSpinnerAlready) {
            container.children[0].style.display = 'table-cell';
            container.removeChild(spinners[0]);
          }
          this.enableActionButton();
        }
      } catch (error) {
        Util.consoleLogError('CCInternalScripts.removeBusyIndicator', error);
      }
    }

    async updateRowStatus() {
      try {
        const collectionItem = this.getCollectionRowItem();
        if (collectionItem) {
          this.removeBusyIndicator();
          this.enableActionButton();
          await collectionItem.updateStatus();
        }
      } catch (error) {
        Util.consoleLogError('CCInternalScripts.updateRowStatus', error);
      }
    }

    async fnStart() {
      Util.consoleLogTrace('CCInternalScripts.fnStart', `Initiating resume call for ${this.resourceId}.`);
      this.restartAttemptCount++;
      try {
        const response = await fetch(this.startURL, this.getFetchArgs('PUT'));
        Util.consoleLogTrace('CCInternalScripts.fnStart', `Response-Start: ${JSON.stringify(response)}`);
        let data;
        if (response.status === 417) {
          data = await response.json();
          if (data && this.restartAttemptCount === 1) {
            setTimeout(this.setRowToBusy.bind(this), 4000);
          }
        }
        if (this.restartAttemptCount === 1) {
          Util.notify("INFO", DojoString.substitute(I18NStringResource.forceresume_introduction, [this.getName(), this.maxRestartAttempts, this.secsBetweenAttempts]));
          setTimeout(this.fnStatus.bind(this), 3000);
        }

        if (data) {
          Util.consoleLogTrace('CCInternalScripts.fnStart', `Data-Start: ${data}`);
          if (
            this.force &&
            data.err &&
            data.err.machine &&
            data.err.machine[0] &&
            (data.err.machine[0].code === 'core.aws.ec2.error.insufficientinstancecapacity' ||
              data.err.machine[0].code === 'core.aws.ec2.error.instancelimitexceeded')
          ) {
            if (!this.finsihedOrAborted && this.restartAttemptCount <= this.maxRestartAttempts) {
              Util.consoleLogWarning(
                'CCInternalScripts.fnStart',
                `Received error ${data.err.machine[0].code} -- Retrying resume attempt ${this.restartAttemptCount + 1} out of possible max of ${this.maxRestartAttempts} in ${
                  this.secsBetweenAttempts
                } seconds`
              );
              Util.notify('INFO', DojoString.substitute(I18NStringResource.forceresume_retrying_start,[this.getName(), this.restartAttemptCount, this.maxRestartAttempts]));
              setTimeout(this.fnStart.bind(this), this.secsBetweenAttempts * 1000); //start again
            } else {
              this.finsihedOrAborted = true;
              Util.consoleLogError(
                'CCInteralScripts.fnStart',
                'Reached max restart attempts.  Setting finishedOrAborted to true.  Aborting.'
              );
              Util.notify('WARNING', DojoString.substitute(I18NStringResource.forceresume_reached_max,[this.getName(), this.maxRestartAttempts]));
              setTimeout(this.removeBusyIndicator.bind(this), 3000);
              return;
            }
          } else {
            this.finsihedOrAborted = true;
            setTimeout(this.removeBusyIndicator.bind(this), 3000);
            Util.consoleLogError('CCInternalScripts.fnStart', 'Unknown start error.');
            Util.notify('WARNING', DojoString.substitute(I18NStringResource.forceresume_process_aborted_due_to_error,[this.getName(), 'Unknown start error']));
          }
        } else {
          Util.consoleLogWarning('CCInternalScripts.fnStart', 'No data received. Setting finishedOrAborted to true.');
          this.finsihedOrAborted = true;
          setTimeout(this.removeBusyIndicator.bind(this), 3000);
        }
      } catch (error) {
        Util.consoleLogError('CCInternalScripts.fnStart', error);
        Util.consoleLogWarning('CCInternalScripts.fnStart', 'Unexpected error occurred.  Aborting. Setting finishedOrAborted to true.');
        this.finsihedOrAborted = true;
        let errmsg = 'Unknown error';
        if (error.message) {
          errmsg = error.message;
        }
        Util.notify('WARNING', DojoString.substitute(I18NStringResource.forceresume_process_aborted_due_to_error,[this.getName(), errmsg]));
        setTimeout(this.removeBusyIndicator.bind(this), 3000);
      }
    }

    async fnStatus() {
      Util.consoleLogTrace('CCInternalScripts.fnStatus', `Initiating status call for ${this.resourceId}.`);
      this.setRowToBusy();
      try {
        const response = await fetch(this.statusURL, this.getFetchArgs('GET'));
        Util.consoleLogTrace('CCInternalScripts.fnStatus', `Response-Status: ${response}`);
        let data;
        if (response.status === 200 || response.status === 417 || response.status === 427) {
          // still loading or error or already started
          data = await response.json();
        } else {
          Util.consoleLogWarning('CCInternalScripts.fnStatus', `Unexpected status code: ${response.status}. Aborting status update`);
        }

        if (data) {
          Util.consoleLogTrace('CCInternalScripts.fnStatus', `Data-Status: ${JSON.stringify(data)}`);
          let state = '';
          if (data && data[0] && data[0].cloud && data[0].cloud.state) {
            state = data[0].cloud.state;
            Util.consoleLogWarning('CCInternalScripts.fnStatus', `State: ${state}`);
          }
          if (state === 'RUNNING') {
            Util.consoleLogWarning('CCInternalScripts.fnStatus', 'Should have started. Setting finishedOrAborted to true.');
            Util.notify('NORMAL', DojoString.substitute(I18NStringResource.forceresume_successful_start, [this.getName()]));

            this.finsihedOrAborted = true;
            setTimeout(this.updateRowStatus.bind(this), 3000)
            return;
          }

          if (state.endsWith('ING') || state === 'PAUSED') {
            if (!this.finsihedOrAborted) {
              //it's doing something, so keep checking
              Util.consoleLogWarning('CCInternalScripts.fnStatus', `Retrying status in ${this.secsBetweenAttempts} seconds`);
              setTimeout(this.fnStatus.bind(this), this.secsBetweenAttempts * 1000);
            } else if (this.finsihedOrAborted) {
              if (state === 'RESUMING') {
                Util.consoleLogWarning('CCInternalScripts.fnStatus', `Restart attempt appears to have succeeded.  State is ${state}.`);
                setTimeout(this.updateRowStatus.bind(this), 3000)
              } else {
                if (this.restartAttemptCount >= this.maxRestartAttempts) {
                  Util.consoleLogWarning(
                    'CCInternalScripts.fnStatus',
                    `State is ${state}.  Max attempts to restart reached.  Current count: ${this.restartAttemptCount}, max: ${this.maxRestartAttempts} Aborting status update`
                  );
                } else {
                  Util.consoleLogWarning(
                    'CCInternalScripts.fnStatus',
                    `Got no data back and aborted, but state is ${state}.  Max attempts to restart not yet reached.  Continuing to try restart in ${this.secsBetweenAttempts} seconds.`
                  );
                  this.finsihedOrAborted = false;
                  Util.notify('INFO', DojoString.substitute(I18NStringResource.forceresume_retrying_start,[this.getName(), this.restartAttemptCount, this.maxRestartAttempts]));
                  setTimeout(this.fnStart.bind(this), this.secsBetweenAttempts * 1000); //start again
                  Util.consoleLogWarning('CCInternalScripts.fnStatus', `Retrying status in ${this.secsBetweenAttempts} seconds`);
                  setTimeout(this.fnStatus.bind(this), (this.secsBetweenAttempts + 1) * 1000); // revive status poll
                }
              }
            }
          } else if (state === 'TERMINATED') { // bogus state.  Continue
            if (!this.finishedOrAborted) {
              Util.consoleLogWarning('CCInternalScripts.fnStatus', `Retrying status in ${this.secsBetweenAttempts} seconds`);
              setTimeout(this.fnStatus.bind(this), this.secsBetweenAttempts * 1000); // setTimeout(this.fnStatus.bind(this), secsBetweenAttempts * 1000);
            } else {
              Util.consoleLogWarning('CCInternalScripts.fnStatus', `State is ${state}.  Process already aborted.`);
              setTimeout(this.removeBusyIndicator.bind(this), 3000);
            }
          } else {
            Util.consoleLogWarning(
              'CCInternalScripts.fnStatus',
              `State is ${state}.  Setting finishedOrAborted to true. Aborting status update.`
            );
            this.finsihedOrAborted = true;
            setTimeout(this.removeBusyIndicator.bind(this), 3000);
          }
        } else {
          Util.consoleLogWarning(
            'CCInternalScripts.fnStatus',
            `Response status was ${response.status}, Aborting status update. Setting finishedOrAborted to true.`
          );
          this.finsihedOrAborted = true;
          setTimeout(this.removeBusyIndicator.bind(this), 3000);
        }
      } catch (error) {
        Util.consoleLogError('CCInternalScripts.fnStatus', error);
        Util.consoleLogError('CCInternalScripts.fnStatus', 'Script error occurred. Aborting. Setting finishedOrAborted to true.');
        this.finsihedOrAborted = true;
        setTimeout(this.removeBusyIndicator.bind(this), 3000);
      }
    }
  }
  return CCInternalScripts;
});


