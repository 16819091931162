
define([
    'dojo/_base/declare',
    'dojo/Evented',
    'dojo/_base/lang',
    'dojo/aspect',
    'dojo/Deferred',
    'dojo/promise/all',
    'mw-statemachine/StateMachine',
    'mw-log/Log'
], function (declare, Evented, lang, aspect, Deferred, all, StateMachine, Log) {
    /**
     * This is the base implementation of the message service. Other message service implementations
     * can register with it to provide the specific type of transport (ie: request/response, cometd,
     * etc).
     */
    return declare([Evented], {

        _MAX_RETRIES: 30,

        logStateChanges: false,

        constructor: function () {
            this._stateOptions = {
                context: this,
                name: 'WraDataService',
                namespace: 'MOTW.wra',
                defaultSubstate: 'disconnected',
                substates: {
                    'disconnected': { transitions: {
                        'onEnterState': this._enterDisconnected,
                        'start': 'connecting'
                    } },

                    'connecting': { transitions: {
                        'onEnterState': this._enterConnecting,
                        '_connectSuccess': 'resubscribing',
                        '_connectFailure': 'reconnecting',
                        'stop': 'disconnected'
                    } },

                    'reconnecting': { transitions: {
                        'onEnterState': this._enterReconnecting,
                        '_connectSuccess': 'resubscribing',
                        '_connectFailure': [this._retryFailure, 'retryDelay'],
                        'stop': 'disconnected'
                    } },

                    'retryDelay': { transitions: {
                        'timeout 2': 'reconnecting',
                        '_reconnect': 'reconnecting',
                        'stop': 'disconnected'
                    } },

                    'resubscribing': { transitions: {
                        'onEnterState': this._enterResubscribing,
                        '_resubscribeSuccess': 'connected',
                        '_resubscribeFailure': 'reconnecting',
                        'stop': 'disconnecting'
                    } },

                    'connected': { transitions: {
                        'onEnterState': this._enterConnected,
                        '_reconnect': 'reconnecting',
                        'stop': 'disconnecting'
                    } },

                    'disconnecting': { transitions: {
                        'onEnterState': this._enterDisconnecting,
                        '_disconnectSuccess': 'disconnected',
                        '_disconnectFailure': 'disconnected',
                        'timeout 2': 'disconnected'
                    } },

                    'fatallyDisconnected': { transitions: {
                        'onEnterState': this._enterFatallyDisconnected,
                        'stop': 'disconnected'
                    } }
                }
            };
        },

        setDelegate: function (delegate) {
            // only update the delegate if it has changed
            if (this._delegate !== delegate) {
                this._delegate = delegate;
                let that = this;
                aspect.after(delegate, 'onConnectionError', function () {
                    Log.warn('MessageService received connection error, reconnecting');
                    that._reconnect();
                }, true);

                this._states = new StateMachine(this._stateOptions);
                aspect.after(this._states, 'onEnterState', lang.hitch(this, this._enterState),
                    true);
                this._states.start();
            }
        },

        // this isn't the obj that registered events, see MessageServiceBase.js
        onConnected: function () {
            this.emit('connected');
        }, // connected for the first time or a reconnect

        onNewlyConnected: function () {}, // connected for the first time (since last disconnect)

        onDisconnected: function () {
            this.emit('disconnected');
        }, // entered the disconnected state

        onReconnected: function () {
            this.emit('reconnected');
        }, // reconnected after first being connected

        onFatallyDisconnected: function () {
            this.emit('fatallyDisconnected');
        }, // error state

        onNotConnected: function () {
            this.emit('notConnected');
        }, // no longer connected

        isStarted: function () {
            return this._currentState !== 'disconnecting' && this._currentState !== 'disconnected';
        },

        isConnected: function () {
            return this._currentState === 'connected';
        },

        isFatallyDisconnected: function () {
            return this._currentState === 'fatallyDisconnected';
        },

        isDisconnected: function () {
            return this._currentState === 'disconnected';
        },

        start: function () {},

        stop: function () {},

        _enterState: function (newState) {
            let oldState = this._currentState;
            this._currentState = newState;

            if (this.logStateChanges) {
                Log.info('MessageService state change: ' + oldState + ' -> ' + newState);
            }

            if (oldState === 'connected') {
                this.onNotConnected();
            }

            if (newState === 'connected' && this._previouslyConnected) {
                this.onReconnected();
            } else if (newState === 'connected' && !this._previouslyConnected) {
                this.onNewlyConnected();
            }
        },

        _enterDisconnected: function () {
            this._previouslyConnected = false;
            this._delegate.cleanup();
            this.onDisconnected();
        },

        _enterConnecting: function () {
            this._retryCount = 0;
            this._delegate.connect().then(this._connectSuccess, this._connectFailure);
        },

        _enterReconnecting: function () {
            this._retryCount += 1;
            Log.info('Reconnecting message service. Attempt ' + this._retryCount, arguments);
            this._delegate.cleanup();
            this._delegate.connect().then(this._connectSuccess, this._connectFailure);
        },

        _reconnect: function () {},
        _connectSuccess: function () {},
        _connectFailure: function () {},

        _timeoutAction: function () {
            if (this.logStateChanges) {
                Log.info('MessageService timeout:', arguments);
            }
        },

        _retryFailure: function () {
            let nextState; // undefined means the default specified in the state machine
            if (this._retryCount >= this._MAX_RETRIES) {
                Log.error('Message service fatally disconnected.');
                nextState = 'fatallyDisconnected';
            }
            return nextState;
        },

        _enterResubscribing: function () {
            let channel, subscriptions;
            let deferreds = [];

            this._delegate.doStartBatch();
            for (channel in this.channelSubscriptions) {
                if (this.channelSubscriptions.hasOwnProperty(channel)) {
                    subscriptions = this.channelSubscriptions[channel];
                    deferreds.push(this._delegate.doSubscribe(channel));
                    subscriptions.subscribed = true;
                }
            }
            this._delegate.doEndBatch();

            all(deferreds).then(this._resubscribeSuccess, this._resubscribeFailure);
        },

        _resubscribeSuccess: function () {},
        _resubscribeFailure: function () {},

        _enterConnected: function () {
            if (!this._delegate.delegateConnected()) {
                // if the delegate isn't connected then something bad has happended and we need to
                // reconnect this can happen if there was an issue resubscribing.
                this._reconnect();
            } else {
                this._retryCount = 0;
                if (this._previouslyConnected) {
                    Log.info('Successfully connected.');
                }
                this._previouslyConnected = true;
                this.onConnected();
            }
        },

        _enterFatallyDisconnected: function () {
            this.onFatallyDisconnected();
        },

        _enterDisconnecting: function () {
            this._delegate.disconnect().then(this._disconnectSuccess, this._disconnectFailure);
        },

        _disconnectSuccess: function () {},
        _disconnectFailure: function () {}

    });
});
