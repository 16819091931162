/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "backbone",
  "templates/timerDropdownTemplate",
  "util",
  "service/cloudStatus",
  "service/cloudResource",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string"
], function (
   _,
   $,
   Backbone,
   TimerDropdownTemplate,
   Util,
   CloudStatus,
   CloudResource,
   I18NStringResource,
   DojoString
) {

  /*
    expected external element: <div class="timeoutDropdownContainer"></div>
  */
  class TimerDropdown extends Backbone.View {

    constructor (params) {
      super(params);
      this.template = TimerDropdownTemplate;
      this.events = {
        "click div.countdownIndicator button#updateTerminationPolicyButton" : "updateTerminationPolicy",
        "change div.countdownIndicator select#updateTerminationPolicyValue" : "trackTerminationPolicyChange",
        "focus div.countdownIndicator select#updateTerminationPolicyValue" : "setPreviousTermPolicyValue",
      };
      // defaults
      this.timeoutPolicyLabel = I18NStringResource.timeoutPolicyLabel;
      this.terminationPolicyResetTimerButton = I18NStringResource.terminationPolicyResetTimerButton;
      this.terminationPolicyResetTimerButtonTooltip = I18NStringResource.terminationPolicyResetTimerButtonTooltip;
      this.dataService = null;
      this.statusAndCountdown = null;
      this.configId = "";
      this.preUpdateMethod = null;
      this.postUpdateMethod = null;
      this.supportsTermPolicy = true;
      this.supportsIdleTermPolicy = false;
      this.termPolicyDropdownValues = {};
      // override defaults as specified in params
      if (params && typeof params === 'object') {
        if (params.timeoutPolicyLabel && typeof params.timeoutPolicyLabel === 'string') {
          this.timeoutPolicyLabel = params.timeoutPolicyLabel;
        }
        if (params.termPolicyDropdownValues && typeof params.termPolicyDropdownValues === 'object') {
          this.termPolicyDropdownValues = params.termPolicyDropdownValues;
        }
        if (params.terminationPolicyResetTimerButton && typeof params.terminationPolicyResetTimerButton === 'string') {
          this.terminationPolicyResetTimerButton = params.terminationPolicyResetTimerButton;
        }
        if (params.terminationPolicyResetTimerButtonTooltip && typeof params.terminationPolicyResetTimerButtonTooltip === 'string') {
          this.terminationPolicyResetTimerButtonTooltip = params.terminationPolicyResetTimerButtonTooltip;
        }
        if ((params.dataService && typeof params.dataService === 'object') &&
            (typeof params.dataService.ui === 'object'
             && typeof params.dataService.workflow === 'object'
             && typeof params.dataService.clear === 'function')) {
          this.dataService = params.dataService;
        } else {
          throw new TypeError("Invalid dataService argument");
        }
        if (params.statusAndCountdown && typeof params.statusAndCountdown === 'object') {
          this.statusAndCountdown = params.statusAndCountdown;
        }
        if (params.preUpdateMethod && typeof params.preUpdateMethod === 'function') {
          this.preUpdateMethod = params.preUpdateMethod;
        }
        if (params.postUpdateMethod && typeof params.postUpdateMethod === 'function') {
          this.postUpdateMethod = params.postUpdateMethod;
        }
        if (params.configId && typeof params.configId === 'string') {
          this.configId = params.configId;
        }
        if (("supportsTermPolicy" in params)) {
          this.supportsTermPolicy = params.supportsTermPolicy;
        }
        if (("supportsIdleTermPolicy" in params)) {
          this.supportsIdleTermPolicy = params.supportsIdleTermPolicy;
        }
      }
      _.bindAll(this, "updateTerminationPolicy");
    }

    // getters and setters
    getTimeoutPolicyLabel () { return this.timeoutPolicyLabel; }
    getTermPolicyDropdownValues () { return this.termPolicyDropdownValues; }
    getSupportsIdleTermPolicy () { return this.supportsIdleTermPolicy; }
    getTerminationPolicyResetTimerButton () { return this.terminationPolicyResetTimerButton; }
    getTerminationPolicyResetTimerButtonTooltip () { return this.terminationPolicyResetTimerButtonTooltip; }
    getDataService () { return this.dataService; }
    setConfigId (configId) { this.configId = configId; }
    getConfigId () { return this.configId; }
    setStatusAndCountdown (statusAndCountdown) { this.statusAndCountdown = statusAndCountdown; }
    getStatusAndCountdown () { return this.statusAndCountdown; }
    setPreUpdateMethod (method) { this.preUpdateMethod = method; }
    getPreUpdateMethod () { return this.preUpdateMethod; }
    setPostUpdateMethod (method) { this.postUpdateMethod = method; }
    getPostUpdateMethod () { return this.postUpdateMethod; }
    getSupportsTermPolicy () { return this.supportsTermPolicy; }

    render () {
      if (!this.getSupportsTermPolicy()) {
        return;
      }


      let terminationValues = []
      for (const [k,v] of Object.entries(this.getTermPolicyDropdownValues())) {
        terminationValues.push({k:k, sort:k.padStart(5,'0'), v:v});
      }
      terminationValues.sort(Util.sortMultipleAttr("sort"))

      const templateParams = {
        timeoutPolicyLabel: this.getTimeoutPolicyLabel(),
        terminationValues: terminationValues,
        supportsIdleTermPolicy: this.getSupportsIdleTermPolicy(),
        terminationPolicyResetTimerButton: this.getTerminationPolicyResetTimerButton(),
        terminationPolicyResetTimerButtonTooltip: this.getTerminationPolicyResetTimerButtonTooltip()
      };
      const html = this.template(templateParams);
      this.$el.empty().html(html);
    }

    trackTerminationPolicyChange(event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      try {
        // Must support termination policy feature
        if (!this.getSupportsTermPolicy()) {
          throw new Error("updateTerminationPolicy", "Does not support termination policy.");
        }
        const select = this.getSelectElement();
        select.dataset.dirty = true;
      } catch (error) {
        Util.consoleLogError("trackTerminationPolicyChange", error);
      }
    }

    setPreviousTermPolicyValue(event) {
      const select = this.getSelectElement();
      const previousValue = select.value;
      select.dataset.previousValue = previousValue;
    }

    setTerminationPolicy (policy) {
      try {
        const select = this.getSelectElement();
        select.value = policy;
      } catch (error) {
        Util.consoleLogError("setTerminationPolicy", error);
      }
    }

    getTerminationPolicy () {
      let terminationPolicy = null;
      try {
        const select = this.getSelectElement();
        const index = select.selectedIndex;
        if (index >= 0) {
          terminationPolicy = select.options[index].value;
        }
      } catch (error) {
        Util.consoleLogError("getTerminationPolicy", error);
      }
      return terminationPolicy;
    }

    async updateTerminationPolicy (event) {
      // process event
      if (event) {
        if (event.preventDefault) {
          event.preventDefault();
        }
        if (event.currentTarget) {
          Util.logDDUXinfoFromClickEvent(event, this.getDataService().logging);
        }
      }

      try {
        // disable UI to prevent multiple clicks
        this.disableItems();

        // various checks: If they fail, don't proceed.
        // Must support termination policy feature
        if (!this.getSupportsTermPolicy()) {
          throw new Error("updateTerminationPolicy", "Does not support termination policy.");
        }

        // Must have a config ID
        const configId = this.getConfigId();
        if (!configId) {
          throw new Error("updateTerminationPolicy", `No configId specified.`);
        }

        // Must have a termination policy value
        const terminationPolicy = this.getTerminationPolicy();
        if (!terminationPolicy) {
          throw new Error("updateTerminationPolicy", `No termination policy.`);
        }

        // If caller supplied a method, call it.
        if (this.getPreUpdateMethod()) {
          this.getPreUpdateMethod()(configId);
        }

        // get config's current detail data
        const currentData = await this.getDataService().ui.getConfigDetailData(configId);

        // Do the actual update
        await this.getDataService().workflow.updateTerminationPolicy(configId, terminationPolicy, this.getTermPolicyDropdownValues(), currentData.product);

        // If this TimerDropdown widget has a StatusAndCountdown widget associated with it,
        // Update that also.
        await this.updateStatusAndCountdown();

        // If the caller supplied a post-update method, call it.
        if (this.getPostUpdateMethod()) {
          await this.getPostUpdateMethod()(this.getConfigId());
        }

        // If edit config form is opened, update the termination policy
        this.updateEditForm(terminationPolicy);
      } catch (error) {
        Util.consoleLogError("updateTerminationPolicy", error);
        try {
          const select = this.getSelectElement();
          const previousValue = select.dataset.previousValue;
          if (previousValue) {
            select.value = previousValue;
          }
        } catch (e) {
          /* do nothing - occurs in tests */
        }
        Util.notify("ERROR", `${DojoString.substitute(I18NStringResource.terminationPolicyUpdateError)}`);
      } finally {
        // Always re-enable the UI.
        this.enableItems();
      }
    }

    updateEditForm (terminationPolicy) {
      const editForm = document.querySelector('form.editConfigForm');
      if (editForm) {
        const terminationPolicySelect = editForm.querySelector('select#mw-termination-policy');
        if (terminationPolicySelect) {
          terminationPolicySelect.value = terminationPolicy;
        }
      }
    }

    async updateStatusAndCountdown() {
      if (this.getStatusAndCountdown()) {
        // Clear cache and get newly updated values
        this.getDataService().clear("ui/getLatestConfigData");
        const config = await this.getDataService().ui.getLatestConfigData(this.getConfigId());
        const crArgs = {
          dataService: this.getDataService(),
          config: config,
          proxyURL: null,
          /* istanbul ignore next */
          fnStopResource: () => { }
        };
        const cloudResource = new CloudResource(crArgs);
        // Update the countdown timer.
        await this.getStatusAndCountdown().updateCountdownTimer(this.getConfigId(), cloudResource.getStatus(), cloudResource);
      }
    }

    getButtonElement (errorIfNotFound = true) {
      const buttonSelector = "div.countdownIndicator button#updateTerminationPolicyButton";
      const button = this.el.querySelector(buttonSelector);
      if (!button && errorIfNotFound) {
        throw new Error(`Unable to find button: ${buttonSelector}`);
      }
      return button;
    }

    getSelectElement (errorIfNotFound = true) {
      const selectSelector = "div.countdownIndicator select#updateTerminationPolicyValue";
      const select = this.el.querySelector(selectSelector);
      if (!select && errorIfNotFound) {
        throw new Error(`Unable to find select: ${selectSelector}`);
      }
      return select;
    }

    disableItems () {
      try {
        const button = this.getButtonElement();
        const select = this.getSelectElement();
        select.disabled = true;
        select.classList.add('disabled');
        button.disabled = true;
        button.classList.add("disabled");
        this.closeEditForm();
      } catch (error) {
        Util.consoleLogError("disableItems", error);
      }
    }

    enableItems () {
      try {
        const button = this.getButtonElement();
        const select = this.getSelectElement();
        select.disabled = false;
        select.classList.remove('disabled');
        button.disabled = false;
        button.classList.remove("disabled");
      } catch (error) {
        Util.consoleLogError("enableItems", error);
      }
    }

    closeEditForm () {
      const editForm = document.querySelector('form.editConfigForm');
      if (editForm) {
        const cancelEditButton = editForm.querySelector('button#cancelEditBtn');
        if (cancelEditButton) {
          cancelEditButton.click();
        }
      }
    }

    hideItems () {
      try {
        const button = this.getButtonElement(false);
        const select = this.getSelectElement(false);
        const selectLabel = this.el.querySelector("div.countdownIndicator label.timeoutPolicyLabel");
        if (button) {
          button.disabled = true;
          button.style.display = "none";
        }
        if (select) {
          select.style.display = "none";
        }
        if (selectLabel) {
          selectLabel.style.display = "none";
        }
      } catch (error) {
        Util.consoleLogError("hideItems", error);
      }
    }

    showItems () {
      try {
        const button = this.getButtonElement();
        const select = this.getSelectElement();
        const selectLabel = this.el.querySelector("div.countdownIndicator label.timeoutPolicyLabel");
        button.disabled = false;
        button.style.display = "inline-block";
        select.style.display = "inline-block";
        if (selectLabel) {
          selectLabel.style.display = "inline-block";
        }
      } catch (error) {
        Util.consoleLogError("showItems", error);
      }
    }

  }
  return TimerDropdown;
});
