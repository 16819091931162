/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "backbone",
  "util",
  "computeResource/computeValueFormatter",
  "templates/wizardConfigInfoSummary",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string"
], function(_, $, Backbone, Util, ComputeValueFormatter, ConfigInfoSummaryTemplate,
            I18NStringResource, DojoString) {

    class ConfigInfoSummary extends Backbone.View {

      constructor (args) {
        super(args);
        this.events = {
          "click div.configInfoContainer > div.configInfoHeader > button.btn-close": "hide",
        };
        this.template = ConfigInfoSummaryTemplate;
        this.setElement(Util.createWizardStepDisposableElement("configInfoWrapper"));
        this.productName = (args && typeof args === 'object' && args.product && typeof args.product === 'string') ? args.product : "";
        this.inputToSummaryFieldMap = new Map();
        this.computeValueFormatter = new ComputeValueFormatter();
        _.bindAll(this, "hide", "show");
      }

      /*
       * Query selector-based element getters
       */
      getSummaryList () { return this.el.querySelector('div.matlabConfigurationBox > ul'); }
      getInfoBoxContainer () { return this.el.querySelector('div.configInfoContainer'); }
      getInfoBox () { return this.el.querySelector('div.configInfoBox'); }
      getLostFeatureList () { return this.el.querySelector('div.configInfoBox ul#lostFeatureList'); }
      getSummaryListSpanByID (id) { return this.el.querySelector(`div.matlabConfigurationBox > ul span#${id}`); }
      getButtonsContainer () { return document.querySelector('div#wizardButtons.buttonContainer'); }

      /*
       * Getters and Setters
       */
      getProductName () { return this.productName; }

      getMap () { return this.inputToSummaryFieldMap; }

      getFormatter () { return this.computeValueFormatter; }

      /*
       *  Config summary
       */

      clear () {
        let summaryList = this.getSummaryList();;
        if (!summaryList) {
          throw new Error("Unabled to find ul element in div.matlabConfigurationBox");
        }
        while (summaryList.lastChild) { summaryList.lastChild.remove(); }
        this.getMap().clear();
      }

      has (inputId) {
        if (!inputId || typeof inputId !== 'string') {
          throw new TypeError("Invalid inputId argument");
        }
        return this.getMap().has(inputId);
      }

      delete (inputId) {
        if (!inputId || typeof inputId !== 'string') {
          throw new TypeError("Invalid inputId argument");
        }
        let summaryList = this.getSummaryList();;
        if (!summaryList) {
          throw new Error("Unabled to find ul element in div.matlabConfigurationBox");
        }
        let span = this.getSummaryListSpanByID(inputId);
        let parentLi;
        if (span) {
          parentLi = span.parentElement;
        }
        if (parentLi) {
          summaryList.removeChild(parentLi);
        }
        return this.getMap().delete(inputId);
      }

      mapInputIdToSummaryId (inputId, summaryId, isRequired, isHighlighted, labelText, initialValue) {
        if (!inputId || typeof inputId !== 'string') {
          throw new TypeError("Invalid inputId argument");
        }
        if (!summaryId || typeof summaryId !== 'string') {
          throw new TypeError("Invalid summaryId argument");
        }
        let formatter = this.getFormatter();
        this.createSummaryFieldElement(
          summaryId,
          isRequired,
          isHighlighted,
          labelText,
          formatter.getDisplayValue(inputId, initialValue)
        );
        let summarySelector = `div.matlabConfigurationBox span#${summaryId}`;
        this.getMap().set(inputId, summarySelector);
      }

      getSummaryTarget (inputId) {
        if (!inputId || typeof inputId !== 'string') {
          throw new TypeError("Invalid inputId argument");
        }
        let targetSelector = this.getMap().get(inputId);
        let target;
        if (targetSelector) {
          target = this.el.querySelector(targetSelector);
        }
        if (targetSelector && !target) {
          Util.consoleLogWarning("getSummaryTarget", `targetSelector ${targetSelector} for inputId ${inputId} not found`);
        }
        return target;
      }

      setSummaryTargetValue (inputId, value) {
        if (!inputId || typeof inputId !== 'string') {
          throw new TypeError("Invalid inputId argument");
        }
        let formatter = this.getFormatter();
        let summaryTarget = this.getSummaryTarget(inputId);
        if (summaryTarget) {
          if (value) {
            summaryTarget.textContent = formatter.getDisplayValue(inputId, value);
            summaryTarget.classList.remove("required");
          } else {
            summaryTarget.textContent = I18NStringResource.requiredValue;
            summaryTarget.classList.add("required");
          }
        }
      }

      setSummaryTargetDefaultValue (inputId) {
        let value = I18NStringResource.requiredValue;
        let summaryTarget = this.getSummaryTarget(inputId);
        if (summaryTarget) {
          summaryTarget.textContent = value;
          summaryTarget.classList.add("required");
        }
      }

      /*
        creates something like:
        <li class="highlight"><span>myFieldName</span><span id="myFieldId">myFieldValue</span></li>
        or:
        <li><span>myFieldName</span><span id="myFieldId" class="required">myFieldValue</span></li>
      */
      createSummaryFieldElement (summaryId, isRequired, isHighlighted, labelText, initialValue) {
        if (!summaryId || typeof summaryId !== 'string') {
          throw new TypeError("Invalid summaryId argument");
        }
        let summaryList = this.getSummaryList();;
        if (!summaryList) {
          throw new Error("Unabled to find ul element in div.matlabConfigurationBox");
        }
        if (!this.getSummaryListSpanByID(summaryId)) {
          let li = document.createElement('li');
          if (isHighlighted) {
            li.classList.add("highlight");
          }
          let labelSpan = document.createElement('span');
          labelSpan.textContent = labelText;
          li.appendChild(labelSpan);
          let valueSpan = document.createElement('span');
          valueSpan.setAttribute("id", summaryId);
          if (initialValue && typeof initialValue === 'string') {
            valueSpan.textContent = initialValue;
          } else {
            if (isRequired) {
              valueSpan.classList.add("required");
              valueSpan.textContent = I18NStringResource.requiredValue;
            }
          }
          li.appendChild(valueSpan);
          summaryList.appendChild(li);
        }
      }

      cloneSummaryList () {
        let liList = [];
        let originList = this.getSummaryList();;
        let liChild = originList.firstChild;
        while (liChild) {
          let liClone = liChild.cloneNode(true);
          liList.push(liClone);
          liChild = liChild.nextElementSibling;
        }
        return liList;
      }

      /*
       * Selection Warning
       */
      addSelectionInfoListItems (lostFeatureArray) {
        let infoBox = this.getInfoBox();
        if (!infoBox) {
          throw new Error("Unable to find div.configInfoBox");
        }
        if (lostFeatureArray &&
            Array.isArray(lostFeatureArray) &&
            lostFeatureArray.length) {
          this.showSelectionWarning();
          this.clearSelectionWarning();
          let infoList = this.getLostFeatureList();
          if (infoList) {
            let preExistingValues = this.getExistingFeatureListValues();
            for (let i of lostFeatureArray) {
              if (!preExistingValues.includes(i.trim())) {
                let li = document.createElement("li");
                li.appendChild(document.createTextNode(i.trim()));
                infoList.appendChild(li);
                preExistingValues.push(i.trim());
              }
            }
            this.adjustWizardButtonsPosition();
          }
        } else {
          this.hideSelectionWarning();
        }
      }

      getExistingFeatureListValues () {
        let existingFeatureListValues = [];
        const infoList = this.getLostFeatureList();
        if (infoList) {
          const liElementList = Array.from(infoList.querySelectorAll("li"));
          if (liElementList.length) {
            for (let li of liElementList) {
              const value = li.textContent;
              existingFeatureListValues.push(value);
            }
          }
        }
        return existingFeatureListValues;
      }

      clearSelectionWarning () {
        const infoList = this.getLostFeatureList();
        if (infoList) {
          while (infoList.lastChild) { infoList.lastChild.remove(); }
        }
      }

      adjustWizardButtonsPosition () {
        let buttons = this.getButtonsContainer();
        let infoBox = this.getInfoBox();
        if (buttons && infoBox) {
          let position = window.getComputedStyle(buttons).getPropertyValue('position');
          let infoBoxHidden = infoBox.style.display === 'none';
          if (position === 'absolute') {
            if (infoBoxHidden) {
              buttons.style.top = '320px';
            } else {
              buttons.style.top = '440px';
            }
          }
        }
      }

      hideSelectionWarning () {
        let infoBoxContainer = this.getInfoBoxContainer();
        let infoBox = this.getInfoBox();
        if (infoBox) {
          this.clearSelectionWarning();
          infoBox.style.display = 'none';
          this.adjustWizardButtonsPosition();
          if (infoBoxContainer) {
            infoBoxContainer.style.display = 'none';
          }
        }
      }

      showSelectionWarning () {
        let infoBoxContainer = this.getInfoBoxContainer();
        if (infoBoxContainer) {
          infoBoxContainer.style.display = 'block';
        }
        let infoBox = this.getInfoBox();
        if (infoBox) {
          infoBox.style.display = 'block';
          this.adjustWizardButtonsPosition();
        }
      }

      cloneLostFeatureList () {
        let liList = [];
        let originList = this.getLostFeatureList();
        let liChild = originList.firstChild;
        while (liChild) {
          let liClone = liChild.cloneNode(true);
          liList.push(liClone);
          liChild = liChild.nextElementSibling;
        }
        return liList;
      }

      show (event) {
        if (event && event.preventDefault) { event.preventDefault(); }
        let configSummary = this.getInfoBoxContainer();
        if (configSummary) {
          configSummary.classList.add('popupwin');
        }
      }

      hide (event) {
        if (event && event.preventDefault) { event.preventDefault(); }
        let configSummary = this.getInfoBoxContainer();
        if (configSummary) {
          configSummary.classList.remove('popupwin');
        }
      }

      render () {
        let templateParameters = {};  // new object
        templateParameters.configSummaryTitle = I18NStringResource.cmlWizardStep1ConfigSummaryTitle;
        templateParameters.matlabConfigTitle = I18NStringResource.cmlWizardStep1ConfigMatlabConfigTitle;
        templateParameters.lostFeaturesText = I18NStringResource.cmlWizardStep1ConfigLostFeaturesText;
        let htmlResult = this.template(templateParameters);
        this.$el.empty().html(htmlResult);
      }

      getConfigValuesMap () {
        let map = new Map();
        let computeValueFormatter = this.getFormatter();
        map.set("productName", {rawValue: this.getProductName(), displayValue: this.getProductName().toUpperCase()});
        let entries = this.getMap().entries();
        let entry = entries.next();
        while (!entry.done) {
          let elementId = entry.value[0];
          let elementIdSelector = entry.value[1];
          if (elementId && elementIdSelector) {
            let element = this.el.querySelector(elementIdSelector);
            let value = "";
            if (element) {
              value = element.textContent;
              map.set(elementId, {rawValue: value, displayValue: computeValueFormatter.getDisplayValue(elementId, value)});
            } else {
              Util.consoleLogWarning('getConfigValuesMap', `Unable to find elementIdSelector ${elementIdSelector}`);
            }
          }
          entry = entries.next();
        }
        return map;
      }

    }

    return ConfigInfoSummary;
});
