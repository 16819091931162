/*jshint esversion: 6 */
define([
  "notification/errormsg/errorMsgOverrideData"
], function(ERROR_MSG_OVERRIDE_DATA) {

  const INVERSE_OPERATOR = "__NOT__";

  /* istanbul ignore next */
  class ErrorCodeMessageMap {

    constructor () {
      this.errorCodeToOperationMap = new Map();
    }

    add (errorCode, operationName, applyErrorCodeInversely, messageData, replaceExisting) {
      let effectiveErrorCode = errorCode.toUpperCase();
      let operationMap;
      if (applyErrorCodeInversely) {
        effectiveErrorCode = INVERSE_OPERATOR + errorCode;
      }
      // Is there already an errorCode entry? use it.
      if (this.errorCodeToOperationMap.has(effectiveErrorCode)) {
        operationMap = this.errorCodeToOperationMap.get(effectiveErrorCode);
        // Does the errorCode entry already have an entry for the operationName? Do nothing unless replaceExisting is true.
        // If it does not, add new entry for the operationName
        if (!operationMap.has(operationName)) {
          operationMap.set(operationName, messageData);
        } else {
          if (replaceExisting) {
            operationMap.set(operationName, messageData);
          }
        }
      } else { // create a new entry for this errorCode.
        operationMap = new Map();
        // Add the operationName and data entry to the map
        operationMap.set(operationName, messageData);
        // add the errorCode and its operationMap to the errorCode map
        this.errorCodeToOperationMap.set(effectiveErrorCode, operationMap);
      }
      return this; // enable chaining
    }

    get (errorCode, operationName, applyErrorCodeInversely) {
      let effectiveErrorCode = errorCode.toUpperCase();
      let messageData = null;
      if (applyErrorCodeInversely) {
        messageData = this.getInverseMapForOperation(effectiveErrorCode, operationName);
      } else {
        if (this.errorCodeToOperationMap.has(effectiveErrorCode)) {
          let operationMap = this.errorCodeToOperationMap.get(effectiveErrorCode);
          if (operationMap.has(operationName)) {
            messageData = operationMap.get(operationName);
          }
        }
      }
      return messageData;
    }

    getInverseMapForOperation (errorCode, operationName) {
      let operationMap;
      let messageData = null;
      let inverseErrorCode;
      let found = false;
      this.errorCodeToOperationMap.forEach(function(value, key, map) {
        if (!found) {
          if (key.indexOf(INVERSE_OPERATOR) === 0) {
            operationMap = value;
            if (operationMap.has(operationName)) {
              inverseErrorCode = key.substr(INVERSE_OPERATOR.length);
              if (errorCode !== inverseErrorCode) {
                messageData = operationMap.get(operationName);
              }
              found = true;
            }
          }
        }
      });
      return messageData;
    }

    initialize (providedOverrideData) {
      let context = this;
      let overrideData = (providedOverrideData && typeof providedOverrideData === "object") ? providedOverrideData : ERROR_MSG_OVERRIDE_DATA;
      overrideData.forEach(function(entry) {
        context.add(entry.errorCode, entry.operationName, entry.inverse, entry.msgData, false);
      });
    }

  }

  return ErrorCodeMessageMap;
});
