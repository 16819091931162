/* jshint esversion: 8 */
/* istanbul ignore next */
define([
  "dao/abstractDao",
  "util"
], function( AbstractDAO, Util ) {
  /**
   * Mock implementation of AbstractDAO interface .
   * Uses config object to get needed URL Parameters
   *
   * throws TypeError if config is missing or not what is expected.
   */
  class MockDAO extends AbstractDAO {
    constructor(config) {
      if (!config || typeof config !== 'object' || !config.getMicroServiceURL) {
        throw new TypeError("Incompatible config file parameter.");
      }
      super();
      this.config = config;
      this.authToken = null;
      this.sessionId = null;
      this.loginData = null;
      this.originId = null;
      this.sessionListener = null;
      this.clientString = "";
      this.abortController = Util.fetchAbortSupported() ? new AbortController() : null;
    }

    useHtmlLocalStorage() { return ( (typeof(Storage) !== "undefined") &&
                                     (typeof(localStorage) === "object") &&
                                     (typeof(localStorage.getItem) === "function") ) ? true : false; }
    updateLoginInformation(sessionRenewalData) {
      if (sessionRenewalData && (typeof sessionRenewalData === "object")) {
        if (sessionRenewalData.sessionId) {
          this.setSessionId(sessionRenewalData.sessionId);
        }
        if (("loginProfile" in sessionRenewalData) && sessionRenewalData.loginProfile) {
          if (sessionRenewalData.loginProfile.mwaToken) {
            this.setAuthToken(sessionRenewalData.loginProfile.mwaToken);
          }
          this.setLoginData({
            firstName: sessionRenewalData.loginProfile.firstName,
            lastName: sessionRenewalData.loginProfile.lastName,
            userID: sessionRenewalData.loginProfile.userId,
            emailAddress: sessionRenewalData.loginProfile.emailAddress,
            token: sessionRenewalData.loginProfile.mwaToken
          });
        }
      }
    }

    clearLoginInformation() {
      this.setAuthToken(null); // assume authToken no good.
      this.setSessionId(null);
      this.setLoginData(null);
    }


    mockSuccessResponse(response) {
        return Promise.resolve(response);
    }
    mockFailureResponse(response) {
        return Promise.reject(response);
    }
    doCommonStuff(responseData, success) {
      let promise = null;
      if (success) {
        promise = this.mockSuccessResponse(responseData);
      } else {
        promise = this.mockFailureResponse(responseData);
      }
      return promise;
    }
    /*
     * Mock method override
     */
    setAuthToken(authToken) { this.authToken = authToken; }
    getAuthToken() { return this.authToken; }
    setOriginId(originId) { this.originId = originId; }
    getOriginId() { return this.originId; }
    setSessionId(sessionId) { this.sessionId = sessionId; }
    setSessionListener(listener) { this.sessionListener = listener; }
    getSessionId() { return this.sessionId; }
    getLoginData() { return this.loginData; }
    setLoginData(data) { this.loginData = data; }
    getUserId() { return (this.getLoginData()) ? this.getLoginData().userID : ""; }
    setUserId(userId) { /* do nothing */ }
    setClientString(clientString) { this.clientString = clientString; }
    getClientString() { return this.clientString; }
    validateLogin() { return true; }
    startParallelServerCluster (clusterId, restParams) { return {}; }
    getWorkflowResourceDetails(id) { return true; } //TODO: fake return
    getCCAPI(service, type, id, action, params, restParams) {
      // getCCAPI("user", "resource", "loggedin",null,{profile:"true"})
      if (service === "user" && type === "resource" && id === "loggedin" && params && typeof params === 'object' && params.profile) {
        const userProfileJSON = '{"email":"tuser@mathworks.com","external_cidr":"98.208.251.68/32","first_name":"Test","last_name":"User","logged_in":true,"products":{"matlab":["3572b437b50bd8e00206df2b4062a3f3","f75fb70f8eefdb8f3bdba463d8164c85"]},"rules":{"3572b437b50bd8e00206df2b4062a3f3":"ee8ac4214f8d9465c87a45c7c8d7ad15","f75fb70f8eefdb8f3bdba463d8164c85":"ee8ac4214f8d9465c87a45c7c8d7ad15"}}';
        const userProfile = JSON.parse(userProfileJSON);
        return userProfile;
      }
      return true;
    } //TODO: fake return
    postCCAPI(service, type, id, action, params, restParams) { return true; } //TODO: fake return
    putCCAPI(service, type, id, action, params, restParams) { return true; } //TODO: fake return
    deleteCCAPI(service, type, id, action, params, restParams) { return true; } //TODO: fake return
    getLegacyParallelAPI(endpoint, id, action, params, restParams) { return true; } //TODO: fake return
    postLegacyParallelAPI(endpoint, id, action, params, restParams) { return true; } //TODO: fake return
    putLegacyParallelAPI(endpoint, id, action, params, restParams) { return true; } //TODO: fake return
    deleteLegacyParallelAPI(endpoint, id) { return true; } //TODO: fake return
    getLegacyParallelServicesURL() {return ""}
    getAWSAccountID() { return ""; }
    logout() { /* do nothing */ }
    getAbortController () { return Util.fetchAbortSupported() ? this.abortController : null; }
    abortAllFetchCalls () { 
      if (Util.fetchAbortSupported()) {
        this.getAbortController().abort();
      }
    }

  }

  return MockDAO;
}); // require
