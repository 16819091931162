/* jshint esversion: 8 */
define([
  "service/productConnect/connectionButtonGenerator",
  "service/landingPageNavButtons",
  "util",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "constants"
], function (
  ConnectionButtonGenerator,
  LandingPageNavButtons,
  Util,
  I18NStringResource, DojoString,
  Constants
) {

  class ParallelServerConnectionButtonGenerator extends ConnectionButtonGenerator {

    constructor (args) {
      super(args);
    }

    getConnectButtonTooltipText () { return I18NStringResource.computeResourcePageActionConnectTooltipParallelServer; }

    _getConnectButtonListener (outputName, port, connectDialogExplanation) {
      return async function (event) {
        let rowElement;
        let originalContent;
        if (event) {
          event.preventDefault();
          if (event.currentTarget) {
           rowElement = event.currentTarget.closest('li.item.item-container');
          }
          if (rowElement) {
            originalContent = Util.addSpinnerToRow(rowElement);
          }
        }
        try {
          if (this.getCloudLink()) {
            if (this.getCloudLink().productFn) {
              await productFn();
            }

            // making API call, not going to cloud resource
            this.getCloudLink().port_from = 443;
            this.getCloudLink().port_to = 443;
          }
          let doConnect = this._makeConnection.bind(this, outputName);
          await doConnect();
          // allow a delay for the link contents to download.
          this._delayedRemoveSpinnerFromRow(rowElement, originalContent);

          return false;
        } catch (error) {
          Util.consoleLogError("_getConnectButtonListener", error);
        }
      }.bind(this);
    }

    async _getLink (outputName) {
      const cloudLink = this.getCloudLink();
      let directLink = cloudLink.link;
      const newTab = cloudLink.new_tab;
      const useProxy = cloudLink.proxy;

      let anchor = document.createElement('a');

      if (useProxy) {
        directLink = await this._getProxyURL(cloudLink.port_from, outputName);
      }

      if (directLink) { // may not exist if the handler is making a j/s call instead of routing to a URL
        if (newTab) {
          anchor.setAttribute('target', `${this.getProduct()}_${this.getConfigId().substring(0,6)}`);
        }

        if (directLink.indexOf("//")<0) {
          directLink = "https://"+directLink;
        }
        const isDefaultPort = ((directLink.startsWith("https://") && cloudLink.port_from === 443) || (directLink.startsWith("http://") && cloudLink.port_from === 80));
        if (!isDefaultPort && !cloudLink.proxy && directLink.indexOf("//") && cloudLink.port_from && directLink.indexOf(":" + cloudLink.port_from) < 0) {
          directLink = directLink+":"+cloudLink.port_from;
        }
        anchor.setAttribute('href', directLink);
      }
      // protect against security threat and keep current page javascript processing.
      anchor.setAttribute('rel', 'noopener');
      return anchor;
    }

    _delayedRemoveSpinnerFromRow(rowElement, originalContent) {
      if (!rowElement || typeof rowElement !== 'object') {
        return;
      }
      setTimeout(function () { Util.removeSpinnerFromRow(rowElement, originalContent); }.bind(this), 3500);
    }

  }

  return ParallelServerConnectionButtonGenerator;
});
