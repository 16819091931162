/* jshint esversion: 8 */
/*
 * This file contains logic for resource states and event
 */
 define([
   "underscore",
   "jquery",
   "dialogs/wizardWarningDialog",
   "util",
   "supportedProducts",
   "dojo/i18n!nls/cloudCenterStringResource",
   "service/cloudStatus",
   "mw-progress-indicator/ProgressIndicator",
   "notification/notificationManager"
], function(_, $, WizardWarningDialog, Util, SupportedProducts,
            I18NStringResource, CloudStatus, ProgressIndicator,
            NotificationManager) {

   class LandingPageNavButtons {

      static createLink (text, tooltip, iconClass, addText=false, buttonId="") {
        let link = document.createElement("span");
        let subLink = document.createElement("button");
        if (buttonId) {
          subLink.id = buttonId;
        }
        let newText = I18NStringResource[`dynamicConnectBtn_${text}`];
        if (newText) {
          text = newText;
        }
        let props = {
          "aria-label": `${text}`,
          "type": "button",
          "tabindex": "0",
          "class": "btn btn_color_blue companion_btn btn-sm icon-attribute",
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "top",
          "data-name": `${text}`,
          "title": `${tooltip}`
        };
        for (const [key, value] of Object.entries(props)) {
          subLink.setAttribute(key, value);
        }
        let iconDiv = document.createElement("div");
        iconDiv.className = iconClass;
        subLink.innerHTML = "";
        subLink.appendChild(iconDiv);
        if (addText) {
          subLink.classList.add("cta-btn");
        }
        link.innerHTML = "";
        link.appendChild(subLink);
        link.setAttribute("data-name", text);
        return link;
      }

      static logEvent (event, logFn) {
        if (event && event.currentTarget) {
          let targetButtonId = event.currentTarget.id;
          if (!targetButtonId && event.currentTarget.tagName.toLowerCase() === 'span' && event.currentTarget.querySelector('button')) {
            let button = event.currentTarget.querySelector('button');
            targetButtonId = button.id;
          }
          if (targetButtonId && typeof logFn === 'function') {
            logFn({
              elementId: targetButtonId,
              elementType: "link",
              eventType: "clicked"
            });
          }
        }
      }

      static addClickListener (el, fn, logFn) {
        if (!el) { return; }
        // el can be either a span or a button.  If a span, get the child button.
        let target = el;
        if (el.tagName.toUpperCase() === 'SPAN') {
          target = el.querySelector('button');
        }
        if (target) {
          target.addEventListener("keyup", function(event){
            if (event && /^(13|32)$/.test(event.which)) {
              LandingPageNavButtons.logEvent(event, logFn);
              fn(event);
            }
            return false;
          });
          target.addEventListener("click", function(event){
            LandingPageNavButtons.logEvent(event, logFn);
            fn(event);
          });
        }
      }

      static createMenuStructureAndGetContainer (target, i18nLabel, i18nTooltip) {
        //create ... label
        target.className = "dropdown menu-container resource-menu context-menu attribute hide-mobile-header";
        let contextMenuButton = LandingPageNavButtons.createMenuThreeDotLabel(i18nLabel, i18nTooltip);
        target.innerHTML = "";
        target.appendChild(contextMenuButton);

        //create menu container
        let ul = LandingPageNavButtons.createMenuContainer();
        target.appendChild(ul);

        //return container for adding menu items
        return ul;
      }

      static createMenuContainer () {
        let ul = document.createElement("ul");
        let contentDiv = document.createElement('div');
        contentDiv.classList.add("menuContent");
        let mobileCoverDiv = document.createElement('div');
        mobileCoverDiv.classList.add("mobileCover");
        ul.appendChild(contentDiv);
        ul.appendChild(mobileCoverDiv);
        ul.classList.add("dropdown-menu", "dropdown-menu-lg-end", "resource-action-dropdown-menu");
        return ul;
      }

      static createMenuThreeDotLabel (i18nLabel, i18nTooltip) {
        let contextMenuButton = LandingPageNavButtons.createLink(i18nLabel, i18nTooltip, "threeDotsVerticalIcon smallIcon");
        contextMenuButton.className = "dropdown-toggle resource-nav hide-mobile-header";
        contextMenuButton.setAttribute("data-bs-toggle", "dropdown");
        // contextMenuButton.setAttribute("aria-haspopup", "true");
        // contextMenuButton.setAttribute("aria-expanded", "false");
        return contextMenuButton;
      }



      static createMobileOnlyMenuTextItem (text) {
        let li = document.createElement("li");
        let textDiv = document.createElement('div');
        textDiv.classList.add("textDiv");
        let exitBtnDiv = document.createElement('div');
        exitBtnDiv.classList.add("exitBtnDiv");
        let btn = document.createElement('button');
        btn.classList.add("btn", "dropdown-item");
        btn.textContent = "x";
        btn.type = 'button';
        btn.setAttribute("data-bs-toggle", "tooltip");
        exitBtnDiv.appendChild(btn);
        textDiv.textContent = text;
        li.appendChild(textDiv);
        li.appendChild(exitBtnDiv);
        li.classList.add("resource-menu-mobile", "hasButtons");
        return li;
      }

      static createAction (text, tooltip, iconClass, product, fn, actionType, row, item, buttonId="", logFn, isLifeCycleAction = false) {
        let link = LandingPageNavButtons.createLink(text, tooltip, iconClass, true, buttonId);
        const rowElement = LandingPageNavButtons.getRowElementFromRowObject(row);
        let linkTarget = link;
        if (link.tagName && link.tagName.toLowerCase() === 'span' && link.querySelector('button')) {
          linkTarget = link.querySelector('button');
        }
        LandingPageNavButtons.addClickListener(linkTarget,
          function(event) {
            if (event) {
              if (event.preventDefault) {
                event.preventDefault();
              }
              if (event.target) {
                if (event.target.disabled) {
                  return;
                }

                event.target.disabled = true;
                event.target.classList.add("disabled");
                LandingPageNavButtons.disableRowButtons(row);
              }
            }

          let action = async function() {
            if (isLifeCycleAction) {
              row.getStatusAndCountdown().renderStatus(CloudStatus.getExpectedStatus(actionType), 1);
              row.getCloudResource().stopCountdown(row.getElementSelector())
            }

            let spinner = document.createElement("div");
            spinner.className = "progressSpinnerContainer";
            spinner.innerHTML = '<mw-progress-indicator type="spinner" size="small"></mw-progress-indicator>';
            let container = rowElement.getElementsByClassName("ip-address")[0];
            let oldContainerContents;
            if (container && spinner) {
              oldContainerContents = container.innerHTML;
              container.innerHTML = "";
              container.appendChild(spinner);
            }
            try {
              if (actionType === 'hard-delete' && item && item.params && item.params['mw-name']) {
                NotificationManager.removeNotification(item.params['mw-name']);
              }
              let resp = await fn(item.id, product);
              if (isLifeCycleAction) {
                try {
                  // don't check actionType === "hard-delete" ... "config deleted" is only returned if hard-delete successful
                  if (actionType==="archive" || resp === "config deleted") {
                    const  container = rowElement.parentElement;
                    const containerId = container.id;
                    const endIndex = containerId.indexOf("-resource-collection");
                    const productName = containerId.substring(0,endIndex);
                    container.removeChild(rowElement);
                    // table header is also an 'li' element
                    if (container.querySelectorAll('li').length === 1) {
                      SupportedProducts.showNoProductResourcesMessage(productName);
                    }
                    Util.notify("NORMAL", I18NStringResource.computeResourcePageDeleteComplete);
                  } else {
                    row.updateStatus();
                  }
                } catch (error) {
                  let message = Util.extractErrorMessage(error);
                  Util.notify('ERROR', message);
                  row.updateStatus();
                }
              } else {
                if (container && spinner && oldContainerContents) {
                  container.removeChild(spinner);
                  container.innerHTML = oldContainerContents;
                }
                LandingPageNavButtons.enableRowButtons(row);
              }
            } catch (e) {
              if(e.errorCode && e.message) {
                let errorCode = e.errorCode;
                let message = e.message;
                // code levels from backend are error, debug, info, warn
                if (e.message && e.message.startsWith("error.")) {
                  const index = e.message.split(".")[1];
                  if (I18NStringResource[index]) {
                    message = I18NStringResource[index];
                  }
                }
                if (e.errorLevel === 'error') {
                  Util.notify('ERROR', message);
                } else if (message.includes("delete") && message.includes("TERMINATING")) { // skip any error triggered by DELETE_IN_PROGRESS
                  Util.consoleLogInfo("landingPageNavButtons", I18NStringResource.computeResourcePageDeleteInProgress);
                } else {
                  Util.notify('ERROR', I18NStringResource.computeResourcePageServerError);
                  Util.consoleLogError("landingPageNavButtons", errorCode + " : " + message);
                }
              } else {
                Util.notify('ERROR', I18NStringResource.computeResourcePageServerError);
                Util.consoleLogError("landingPageNavButtons", e);
              }
              row.updateStatus();
            }
          };

          if (LandingPageNavButtons.actionTypeShowsWarningDialog(actionType)) {
            LandingPageNavButtons.showWarningDialog(action, actionType, item.params.initial_startup_check, function(){
              row.updateStatus();
            });
          } else {
            LandingPageNavButtons.doAction(action, actionType);
          }
          return false;
        }, logFn);
        return link;
      }

      static getRowElementFromRowObject (row) {
        let rowElement;
        if (row && typeof row === 'object' && ("getElement" in row) && row.getElement()) {
          rowElement = row.getElement();
        } else {
          throw new TypeError("Invalid row argument");
        }
        return rowElement;
      }

      static disableRowButtons (row) {
        const rowElement = LandingPageNavButtons.getRowElementFromRowObject(row);
        for (const className of [".resource-primary-cta", ".resource-menu"]) {
          let btns = rowElement.querySelectorAll(className + " button");
          if (btns) {
            for (const btn of btns) {
              btn.disabled = true;
              btn.classList.add("disabled");
            }
          }
        }
      }

      static enableRowButtons (row) {
        const status = row.getStatusFromRow();
        const rowElement = LandingPageNavButtons.getRowElementFromRowObject(row);
        for (const className of [".resource-primary-cta", ".resource-menu"]) {
          let btns = rowElement.querySelectorAll(className + " button");
          if (btns) {
            for (const btn of btns) {
              if ((status === CloudStatus.Enum.RUNNING) || !btn.id.startsWith("cta-")) {
                btn.disabled = false;
                btn.classList.remove("disabled");
              }
            }
          }
        }
      }

      static dragNotAllowed (event) {
         event.preventDefault();
         event.originalEvent.dataTransfer.dropEffect = 'none';
      }

      static actionTypeShowsWarningDialog (actionType) {
        let usesWarningDialog = false;
        switch (actionType) {
          case "stop": //reset
          case "archive": //delete
          case "hard-delete": //reset & delete
          case "pause": //stop
            usesWarningDialog = true;
            break;
          default:
            usesWarningDialog = false;
        }
        return usesWarningDialog;
      }

      static doAction (action, actionType) {
        const method = action.bind(this, actionType);
        method();
      }

      static showWarningDialog (proceedMethod, actionType, startupType, cancelMethod) {
        let dialogTitle = "";
        let proceedPrompt = "";
        let dialogPromptText = "";
        let exitWizardButtonLabel = "";
        switch (actionType) {

          case "stop": //reset
          dialogTitle = I18NStringResource.computeResourcePageConfirmStopDialogTitle;
          if (startupType === "template_only") {
            dialogPromptText = I18NStringResource.computeResourcePageConfirmStopTemplateOnlyDialogPromptText;
            proceedPrompt = I18NStringResource.computeResourcePageConfirmStopTemplateOnlyProceedPrompt;
          } else {
            proceedPrompt = I18NStringResource.computeResourcePageConfirmStopProceedPrompt;
            dialogPromptText = I18NStringResource.computeResourcePageConfirmStopDialogPromptText;
          }
          break;

          case "archive": //delete
            dialogTitle = I18NStringResource.computeResourcePageConfirmDeleteDialogTitle;
            proceedPrompt = I18NStringResource.computeResourcePageConfirmDeleteProceedPrompt;
            dialogPromptText = I18NStringResource.computeResourcePageConfirmDeleteDialogPromptText;
          break;

          case "hard-delete": //reset & delete
            dialogTitle = I18NStringResource.computeResourcePageConfirmDeleteDialogTitle;
            proceedPrompt = I18NStringResource.computeResourcePageConfirmDeleteProceedPrompt;
            dialogPromptText = I18NStringResource.computeResourcePageConfirmDeleteDialogPromptText;
            exitWizardButtonLabel = I18NStringResource.computeResourcePageConfirmDeleteDialogExitButtonLabel;
          break;

          case "pause": //stop
            dialogTitle = I18NStringResource.computeResourcePageConfirmPauseDialogTitle;
            if (startupType === "template_only") {
              dialogPromptText = I18NStringResource.computeResourcePageConfirmStopTemplateOnlyDialogPromptText;
              proceedPrompt = I18NStringResource.computeResourcePageConfirmStopTemplateOnlyProceedPrompt;
            } else {
              proceedPrompt = I18NStringResource.computeResourcePageConfirmPauseProceedPrompt;
              dialogPromptText = I18NStringResource.computeResourcePageConfirmPauseDialogPromptText;
            }
          break;

          default:
            dialogTitle = I18NStringResource.computeResourcePageConfirmUnknownDialogTitle + actionType;
            proceedPrompt = I18NStringResource.computeResourcePageConfirmUnknownProceedPrompt + actionType + "?";
            dialogPromptText = I18NStringResource.computeResourcePageConfirmUnknownDialogPromptText;

        }
        //show modal dialog for confirmation
        let wizardWarningModalDialog = new WizardWarningDialog({
          title: dialogTitle,
          text: dialogPromptText,
          prompt: proceedPrompt,
          actionFn: proceedMethod.bind(this),
          cancelFn: (cancelMethod)?cancelMethod.bind(this):null,
          hideIcon: false,
          exitWizardButtonLabel: exitWizardButtonLabel
        });
        wizardWarningModalDialog.show();
      }

    static stringToURL (contents, mimeType) {
      return URL.createObjectURL(new Blob([contents], {type: mimeType}));
    }

  }

  return LandingPageNavButtons;
}); // require
