/* jshint esversion: 11 */
define([
    "underscore",
    "lit-element/lit-element.bundled",
    "components/cloudCenterElement",
    "util",
    "dojo/i18n!nls/cloudCenterStringResource",
    "validation/inlineComponentValidation",
    "components/inlinePopover"
], function(
    _,
    Lit,
    CloudCenterElement,
    Util,
    I18NStringResource,
    InlineComponentValidation,
    InlinePopover
) {

    class MWClusterWorkerPicker extends CloudCenterElement {

        constructor () {
            super();
        }

        cceInitialize () {
            super.cceInitialize();
            Util.consoleLogTrace('MWClusterWorkerPicker`, `cceInitialize invoked');
            // Default values
            this.dduxenabled = false;
            this.vmsizepickerid = "";
            this.vmsizepickerlabel = "";
            this.vmsizepickerplaceholder = "";
            this.vmsizepickerpoptext = "";
            this.vmsizepickervalue = "";
            this.numworkerspernodeid = "";
            this.numworkerspernodelabel = "";
            this.numworkerspernodeplaceholder = "";
            this.numworkerspernodepoptext = "";
            this.numworkerspernodevalue = "";
            this.maxnumworkersid = "";
            this.maxnumworkerslabel = "";
            this.maxnumworkersplaceholder = "";
            this.maxnumworkerspoptext = "";
            this.maxnumworkersvalue = "";
            this.machinesinclusterid = "";
            this.machinesinclusterlabel = "";
            this.machinesinclusterplaceholder = "";
            this.machinesinclusterpoptext = "";
            this.machinesinclustervalue = "";
            this.initialnummachinesinclusterid = "";
            this.initialnummachinesinclusterlabel = "";
            this.initialnummachinesinclusterplaceholder = "";
            this.initialnummachinesinclusterpoptext = "";
            this.initialnummachinesinclustervalue = "";
            this.minnummachinesinclusterid = "";
            this.minnummachinesinclusterlabel = "";
            this.minnummachinesinclusterplaceholder = "";
            this.minnummachinesinclusterpoptext = "";
            this.minnummachinesinclustervalue = "";
            this.enableautoscalingcheckboxid = "";
            this.enableautoscalingcheckboxlabel = "";
            this.enableautoscalingcheckboxplaceholder = "";
            this.enableautoscalingcheckboxpoptext = "";
            this.enableautoscalingcheckboxvalue = "No";
            this.enableautoscalingcheckboxchecked = false;
            this.enableautoscalingcheckboxvalidvalues = ["No", "Yes"];
            // setup debounced event handler so it only gets called once per flurry of same events
            // Both vpc and subnet selects will receive "optionsLoaded" events. This tries to
            // update the UI once after the last event (assuming they all come in within a second).
            this._debouncedOptionsLoaded = _.debounce(this._onOptionsLoaded, 1000).bind(this);
            this.isInitialized = false;
        }

        static get properties () {
            return {
                disabled: {type: Boolean},
                vmsizepickerid: {type: String},
                vmsizepickerlabel: {type: String},
                vmsizepickerplaceholder: {type: String},
                vmsizepickerpoptext: {type: String},
                vmsizepickervalue: {type: String},
                numworkerspernodeid: {type: String},
                numworkerspernodelabel: {type: String},
                numworkerspernodeplaceholder: {type: String},
                numworkerspernodepoptext: {type: String},
                numworkerspernodevalue: {type: String},
                maxnumworkersid: {type: String},
                maxnumworkerslabel: {type: String},
                maxnumworkersplaceholder: {type: String},
                maxnumworkerspoptext: {type: String},
                maxnumworkersvalue: {type: String},
                machinesinclusterid: {type: String},
                machinesinclusterlabel: {type: String},
                machinesinclusterplaceholder: {type: String},
                machinesinclusterpoptext: {type: String},
                machinesinclustervalue: {type: String},
                initialnummachinesinclusterid: {type: String},
                initialnummachinesinclusterlabel: {type: String},
                initialnummachinesinclusterplaceholder: {type: String},
                initialnummachinesinclusterpoptext: {type: String},
                initialnummachinesinclustervalue: {type: String},
                minnummachinesinclusterid: {type: String},
                minnummachinesinclusterlabel: {type: String},
                minnummachinesinclusterplaceholder: {type: String},
                minnummachinesinclusterpoptext: {type: String},
                minnummachinesinclustervalue: {type: String},
                enableautoscalingcheckboxid: {type: String},
                enableautoscalingcheckboxlabel: {type: String},
                enableautoscalingcheckboxplaceholder: {type: String},
                enableautoscalingcheckboxpoptext: {type: String},
                enableautoscalingcheckboxvalue: {type: String},
                enableautoscalingcheckboxchecked: {type: Boolean},
                enableautoscalingcheckboxvalidvalues: {type: Array}
            };
        }

        // Called after initial rendering
        firstUpdated () {
            Util.consoleLogTrace(`MWClusterWorkerPicker.firstUpdated`, `invoked`);
            const maxWorkersInClusterInput = this.renderRoot.querySelector(`input[type="number"]#${this.maxnumworkersid}`);
            const workersPerNode = this._getNumWorkersPerNode();
            if (maxWorkersInClusterInput) {
                maxWorkersInClusterInput.value = workersPerNode;
                maxWorkersInClusterInput.dispatchEvent(new Event('change'));
            }
        }

        _getAffirmativeValue () {
            return this.enableautoscalingcheckboxvalidvalues[1];
        }

        _getNegativeValue () {
            return this.enableautoscalingcheckboxvalidvalues[0];
        }

        validate (testTargetId, testTargetTag) {
            const elementSelectInfo = this.getElementSelectionFieldsFromTestTarget(testTargetId, testTargetTag);
            Util.consoleLogTrace(`MWClusterWorkerPicker.validate`, `invoked for ${elementSelectInfo.selector}`);
            let isValid = true;
            const targetElement = this.renderRoot.querySelector(elementSelectInfo.selector);
            const selectedWorkerTypeCounts = this._getSelectedWorkerInstanceTypeCounts();
            if (targetElement) {
                switch (testTargetId) {
                    case this.numworkerspernodeid:
                        const numWorkersPerNode = parseInt(targetElement.value);
                        isValid = (0 < numWorkersPerNode && numWorkersPerNode <= selectedWorkerTypeCounts.core);
                        break;
                    case this.maxnumworkersid:
                        if ( (this._getMaxWorkerCount() < this._getNumWorkersPerNode()) ||
                                (this._getMaxWorkerCount() > 1024) ||
                                (!targetElement.checkValidity()) ) {
                            isValid = false;
                        }
                        break;
                    default:
                        break;
                }
            }
            return isValid;
        }

        _requiresAdditionalFractionOfAMachine () {
            return ((this._getMaxWorkerCount() % this._getNumWorkersPerNode()) !== 0);
        }

        _getSelectedWorkerInstanceTypeCounts () {
            const selectedOptionCounts = {
                core: -1,
                vCPU: -1,
                GPU: -1
            };
            const vmSizeSelect = this.renderRoot.querySelector(`select#${this.vmsizepickerid}`);
            if (vmSizeSelect) {
                const selectedOptionIndex = vmSizeSelect.selectedIndex;
                if (selectedOptionIndex > 0) {
                    const optionText = vmSizeSelect.options[selectedOptionIndex].text;
                    const countDataRegex = /^.*\((.*)\)$/;
                    const numberData = countDataRegex.exec(optionText);
                    if (numberData && numberData.length > 1) {
                        const dataPairs = numberData[1].split(',');
                        const dataPairRegex = /^([\d \.]+) (.*)$/;
                        for (const pair of dataPairs) {
                            const matches = dataPairRegex.exec(pair.trim());
                            if (matches && matches.length === 3) {
                                const key = matches[2];
                                const val = parseFloat(matches[1]);
                                selectedOptionCounts[key] = val;
                            }
                        }
                    }
                }
            }
            return selectedOptionCounts;
        }

        _onWorkerMachineTypeChange (event) {
            const selectedOptionCounts = this._getSelectedWorkerInstanceTypeCounts();
            let numWorkersPerNode = String((selectedOptionCounts.GPU > 0 ? selectedOptionCounts.GPU : (selectedOptionCounts.core > 0 ? selectedOptionCounts.core: 0)));
            if (!this.isInitialized && this.numworkerspernodevalue) {
                numWorkersPerNode = this.numworkerspernodevalue;
            }
            const numWorkersPerNodeInput = this.renderRoot.querySelector(`input#${this.numworkerspernodeid}`);
            const maxNumWorkersInput = this.renderRoot.querySelector(`input[type="number"]#${this.maxnumworkersid}`);
            if (Boolean(!isNaN(numWorkersPerNode) && (typeof numWorkersPerNodeInput === 'object'))) {
                numWorkersPerNodeInput.value = numWorkersPerNode;
                numWorkersPerNodeInput.max = selectedOptionCounts.core;
                if (this._isAutoScalingEnabled()) {
                    this.updateInitialWorkersCount(0);
                } else {
                    this.updateInitialWorkersCount(numWorkersPerNode);
                }
                this._setMaxWorkerCountIncrement(numWorkersPerNode);
                maxNumWorkersInput.dispatchEvent(new Event("blur")); // force validation
            }
            this.updateMachinesInCluster();

        }

        _onWorkersPerMachineChange (event) {
            const numWorkersPerNode = this._getNumWorkersPerNode();
            this._setMaxWorkerCountIncrement(numWorkersPerNode);
            this.updateInitialWorkersCount(numWorkersPerNode);
            this._setMaxWorkerCountIncrement(numWorkersPerNode);
            const machinesInCluster = this._getMachinesInClusterCount();
            this.initialnummachinesinclustervalue = machinesInCluster;
            this.minnummachinesinclustervalue = machinesInCluster;
            this.updateMachinesInCluster();
        }

        updateMachinesInCluster() {
            const numMachinesInClusterInput = this.renderRoot.querySelector(`input[type="number"]#${this.machinesinclusterid}`);
            if (numMachinesInClusterInput) {
                const fractionalCount = this._getMaxWorkerCount() / this._getNumWorkersPerNode();
                const needFractionOfMachine = this._requiresAdditionalFractionOfAMachine();
                const numMachinesInCluster = (needFractionOfMachine ? Math.trunc(fractionalCount) + 1 : Math.trunc(fractionalCount));
                numMachinesInClusterInput.value = numMachinesInCluster;
                if (!this._isAutoScalingEnabled()) {
                    this.initialnummachinesinclustervalue = numMachinesInCluster;
                    this.minnummachinesinclustervalue = numMachinesInCluster;
                } else {
                    this.initialnummachinesinclustervalue = 0;
                    this.minnummachinesinclustervalue = 0;
                }
            }
        }

        updateInitialWorkersCount (count) {
            const initialNumWorkersInput = this.renderRoot.querySelector(`input[type="number"]#initialNumWorkersInCluster`);
            if (initialNumWorkersInput) {
                initialNumWorkersInput.value = String(count);
            }
        }

        _onMaxWorkersInClusterChange (event) {
            const count = this._getMaxWorkerCount();
            if (!this._isAutoScalingEnabled()) {
                this.updateInitialWorkersCount(count);
            }
            this.updateMachinesInCluster();
        }

        _getMachinesInClusterCount () {
            let count = -1;
            const numMachinesInClusterInput = this.renderRoot.querySelector(`input[type="number"]#${this.machinesinclusterid}`);
            if (numMachinesInClusterInput) {
                count = parseInt(numMachinesInClusterInput.value);
            }
            return count;
        }

        _getInitialWorkerCount () {
            let count = -1;
            const initialNumWorkersInput = this.renderRoot.querySelector(`input[type="number"]#initialNumWorkersInCluster`);
            if (initialNumWorkersInput) {
                count = parseInt(initialNumWorkersInput.value);
            }
            return count;
        }

        _getMaxWorkerCount () {
            let count = -1;
            const maxNumWorkersInput = this.renderRoot.querySelector(`input[type="number"]#${this.maxnumworkersid}`);
            if (maxNumWorkersInput) {
                count = parseInt(maxNumWorkersInput.value);
            }
            return count;
        }

        _setMaxWorkerCountIncrement (increment) {
            const maxNumWorkersInput = this.renderRoot.querySelector(`input[type="number"]#${this.maxnumworkersid}`);
            if (maxNumWorkersInput) {
                maxNumWorkersInput.step = String(increment);
                maxNumWorkersInput.min = String(increment);
            }
        }

        _getNumWorkersPerNode () {
            let count = -1;
            const numWorkersPerNodeInput = this.renderRoot.querySelector(`input[type="number"]#${this.numworkerspernodeid}`);
            if (numWorkersPerNodeInput) {
                count = parseInt(numWorkersPerNodeInput.value);
            }
            return count;
        }

        _isAutoScalingEnabled () {
            let isAutoScalingEnabled = false;
            const checkboxElement = this.renderRoot.querySelector(`input[type="checkbox"]#${this.enableautoscalingcheckboxid}`);
            if (checkboxElement) {
                isAutoScalingEnabled = checkboxElement.checked;
            }
            if (!isAutoScalingEnabled) {
                checkboxElement.removeAttribute('checked');
            }
            return isAutoScalingEnabled;
        }

        _onEnableAutoScalingChange (event) {
           if (this._isAutoScalingEnabled()) {
            this.updateInitialWorkersCount(0);
            this.initialnummachinesinclustervalue = 0;
            this.minnummachinesinclustervalue = 0;
            this.enableautoscalingcheckboxvalue = this._getAffirmativeValue();
            this.enableautoscalingcheckboxchecked = true;
           } else {
            const count = this._getMaxWorkerCount();
            this.updateInitialWorkersCount(count);
            const machinesInClusterCount = this._getMachinesInClusterCount();
            this.initialnummachinesinclustervalue = machinesInClusterCount;
            this.minnummachinesinclustervalue = machinesInClusterCount;
            this.enableautoscalingcheckboxvalue = this._getNegativeValue();
            this.enableautoscalingcheckboxchecked = false;
           }
           const inc = parseInt(this._getNumWorkersPerNode());
           this._setMaxWorkerCountIncrement(inc);
       }

       _onOptionsLoaded (event) { 
            // The options are loaded with a backend default.
            // If an override value is specified in the attribute vmsizepickervalue,
            // update the select once its options are loaded.
            Util.consoleLogTrace(`_onOptionsLoaded`, `called`); 
            const vmSizePicker = this.renderRoot.querySelector(`select#${this.vmsizepickerid}`);
            const currentValue = vmSizePicker.value;
            if (currentValue !== this.vmsizepickervalue) {
                vmSizePicker.value = this.vmsizepickervalue;
                vmSizePicker.dispatchEvent(new Event("change"));
            }
            this.isInitialized = true; 
        }

        // Only allow input of UP and DOWN arrow keys on number inputs.
        // Of course, clicking on input's spinner also works.
        // This forces changes to be in step-size increments and decrements.
        _allowOnlyUpDownArrowInput (event) {
            if (event) {
                const code = event.keyCode || event.which;
                if (code != 9 && code != 16) {
                    event.preventDefault();
                }
                const focusedElement = document.activeElement;
                const section2InputValidationContainer = focusedElement.parentElement;
                Util.hideValidationError(section2InputValidationContainer);
                const isNumber = (code >= 48 && code <= 57); // "0" <= code <= "9"
                const isUpDownArrow = (code == 38 || code == 40 ); // UP arrow key or DOWN arrow key
                if (isNumber || isUpDownArrow) {
                    if (event.preventDefault) {
                        event.preventDefault();
                    }    
                }
                if (isUpDownArrow) {
                    if (focusedElement && focusedElement.tagName.toUpperCase() === "INPUT" && focusedElement.type === 'number') {
                        if (code == 38) {
                            focusedElement.stepUp();
                        }
                        if (code == 40) {
                            focusedElement.stepDown();
                        }
                        focusedElement.dispatchEvent(new Event("change"));
                    }
                } else  { // other keys
                    if (isNumber) {
                        Util.displayValidationError(section2InputValidationContainer, I18NStringResource.workerPickerNumKeydownError);
                        return false;
                    }
                }
            }
        }

        _addArrowKeysSubText () {
            return Lit.html`<sub>${I18NStringResource.workerPickerUseArrowKeys}&uarr;&darr;</sub>`;
        }

        // Render element template
        render () {
            return Lit.html`
                <fieldset class="mwClusterWorkerPickerFieldset" id="${'mwClusterWorkerPickerFieldset' + this.timestamp}">
                    <fieldset class="workersInCluster">
                        <legend>${I18NStringResource.workerPickerWorkersInClusterFieldsetLabel}</legend>
                        <div class="workersInClusterCounts">
                            <div class="inputContainer">
                                <div class="labelContainer">
                                    <label for="initialNumWorkersInCluster">${I18NStringResource.workerPickerInitialNumWorkersInClusterLabel}</label>
                                </div>
                                <div class="section2InputValidationContainer disabled">
                                    <input type="number" name="initialNumWorkersInCluster" id="initialNumWorkersInCluster" value="0" readonly />
                                    <inline-validation elementid="initialNumWorkersInCluster" />
                                </div>
                                <inline-popover poptext="${I18NStringResource.workerPickerInitialNumWorkersInClusterPopText}" />
                            </div>
                            <div class="inputContainer ${!this.isMobile ? "withSub" : ""}">
                                <div class="labelContainer">
                                    <label for="${this.maxnumworkersid}">${I18NStringResource.workerPickerMaxNumWorkersInClusterUpperLimit}</label>
                                </div>
                                <div class="section2InputValidationContainer siblingAfterNote">
                                    <input  type="number" 
                                            name="${this.maxnumworkersid}" 
                                            id="${this.maxnumworkersid}" 
                                            value="${this.maxnumworkersvalue}"
                                            min="1"
                                            max="1024"
                                            ?disabled=${this.disabled}
                                            @keydown=${this.isMobile ? Lit.nothing : this._allowOnlyUpDownArrowInput}
                                            @change=${this._onMaxWorkersInClusterChange} />
                                    <inline-validation elementid="${this.maxnumworkersid}" />
                                </div>
                                ${!this.isMobile ? this._addArrowKeysSubText() : Lit.nothing}
                                <div class="afterNote">${I18NStringResource.workerPickerMaxWorkersInClusterNote}</div>
                                <inline-popover poptext="${this.maxnumworkerspoptext}" />
                            </div>
                        </div>
                        <div class="workersInClusterCounts">
                            <div class="inputContainer">
                                <div class="section2InputValidationContainer">
                                    <input  type="checkbox"
                                            data-checkboxtype="yesno"
                                            name="${this.enableautoscalingcheckboxid}" 
                                            id="${this.enableautoscalingcheckboxid}" 
                                            value="${this.enableautoscalingcheckboxvalue}"
                                            ?checked=${this.enableautoscalingcheckboxchecked}
                                            ?disabled=${this.disabled}
                                            @change=${this._onEnableAutoScalingChange} />
                                </div>
                                <div class="standardCheckboxLabelContainer">
                                    <label for="${this.enableautoscalingcheckboxid}">${this.enableautoscalingcheckboxlabel}</label>
                                </div>
                                <inline-popover poptext="${this.enableautoscalingcheckboxpoptext}" />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset class="workersInCluster">
                        <legend>${I18NStringResource.workerPickerMachinesInClusterFieldsetLabel}</legend>
                        <div class="machinesInClusterInputs">
                            <div class="inputContainer">
                                <div class="labelContainer">
                                    <label for="${this.vmsizepickerid}">${this.vmsizepickerlabel}</label>
                                </div>
                                <div class="section2InputValidationContainer">
                                    <select name="${this.vmsizepickerid}"
                                            id="${this.vmsizepickerid}" 
                                            ?disabled=${this.disabled}
                                            @change=${this._onWorkerMachineTypeChange}
                                            @nonDependentOptionsLoaded=${{handleEvent: () => this._debouncedOptionsLoaded(), once: true}}>
                                        <option value="" disabled hidden selected>${this.vmsizepickerplaceholder}</option>
                                    </select>
                                    <inline-validation elementid="${this.vmsizepickerid}" />
                                </div>
                                <inline-popover poptext="${this.vmsizepickerpoptext}" />
                            </div>
                            <div class="inputContainer ${!this.isMobile ? "withSub" : ""}">
                                <div class="labelContainer">
                                    <label for="${this.numworkerspernodeid}">${this.numworkerspernodelabel}</label>
                                </div>
                                <div class="section2InputValidationContainer">
                                    <input  type="number" 
                                            name="${this.numworkerspernodeid}" 
                                            id="${this.numworkerspernodeid}" 
                                            value="${this.numworkerspernodevalue}"
                                            ?disabled=${this.disabled}
                                            @keydown=${this.isMobile ? Lit.nothing : this._allowOnlyUpDownArrowInput}
                                            @change=${this._onWorkersPerMachineChange}
                                            min="1" />
                                    <inline-validation elementid="${this.numworkerspernodeid}" />
                                </div>
                                ${!this.isMobile ? this._addArrowKeysSubText() : Lit.nothing}
                                <inline-popover poptext="${this.numworkerspernodepoptext}" />
                            </div>
                            <div class="inputContainer">
                                <div class="labelContainer">
                                    <label for="${this.machinesinclusterid}">${I18NStringResource.workerPickerMachinesInClusterLabel}</label>
                                </div>
                                <div class="section2InputValidationContainer siblingAfterNote disabled">
                                    <input type="number" name="${this.machinesinclusterid}" id="${this.machinesinclusterid}" value="${this.machinesinclustervalue}" readonly />
                                    <inline-validation elementid="${this.machinesinclusterid}" />
                                </div>
                                <div class="afterNote">${I18NStringResource.workerPickerMachinesInClusterNote}</div>
                                <inline-popover poptext="${this.machinesinclusterpoptext}" />
                            </div>
                        </div>
                    </fieldset>
                    <div class="hiddenFieldContainer">
                        <input type="hidden" name="${this.initialnummachinesinclusterid}" id="${this.initialnummachinesinclusterid}" value="${this.initialnummachinesinclustervalue}" />
                        <input type="hidden" name="${this.minnummachinesinclusterid}" id="${this.minnummachinesinclusterid}" value="${this.minnummachinesinclustervalue}" />
                    </div>
                    <div class="afterNote costWarning">${I18NStringResource. workerPickerAfterNote}</div>
                </fieldset>
            `;
        }

    }

    // Register custom element '<mw-cluster-worker-picker />'
    customElements.define('mw-cluster-worker-picker', MWClusterWorkerPicker);

    return MWClusterWorkerPicker;
});