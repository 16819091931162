/* jshint esversion: 8 */
/*
  Application-wide constants
*/
define([], function () {
  const CCWA_Constants = Object.freeze({
    MICROSOFT_AZURE_CREATE_REFRESH_TOKEN_CREDENTIAL_ID: "37cd6705bfa576174e8d2aeff7ca2bb8",
    AMAZON_AWS_CREATE_ROLE_CREDENTIAL_ID: "ee8ac4214f8d9465c87a45c7c8d7ad15",
    AMAZON_AWS_CFE_DEMOS_CREDENTIAL_ID: "1ad385a3c4d1539c066227aacb88cac1",
    ALL_TOGETHER_NOW_CREDENTIAL_TYPE_ID: "a6f5bc624e4670f054d3aadc46d92715",
    MICROSOFT_GRAPH_CREATE_REFRESH_TOKEN_CREDENTIAL_ID: "ea11b7f92d5d247eacebb85a9ed8f5b1",
    CLOUD_CENTER_HELP_DOC_URL: "https://www.mathworks.com/help/cloudcenter/mathworks-cloud-center.html",
    loglevels: {
      ERROR: 1,
      WARNING: 2,
      INFO: 4,
      TRACE: 8
    },
    HTTP_PORT: "80",
    HTTPS_PORT: "443",
    NICE_DCV_PORT: "8443",
    RDP_PORT: "3389",
    PARALLEL_SERVER_PORT_START: "27350",
    PARALLEL_SERVER_PORT_END: "27613",
    COUNTDOWN_REFRESH_INTERVAL: 1,
    // 31 seconds before data is staleZZ
    REFRESH_CREDENTIAL_INFO_INTERVAL: (30 * 1000) + 1,
    CLOUD_CENTER_VAULT_INTERNAL_ERROR: "cloudcenter.vault.error.internalerror",
    UNKNOWN_CREDENTIAL_TYPE_ID: "unknown",
    UNKNOWN_CREDENTIAL_PLATFORM: "unknown",
    WEB_DESKTOP_TAG: 'webdesktop',
    WEB_DESKTOP_AUTH_PARAM: 'mwi_auth_token',
    NO_CRED_PROMPT: "no_cred_prompt",
    CLOUD_LINK_CUSTOM_ACCESS_PREFIX: "custom_access_",
    CLOUD_LINK_DIRECT_CONNECT_OPERAND: "_direct"
  });

  return CCWA_Constants;
});
