/* jshint esversion: 8 */
define([
    "util"
], function( Util) {

    /**
     * EC2Service handles AWS ec2-specific interaction with Cloud Center server via DAO.
     */
    class EC2Service {

        constructor (args) {
            if (!args || typeof args !== "object" || !args.dao) {
            throw new TypeError("Invalid args argument");
            }
            this.dao = args.dao;
        }

        getDAO () { return this.dao; }

        /****
            Private API calls to AWS EC2 service
        ****/

        async getRegion (credId) {
            Util.consoleLogTrace("EC2Service.getRegion", "called");
            const credIdValid = (credId && typeof credId === 'string' && Util.isMD5(credId));
            if (!credIdValid) {
                throw new TypeError("Invalid credId argument");
            }
            return await this.getDAO().getCCAPI("awsec2", "resource", credId, "region", undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async getInstanceType (credId, region, subnet) {
            Util.consoleLogTrace("EC2Service.getInstanceType", "called");
            const credIdValid = (credId && typeof credId === 'string' && Util.isMD5(credId));
            if (!credIdValid) {
                throw new TypeError("Invalid credId argument");
            }
            const regionValid = (region && typeof region === 'string');
            if (!regionValid) {
                throw new TypeError("Invalid region argument");
            }
            const params = {region:region};
            if (subnet) {
                if (typeof subnet !== 'string') {
                    throw new TypeError("Invalid subnet argument");
                }
                params.subnet = subnet;
            }
            return await this.getDAO().getCCAPI("awsec2", "resource", credId, "instancetype", params, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async getSSHKey (credId, region) {
            Util.consoleLogTrace("EC2Service.getSSHKey", "called");
            const credIdValid = (credId && typeof credId === 'string' && Util.isMD5(credId));
            if (!credIdValid) {
                throw new TypeError("Invalid credId argument");
            }
            const regionValid = (region && typeof region === 'string');
            if (!regionValid) {
                throw new TypeError("Invalid region argument");
            }
            return await this.getDAO().getCCAPI("awsec2", "resource", credId, "sshkey", {region:region, refresh:"false"}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async getImage (credId, rulesId, region, filterOnCurrentUser = false) {
            Util.consoleLogTrace('EC2Service.getImage', 'called');
            const credIdValid = credId && typeof credId === 'string' && Util.isMD5(credId);
            if (!credIdValid) {
                throw new TypeError('Invalid credId argument');
            }
            const regionValid = region && typeof region === 'string';
            if (!regionValid) {
                throw new TypeError('Invalid region argument');
            }
            return await this.getDAO().getCCAPI('workflow','resource',credId,'image',{
                region: region,
                all: 'false',
                rules_id: rulesId,
                refresh: 'false',
                apply_user_filter: filterOnCurrentUser
            },{ fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout() });
        }

        async getVPC (credId, region) {
            Util.consoleLogTrace("EC2Service.getVPC", "called");
            const credIdValid = (credId && typeof credId === 'string' && Util.isMD5(credId));
            if (!credIdValid) {
                throw new TypeError("Invalid credId argument");
            }
            const regionValid = (region && typeof region === 'string');
            if (!regionValid) {
                throw new TypeError("Invalid region argument");
            }
            return await this.getDAO().getCCAPI("awsec2", "resource", credId, "vpc", {region:region, refresh:"false"}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async getSubnet (credId, region, vpc) {
            Util.consoleLogTrace("EC2Service.getSubnet", "called");
            const credIdValid = (credId && typeof credId === 'string' && Util.isMD5(credId));
            if (!credIdValid) {
                throw new TypeError("Invalid credId argument");
            }
            const regionValid = (region && typeof region === 'string');
            if (!regionValid) {
                throw new TypeError("Invalid region argument");
            }
            if (!vpc || typeof vpc !== "string") {
                throw new TypeError("Invalid vpc argument");
            }
            return await this.getDAO().getCCAPI("awsec2", "resource", credId, "subnet", {vpc:vpc, region:region, refresh:"false"}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async getSSLCertificate (credId, region) {
            Util.consoleLogTrace("EC2Service.getSSLCertificate", "called");
            const credIdValid = (credId && typeof credId === 'string' && Util.isMD5(credId));
            if (!credIdValid) {
                throw new TypeError("Invalid credId argument");
            }
            const regionValid = (region && typeof region === 'string');
            if (!regionValid) {
                throw new TypeError("Invalid region argument");
            }
            return await this.getDAO().getCCAPI("awsec2", "resource", credId, "sslcertificate", {region:region, refresh:"true"}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async createSSLCertificate (credId, region, orgName, countryCode) {
            Util.consoleLogTrace("EC2Service.createtSSLCertificate", "called");
            const credIdValid = (credId && typeof credId === 'string' && Util.isMD5(credId));
            if (!credIdValid) {
                throw new TypeError("Invalid credId argument");
            }
            const regionValid = (region && typeof region === 'string');
            if (!regionValid) {
                throw new TypeError("Invalid region argument");
            }
            if (!orgName || typeof orgName !== 'string') {
                throw new TypeError("Invalid orgName argument");
            }
            if (!countryCode || typeof countryCode !== 'string' || countryCode.length !== 2) {
                throw new TypeError("Invalid countryCode argument");
            }
            return await this.getDAO().postCCAPI("awsec2", "resource", credId, "sslcertificate", {
                region: region,
                refresh: "false",
                country: countryCode,
                organization: orgName
            }, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

    }
    return EC2Service;
});
