/* Copyright 2012-2020 The MathWorks, Inc. */
define([
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/aspect',
    'dojo/Deferred',
    'mw-log/Log',
    './MessageServiceState',
    './transportEnums'
], function (declare, lang, aspect, Deferred, Log, MessageServiceState, transportEnums) {
    /**
     * This is the base implementation of the message service. It is responsible for lifecycle state
     * and subscription dispatching. It has a delegate message service which it uses to perform
     * actions. The delegate has very little state and returns promises and a couple events to
     * signal to this class.
     */
    return declare([MessageServiceState], {

        logSubscriptions: false,

        constructor: function () {
            this.batching = true;
            this.typeSerializers = [];
            this.typeDeserializers = {};
            this.channelSubscriptions = {}; // keep track of subscriptions
        },

        setDelegate: function (delegate) {
            this.inherited('setDelegate', arguments);
            aspect.after(this._delegate, 'onMessage', lang.hitch(this, this.handleMessage), true);
        },

        /**
         * Publish a message to a channel
         * @param channel The channel to publish to
         * @param data The message
         */
        publish: function (channel, data) {
            if (this.isConnected() || this._currentState === 'resubscribing') {
                let promise = this._queuePublish(channel, this.serialize(data));
                this._deferProcessQueue();
                return promise;
            } else {
                return this._queuePublish(channel, this.serialize(data));
            }
        },

        /**
         * Subscribe to a channel
         * @param channel The channel to subscribe to
         * @param handler Handler function (can be a string if the scope is specified)
         * @param scope Scope to execute the handler function in
         */
        subscribe: function (channel, handler, scope) {
            if (this.logSubscriptions) {
                Log.info('MessageService subscribe: ' + channel, handler, scope);
            }

            this._addHandler(this.channelSubscriptions, channel, handler, scope);

            if (this.isConnected() || this._currentState === 'resubscribing') {
                let promise = this._queueSubscribe(channel, handler, scope);
                this._deferProcessQueue();
                return promise;
            } else {
                return this._queueSubscribe(channel, handler, scope);
            }
        },

        /**
         * Unsubscribe from a channel. The arguments must be the exact same as used to subscribe.
         * @param channel The channel to unsubscribe from
         * @param handler Handler function (can be a string if the scope is specified)
         * @param scope Scope used to execute the handler function in
         */
        unsubscribe: function (channel, handler, scope) {
            if (this.logSubscriptions) {
                Log.info('MessageService unsubscribe: ' + channel, handler, scope);
            }

            let exists = this._removeHandler(this.channelSubscriptions, channel, handler, scope);

            if (exists) {
                if (this._removeQueuedMatchingSubscribe(channel, handler, scope)) {
                    return (new Deferred()).resolve();
                } else {
                    if (this.isConnected() || this._currentState === 'resubscribing') {
                        let promise = this._queueUnsubscribe(channel, handler, scope);
                        this._deferProcessQueue();
                        return promise;
                    } else {
                        return (new Deferred()).resolve();
                    }
                }
            } else {
                Log.error('Not subscribed to channel: ' + channel + ", can't unsubscribe.");
                return (new Deferred()).reject('Not subscribed to channel: ' + channel +
                    ", can't unsubscribe.");
            }
        },

        /**
         * Manually flush any queued operations (subscribe, unsubscribe, publish).
         */
        flush: function () {
            if (this.isConnected()) {
                this._processQueue();
            } else {
                throw new Error('Can only manually flush the queue when connected.');
            }
        },

        setLatency: function (latency) {
            if (this._delegate && this._delegate.doSetLatency) {
                this._delegate.doSetLatency(latency);
            }
        },

        setErrorRate: function (errorRate) {
            if (this._delegate && this._delegate.doSetErrorRate) {
                this._delegate.doSetErrorRate(errorRate);
            }
        },

        /**
         * Returns transport type if we are connected
         * This is "unknown", "long-polling", or "websockets"
         * New transports may be added in which case additional
         * return values will be possible.
         * @returns string Name of the transport used by messageservice to communicate
         */
        getTransport: function () {
            if (this._delegate && this._delegate.getTransport) {
                return this._delegate.getTransport();
            }
            return transportEnums.UNKNOWN;
        },

        _enterDisconnected: function () {
            this.inherited('_enterDisconnected', arguments);
            this._queuedOps = [];
        },

        _enterConnecting: function () {
            this.inherited('_enterConnecting', arguments);
            // clear the flag that says if we subscribed or not yet
            Object.keys(this.channelSubscriptions).forEach(function (subscriptions) {
                subscriptions.subscribed = false;
            });
        },

        _enterConnected: function () {
            this.inherited('_enterConnected', arguments);
            this._processQueue();
        },

        _enterDisconnecting: function () {
            this._processQueue();
            this.inherited('_enterDisconnecting', arguments);
        },

        _queuePublish: function (channel, data) {
            let deferred = new Deferred();
            this._queuedOps.push({
                type: 'publish',
                channel: channel,
                data: data,
                deferred: deferred
            });
            return deferred.promise;
        },

        _queueSubscribe: function (channel, handler, scope) {
            let deferred = new Deferred();
            this._queuedOps.push({
                type: 'subscribe',
                channel: channel,
                handler: handler,
                scope: scope,
                deferred: deferred
            });
            return deferred.promise;
        },

        /**
         * Looks for an exising subscription in the queue (working backwards)
         * If a subscription exists the function removes it from the queue,
         * fulfills the promise, and returns true.
         *
         * If no matching subscription exists in the queue return false.
         *
         * @private
         *
         * @param {*} channel
         * @param {*} handler
         * @param {*} scope
         * @returns {boolean} True is subscribe is found in queue, false otherwise
         */
        _removeQueuedMatchingSubscribe: function (channel, handler, scope) {
            let queuedItem;
            for (let i = this._queuedOps.length - 1; i >= 0; i -= 1) {
                queuedItem = this._queuedOps[i];
                if (queuedItem.type === 'subscribe' &&
                    queuedItem.channel === channel &&
                    queuedItem.handler === handler &&
                    queuedItem.scope === scope) {
                    // This unsubscribe is being requested while the "subscribe" is still in the
                    // Queue. Don't bother queuing the unsubscribe, and just fulfull the subscribe.
                    this._queuedOps.splice(i, 1);

                    let delegateConnected = this._delegate && this._delegate.delegateConnected();
                    let connected = this.isConnected() && delegateConnected;
                    if (connected) {
                        // Resolve if we are connected
                        queuedItem.deferred.resolve();
                    } else {
                        // Normally we would reject subscribe, but this would
                        // cause an incompatability, as teams are not expecting
                        // rejections due to an unsubscribe before starting MessageService

                        // We could resolve the subscription, but this would also cause an incompatability.
                        // since resolving the subscription could indicate that we are "connected"

                        // Behavior is tested and locked down that these "lost" subscriptions just
                        // have their promises "left hanging" - neither resolved nor rejected.
                    }
                    return true;
                }
            }
        },

        _queueUnsubscribe: function (channel, handler, scope) {
            let deferred = new Deferred();
            this._queuedOps.push({
                type: 'unsubscribe',
                channel: channel,
                handler: handler,
                scope: scope,
                deferred: deferred
            });
            return deferred.promise;
        },

        _processQueue: function () {
            let delegateConnected = this._delegate && this._delegate.delegateConnected();
            let connected = this.isConnected() && delegateConnected;
            if (!connected && this._currentState !== 'disconnecting') {
                // don't process the queue if we don't have a connection to the server
                return;
            }

            this._delegate.doStartBatch();
            let queue = this._queuedOps;
            this._queuedOps = [];
            queue.forEach(function (op) {
                let promise;
                if (op.type === 'publish') {
                    promise = this._delegate.doPublish(op.channel, op.data);
                } else if (op.type === 'subscribe') {
                    promise = this._doSubscribe(op.channel, op.handler, op.scope);
                } else if (op.type === 'unsubscribe') {
                    promise = this._doUnsubscribe(op.channel, op.handler, op.scope);
                }

                // connect the callbacks of the promise to the supplied deferred to pass along the
                // result.
                promise.then(op.deferred.resolve, op.deferred.reject);
            }, this);
            this._delegate.doEndBatch();
        },

        _deferProcessQueue: function () {
            if (this.batching && !this._deferredTimeout) {
                let that = this;
                this._deferredTimeout = setTimeout(function () {
                    that._deferredTimeout = false;
                    that._processQueue();
                }, 0);
            } else if (!this.batching) {
                this._processQueue();
            }
        },

        _doSubscribe: function (channel) {
            if (this.channelSubscriptions[channel].subscribed) {
                return (new Deferred()).resolve();
            } else {
                let result = this._delegate.doSubscribe(channel);
                this.channelSubscriptions[channel].subscribed = true;
                return result;
            }
        },

        _doUnsubscribe: function (channel) {
            if (!this.channelSubscriptions[channel]) {
                return this._delegate.doUnsubscribe(channel);
            } else {
                return (new Deferred()).resolve();
            }
        },

        /**
         * Handle messages from the server.
         * @param message The message to be handled
         */
        handleMessage: function (message) {
            let i;
            if (message && message.channel) {
                if (message.data) {
                    message.data = this.deserialize(message.data);
                }

                let pathParts = message.channel.split('/');
                for (i = 1; i <= pathParts.length; i += 1) {
                    // call any subscribers are registered with a **
                    this._callHandlers(
                        this.channelSubscriptions,
                        pathParts.slice(0, i).concat('**').join('/'),
                        message
                    );
                }

                // call any subs with * in their channel (/chan1/* matches /chan1/star)
                this._callHandlers(
                    this.channelSubscriptions,
                    pathParts.slice(0, pathParts.length - 1).concat('*').join('/'),
                    message
                );
                // the base of the channel should match as well (/chan1/* matches /chan1)
                this._callHandlers(
                    this.channelSubscriptions,
                    pathParts.concat('*').join('/'),
                    message
                );

                // call exact match subscribers
                this._callHandlers(this.channelSubscriptions, message.channel, message);
            }
        },

        /**
         * Register a message service to handle all messages published on a particular channel.
         * @param channel The channel to redirect to the mock message service.
         * @param handler The message service handler (can be a string if the scope is specified).
         * @param scope The scope to execute the handler with.
         */
        registerMessageHandler: function (channel, handler, scope) {
            Log.error('registerMessageHandler is not supported any more.');
        },

        /**
         * Deregister a message service handler.
         * @param channel The channel to redirect to the mock message service.
         * @param handler The message service handler (can be a string if the scope is specified).
         * @param scope The scope to execute the handler with.
         */
        deregisterMessageHandler: function (channel, handler, scope) {
            Log.error('deregisterMessageHandler is not supported any more.');
        },

        registerTypeSerializer: function (typeMatcher, type, serializer) {
            this.typeSerializers.push({
                typeMatcher: typeMatcher,
                type: type,
                serializer: serializer
            });
        },

        registerTypeDeserializer: function (type, deserializer) {
            this.typeDeserializers[type] = deserializer;
        },

        serialize: function (data) {
            if (lang.isArray(data)) {
                return this._serializeArray(data);
            } else if (data instanceof Number || data instanceof Boolean || lang.isString(data)) {
                return data;
            } else {
                return this._serializeObject(data);
            }
        },

        deserialize: function (data) {
            if (lang.isArray(data)) {
                return this._deserializeArray(data);
            } else if (data instanceof Number || data instanceof Boolean || lang.isString(data)) {
                return data;
            } else {
                return this._deserializeObject(data);
            }
        },

        _addHandler: function (channelHandlers, channel, handler, scope) {
            let subscriptions, subscription;
            scope = scope || this;
            if (typeof handler === 'string' && typeof scope[handler] !== 'function') {
                // not a valid handler
                Log.warn('Not adding invalid handler: ' + handler);
                return;
            }

            subscriptions = channelHandlers[channel];
            if (!subscriptions) {
                subscriptions = [];
                channelHandlers[channel] = subscriptions;
            }

            subscription = { channel: channel, handler: handler, scope: scope };
            subscriptions.push(subscription);

            subscriptions.sort(function (a) {
                return a.exclusive ? -1 : 0;
            });
        },

        _removeHandler: function (channelHandlers, channel, handler, scope) {
            let subscriptions;
            scope = scope || this;
            subscriptions = channelHandlers[channel];

            let index = this._getHandlerIndex(subscriptions, { handler: handler, scope: scope });
            if (index >= 0) {
                subscriptions.splice(index, 1);
            }

            if (!subscriptions || subscriptions.length === 0) {
                delete channelHandlers[channel]; // clean up the array
            }
            return index >= 0;
        },

        _callHandlers: function (channelHandlers, channel, message) {
            let subscriptions;
            let i;
            if (channelHandlers.hasOwnProperty(channel)) {
                subscriptions = channelHandlers[channel];
                for (i = 0; i < subscriptions.length; i += 1) {
                    try {
                        if (typeof subscriptions[i].handler === 'string') {
                            // This next line is not a useless call
                            // the "this" param in here is:
                            // - subscriptions[i].scope
                            // If we used a regular function call, this would be:
                            // - subscriptions[i].scope[subscriptions[i].handler]
                            subscriptions[i].scope[subscriptions[i].handler].call( // eslint-disable-line no-useless-call
                                subscriptions[i].scope,
                                message
                            );
                        } else {
                            subscriptions[i].handler.call(subscriptions[i].scope, message);
                        }
                    } catch (e) {
                        let jsonIndentation = 2;
                        Log.error('Error while executing message handler on channel: ' + channel +
                            ', message: ' + JSON.stringify(message, null, jsonIndentation) + ', error: ', e);
                    }
                }
            }
        },

        _getHandlerIndex: function (handlers, handler) {
            if (!handlers) {
                return -1;
            }

            let i;
            for (i = 0; i < handlers.length; i += 1) {
                if (handler.handler === handlers[i].handler &&
                    handler.scope === handlers[i].scope) {
                    return i;
                }
            }
            return -1;
        },

        _serializeObject: function (data) {
            let key, i;

            for (i = 0; i < this.typeSerializers.length; i += 1) {
                let serializer = this.typeSerializers[i];
                if (serializer.typeMatcher(data)) {
                    return {
                        __type__: serializer.type,
                        __value__: serializer.serializer.call(this, data)
                    };
                }
            }

            if (data instanceof window.Image) {
                // ignore images
                return data;
            }

            for (key in data) {
                if (data.hasOwnProperty(key)) {
                    data[key] = this.serialize(data[key]);
                }
            }
            return data;
        },

        _serializeArray: function (data) {
            let i; let len = data.length;
            for (i = 0; i < len; i += 1) {
                data[i] = this.serialize(data[i]);
            }
            return data;
        },

        _deserializeObject: function (data) {
            let key;
            if (data === undefined || data === null) {
                return null;
            }

            if (data.hasOwnProperty('__type__') && data.hasOwnProperty('__value__')) {
                try {
                    return this.typeDeserializers[data.__type__].call(this, data.__value__);
                } catch (e) {
                    Log.error('Error while trying to deserialize: ', data, 'Error: ', e);
                }
            }

            for (key in data) {
                if (data.hasOwnProperty(key) && data[key] !== null && data[key] !== undefined) {
                    data[key] = this.deserialize(data[key]);
                }
            }
            return data;
        },

        _deserializeArray: function (data) {
            let i; let len = data.length;
            for (i = 0; i < len; i += 1) {
                data[i] = this.deserialize(data[i]);
            }
            return data;
        }

    });
});
