define([

], function() {

    class UIElementUtil {
      static shouldBeSelectElement(dataType, uiElementType) {
        let isSelect = false;
        if (UIElementUtil.QUERY_TYPES.includes(dataType) && (uiElementType === 'text' || uiElementType === 'select')) {
          isSelect = true;
        }
        return isSelect;
      }

      static validationFromConstraints(constraints) {
        const validation = {};
        const hasMin = constraints.min_value !== undefined && constraints.min_value !== null && !isNaN(constraints.min_value); //min can be 0
        const hasMax = constraints.max_value !== undefined && constraints.max_value !== null && !isNaN(constraints.max_value); //max can be 0
        if (constraints.regex) {
          validation.regex = constraints.regex;
        } else if (hasMin || hasMax) {
          validation.range = {};
          if (hasMin) {
            validation.range.min = constraints.min_value;
          }
          if (hasMax) {
            validation.range.max = constraints.max_value;
          }
        }
        if (constraints.error && typeof constraints.error === 'string') {
          validation.errorMessage = constraints.error;
        }
        if (constraints.match_param_id) {
          validation.matchValueWith = constraints.match_param_id;
        }
        return validation;
      }

      static getPlatformFromQueryType(queryType) {
        let platform = '';
        if (UIElementUtil.QUERY_TYPES.includes(queryType)) {
          const pieces = queryType.split('_');
          platform = pieces[0];
        }
        return platform;
      }

      static networkType(queryType) {
        const isNetwork = ['aws_ec2_network', 'azure_vnet', 'azure_network'].includes(queryType);
        const isSubnet = ['aws_ec2_subnet', 'azure_subnet'].includes(queryType);
        return [isNetwork, isSubnet];
      }

      static isImage(queryType) {
        return ['aws_ec2_image'].includes(queryType);
      }
    }

    Object.defineProperty(UIElementUtil,
        "QUERY_TYPES", {
        value: [
            "aws_ec2_network",
            "aws_ec2_subnet",
            "aws_ec2_instance_type",
            "aws_ec2_keypair",
            "aws_acm_certificate_arn",
            "aws_ec2_region",
            "aws_ec2_image",
            "azure_resource_group",
            "azure_vnet",
            "azure_network",
            "azure_subnet",
            "azure_vmsize",
            "azure_region",
            "aws_mwa_license_entitlement",
            "azure_mwa_license_entitlement"
        ]
    });

  return UIElementUtil;
});
