
define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/aspect",

    "mw-log/Log",
    "./Trigger"
], function (declare, lang, aspect, Log, Trigger) {

    return declare(Trigger, {
        constructor: function () {
            this.connection = {};
        },

        enable: function () {
            // connect to the event 'name' on the context object and use hitch to ensure that the
            // name of this trigger is passed as a parameter.
            if (lang.isFunction(this.context[this.name])) {
                this.cachedFunction = this.context[this.name];
            }
            this.connection = aspect.after(
                this.context,
                this.name,
                lang.hitch(null, this.triggerHandler, this.name),
                true
            );
        },

        disable: function () {
            this.connection.remove();
            if (this.cachedFunction) {
                this.context[this.name] = this.cachedFunction;
            }
        }
    });
});