define([
    "util"
], function(
    Util
) {

    async function _getObjectFromFetch (url) {
      let response, resultObject;
      try {
        response = await fetch(url, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          }
        });
        resultObject = await response.json();
      } catch (error) {
        Util.consoleLogWarning('getObjectFromFetch', error);
        resultObject = null;
      }
      return resultObject;
    }

    function _extractReleasesFromResponseData (data, defaultReleases) {
      let releases = [...defaultReleases];
      if (data.errors && data.errors.length) {
        const dError = data.errors[0];
        if (dError && typeof dError === 'object' && dError.message &&  dError.code === "2006") {
          const parts = data.errors[0].message.split(":");
          let tmpReleases = parts[parts.length - 1];
          let releasesArray = tmpReleases.split(",");
          for (const idx in releasesArray) {
            releasesArray[idx] = releasesArray[idx].trim().replaceAll("\"", "");
          }
          releases = releasesArray;
        }
      }
      return releases;
    }

    class LegacyParallelClusterService {

        constructor (args) {
            if (!args || typeof args !== "object" || !args.dao) {
              throw new TypeError("Invalid args argument");
            }
            this.dao = args.dao;
        }

        getDAO () { return this.dao; }

        async getAll () {
            return await this.getDAO().getLegacyParallelAPI("service/v1/cluster/list", null, null, {all:"true"}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async getAllUI () {
            return await this.getDAO().getLegacyParallelAPI("cluster/list", null, null, null, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async stop (uuid) {
            return await this.getDAO().postLegacyParallelAPI("service/v1/cluster/stop", uuid, null, null, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async start (uuid) {
            return await this.getDAO().postLegacyParallelAPI("service/v1/cluster/start", uuid, null, null, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async versions (version = "R2020a") {
          const defaultReleases = ["R2023a", "R2022b", "R2022a", "R2021b", "R2021a"];
          let releases = defaultReleases;
          const CCUrl = Util.getLegacyParallelServicesURL();
          if (CCUrl) {
            const versionURL = `${CCUrl}/v2/features?clientString=cloudcenter&matlabRelease=${version}`;
            const data = await _getObjectFromFetch(versionURL);
            if (data) {
              releases = _extractReleasesFromResponseData(data, defaultReleases);
            }
          }
          return releases;
        }

    }
    return LegacyParallelClusterService;
});
