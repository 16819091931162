/* jshint esversion: 8 */
define([
    "lit-element/lit-element.bundled",
    "components/cloudCenterElement",
    "validation/inlineComponentValidation",
    "components/inlinePopover",
    "util"
], function(
    Lit,
    CloudCenterElement,
    InlineComponentValidation,
    InlinePopover,
    Util
) {

    class LicenseManagerSimple extends CloudCenterElement {

        constructor () {
            super();
        }

        cceInitialize () {
            super.cceInitialize();
            Util.consoleLogTrace('LicenseManagerSimple`, `cceInitialize invoked');
            // Default values
            this.elementlabel = "";
            this.poptext = "";
            this.dduxenabled = false;
            this.lminputid = "";
            this.lminputplaceholder = "";
            this.lminputvalue = "";
        }

        // Reactive Properties -- updates rendering when values change
        static get properties () {
            return {
                elementlabel: {type: String},
                poptext: {type: String},
                dduxenabled: {type: Boolean},
                lminputid: {type: String},
                lminputplaceholder: {type: String},
                lminputvalue: {type: String}
            };
        }

        // Render element template
        render () {
            return Lit.html`
                <div class="labelContainer">
                    <label for="${'licenseManagerFieldset' + this.timestamp}">${this.elementlabel}</label>
                </div>
                <div class="section2InputValidationContainer" id="${'licenseManagerFieldset' + this.timestamp}">
                    <input type="text" id="${this.lminputid}" name="${this.lminputid}" placeholder="${this.lminputplaceholder}" value="${this.lminputvalue}" />
                    <inline-validation elementid="${this.lminputid}" />
                </div>
                <inline-popover poptext="${this.poptext}" />
            `;
        }
    }
    // Register custom element '<license-manager-simple elementid="myelementid" />'
    customElements.define('license-manager-simple', LicenseManagerSimple);

    return LicenseManagerSimple;
});
