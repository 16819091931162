define({
    root: ({
        unsupportedError: "<p>We're sorry...</p>" +
                    "<p>The page you're accessing is not supported on this browser.</p>" +
                    "<p>Please change or upgrade to one of the following supported browsers:</p>" +
                    "<ul>" +
                    "<li>On Windows Vista or Windows 7:" +
                    "<ul>" +
                    "<li>Chrome 12 and above</li>" +
                    "<li>Firefox 7 and above</li>" +
                    "<li>Internet Explorer 9 and above</li>" +
                    "</ul>" +
                    "</li>" +
                    "<li>On Mac 10.6 or Mac 10.7:" +
                    "<ul>" +
                    "<li>Safari 5.0 and above</li>" +
                    "<li>Chrome 12 and above</li>" +
                    "</ul>" +
                    "</li>" +
                    "</ul>"
    })
});