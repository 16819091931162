/* jshint esversion: 8 */
define([
    "lit-element/lit-element.bundled",
    "components/cloudCenterElement",
    "util",
    "dojo/i18n!nls/cloudCenterStringResource",
    "dojo/string",
    "validation/inlineComponentValidation",
    "service/datatransform/ruleInfo"
], function(
    Lit,
    CloudCenterElement,
    Util,
    I18NStringResource,
    DojoString,
    InlineComponentValidation,
    RuleInfo
) {

    class EditableText extends CloudCenterElement {

        constructor () {
            super();
            this.doingSave = false;
            this.showingError = false;
        }

        cceInitialize () {
            super.cceInitialize();
            Util.consoleLogTrace('EditableText`, `cceInitialize invoked');
             // Default values
             this.dduxenabled = false;
             this.resourceid = "";
             this.inputfieldname = "defaultEditNameInput";
             this.resourcenamevalue = "";
             this.resourcetype = "";
             this.islink = false;
             this.linkclassname = "";
             this.usebuttons = false;
             this.validator = "^(\\w+).*$";
             this.readonly = false;
             this.maxlength = 50;
             this.editbuttontitle = I18NStringResource.editableTextEditButtonTitle;
             this.eol = null;
        }

        // Reactive Properties -- updates rendering when values change
        static get properties () {
            return {
                dduxenabled: {type: Boolean},
                resourceid: {type: String},
                inputfieldname: {type: String},
                resourcenamevalue: {type: String},
                resourcetype: {type: String},
                islink: {type: Boolean},
                linkclassname: {type: String},
                usebuttons: {type: Boolean},
                validator: {type: String},
                readonly: {type: Boolean},
                maxlength: {type: Number},
                editbuttontitle: {type: String},
                eol: {type: Object}
            };
        }

        firstUpdated () {
            Util.consoleLogTrace(`EditableText.firstUpdated`, `invoked`);
            const elementInfo = {
                isCustomElement: true,
                id: `editNameInput${this.timestamp}`,
                elementType: "text",
                customElementInfo: {
                    type: "input"
                },
                validation: {
                    regex: this.validator,
                    errorMessage: I18NStringResource.editableTextValidationError
                }
            };
            const inputContainerSelector = 'div.section2InputValidationContainer';
            this.setupValidation(elementInfo, inputContainerSelector);
        }

        getUpdateMethod () {
            return this.updateMethod;
        }

        closeEditContainer () {
            const linkContainer = this.renderRoot.querySelector('div.linkContainer');
            const editNameContainer = this.renderRoot.querySelector('div.editNameContainer');
            if (editNameContainer) {
                const editNameInput = editNameContainer.querySelector('input[type="text"].editNameInput');
                if (editNameInput) {
                    editNameInput.value = this.resourcenamevalue;
                }
                editNameContainer.style.display = 'none';
            }
            if (linkContainer) {
                linkContainer.style.display = 'block';
            }
        }

        _onHandleEdit (event) {
            this._logClick(event);
            const linkContainer = this.renderRoot.querySelector('div.linkContainer');
            const editNameContainer = this.renderRoot.querySelector('div.editNameContainer');
            if (linkContainer) {
                linkContainer.style.display = 'none';
            }
            if (editNameContainer) {
                const editNameInput = editNameContainer.querySelector('input[type="text"].editNameInput');
                editNameContainer.style.display = 'flex';
                if (editNameInput) {
                    editNameInput.focus();
                    editNameInput.select();
                }
            }
        }

        _onHandleEditBlur (event) {
            if (!this.doingSave && !this.showingError) {
                this.closeEditContainer();
            }
        }

        _onHandleCancelEditButtonClicked (event) {
            this._logClick(event);
            this.showingError = false;
            this.closeEditContainer();
        }

        async _onHandleSaveButtonClicked (event) {
            this._logClick(event);
            const editNameInput = this.renderRoot.querySelector(`input[name="${this.inputfieldname}"]`);
            const newValue = editNameInput ? editNameInput.value : this.resourcenamevalue;
            let focusedElement = document.activeElement;
            if (this.validate("editNameInput", "text")) {
                await this.doSave(newValue);
                this.closeEditContainer();
            }
        }

        validate (testTargetId, testTargetTag) {
            Util.consoleLogTrace(`EditableText.validate`, `invoked with ${testTargetTag}#${testTargetId}`);
            let isValid = true;
            const regex = new RegExp(this.validator);
            const editNameInput = this.renderRoot.querySelector(`input[name="${this.inputfieldname}"]`);
            const value = editNameInput.value;
            if (value === "" || !this.getElementValidator(testTargetId)()) {
                isValid = false;
            }
            isValid = regex.test(value);
            this.showingError = !isValid;
            if (this.showingError) {
                editNameInput.classList.add('validationError');
            } else {
                editNameInput.classList.remove('validationError');
            }
            return isValid;
        }

        async doSave (newName) {
            let result;
            if (this.resourcenamevalue !== newName) {
                if (typeof this.getUpdateMethod() === 'function') {
                    this.doingSave = true;
                    const data = {};
                    data.resourceId = this.resourceid;
                    data.params = {};
                    data.params[this.inputfieldname] = newName;
                    const spinner =  Util.createSpinner();
                    const editNameInput = this.renderRoot.querySelector(`input[name="${this.inputfieldname}"]`);
                    const buttonContainer = this.usebuttons ? this.renderRoot.querySelector(`div.buttonContainer`) : null;
                    try {
                        if (editNameInput) {
                            editNameInput.before(spinner);
                            editNameInput.style.display = 'none';
                        }
                        if (buttonContainer) {
                            buttonContainer.style.display = 'none';
                        }
                        result = await this.getUpdateMethod()(data);
                        Util.consoleLogTrace('doSave', `Saved new name "${newName}"`);
                    } catch (error) {
                        Util.consoleLogTrace('doSave', `Did not save new name "${newName}": ${error.message}`);
                        Util.consoleLogWarning('doSave', error);
                    } finally {
                        if (editNameInput) {
                            editNameInput.parentElement.removeChild(spinner);
                            editNameInput.style.display = 'inline-block';
                        }
                        if (buttonContainer) {
                            buttonContainer.style.display = 'block';
                        }
                        if (result) {
                            this.resourcenamevalue = newName; // causes a re-rendering
                        }
                        this.doingSave = false;
                    }
                }
            } else {
                Util.consoleLogTrace('doSave', 'Not saving. Name did not change.');
            }
        }

        async _onKeydown (e) {
            const code = e.keyCode || e.which;
            switch (code) {
                case 13: // Enter key
                    e.preventDefault();
                    let focusedElement = document.activeElement;
                    if (focusedElement.tagName.toUpperCase() === "INPUT") {
                        const newValue = focusedElement.value;
                        if (this.validate("editNameInput", "text")) {
                            await this.doSave(newValue);
                            this.closeEditContainer();
                        } else {
                            focusedElement.blur();
                            this.showingError = false;
                        }
                    }
                    break;

                case 27: // Esc key
                    this.showingError = false;
                    this.closeEditContainer();
                    break;

                default:
                    /* do nothing */
            }
        }

        _renderButtonContainer () {
            return Lit.html`
                <div class="buttonContainer">
                    <button type="button" id="${'saveButton' + this.timestamp}" class="saveBtn btn" title="${I18NStringResource.editableTextSaveButtonTitle}" data-bs-toggle="tooltip" data-bs-placement="top"><div class="checkIcon" @click=${this._onHandleSaveButtonClicked} /></button>
                    <button type="button" id="${'cancelEditButton' + this.timestamp}" class="cancelBtn btn" title="${I18NStringResource.editableTextCancelButtonTitle}" data-bs-toggle="tooltip" data-bs-placement="top"><div class="cancelEditNameIcon" @click=${this._onHandleCancelEditButtonClicked} /></button>
                </div>
            `;
        }

        _renderEditButton () {
            return Lit.html`
                <button type="button"
                    title="${this.editbuttontitle}"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    class="btn btn_color_blue companion_btn btn-sm icon-attribute"
                    id="${'editButton' + this.timestamp}"
                    @click=${this._onHandleEdit}>
                        <div class="editNameIcon" />
                </button>
            `;
        }

        _renderLink () {
            return Lit.html`
                <a  href="/resource-detail/${this.resourcetype}/${this.resourceid}"
                    class="${this.linkclassname}"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="${this.resourcenamevalue}"
                    data-resourcetype="${this.resourcetype}"
                    data-id="${this.resourceid}">
                ${this.resourcenamevalue}
                </a>
            `;
        }

        _renderDeprecatedWarning() {
            let sunsetDate = Util.formatDateYMD(new Date(this.eol.SunsetOnDate.seconds * 1000));
            sunsetDate = Util.extractMonthYearString(sunsetDate);
            const status = RuleInfo._EOLStatusNames[RuleInfo.StatusIntDeprecated];
            const msg = DojoString.substitute(I18NStringResource.releaseIsDeprecated, [sunsetDate]);
            const tooltip = DojoString.substitute(I18NStringResource.deprecatedTooltip, [sunsetDate]);
            return Lit.html`
                <button type="button" class="warning-notification deprecated"  @click="${{ handleEvent: (event) => Util.notify('WARNING', msg) }}">
                    <span class="icon-alert-warning" title="${tooltip}"></span>
                </button>
            `;
        }

        _renderSunsettedWarning() {
            const sunsetDate = Util.formatDateYMD(new Date(this.eol.SunsetOnDate.seconds * 1000));
            const status = RuleInfo._EOLStatusNames[RuleInfo.StatusIntSunsetted];
            const msg = DojoString.substitute(I18NStringResource.releaseIsSunsetted, [this.resourcenamevalue, status, sunsetDate]);
            const tooltip = DojoString.substitute(I18NStringResource.sunsettedTooltip, [sunsetDate]);
            return Lit.html`
                <button type="button" class="warning-notification sunsetted" @click="${{ handleEvent: (event) => Util.notify('WARNING', msg) }}">
                            <span class="icon-alert-warning" title="${tooltip}"></span>
                </button>
            `;
        }

        _renderText () {
            return Lit.html`
                <div class="enPlainText">
                    ${this.resourcenamevalue}
                </div>
            `;
        }

        // Render element template
        render () {
            return Lit.html`
                <div class="editableTextElementContainer">
                    <div class="editNameContainer ${this.usebuttons ? 'usingButtons' : ''}" style="display:none;">
                        <form name="editNameForm">
                            <div class="section2InputValidationContainer">
                                <input  name="${this.inputfieldname}"
                                        id="${'editNameInput' + this.timestamp}"
                                        class="editNameInput"
                                        type="text"
                                        value="${this.resourcenamevalue}"
                                        @blur=${!this.usebuttons ? this._onHandleEditBlur : Lit.nothing}
                                        @keydown=${{handleEvent: (event) => this._onKeydown(event), once: false}}
                                        maxlength=${this.maxlength}
                                />
                                <inline-validation elementid="${'editNameInput' + this.timestamp}" />
                            </div>
                            ${this.usebuttons ? this._renderButtonContainer() : Lit.nothing}
                        </form>
                    </div>
                    <div class="linkContainer">
                        ${this.islink ? this._renderLink() : this._renderText()}
                        ${!this.readonly ? this._renderEditButton() : Lit.nothing}
                        ${this.eol && this.eol.Status === 2 ? this._renderDeprecatedWarning() : this.eol && this.eol.Status === 3 ? this._renderSunsettedWarning() : Lit.nothing}
                    </div>
                </div>
            `;
        }
    }
    // Register custom element '<editable-text />'
    customElements.define('editable-text', EditableText);

    return EditableText;
});
