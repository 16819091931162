/* jshint esversion: 8 */
define([

], function() {

  class CredentialInfo {

    constructor () {
      this.platform = "";
      this.typeId = "";
      this.id = "";
      this.subId = "";
      this.name = "";
    }

    set cloudPlatform (platform) { this.platform = platform; }
    get cloudPlatform () { return this.platform; }

    set credentialTypeId (typeId) { this.typeId = typeId; }
    get credentialTypeId () { return this.typeId; }

    set credentialId (id) { this.id = id; }
    get credentialId () { return this.id; }

    set subscriptionId (subId) { this.subId = subId; }
    get subscriptionId () { return this.subId; }

    set displayName (name) { this.name = name; }
    get displayName () { return this.name; }

    set optionValue (ignore) { /* do nothing */ }
    get optionValue () {
      //if cloudPlatform = azure, insert subsciption id after account id
      if(this.platform === 'azure') {
        return `${this.cloudPlatform}:${this.credentialTypeId}:${this.credentialId}(${this.subId}):${this.displayName}`;
      } else {
      return `${this.cloudPlatform}:${this.credentialTypeId}:${this.credentialId}:${this.displayName}`;
      }
    }

    static convertOptionValue (optionValue) {
      if (! (optionValue && typeof optionValue === 'string' && optionValue.indexOf(":") > 0)) {
        throw new TypeError("Invalid optionValue argument");
      }
      let data = { credentialTypeId: "", credentialId: "" };
      let pieces = optionValue.split(":");
      if (pieces && pieces.length >= 4) {
        data.cloudPlatform = pieces[0];
        data.credentialTypeId = pieces[1];
        data.credentialId = pieces[2];
        data.displayName = pieces.slice(3).join(":")
      } else {
        throw new TypeError("optionValue had unrecognized format");
      }
      return data;
    }

  }

  return CredentialInfo;
});
