define([
    "util"
], function (
    Util
) {
    class CloudService {

        constructor (args) {
            if (!args || typeof args !== "object" || !args.dao) {
              throw new TypeError("Invalid args argument");
            }
            this.dao = args.dao;
        }

        getDAO () { return this.dao; }

        async refreshCloudData () {
            return await this.getDAO().getCCAPI("cloud", "resource", "id", "refresh-cloud-data", {"refresh":"true"}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async crawlCloudAccounts (credId) {
            if (!credId) {
                credId = "all";
            }
            // cloud/resource/${credID?credID:"all"}/crawl?refresh=false
            return await this.getDAO().getCCAPI("cloud", "resource", credId, "crawl", {"refresh":"false"}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

    }
    return CloudService;
});
