define([
    "service/general/userService",
    "service/general/workflowService",
    "util",
    "dojo/i18n!nls/cloudCenterStringResource",
    "dojo/string"
], function (
    UserService,
    WorkflowService,
    Util,
    I18NStringResource,
    DojoString
) {
    class SharingService {

        constructor (args) {
            if (!args || typeof args !== "object" || !args.dao) {
              throw new TypeError("Invalid args argument");
            }
            this.dao = args.dao;
            this.user = new UserService({dao: this.dao});
            this.workflow = new WorkflowService({dao: this.dao});
        }

        getDAO () { return this.dao; }
        getUserService () { return this.user; }
        getWorkflowService () { return this.workflow; }

        async declineSharedCredential (credentialId, userId) {
            if (!credentialId || typeof credentialId !== 'string') {
              throw new TypeError("Invalid credentialId argument");
            }
            if (!userId) {
              const userProfile = await  this.getUserService().profile();
              if (userProfile) {
                userId = userProfile.account;
              }
            }
            const params = {
              users: userId
            };
            if (!userId || typeof userId !== 'string') {
              throw new TypeError("Invalid userId argument");
            }

            let configs = [];
            try {
              configs = await this.getWorkflowService().list("config", `mw-name,mw-credential-id=${credentialId}`, undefined);
            } catch (error) {
              Util.consoleLogWarning('declineSharedCredential', error);
              configs = [];
            }
            const names = await Util.collectExistingConfigNames(configs);
            if (names) {
              throw new Error(DojoString.substitute(I18NStringResource.credentialsPageUnableToDelete, [names]));
            }

            return await this.getDAO().deleteCCAPI("share", "resource", credentialId, null, params, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

    }
    return SharingService;
});
