/* jshint esversion: 8 */
define([
    "lit-element/lit-element.bundled",
    "components/cloudCenterElement",
    "util",
    "dojo/i18n!nls/cloudCenterStringResource",
    "validation/inlineComponentValidation",
    "components/inlinePopover"
], function (
    Lit,
    CloudCenterElement,
    Util,
    I18NStringResource,
    InlineComponentValidation,
    InlinePopover
) {

    class AutoLoginLicenseManager extends CloudCenterElement {

        constructor() {
            super();
        }

        cceInitialize() {
            super.cceInitialize();
            Util.consoleLogTrace('AutoLoginLicenseManager`, `cceInitialize invoked');
            // Default values
            this.entitlementid = "";
            this.entitlementlabel = "";
            this.entitlementpoptext = "";
            this.entitlementvalue = "";
            this.elementlabel = "";
            this.poptext = "";
            this.dduxenabled = false;
            this.lminputid = "";
            this.lminputplaceholder = "";
            this.lminputvalue = "";
            this.autologinid = "";
            this.autologinlabel = "";
            this.autologinpoptext = "";
            this.autologinvalue = "";
        }

        // Reactive Properties -- updates rendering when values change
        static get properties() {
            return {
                entitlementid: { type: String },
                entitlementlabel: { type: String },
                entitlementpoptext: { type: String },
                entitlementvalue: { type: String },
                elementlabel: { type: String },
                poptext: { type: String },
                dduxenabled: { type: Boolean },
                lminputid: { type: String },
                lminputplaceholder: { type: String },
                lminputvalue: { type: String },
                autologinid: { type: String },
                autologinlabel: { type: String },
                autologinpoptext: { type: String },
                autologinvalue: { type: String }
            };
        }

        // Called after initial rendering
        firstUpdated() {
            Util.consoleLogTrace(`AutoLoginLicenseManager.firstUpdated`, `invoked`);
            this._updateUI();
        }

        validate(testTargetId, testTargetTag) {
            Util.consoleLogTrace(`AutoLoginLicenseManager.validate`, `invoked with ${testTargetTag}#${testTargetId}`);
            let isValid = true;
            const choice = this.renderRoot.querySelector('input[name="lmChoice"]:checked').value;
            const input = this.renderRoot.querySelector(`input#${this.lminputid}`);
            if (choice !== "onlineLicensing" && (input.value === "" || !this.getElementValidator(testTargetId)())) {
                isValid = false;
            }
            return isValid;
        }

        _updateUI(event) {
            const choice = this.renderRoot.querySelector('input[name="lmChoice"]:checked').value;
            const entitlementInput = this.renderRoot.querySelector(`select#${this.entitlementid}`);
            const selectEntitlement = this.renderRoot.querySelector(`div#selectEntitlementID`);
            const input = this.renderRoot.querySelector(`input#${this.lminputid}`);
            if (entitlementInput.length <= 2) {
                selectEntitlement.style.display = "none"
            } else {
                selectEntitlement.style.display = ""
            }
            if (choice === "onlineLicensing") {
                input.value = "";
                // Cause the inline validation to clear/hide
                input.dispatchEvent(new Event("focus"));
                // Cause the input to validate again with the new value, which will update the UI buttons
                input.dispatchEvent(new Event("blur"));
                input.disabled = true;
                input.classList.add('disabled');

                entitlementInput.disabled = false;
                entitlementInput.classList.remove('disabled');
                entitlementInput.focus();
            } else {
                input.disabled = false;
                input.classList.remove('disabled');
                input.focus();

                entitlementInput.dispatchEvent(new Event("focus"));
                entitlementInput.disabled = true;
                entitlementInput.classList.add('disabled');
            }
        }

        // Render element template
        render() {
            return Lit.html`
            <fieldset class ="section2Group allmGroup">
                <div class = "allmCont section2InputContainer">
                    <label for="${'section2InputContainer' + this.timestamp}">${this.elementlabel}</label>
                    <div class="licenseManagerOptionSelectors">
                        <div class="section2InputContainer" id="${'section2InputContainer' + this.timestamp}">
                            <div class="radioButtonContainer">
                                <input type="radio" name="lmChoice" @change=${this._updateUI} @click=${this._logClick} value="onlineLicensing" id="${'onlineLicensing' + this.timestamp}" ?checked=${!this.lminputvalue} />
                                <label for="${'onlineLicensing' + this.timestamp}">${I18NStringResource.licenseManagerOnlineRadioLabel}</label>
                            </div>

                            <div class="licenseManagerInputFields"  id="selectEntitlementID">
                                <div class="section2InputValidationContainer">
                                    <select name="${this.entitlementid}" id="${this.entitlementid}" @change=${this._updateUI}  >
                                        <option value="" disabled hidden ?selected=${!this.entitlementvalue}></option>
                                    </select>
                                    <inline-validation elementid="a${this.entitlementid}" />
                                </div>
                                <inline-popover poptext="${this.entitlementpoptext}" />
                            </div>
                        </div>
                        <div class="section2InputContainer" id="${'section2InputContainer' + this.timestamp}">
                            <div class="radioButtonContainer">
                                <input type="radio" name="lmChoice" @change=${this._updateUI} @click=${this._logClick} value="networkLicenseMgr" id="${'networkLicenseMgr' + this.timestamp}" ?checked=${this.lminputvalue} />
                                <label for="${'networkLicenseMgr' + this.timestamp}">${I18NStringResource.licenseManagerNetworkRadioLabel}</label>
                            </div>
                            <div class="licenseManagerInputFields">
                                <div class="section2InputValidationContainer">
                                    <input type="text" id="${this.lminputid}" name="${this.lminputid}" placeholder="${this.lminputplaceholder}" value="${this.lminputvalue}">
                                    <inline-validation elementid="${this.lminputid}" />
                                </div>
                                <inline-popover poptext="${this.poptext}" />
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            `;
        }

        static UpdateDetailsPageValues(uiElementInfo, values) {
            let hideEntitlement = false;
            for (let subElement of uiElementInfo.customElementInfo.sub_elements) {
                if (subElement.type === "network_license_manager") {
                    if (values[subElement.id]) {
                        hideEntitlement = true;
                    }
                    break;
                }
            }
            if (hideEntitlement) {
                for (let subElement of uiElementInfo.customElementInfo.sub_elements) {
                    if (subElement.type.includes("mwa_license_entitlement")) {
                        values[subElement.id] = "";
                        break;
                    }
                }
            }
        }
    }

    // Register custom element '<license-manager elementlabel="mylabel", poptext="popover text" dduxenabled="true" />'
    customElements.define('autologin-licensemanager', AutoLoginLicenseManager);

    return AutoLoginLicenseManager;
});
