define([
  "underscore",
  "jquery",
  "backbone",
  "util",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string"
], function( _, $, Backbone, Util, I18NStringResource, DojoString ) {

    class NotificationManager extends Backbone.View {

      constructor (options) {
        if (!options || typeof options !== "object" || !options.notificationAreaId || typeof options.notificationAreaId !== "string") {
          throw new TypeError("Invalid options argument");
        }
        options.events = {

        };
        super(options);
        this.template = _.template(`
          <div id="ccwaNotificationMessage" class="alert alert-success alert-dismissable">
            <span class="alert_icon icon-alert-success"></span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" tabindex="0"></button>
            <p class="alert_heading">
              <strong>
                <span class="severityLabel">${I18NStringResource.successNotificationTitle}</span>
              </strong>
              <span>
                <button title="${I18NStringResource.notificationToggleCollapse}" type="button" class="btn collapse_toggle" tabindex="0">
                  <span class="visually-hidden">${I18NStringResource.notificationToggleCollapse}</span>
                  <span class="hideShowText icon-arrow-open-down" aria-hidden="true"></span>
                </button>
              </span>
            </p>
            <ul class="notificationMessageList">
              <li class="notificationMessage"><%- notificationMsg %></li>
            </ul>
          </div>
          `);
        this.linkTemplate = _.template(`
          <div id="ccwaNotificationMessage" class="alert alert-success alert-dismissable">
            <span class="alert_icon icon-alert-success"></span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" tabindex="0"></button>
            <p class="alert_heading">
              <strong>
                <span class="severityLabel">${I18NStringResource.successNotificationTitle}</span>
              </strong>
              <span>
                <button title="${I18NStringResource.notificationToggleCollapse}" type="button" class="btn collapse_toggle" tabindex="0">
                  <span class="visually-hidden">${I18NStringResource.notificationToggleCollapse}</span>
                  <span class="hideShowText icon-arrow-open-down" aria-hidden="true"></span>
                </button>
              </span>
            </p>
            <ul class="notificationMessageList">
              <li class="notificationMessage"><%- notificationMsg %></li>
              <li class="notificationLink"><div class="notificationContentText"><a class="notificationContentLink"><%- linkText %></a></div></li>
            </ul>
          </div>
        `);
        this.warningTemplate = _.template(`
          <div id="ccwaNotificationWarningMessage" class="alert alert-warning alert-dismissable">
            <span class="alert_icon icon-alert-warning"></span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" tabindex="0"></button>
            <p class="alert_heading">
              <strong>
                <span class="severityLabel">${I18NStringResource.warningNotificationTitle}</span>
              </strong>
              <span>
                <button title="${I18NStringResource.notificationToggleCollapse}" type="button" class="btn collapse_toggle" tabindex="0">
                  <span class="visually-hidden">${I18NStringResource.notificationToggleCollapse}</span>
                  <span class="hideShowText icon-arrow-open-down" aria-hidden="true"></span>
                </button>
              </span>
            </p>
            <ul class="notificationMessageList">
              <li class="notificationMessage"><%- notificationMsg %></li>
            </ul>
          </div>
          `);
        this.errorTemplate = _.template(`
          <div id="ccwaNotificationErrorMessage" class="alert alert-danger alert-dismissable">
            <span class="alert_icon icon-alert-error"></span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" tabindex="0"></button>
            <p class="alert_heading">
              <strong>
                <span class="severityLabel">${I18NStringResource.errorNotificationTitle}</span>
              </strong>
              <span>
                <button title="${I18NStringResource.notificationToggleCollapse}" type="button" class="btn collapse_toggle" tabindex="0">
                  <span class="visually-hidden">${I18NStringResource.notificationToggleCollapse}</span>
                  <span class="hideShowText icon-arrow-open-down" aria-hidden="true"></span>
                </button>
              </span>
            </p>
            <ul class="notificationMessageList">
              <li class="notificationMessage"><%- notificationMsg %></li>
            </ul>
          </div>
          `);
        this.infoTemplate = _.template(`
          <div id="ccwaNotificationInfoMessage" class="alert alert-info alert-dismissable">
            <span class="alert_icon icon-alert-info-reverse"></span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" tabindex="0"></button>
            <p class="alert_heading">
              <strong>
                <span class="severityLabel">${I18NStringResource.infoNotificationTitle}</span>
              </strong>
              <span>
                <button title="${I18NStringResource.notificationToggleCollapse}" type="button" class="btn collapse_toggle" tabindex="0">
                  <span class="visually-hidden">${I18NStringResource.notificationToggleCollapse}</span>
                  <span class="hideShowText icon-arrow-open-down" aria-hidden="true"></span>
                </button>
              </span>
            </p>
            <ul class="notificationMessageList">
              <li class="notificationMessage"><%- notificationMsg %></li>
            </ul>
          </div>
        `);
        this.resourceErrorTemplate = _.template(`
          <div id="ccwaNotificationResourceMessage" class="alert alert-danger alert-dismissable resource-message">
            <span class="alert_icon icon-alert-error"></span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" tabindex="0"></button>
            <p class="alert_heading">
              <strong>
                <span class="severityLabel">${I18NStringResource.errorNotificationTitle}</span>
              </strong>
            </p>
            <ul class="notificationMessageList">
              <li class="notificationMessage"><%- notificationMsg %></li>
            </ul>
          </div>
          `);
          this.resourceWarningTemplate = _.template(`
            <div id="ccwaNotificationResourceMessage" class="alert alert-warning alert-dismissable resource-message">
              <span class="alert_icon icon-alert-warning"></span>
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" tabindex="0"></button>
              <p class="alert_heading">
                <strong>
                  <span class="severityLabel">${I18NStringResource.warningNotificationTitle}</span>
                </strong>
              </p>
              <ul class="notificationMessageList">
                <li class="notificationMessage"><%- notificationMsg %></li>
              </ul>
            </div>
            `);
        this.notificationAreaId = options.notificationAreaId;
      }

      getNotificationAreaId () {
        return this.notificationAreaId;
      }

      getNotificationArea () {
          return $('#' + this.notificationAreaId);
      }


      /**
       * Write supplied success message to the notification area
       */
      notifyUserOfSuccess (msg, doLogout) {
        if (!msg || typeof msg !== "string") {
          throw new TypeError("Invalid msg argument");
        }
        this.render(msg, doLogout, 'success');
      }

      /**
       * Write supplied success message to the notification area with link
       */
      notifyUserOfSuccessWithLink (msg, linkText, linkCallback) {
        if (!msg || typeof msg !== "string") {
          throw new TypeError("Invalid msg argument");
        }
        if (!linkText ||typeof linkText !== "string") {
          throw new TypeError("Invalid linkText argument");
        }
        if (!linkCallback || typeof linkCallback !== "function") {
          throw new TypeError("Invalid link callback argument");
        }
        this.render(msg, false, 'successLink', linkText, linkCallback);
      }

      /**
       * Write supplied error message to the notification area
       */
      notifyUserOfError (msg, doLogout) {
        if (!msg || typeof msg !== "string") {
          throw new TypeError("Invalid msg argument");
        }
        this.render(msg, doLogout, 'error');
      }

      notifyUserOfWarning (msg, doLogout) {
        if (!msg || typeof msg !== "string") {
          throw new TypeError("Invalid msg argument");
        }
        this.render(msg, doLogout, 'warning');
      }

      notifyUserOfResourceError (msg, doLogout) {
        if (!msg || typeof msg !== "string") {
          throw new TypeError("Invalid msg argument");
        }
        this.render(msg, doLogout, 'resourceError');
      }

      notifyUserOfResourceWarning (msg, doLogout) {
        if (!msg || typeof msg !== "string") {
          throw new TypeError("Invalid msg argument");
        }
        this.render(msg, doLogout, 'resourceWarning');
      }

      /**
       * Write supplied info message to the notification area
       */
      notifyUserOfInfo (msg, doLogout) {
        if (!msg || typeof msg !== "string") {
          throw new TypeError("Invalid msg argument");
        }
        this.render(msg, doLogout, 'info');
      }

      /**
       * Clear and hide the notification area if any
       */
      clearErrorNotification (doLogout, target) {
        if (this.getNotificationArea()) {
          if (target) {
            // div class="alert" > button class="btn-close" > span
            let notification = target;
            if (notification.tagName.toUpperCase() === 'SPAN') {
              notification = notification.parentElement;
            }
            if (notification.tagName.toUpperCase() === 'BUTTON') {
              notification = notification.parentElement;
            }
            if (notification.tagName.toUpperCase() === 'DIV' && notification.classList.contains('alert')) {
              notification = $(notification);
            }
            notification.remove();
            this.adjustNotificationContainerOrientation();
          }
        }
        if (doLogout) {
            // Send user back to login page
            $.event.trigger("exit_app:ccwa");
        }
      }

      /**
       * Start listening for quota controller events
       */
      start (fnLogoutHandler) {
        if (!fnLogoutHandler || typeof fnLogoutHandler !== 'function') {
            throw new TypeError("Logout handler unexpected type.");
        }
        $(document).on('exit_app:ccwa', function() {
            fnLogoutHandler();
        } );
      }

      /**
       * Stop listening for quota controller events
       */
      stop () {
        $(document).off('exit_app:ccwa');
      }

      getNotificationTypeDataFromType (type) {
        let notificationTypeId = "";
        let template;
        switch(type) {
          case 'success':
            notificationTypeId = "ccwaNotificationMessage";
            template = this.template;
            break;
          case 'successLink':
            notificationTypeId = "ccwaNotificationMessage";
            template = this.linkTemplate;
            break;
          case 'warning':
            notificationTypeId = "ccwaNotificationWarningMessage";
            template = this.warningTemplate;
            break;
          case 'error':
            notificationTypeId = "ccwaNotificationErrorMessage";
            template = this.errorTemplate;
            break;
          case 'info':
            notificationTypeId = "ccwaNotificationInfoMessage";
            template = this.infoTemplate;
            break;
        }
        return {notificationTypeId: notificationTypeId, template: template};
      }

      allNotificationBoxesAreMinimized () {
        let allMinimized = false;
        let allNotificationBoxes = document.querySelectorAll('div.alert:not(.resource-message)');
        let minimizedCount = 0;
        if (allNotificationBoxes && allNotificationBoxes.length) {
          allNotificationBoxes.forEach(box => { if (box.classList.contains("collapsed")) { minimizedCount++; }});
        }
        if ((allNotificationBoxes.length > 0) && (minimizedCount === allNotificationBoxes.length)) {
          allMinimized = true;
        }
        return allMinimized;
      }
      adjustNotificationContainerOrientation () {
        let allNotificationBoxesCollapsed = this.allNotificationBoxesAreMinimized();
        let notificationsContainer = document.querySelector(`div#${this.getNotificationAreaId()}`);
        if (notificationsContainer) {
          if (allNotificationBoxesCollapsed) {
            notificationsContainer.style['flex-direction'] = "row";
          } else {
            notificationsContainer.style['flex-direction'] = "column";
          }
        }
      }

      toggleShowAllNotifications (target) {
        let parent;
        let textSpan;
        let button;
        if (target) {
          if (target.tagName === "BUTTON" && target.classList.contains("collapse_toggle")) {
            button = target;
            textSpan = button.querySelector('span.hideShowText');
          }
          parent = target.parentElement;
        }
        if (parent && parent.tagName === "SPAN") {
          do {
            parent = parent.parentElement;
          } while (parent.tagName !== "DIV");
        }
        if (parent && parent.classList.contains("alert")) {
          let notificationList = parent.querySelector("ul.notificationMessageList");
          if (notificationList) {
            let isHidden = notificationList.style.display === 'none';
            if (isHidden) {
              if (textSpan) {
                textSpan.classList.remove("icon-arrow-open-right");
                textSpan.classList.add("icon-arrow-open-down");
              }
              notificationList.style.display = 'block';
              parent.classList.remove("collapsed");
            } else {
              if (textSpan) {
                textSpan.classList.remove("icon-arrow-open-down");
                textSpan.classList.add("icon-arrow-open-right");
              }
              notificationList.style.display = 'none';
              parent.classList.add("collapsed");
            }
            this.adjustNotificationContainerOrientation();
          }
        }
      }

      addNotification (type, doLogout, msg, linkText, linkCallback) {
        let context = this;
        let typeData = this.getNotificationTypeDataFromType(type);
        let notificationTypeId = typeData.notificationTypeId;
        let template = typeData.template;
        let notificationBox = this.getNotificationArea().find(`div#${notificationTypeId}`);
        if (notificationBox.length === 1) {
          let messageList = notificationBox.find('ul.notificationMessageList');
          let newNotificationHtml = `<li class="notificationMessage">${msg}</li>`;
          if (type === 'successLink' && linkText) {
            newNotificationHtml += `<li class="notificationLink"><div class="notificationContentText"><a class="notificationContentLink">${linkText}</a></div></li>`;
          }
          let newNotification = $(newNotificationHtml);
          messageList.prepend(newNotification);
          if (linkText && typeof linkCallback === 'function') {
            let anchor = newNotification.find('a');
            if (anchor && anchor.length) {
              anchor.on("click", function(event) {event.preventDefault(); linkCallback();});
            }
          }
        } else {
          notificationBox = $(template({notificationMsg:msg, linkText: linkText}));
          let closeButton = notificationBox.find('button.btn-close');
          if (closeButton) {
            closeButton.on('click', function (event) { event.preventDefault(); context.clearErrorNotification(doLogout, event.currentTarget); });
          }
          let showToggle = notificationBox.find('.collapse_toggle');
          if (showToggle) {
            showToggle.on('click', function (event) { event.preventDefault(); context.toggleShowAllNotifications(event.currentTarget); });
          }
          const releaseMsgArea = document.querySelector('#ccwaNotificationResourceMessage');
          if (releaseMsgArea) {
            const notificationElement = $(notificationBox)[0];
            if (notificationElement) {
              releaseMsgArea.before(notificationElement);
            }
          } else {
            this.getNotificationArea().append(notificationBox);
          }
          if (linkText && typeof linkCallback === 'function') {
            let link = notificationBox.find('a.notificationContentLink');
            if (link && link.length) {
              link.on('click', function(event) {event.preventDefault(); linkCallback();});
            }
            $('a.notificationContentLink', notificationBox).focus();
          }
          this.adjustNotificationContainerOrientation();
        }
        return notificationBox;
      }

      showResourceNotification (type, msg) {
        let existingResourceMessage = document.querySelector('div#ccwaNotificationResourceMessage');
        if (existingResourceMessage) {
          let closeBtn = existingResourceMessage.querySelector('button.btn-close');
          if (closeBtn) {
            closeBtn.click();
          }
        }
        let template = this.resourceErrorTemplate;
        if (type === 'resourceWarning') {
          template = this.resourceWarningTemplate;
        }
        let regularNotifications = document.querySelectorAll('div.alert:not(.resource-message)');
        if (regularNotifications && regularNotifications.length) {
          regularNotifications.forEach(box => {
            if (!box.classList.contains('collapsed')) {
              let toggle = box.querySelector('button.btn.collapse_toggle');
              if (toggle) {
                toggle.click();
              }
            }
          });
        }
        let notificationBox = $(template({notificationMsg:msg, linkText: ''}));
        this.getNotificationArea().append(notificationBox);
        if (Util.isMobileBrowser()) {
          window.scrollTo(0, 0);
        }
      }

      render (msg, doLogout, type, linkText, linkCallback) {
        if (!msg || typeof msg !== "string") {
          throw new TypeError("Invalid msg argument");
        }
        if (type !== 'success' && type !== 'successLink' && type !== 'error' && type !== 'warning' && type !== 'info' &&
            type !== 'resourceError' && type !== 'resourceWarning') {
          throw new TypeError("Invalid message type argument");
        }
        if (type === 'successLink' && (!linkText || typeof linkText !== "string")) {
          throw new TypeError("Invalid linkText argument");
        }
        if (type === 'successLink' && (!linkCallback ||typeof linkCallback !== "function")) {
          throw new TypeError("Invalid link callback argument");
        }
        if (this.getNotificationArea()) {
          this.getNotificationArea().show();
          if (type === 'resourceError' || type === 'resourceWarning') {
            this.showResourceNotification(type, msg);
          } else {
            if (type === 'successLink') {
              this.addNotification(type, doLogout, msg, linkText, linkCallback);
            } else {
              this.addNotification(type, doLogout, msg);
            }
          }
        } else {
          alert(msg);
        }
      }

      static removeNotification (resourceName) {
        let notification = document.querySelector('div.alert-info');
        if (notification) {
          let closeButton = notification.querySelector('div.alert-info button.btn-close');
          let messageList = notification.querySelector('ul.notificationMessageList');
          let messageArray = (messageList ? messageList.querySelectorAll('li.notificationMessage') : []);
          let startPrefix = `${resourceName}:`;
          let stopPostfix = `'${resourceName}'`;
          if (closeButton && messageArray.length <= 1) {
            if ( (messageArray.length === 1 && (messageArray[0].textContent.includes(stopPostfix) || messageArray[0].textContent.indexOf(startPrefix) === 0)) || !messageArray.length) {
              closeButton.click();
            }
          } else {
            if (messageArray.length > 1) {
              for (let m of messageArray) {
                if (m.textContent.indexOf(startPrefix) === 0 || m.textContent.endsWith(stopPostfix)) {
                  messageList.removeChild(m);
                  break;
                }
              }
            }
          }
        }
      }

    }

    return NotificationManager;
}); // require
