define([
    "util"
], function (
    Util
) {
    class UserService {

        constructor (args) {
            if (!args || typeof args !== "object" || !args.dao) {
              throw new TypeError("Invalid args argument");
            }
            this.dao = args.dao;
        }

        getDAO () { return this.dao; }

        async clearPrefs () {
            Util.consoleLogTrace("UserService.clearPrefs", "called");
            return await this.getDAO().deleteCCAPI("user", "resource", "settings", "workflow", undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async getPrefs () {
            Util.consoleLogTrace("UserService.getPrefs", "called");
            return await this.getDAO().getCCAPI("user", "resource", "settings", "workflow", undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async loggedIn () {
            Util.consoleLogTrace("UserService.loggedIn", "called");
            return await this.getDAO().getCCAPI("user", "resource", "loggedin", undefined, undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async profile () {
            Util.consoleLogTrace("UserService.profile", "called");
            let data = await this.getDAO().getCCAPI("user", "resource", "loggedin",null,{profile:"true"}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
            if (data && data.logged_in) {
              if (!data.profile) {
                data.profile = {};
              }
              if (!data.profile.products) {
                data.profile.products = {};
              }
            }
            return data;
        }

    }
    return UserService;
});
