define([
  "underscore",
  "jquery",
  "bootstrap",
  "templates/wizardWarningDialog",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "util"
], function(_, $, Bootstrap, WarningDialogTemplate, I18NStringResource, DojoString, Util) {

  class WizardWarningDialog {

    constructor (args) {
      if (!this.validateArgs(args)) {
        throw new TypeError("Invalid args");
      }
      this.wizardWarningHeader = args.title;
      this.wizardWarningBodyPrompt = args.text;
      this.proceedPrompt = args.prompt;
      this.actionFn = args.actionFn;
      this.cancelFn = args.cancelFn;
      this.hideIcon = args.hideIcon || false;
      this.exitWizardButtonLabel = args.exitWizardButtonLabel || I18NStringResource.wizardWarningDialogProceedButtonLabel;
      this.cancelWizardButtonLabel = args.cancelWizardButtonLabel || I18NStringResource.wizardWarningDialogCancelButtonLabel;
      this.modalContainerId = "#modalContainer";
      this.dialogElementSelector = "div#wizardWarningDialog";
    }

    getModalContainer () { return document.querySelector(this.modalContainerId); }

    getDialogElement() { return document.querySelector(this.dialogElementSelector); }

    getExitWizardButton() { return this.getDialogElement().querySelector('.exitWizardBtn'); }

    getCancelWizardButton() { return this.getDialogElement().querySelector('.cancelWizardBtn'); }

    getCloseWizardButton() { return this.getDialogElement().querySelector('button.btn-close'); }

    getActiveElement () { return document.activeElement; }

    validateArgs (args) {
      let isValid = false;
      if (args && typeof args === 'object') {
        if (
          ("title" in args && typeof args.title === 'string') && //NOSONAR
          ("text" in args && typeof args.text === 'string') && //NOSONAR
          ("prompt" in args && typeof args.prompt === 'string') && //NOSONAR
          (args.actionFn && typeof args.actionFn === 'function') &&
          (!args.cancelFn || typeof args.cancelFn === 'function')
        ) {
          isValid = true;
        }
      }
      return isValid;
    }

    dragNotAllowed (event) {
       event.preventDefault();
       event.originalEvent.dataTransfer.dropEffect = 'none';
    }

    doAction () {
      this.disableButtons(true);
      try {
        this.actionFn();
      } catch (error) {
        Util.consoleLogError("doAction", error);
      } finally {
        const dialogEl = this.getDialogElement();
        if (dialogEl) {
          const modal = Bootstrap.Modal.getOrCreateInstance(dialogEl);
          modal.hide();
        }
      }
    }
    doCancel () {
      this.disableButtons(true);
      if (this.cancelFn) {
        try {
          this.cancelFn();
        } catch (error) {
          Util.consoleLogError("doCancel", error);
        } finally {
          const dialogEl = this.getDialogElement();
          const modal = Bootstrap.Modal.getOrCreateInstance(dialogEl);
          modal.hide();
        }
      }
    }

    disableButtons(disabled) {
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        for (const btn of [this.getCancelWizardButton(), this.getExitWizardButton()]) {
          if (btn) {
            if (disabled) {
              btn.setAttribute("disabled", "");
              btn.classList.add('disabled');
            } else {
              btn.removeAttribute("disabled");
              btn.classList.remove('disabled');
            }
          }
        }
      }
    }

    focusOnCancelWizardButton() {
      this.disableButtons(false);
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        const cancelWizardButton = this.getCancelWizardButton();
        if (cancelWizardButton) {
          cancelWizardButton.focus();
        }
      }
    }

    disposeOfModal() {
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        const modal = Bootstrap.Modal.getOrCreateInstance(dialogEl);
        modal.hide();
      }
      $.event.trigger("wizardwarningdialogclosed:ccwa");
    }

    show () {
      const html = WarningDialogTemplate({
        wizardWarningHeader: this.wizardWarningHeader,
        wizardWarningBodyPrompt: this.wizardWarningBodyPrompt,
        proceedPrompt: this.proceedPrompt,
        exitWizardButtonLabel: this.exitWizardButtonLabel,
        cancelWizardButtonLabel: this.cancelWizardButtonLabel,
        hideIcon: this.hideIcon
      });
      const modalContainer = this.getModalContainer()
      if (modalContainer) {
        modalContainer.innerHTML = html;
        Util.clearAllBootstrapBackdrops();
        const dialogEl = this.getDialogElement();
        if (dialogEl) {
          dialogEl.addEventListener('shown.bs.modal', this.focusOnCancelWizardButton.bind(this));
          dialogEl.addEventListener('hidden.bs.modal', this.disposeOfModal.bind(this));
          dialogEl.addEventListener('dragover', this.dragNotAllowed);
          const exitWizardBtn = this.getExitWizardButton();
          if (exitWizardBtn) {
            exitWizardBtn.addEventListener('click',this.doAction.bind(this));
          }
          const cancelWizardBtn = this.getCancelWizardButton();
          if (cancelWizardBtn) {
            cancelWizardBtn.addEventListener('click', this.doCancel.bind(this));
          }
          const closeBtn = this.getCloseWizardButton();
          if (closeBtn) {
            closeBtn.addEventListener('click', this.doCancel.bind(this));
          }
          dialogEl.addEventListener('keydown', this.onKeydown.bind(this));
          const modal = new Bootstrap.Modal(dialogEl, {backdrop: 'static'});
          modal.show();
        }
      }
    }

    onKeydown (e) {
      const code = e.keyCode || e.which;
      if (code == 13) { // Enter key
        e.preventDefault();
        let focusedElement = this.getActiveElement();
        if (focusedElement &&
            focusedElement.tagName.toUpperCase() === "BUTTON" && (
              focusedElement.className === 'btn-close'
              || focusedElement.id === "warningCancelWizardButton" ||
              focusedElement.id === "warningExitWizardButton"
            )
        ) {
          focusedElement.click();
        }
      }
    }

  }

  return WizardWarningDialog;
});
