/* jshint esversion: 8 */
/*
 * This file contains logic for loading machine list picker by location (ie, vpc > subnet > machine)
 */
define([
    "underscore",
    "jquery",
    "lit-element/lit-element.bundled",
    "dojo/i18n!nls/cloudCenterStringResource",
    'dojo/string',
    "util",
    "service/components/abstractMachineTypeChooseWithFinder",
], function (
    _,
    $,
    Lit,
    I18NStringResource,
    DojoString,
    Util,
    MachineTypeWithFinder
) {

    class ServiceByLocation extends MachineTypeWithFinder {
        constructor(args) {
            super(args);

            this.buildVPCList();
        }
        selectionRequiresStep1Change() {
            return [null, null]
        } //abstract impl.
        updateStepsAndNavigate() { } //abstract impl.

        machineTypePicker() {
            return this.root().querySelector(`div.radioButtonContainer.byLocation select#Manual${this.machinetypeid}`);
        }
        subnetPicker() {
            return this.root().querySelector(`div.radioButtonContainer.byLocation select#Manual${this.subnetid}`);
        }
        vpcPicker() {
            return this.root().querySelector(`div.radioButtonContainer.byLocation select#Manual${this.vpcid}`);
        }

        getSelectedData() {
            let vpcContainer = this.vpcPicker();
            let subnetContainer = this.subnetPicker();
            let machineTypeContainer = this.machineTypePicker();
            return [vpcContainer.value, subnetContainer.value, machineTypeContainer.value];
        }


        async buildVPCList() {
            this.toggleApplyButton(false);
            const container = this.root().querySelector(`div.radioButtonContainer.byLocation`);
            if (!container) {
                setTimeout(() => this.buildVPCList(), 100);
                return;
            }
            const defaults = await this.getDefaults();

            let vpcContainer = this.vpcPicker();
            let subnetContainer = this.subnetPicker();
            let machineTypeContainer = this.machineTypePicker();

            this.disableAll([vpcContainer, subnetContainer, machineTypeContainer], I18NStringResource.machineTypeFinderLoading)

            const dataService = this.getDataService().getPlatformDataService("aws");
            this.allNetworksAndSubnetsInThisRegion = await dataService.getEC2Service().getVPC(this.getCurrentCredentialId(), this.getCurrentLocation());
            let vpcOptions = []
            this.vpcIDsToSubnets = {};
            this.vpcDescToNetwork = {};
            this.vpcIDsToNetwork = {};
            this.descs = [];
            let maxNameLen = 0;
            let maxVPCLen = 0;
            let maxVPCIPv4Len = 0;
            const heading = {
                Name: "VPC Name",
                ID: "VPC ID",
                IPv4: "VPC IPv4 CIDR",
                Default: " (Default)"
            }
            this.allNetworksAndSubnetsInThisRegion.msg.VPC = {
                Name: heading.Name,
                ID: heading.ID,
                IPv4: heading.IPv4,
            }
            for (const vpc of this.allNetworksAndSubnetsInThisRegion.msg) {
                if (maxNameLen < vpc.Name.length) {
                    maxNameLen = vpc.Name.length;
                }
                if (maxVPCLen < vpc.ID.length) {
                    maxVPCLen = vpc.ID.length;
                }
                if (maxVPCIPv4Len < vpc.IPv4.length) {
                    maxVPCIPv4Len = vpc.IPv4.length;
                }
            }
            delete this.allNetworksAndSubnetsInThisRegion.msg.VPC;

            const spaceChar = '\u00A0'
            const defaultText = " (Default)"

            heading.Name = heading.Name.padEnd(maxNameLen, spaceChar);
            heading.ID = heading.ID.padEnd(maxVPCLen, spaceChar);
            heading.IPv4 = heading.IPv4.padEnd(maxVPCIPv4Len, spaceChar);
            heading.Default = defaultText
            let optHeading = new Option(`${heading.Name} | ${heading.ID}${heading.Default} | ${heading.IPv4}`, "")
            optHeading.selected = false
            optHeading.disabled = true
            optHeading.style.fontFamily = "monospace";
            vpcOptions.push(optHeading);

            for (const vpc of this.allNetworksAndSubnetsInThisRegion.msg) {
                let name = vpc.Name.padEnd(maxNameLen, spaceChar);
                let id = vpc.ID.padEnd(maxVPCLen, spaceChar);
                let isDefault = ((vpc.IsDefault) ? defaultText : "").padEnd(defaultText.length, spaceChar);
                let ipv4 = vpc.IPv4.padStart(maxVPCIPv4Len, spaceChar);

                let desc = `${name} | ${id}${isDefault} | ${ipv4}`;
                vpc.desc = desc;
                this.vpcDescToNetwork[desc] = vpc;
                this.vpcIDsToNetwork[vpc.ID] = vpc;
                this.vpcIDsToSubnets[vpc.ID] = Object.entries(vpc.Subnets);

                this.descs.push(desc);
            }
            this.descs.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
            for (const desc of this.descs) {
                const vpc = this.vpcDescToNetwork[desc];
                const opt = new Option(desc, vpc.ID);
                opt.style.fontFamily = "monospace";
                opt.textContent = opt.textContent.replace(/'\u00A0'/g, '&nbsp;')
                if (this.initalLoad && vpc.ID === this.vpcvalue) {
                    opt.selected = true;
                }
                vpcOptions.push(opt);
            }

            vpcContainer.innerHTML = '';
            if (vpcOptions.length === 0) {
                vpcContainer.append(new Option("No VPCs Found", ''));
                return;
            }
            if (vpcOptions.length === 2) {
                vpcOptions[1].selected = true;
            } else {
                for (const opt of vpcOptions) {
                    opt.selected = (opt.value === this.vpc)
                }
            }
            vpcContainer.append(...vpcOptions);
            // this.simulateRadioClickIfSelected("byLocation")            
            // vpcContainer.value = defaults.vpc;
            await this.buildSubnetList()
            vpcContainer.disabled = !this.active();


        }
        async buildSubnetList() {
            this.toggleApplyButton(false);
            let vpcContainer = this.vpcPicker();
            const defaults = await this.getDefaults();

            let instanceTypeContainer = this.machineTypePicker();
            let loadingOpt = new Option(I18NStringResource.machineTypeFinderLoading, '')
            instanceTypeContainer.innerHTML = ''
            instanceTypeContainer.disabled = true
            instanceTypeContainer.classList.add('disabled');
            instanceTypeContainer.append(loadingOpt);

            const vpcId = vpcContainer.value
            let subnetContainer = this.subnetPicker();
            const heading = {

                Name: "Subnet Name",
                ID: "Subnet ID",
                IPv4: "Subnet IPv4 CIDR",
                Type: "Type", //Public/Private
                Default: " (Default)"

            }

            const subnets = this.vpcIDsToSubnets[vpcId];

            let subnetOptions = []

            this.subnetDescToSubnet = {};
            this.vpcIDsToNetwork = {};
            this.subnetIDToDesc = {};
            let maxNameLen = 0;
            let maxIDLen = 0;
            let maxIPv4Len = 0;

            const headingSubnet = ["Subnet ID", { Name: heading.Name, IPv4: heading.IPv4 }]
            subnets.push(headingSubnet);

            for (const [subnetId, subnet] of subnets) {
                if (maxNameLen < subnet.Name.length) {
                    maxNameLen = subnet.Name.length;
                }
                if (maxIDLen < subnetId.length) {
                    maxIDLen = subnetId.length;
                }
                if (maxIPv4Len < subnet.IPv4.length) {
                    maxIPv4Len = subnet.IPv4.length;
                }
            }
            subnets.pop() //remove the heading
            const spaceChar = '\u00A0'
            const defaultText = " (Default)"
            let descs = []

            heading.Name = heading.Name.padEnd(maxNameLen, spaceChar);
            heading.ID = heading.ID.padEnd(maxIDLen, spaceChar);
            heading.IPv4 = heading.IPv4.padEnd(maxIPv4Len, spaceChar);
            heading.Type = heading.Type.padEnd("private".length, spaceChar);
            heading.Default = defaultText
            let optHeading = new Option(`${heading.Name} | ${heading.ID}${heading.Default} | ${heading.Type} | ${heading.IPv4}`, "")
            optHeading.selected = false
            optHeading.disabled = true
            optHeading.style.fontFamily = "monospace";
            subnetOptions.push(optHeading);

            for (const [subnetId, subnet] of subnets) {
                let name = subnet.Name.padEnd(maxNameLen, spaceChar);
                let id = subnetId.padEnd(maxIDLen, spaceChar);
                let isDefault = ((subnet.IsDefault) ? defaultText : "").padEnd(defaultText.length, spaceChar);
                let ipv4 = subnet.IPv4.padStart(maxIPv4Len, spaceChar);
                let type = subnet.Type.padEnd("private".length, spaceChar);

                let desc = `${name} | ${id}${isDefault} | ${type} | ${ipv4}`;
                subnet.desc = desc;
                this.subnetDescToSubnet[desc] = subnet;
                descs.push(desc);
                this.subnetIDToDesc[subnetId] = desc;
            }
            descs.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
            let selected = false;
            let firstOpt;
            for (const desc of descs) {
                const subnet = this.subnetDescToSubnet[desc];
                const opt = new Option(desc, subnet.SubnetID);
                opt.style.fontFamily = "monospace";
                opt.textContent = opt.textContent.replace(/'\u00A0'/g, '&nbsp;')
                if (subnet.Type !== "public") {
                    opt.disabled = true;
                } else if (this.initalLoad && subnet.ID === this.subnetvalue) {
                    opt.selected = true;
                    selected = true
                } else if (!selected && !firstOpt) {
                    firstOpt = opt;
                }

                subnetOptions.push(opt);
            }

            if (firstOpt) {
                firstOpt.selected = true;
                selected = true
            }

            subnetContainer.innerHTML = '';
            let msg;
            if (subnetOptions.length === 0) {
                subnetContainer.append(new Option("No Subnets Found", ''));
                instanceTypeContainer.innerHTML = "";
                instanceTypeContainer.append(new Option("No Subnets Found", ''));
                msg = DojoString.substitute(I18NStringResource.machineTypeFinderNoSupportedSubnetsForTheSelectedVPC, [this.getCurrentLocation(), vpcContainer.value]);
            } else if (!selected) {
                msg = DojoString.substitute(I18NStringResource.machineTypeFinderNoPublicSubnetsForTheSelectedVPC, [this.getCurrentLocation(), vpcContainer.value]);
            }
            if (msg) {
                Util.notify("WARNING", msg);
                subnetContainer.innerHTML = ''
                subnetContainer.append(new Option(msg, ''));
                instanceTypeContainer.innerHTML = "";
                instanceTypeContainer.append(new Option(msg, ''));
                return;
            }
            if (subnetOptions.length === 2) {
                subnetOptions[1].selected = true;
            } else {
                for (const opt of subnetOptions) {
                    opt.selected = (opt.value === this.subnetvalue)
                }
            }
            subnetContainer.append(...subnetOptions);

            await this.updateMachineTypePickerWithSubnet()


        }


        async updateMachineTypePickerWithSubnet() {
            this.toggleApplyButton(false);
            this.machineTypePicker().innerHTML = '';
            let vpcContainer = this.vpcPicker();
            let subnetContainer = this.subnetPicker();
            let instanceTypeContainer = this.machineTypePicker();

            let loadingOpt = new Option(I18NStringResource.machineTypeFinderLoading, '')
            instanceTypeContainer.innerHTML = ''
            instanceTypeContainer.disabled = true
            instanceTypeContainer.classList.add('disabled');
            instanceTypeContainer.append(loadingOpt);

            const dataService = this.getDataService().getPlatformDataService("aws");
            let allAvailableInstancesInSubnet = await dataService.getEC2Service().getInstanceType(this.getCurrentCredentialId(), this.getCurrentLocation(), subnetContainer.value);
            let options = await this.getSupportedInstanceTypesAsOptions(allAvailableInstancesInSubnet)
            if (options.length === 0) {
                const msg = DojoString.substitute(I18NStringResource.machineTypeFinderNoSupportedInstancesForTheSelectedLocation, [this.getCurrentLocation(), vpcContainer.value, subnetContainer.value]);
                Util.notify("WARNING", msg);
                instanceTypeContainer.innerHTML = ''
                instanceTypeContainer.append(new Option(msg, ''));
                return;
            }

            instanceTypeContainer.innerHTML = '';
            if (options.length === 2) {
                options[1].selected = true;
            } else {
                for (const opt of options) {
                    opt.selected = (opt.value === this.machinetypevalue)
                }
            }
            instanceTypeContainer.append(...options);

            vpcContainer.disabled = !this.active();
            subnetContainer.disabled = !this.active();
            instanceTypeContainer.disabled = !this.active();
            if (this.active()) {
                vpcContainer.classList.remove('disabled');
                subnetContainer.classList.remove('disabled');
                instanceTypeContainer.classList.remove('disabled');

                instanceTypeContainer.focus();

                this.toggleApplyButton();

            }
            if (this.isInitialLoad()) {
                // this is the default picker, so it's here, and "checked=true" and no "hidden=true" are applied in the HTML
                // if something comes in for a region/combination that's not applicable, this will flush the choice to something applicable                    
                // in theory this is a no-op because the pickers should all be set to the previously picked value
                this.applyChoices();

                this.setInitialLoad(false);
            }

        }
        render() {
            return Lit.html`
            <div class="radioButtonContainer byLocation container-fluid">
            <div class="row">
                <div class="col-12 labelContainer">
            
            <input type="radio" name="findByChoice" 
                @change=${this.updateUI} 
                @click=${this.logClick} 
                data-findby="byLocation" value="byLocation" id="${'byLocation' + this.timestamp}" />
            <label for="${'byLocation' + this.timestamp}">${I18NStringResource.machineTypeFinderByLocation}</label>
            </div>
            </div>

            <div class="sectionContents"><fieldset class="section2Group"><div class="section2InputContainer"  data-findby="byLocation" hidden>
                <label for="Manual${this.vpcid}">${this.vpclabel}</label>
                <div class="section2InputValidationContainer">
                    <select name="Manual${this.vpcid}" id="Manual${this.vpcid}" data-findby="byLocation" class="disabled" disabled 
                        @change=${this.buildSubnetList.bind(this)} >
                        
                        <option value="" disabled hidden selected>${this.vpcplaceholder}</option>
                    </select>
                    <inline-validation elementid="Manual${this.vpcid}" />
                </div>
            </div>
                
            <div class="sectionContents"><fieldset class="section2Group"><div class="section2InputContainer"  data-findby="byLocation" hidden>
                <label for="Manual${this.subnetid}">${this.subnetlabel}</label>
                <div class="section2InputValidationContainer">
                    <select name="Manual${this.subnetid}" id="Manual${this.subnetid}" data-findby="byLocation" class="disabled" disabled 
                        @optionsLoaded=${{ handleEvent: () => this.debouncedOptionsLoaded(), once: true }} 
                        @change=${this.updateMachineTypePickerWithSubnet.bind(this)} >
                        
                        <option value="" disabled hidden selected>${this.subnetplaceholder}</option>
                    </select>
                    <inline-validation elementid="Manual${this.subnetid}" />
                </div>
            </div>
                
            <div class="sectionContents"><fieldset class="section2Group"><div class="section2InputContainer"  data-findby="byLocation" hidden>
                <label for="Manual${this.machinetypeid}">${this.machinetypelabel}</label>
                <div class="section2InputValidationContainer">
                    <select name="Manual${this.machinetypeid}" id="Manual${this.machinetypeid}" data-findby="byLocation" class="disabled" disabled 
                        @optionsLoaded=${{ handleEvent: () => this.debouncedOptionsLoaded(), once: true }} 
                        @change=${this.toggleApplyButton} >
                        
                        <option value="" disabled hidden selected>${this.machinetypeplaceholder}</option>
                    </select>
                    <inline-validation elementid="Manual${this.machinetypeid}" />
                </div>
            </div>
            </div>
            `

        }

    }


    return ServiceByLocation;
}); // require
