/* jshint esversion: 8 */
define([
    "lit-element/lit-element.bundled",
    "components/cloudCenterElement",
    "validation/inlineComponentValidation",
    "components/inlinePopover",
    "util"
], function(
    Lit,
    CloudCenterElement,
    InlineComponentValidation,
    InlinePopover,
    Util
) {

    class StackRollbackChoice extends CloudCenterElement {

        constructor () {
            super();
        }

        cceInitialize () {
            super.cceInitialize();
            Util.consoleLogTrace('StackRollbackChoice`, `cceInitialize invoked');
            // Default values
            this.elementlabel = "";
            this.poptext = "";
            this.dduxenabled = false;
            this.rollbackinputid = "";
            this.rollbackinputplaceholder = "";
            this.rollbackinputvalue = "";
            this.rollbackcheckboxchecked = false;
        }

        // Reactive Properties -- updates rendering when values change
        static get properties () {
            return {
                disabled: {type: Boolean},
                elementlabel: {type: String},
                poptext: {type: String},
                dduxenabled: {type: Boolean},
                rollbackinputid: {type: String},
                rollbackinputplaceholder: {type: String},
                rollbackinputvalue: {type: String},
                rollbackcheckboxchecked: {type: Boolean},
            };
        }

        // Called after initial rendering
        firstUpdated () {
            Util.consoleLogTrace(`StackRollbackChoice.firstUpdated`, `invoked`);
            const currentValue = this.renderRoot.querySelector(`input[type="checkbox"]#${this.rollbackinputid}`).value;
            if (currentValue === "true") {
                this.rollbackcheckboxchecked = true;
            } else {
                this.rollbackcheckboxchecked = false;
            }
        }

        _onRollbackChoiceChange (event) {
            this.rollbackcheckboxchecked = !this.rollbackcheckboxchecked;
            this.rollbackinputvalue = this.rollbackcheckboxchecked.toString();
        }

        // Render element template
        render () {
            return Lit.html`
                <div class="labelContainer">
                    <label for="${'stackRollbackChoiceFieldset' + this.timestamp}">${this.elementlabel}</label>
                </div>
                <div class="section2InputValidationContainer" id="${'stackRollbackChoiceFieldset' + this.timestamp}">
                    <input type="checkbox"
                            id="${this.rollbackinputid}"
                            name="${this.rollbackinputid}"
                            value="${this.rollbackinputvalue}"
                            data-checkboxtype="truefalse"
                            ?checked=${this.rollbackcheckboxchecked}
                            ?disabled=${this.disabled}
                            @change=${this._onRollbackChoiceChange}
                    />
                    <inline-validation elementid="${this.rollbackinputid}" />
                </div>
                <inline-popover poptext="${this.poptext}" />
            `;
        }
    }
    // Register custom element '<stack-rollback-choice elementid="myelementid" />'
    customElements.define('stack-rollback-choice', StackRollbackChoice);

    return StackRollbackChoice;
});
