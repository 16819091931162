/* jshint esversion: 8 */
define([
  "util",
  "supportedProducts",
  "computeResource/collections/collectionItem",
  "computeResource/collections/cluster/clusterCollectionItem",
  "computeResource/collections/matlab/matlabCollectionItem",
  "computeResource/collections/onlineServer/onlineServerCollectionItem",
  "computeResource/collections/parallelServer/parallelServerCollectionItem",
  "computeResource/collections/productionServer/productionServerCollectionItem",
  "computeResource/collections/webAppServer/webAppServerCollectionItem",
  "computeResource/collections/licenseManager/licenseManagerCollectionItem",
  "computeResource/collections/cfeDemo/cfeDemoCollectionItem",
  "computeResource/collections/cloudNetwork/cloudNetworkCollectionItem",
], function (
  Util, SupportedProducts, CollectionItem, ClusterCollectionItem,
  MatlabCollectionItem, OnlineServerCollectionItem,
  ParallelServerCollectionItem, ProductionServerCollectionItem,
  WebAppServerCollectionItem,LicenseManagerCollectionItem,
  CFEDemoCollectionItem,
  CloudNetworkCollectionItem
) {

  const _amdClosureArgs = {
    "MatlabCollectionItem": MatlabCollectionItem,
    "ClusterCollectionItem": ClusterCollectionItem,
    "OnlineServerCollectionItem": OnlineServerCollectionItem,
    "ParallelServerCollectionItem": ParallelServerCollectionItem,
    "ProductionServerCollectionItem": ProductionServerCollectionItem,
    "WebAppServerCollectionItem": WebAppServerCollectionItem,
    "LicenseManagerCollectionItem": LicenseManagerCollectionItem,
    "CfeDemoCollectionItem": CFEDemoCollectionItem,
    "CloudNetworkCollectionItem": CloudNetworkCollectionItem
  };

  class CollectionItemFactory {

    static initializeCollectionItemTemplates () {
      const productTemplates = {};
      const products = SupportedProducts.getProductNameList();
      for (let product of products) {
        const camelCaseProduct = Util.underscoreToTitleCase(product, false);
        const collectionItemClass = `${camelCaseProduct}CollectionItem`;
        const productItemConstructor = _amdClosureArgs[collectionItemClass];
        if (!productItemConstructor) {
          throw new TypeError(`No class constructor method found for product ${product}`);
        }
        productTemplates[product] = productItemConstructor;
      }
      return productTemplates;
    }

    static getCollectionItemClass (product) {
      if (!product || typeof product !== 'string' || !SupportedProducts.getProductNameList().includes(product)) {
        throw new TypeError("Invalid product argument");
      }
      const clazz = CollectionItemFactory.COLLECTION_ITEM_PRODUCT_TEMPLATES[product];
      return clazz;
    }

    static createCollectionItem (product, args) {
      const clazz = CollectionItemFactory.getCollectionItemClass(product);
      const item = new clazz(args);
      return item;
    }

    static isValidCollectionItem (item) {
      let isValid = false;
      if (item && typeof item === 'object' && item instanceof CollectionItem) {
        isValid = true;
      }
      return isValid;
    }

    static createCollectionHeader (product) {
      const clazz = CollectionItemFactory.getCollectionItemClass(product);
      return clazz.createCollectionHeader();
    }

    static getWizardStep1Fields (product) {
      if (!product || typeof product !== 'string' || !SupportedProducts.getProductNameList().includes(product)) {
        throw new TypeError("Invalid product argument");
      }
      const clazz = CollectionItemFactory.getCollectionItemClass(product);
      return clazz.getStep1FieldNames();
    }

  }
  Object.defineProperty(CollectionItemFactory, "COLLECTION_ITEM_PRODUCT_TEMPLATES", {
    value: CollectionItemFactory.initializeCollectionItemTemplates(),
    writable: false,
    configurable: false,
    enumerable: false
  });

  return CollectionItemFactory;
});
