
define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "mw-log/Log",
    "./Trigger"
], function (declare, lang, Log, Trigger) {

    return declare(Trigger, {
        constructor: function () {
            var nameParts = this.name.split(" ");
            if (nameParts.length < 2 || nameParts[0] !== "timeout") {
                Log.error("Invalid trigger name for a TimeoutTrigger. " +
                    "Could not parse out the type of handler.");
            }
            this.timeoutInSeconds = parseFloat(nameParts[1]);
            this.handlerType = nameParts[0];
        },

        enable: function () {
            this.disable(); // clear the timeout just in case one already is setup.
            this.timeoutHandle = setTimeout(
                lang.hitch(null, this.triggerHandler, this.name),
                this.timeoutInSeconds * 1000
            );
        },

        disable: function () {
            if (this.timeoutHandle) {
                clearTimeout(this.timeoutHandle);
                this.timeoutHandle = null;
            }
        }
    });
});