define ([
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string"
], function(I18NStringResource, DojoString) {

  const StatusEnum = Object.freeze({
      PAUSED: Symbol("paused"),
      TERMINATED: Symbol("terminated"),

      RESUMING: Symbol("resuming"),
      STARTING: Symbol("starting"),
      STARTED: Symbol("started"),

      RUNNING: Symbol("running"),

      PAUSING: Symbol("pausing"),
      TERMINATING: Symbol("terminating"),

      WAITING_FOR_RESOURCES: Symbol("waiting_for_resources"),
      REMOVING_RESOURCES: Symbol("removing_resources"),
      ERROR_OCCURRED: Symbol("error_occurred"),
      UNKNOWN: Symbol("unknown")
  });


  const map = new Map();
    map.set(StatusEnum.PAUSED, {
    classname: "status-paused",
    text: I18NStringResource.computeResourcePageStatusPaused,
    menuItems: ["resume", "edit", "clone", "hard-delete", "copy-password", "copy-shareresource"]
  });

  map.set(StatusEnum.TERMINATED, {
    classname: "status-stopped",
    text: I18NStringResource.computeResourcePageStatusStopped,
    menuItems: ["start", "edit", "clone", "hard-delete", "copy-password", "copy-shareresource"] //primary call to action is first
  });

  map.set(StatusEnum.STARTED, { //this is when the stack is on but not ready
    classname: "status-starting",
    text: I18NStringResource.computeResourcePageStatusInitializing,
    menuItems: ["pause", "clone", "hard-delete", "copy-password", "copy-shareresource"]
  });
  map.set(StatusEnum.RESUMING, map.get(StatusEnum.STARTED));
  map.set(StatusEnum.STARTING, map.get(StatusEnum.STARTED));

  map.set(StatusEnum.RUNNING, { //this is when everything is ready, including any software on the instance(s)
    classname: "status-running",
    text: I18NStringResource.computeResourcePageStatusStarted,
    menuItems: ["pause", "edit", "rdp", "clone", "hard-delete", "copy-password", "copy-shareresource"]
  });

  map.set(StatusEnum.PAUSING, {
    classname: "status-pausing",
    text: I18NStringResource.computeResourcePageStatusPausing,
    menuItems: ["resume", "clone", "hard-delete", "copy-password", "copy-shareresource"]
  });
  map.set(StatusEnum.TERMINATING, {
    classname: "status-stopping",
    text: I18NStringResource.computeResourcePageStatusStopping,
    menuItems: ["start"]
  });


  map.set(StatusEnum.WAITING_FOR_RESOURCES, {
    classname: "status-running",
    text: I18NStringResource.computeResourcePageStatusUpdating,
    menuItems: ["stop", "edit", "clone", "hard-delete"]
  });
  map.set(StatusEnum.REMOVING_RESOURCES, map.get(StatusEnum.WAITING_FOR_RESOURCES));

  map.set(StatusEnum.ERROR_OCCURRED, {
    classname: "status-running",
    text: I18NStringResource.computeResourcePageStatusUpdating,
    menuItems: ["start", "stop", "edit", "pause", "resume", "clone", "hard-delete"]
  });
  map.set(StatusEnum.UNKNOWN, map.get(StatusEnum.ERROR_OCCURRED));

  class CloudStatus {

    static isValidStatus (status) {
      return (status && (Object.keys(CloudStatus.Enum).find(key => CloudStatus.Enum[key] === status)));
    }

    static getStatusClass (status) {
      if (CloudStatus.isValidStatus(status) && CloudStatus.statusClasses.has(status)) {
        return CloudStatus.statusClasses.get(status).classname;
      }
    }

    static getStatusByDisplayText (text) {
      let status = CloudStatus.Enum.UNKNOWN;
      if (text && typeof text === 'string') {
        const entry = CloudStatus.statusClasses.keys().find(key => {
          const entry = CloudStatus.statusClasses.get(key);
          if (entry.text === text) {
            status = key;
            return true;
          }
          return false;
        });
      }
      return status;
    }

    static getStatusDisplayText (status) {
      if (CloudStatus.isValidStatus(status) && CloudStatus.statusClasses.has(status)) {
        return CloudStatus.statusClasses.get(status).text;
      }
    }

    static getMenuItems (status) {
      if (CloudStatus.isValidStatus(status) && CloudStatus.statusClasses.has(status)) {
        // return a copy, not a reference to the original to prevent modifications
        const orig = CloudStatus.statusClasses.get(status).menuItems;
        const copy = JSON.parse(JSON.stringify(orig));
        return copy;
      }
    }

    static addMenuItem(status, actionId) {
      if (CloudStatus.isValidStatus(status) && CloudStatus.statusClasses.has(status) && actionId && typeof actionId === "string") {
        const menuItems = CloudStatus.statusClasses.get(status).menuItems;
        menuItems.push(actionId);
      }
    }

    static isValidStatusData (statusData) {
      let isValid = false;
      if (statusData && typeof statusData === 'object') {
        // if (statusData && statusData.state) {
          isValid = true;
        // }
      }
      return isValid;
    }

    static requiresMachineData (statusData) {
      let machineDataRequired = false;
      if (CloudStatus.isValidStatusData(statusData)) {
        if (statusData.state === "STARTED") {
          machineDataRequired = true;
        }
      }
      return machineDataRequired;
    }

    /*
    cloudStatus
    {
    "msg": {
        "configId": "",
        "instanceId": "",
        "provider": "aws",
        "rulesId": "",
        "state": 1,
        "stateText": "CREATE_COMPLETE",
        "templateId": ""
    }
    }

    2: if CREATE_COMPLETE:
    cloudMachines
    {
    "msg": [{
            "description": "",
            "events": {},
            "id": "i-034cd170db7152936",
            "location": "us-east-1b",
            "private_dns": "ip-172-31-2-xx.ec2.internal",
            "private_ip": "172.31.2.xx",
            "public_dns": "ec2-3-90-216-xx28.compute-1.amazonaws.com",
            "public_ip": "3.90.216.xx",
            "state": "running",
            "state_reason": "",
            "status": "ok",
            "system_status": "ok"
        }
    ]
    }
    */
    static getStatus (statusData) {
      let status = CloudStatus.Enum.UNKNOWN;
      if (CloudStatus.isValidStatusData(statusData)) {
        switch (statusData.state) {
          case "PAUSED":
            status = CloudStatus.Enum.PAUSED;
            break;
          case "TERMINATED":
          case "SHUTDOWN":
            status = CloudStatus.Enum.TERMINATED;
            break;
          case "RESUMING": //Compute in progress
            status = CloudStatus.Enum.RESUMING;
            break;
          case "STARTING": //Stack in progress
            status = CloudStatus.Enum.STARTING;
            break;
          case "STARTED": //Stack complete but software not ready
            status = CloudStatus.Enum.STARTED;
            break;
          case "RUNNING":
            status = CloudStatus.Enum.RUNNING;
            break;
          case "PAUSING":
            status = CloudStatus.Enum.PAUSING;
            break;
          case "TERMINATING":
            status = CloudStatus.Enum.TERMINATING;
            break;
          case "REMOVING_RESOURCES":
            status = CloudStatus.Enum.REMOVING_RESOURCES;
            break;
          case "REMOVING_RESOURCES":
            status = CloudStatus.Enum.REMOVING_RESOURCES;
            break;
          case "ERROR_OCCURRED":
            status = CloudStatus.Enum.ERROR_OCCURRED;
            break;
          case "UNKNOWN":
          default:
            status = CloudStatus.Enum.UNKNOWN;
        }
      }
      return status;
    }

    static getExpectedStatus(action) {
      let status;
      switch (action) {
        case "stop":
          status = CloudStatus.Enum.PAUSING;
          break;
        case "hard-delete":
          status = CloudStatus.Enum.TERMINATING;
          break;
        case "pause":
          status = CloudStatus.Enum.PAUSING;
          break;
        case "start":
          status = CloudStatus.Enum.STARTING;
          break;
        case "resume":
          status = CloudStatus.Enum.RESUMING;
          break;
        default:
          status = CloudStatus.Enum.UNKNOWN;
      }
      return status;
    }

    static isStartingUpStatus (status) {
      let isStarting = false;
      if (CloudStatus.isValidStatus(status) && (
        status === CloudStatus.Enum.RESUMING ||
        status === CloudStatus.Enum.STARTING ||
        status === CloudStatus.Enum.STARTED
      )) {
        isStarting = true;
      }
      return isStarting;
    }

    static isShuttingDownStatus (status) {
      let isShuttingDown = false;
      if (CloudStatus.isValidStatus(status) && (
        status === CloudStatus.Enum.PAUSING ||
        status === CloudStatus.Enum.TERMINATING)
      ) {
        isShuttingDown = true;
      }
      return isShuttingDown;
    }

    static isTransitionStatus (status) {
      let isTransition = false;
      if (status && (
        CloudStatus.isStartingUpStatus(status) ||
        CloudStatus.isShuttingDownStatus(status))
      ) {
            isTransition = true;
      }
      return isTransition;
    }

  }

  Object.defineProperty(CloudStatus, "Enum", {
    value: StatusEnum,
    writable: false,
    configurable: false,
    enumerable: true
  });

  Object.defineProperty(CloudStatus, "statusClasses", {
    value: map,
    writable: false,
    configurable: false,
    enumerable: false
  });

  return CloudStatus;
});
