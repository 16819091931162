/* jshint esversion: 8 */
define([
  "util",
  "supportedProducts",
  "service/productConnect/connectionButtonGenerator",
  "service/productConnect/matlab/matlabConnectionButtonGenerator",
  "service/productConnect/cluster/clusterConnectionButtonGenerator",
  "service/productConnect/onlineServer/onlineServerConnectionButtonGenerator",
  "service/productConnect/parallelServer/parallelServerConnectionButtonGenerator",
  "service/productConnect/productionServer/productionServerConnectionButtonGenerator",
  "service/productConnect/webAppServer/webAppServerConnectionButtonGenerator",
  "service/productConnect/licenseManager/licenseManagerConnectionButtonGenerator",
  "service/productConnect/cfeDemo/cfeDemoConnectionButtonGenerator",
  "service/productConnect/cloudNetwork/cloudNetworkConnectionButtonGenerator"
], function (
  Util,
  SupportedProducts,
  ConnectionButtonGenerator,
  MatlabConnectionButtonGenerator,
  ClusterConnectionButtonGenerator,
  OnlineServerConnectionButtonGenerator,
  ParallelServerConnectionButtonGenerator,
  ProductionServerConnectionButtonGenerator,
  WebAppServerConnectionButtonGenerator,
  LicenseManagerConnectionButtonGenerator,
  CFEDemoConnectionButtonGenerator,
  CloudNetworkConnectionButtonGenerator
) {

  const _amdClosureArgs = {
    "MatlabConnectionButtonGenerator": MatlabConnectionButtonGenerator,
    "ClusterConnectionButtonGenerator": ClusterConnectionButtonGenerator,
    "OnlineServerConnectionButtonGenerator": OnlineServerConnectionButtonGenerator,
    "ParallelServerConnectionButtonGenerator": ParallelServerConnectionButtonGenerator,
    "ProductionServerConnectionButtonGenerator": ProductionServerConnectionButtonGenerator,
    "WebAppServerConnectionButtonGenerator": WebAppServerConnectionButtonGenerator,
    "LicenseManagerConnectionButtonGenerator": LicenseManagerConnectionButtonGenerator,
    "CfeDemoConnectionButtonGenerator": CFEDemoConnectionButtonGenerator,
    "CloudNetworkConnectionButtonGenerator": CloudNetworkConnectionButtonGenerator
  };

  class ConnectionButtonGeneratorFactory {

    static initializeConnectButtonGeneratorProductMap () {
      const connectButtonGeneratorByProductMap = {};
      const products = SupportedProducts.getProductNameList();
      for (let product of products) {
        const camelCaseProduct = Util.underscoreToTitleCase(product, false);
        const connectButtonGeneratorClass = `${camelCaseProduct}ConnectionButtonGenerator`;
        const connectButtonGeneratorConstructor = _amdClosureArgs[connectButtonGeneratorClass];
        if (!connectButtonGeneratorConstructor) {
          throw new TypeError(`No class constructor method found for product ${product}`);
        }
        connectButtonGeneratorByProductMap[product] = connectButtonGeneratorConstructor;
      }
      return connectButtonGeneratorByProductMap;
    }

    static getConnectionButtonGeneratorClass (product) {
      if (!product || typeof product !== 'string' || !SupportedProducts.getProductNameList().includes(product)) {
        throw new TypeError("Invalid product argument");
      }
      const clazz = ConnectionButtonGeneratorFactory.CONNECT_BUTTON_GENERATOR_PRODUCT_MAP[product];
      return clazz;
    }

    static getConnectButtonGeneratorByProduct (product, args) {
      const clazz = ConnectionButtonGeneratorFactory.getConnectionButtonGeneratorClass(product);
      const item = new clazz(args);
      return item;
    }

    static isValidConnectionButtonGenerator (item) {
      let isValid = false;
      if (item && typeof item === 'object' && item instanceof ConnectionButtonGenerator) {
        isValid = true;
      }
      return isValid;
    }

    static getConnectButtonGeneratorProductMapIterator () {
      return Object.keys(ConnectionButtonGeneratorFactory.CONNECT_BUTTON_GENERATOR_PRODUCT_MAP);
    }
  }
  Object.defineProperty(ConnectionButtonGeneratorFactory, "CONNECT_BUTTON_GENERATOR_PRODUCT_MAP", {
    value: ConnectionButtonGeneratorFactory.initializeConnectButtonGeneratorProductMap(),
    writable: false,
    configurable: false,
    enumerable: false
  });

  return ConnectionButtonGeneratorFactory;
});
