/* jshint esversion: 8 */
/*
 * This file contains logic for loading machine list picker by location (ie, vpc > subnet > machine)
 */
define([
    "underscore",
    "jquery",
    "lit-element/lit-element.bundled",
    "dojo/i18n!nls/cloudCenterStringResource",
    'dojo/string',
    "util",
    "service/components/abstractMachineTypeChooseWithFinder",
], function (
    _,
    $,
    Lit,
    I18NStringResource,
    DojoString,
    Util,
    MachineTypeWithFinder
) {

    class ServiceByExistingResource extends MachineTypeWithFinder {
        constructor(args) {
            super(args);

            this.buildExistingResourceList();
        }

        existingResourcePicker() {
            return this.root().querySelector(`div.radioButtonContainer.byExistingResource select#existingResourcePicker`);
        }
        existingResourceMachineTypePicker() {
            return this.root().querySelector(`div.radioButtonContainer.byExistingResource select#existingResourceMachineTypePicker`);
        }

        async buildMachineListByCredentialIdLocationAndSubnet() {
            this.toggleApplyButton(false);
            const existingResourceContainer = this.existingResourcePicker()
            const instanceTypeContainer = this.existingResourceMachineTypePicker()

            let loadingOpt = new Option(I18NStringResource.machineTypeFinderLoading, '')
            instanceTypeContainer.innerHTML = ''
            instanceTypeContainer.disabled = true
            instanceTypeContainer.classList.add('disabled');
            instanceTypeContainer.append(loadingOpt);

            const opt = existingResourceContainer.selectedOptions[0];
            const configData = this.getConfigMap().get(opt.value);


            const dataService = this.getDataService().getPlatformDataService("aws");
            let allAvailableInstancesInSubnet = await dataService.getEC2Service().getInstanceType(configData.credentialid, configData.location, configData.subnet);
            let options = await this.getSupportedInstanceTypesAsOptions(allAvailableInstancesInSubnet)
            if (this.active() && options.length === 0) {
                const msg = DojoString.substitute(I18NStringResource.machineTypeFinderNoSupportedInstancesForTheSelectedExistingResource, [configData.accountdesc, configData.accountid, configData.location, configData.subnet]);
                Util.notify("WARNING", msg);
                instanceTypeContainer.innerHTML = ''
                instanceTypeContainer.append(new Option(msg, ''));
                return;
            }

            instanceTypeContainer.innerHTML = '';
            instanceTypeContainer.append(...options); // add rows
            if (this.active()) {
                instanceTypeContainer.disabled = false;
                instanceTypeContainer.classList.remove('disabled');

                instanceTypeContainer.focus();

                this.toggleApplyButton();

            }
            if (this.isInitialLoad()) {
                this.setInitialLoad(false);
            }



        }

        async buildExistingResourceList() {
            this.toggleApplyButton(false);
            const container = this.existingResourcePicker()
            const machineTypePicker = this.existingResourceMachineTypePicker()
            if (!container) {
                setTimeout(() => this.buildExistingResourceList(), 100);
                return;
            }

            this.disableAll([container, machineTypePicker], I18NStringResource.machineTypeFinderLoading)

            let existingResources = await this.getDataService().workflow.search("config", {
                "cloud_provider": "aws"
            }, { show_all: true });
            // search (dataType, facets, baseParams) {
            const data = await this
            if (!existingResources || existingResources.length === 0) {
                // clear the whole radio button group
                this.root().querySelector(`div.radioButtonContainer.byExistingResource`).innerHTML = '';
                return;
            }



            let options = [];
            const configs = new Map();
            const valueMaxLen = {};
            const sortedHeadings = [
                "name",
                "product",
                "version",
                "location",
                "vpc",
                "subnet",
                "machinetype",
                "accountdesc",
                "accountid"
            ];
            for (const config of existingResources) {
                const name = config.params["mw-name"];
                const id = config.id;
                const provider = config.params.cloud_provider;
                const location = config.params["mw-cloud-location"];

                if (this.currentPage === "edit") {
                    if (location !== this.getCurrentLocation()) {
                        continue;
                    }
                }

                const product = config.params.product.replace(/_/g, " ").replace(/matlab/g, "MATLAB");
                const version = config.params.version;
                const subnet = (config.params[this.subnetid]) ? config.params[this.subnetid] : ""
                const vpc = (config.params[this.vpcid]) ? config.params[this.vpcid] : ""
                const machineType = (config.params[this.machinetypeid]) ? config.params[this.machinetypeid] : ""
                const mwCredentialId = config.params["mw-credential-id"]
                const accountId = config.cloud.account.account_id
                const accountDesc = config.cloud.account.description
                const mwCredTypeId = Object.keys(config.params.credential_type)[0]

                if (vpc && subnet) {

                    const tmpConfig = {
                        name: name,
                        product: product,
                        version: version,
                        location: location,
                        credentialid: mwCredentialId,
                        credentialtypeid: mwCredTypeId,
                        accountid: accountId,
                        accountdesc: accountDesc,
                        rulesId: config.rules_id,
                        vpc: vpc,
                        subnet: subnet,
                        machinetype: machineType,
                        platform: provider
                    }
                    for (const key of sortedHeadings) {
                        const value = tmpConfig[key];
                        if (valueMaxLen[key] === undefined || value.length > valueMaxLen[key]) {
                            valueMaxLen[key] = value.length;
                        }
                    }
                    configs.set(id, tmpConfig)

                }
            }

            const spaceChar = '\u00A0'

            const headers = {
                name: "Name",
                product: "Product",
                version: "Version",
                machinetype: "Machine",
                accountdesc: "Cloud Acct Desc",
                accountid: "Cloud Acct",
                location: "Location",
                vpc: "VPC",
                subnet: "Subnet"

            }
            for (const [key, value] of Object.entries(headers)) {
                if (valueMaxLen[key] === undefined || value.length > valueMaxLen[key]) {
                    valueMaxLen[key] = value.length;
                }
            }

            let heading = ""
            for (const key of sortedHeadings) {
                if (heading) {
                    heading += " | "
                }
                const header = headers[key].padEnd(valueMaxLen[key], spaceChar);
                heading += header
            }

            const headerOpt = new Option(heading, '');

            headerOpt.style.fontFamily = "monospace";
            headerOpt.textContent = headerOpt.textContent.replace(/'\u00A0'/g, '&nbsp;')
            headerOpt.disabled = true
            options.push(headerOpt);

            for (const [id, config] of configs.entries()) {
                let desc = "";
                for (const key of sortedHeadings) {
                    if (desc) {
                        desc += " | "
                    }
                    desc += config[key].padEnd(valueMaxLen[key], spaceChar);
                }
                const opt = new Option(desc, id);
                opt.style.fontFamily = "monospace";
                opt.textContent = opt.textContent.replace(/'\u00A0'/g, '&nbsp;')
                options.push(opt);
            }

            container.innerHTML = '';
            if (options.length === 2) {
                options[1].selected = true;
            } else {
                let allowSelect = true;
                for (const opt of options) {
                    opt.selected = (allowSelect && opt.textContent.includes(this.vpcvalue) && opt.textContent.includes(this.subnetvalue))
                    if (allowSelect && opt.selected) {
                        allowSelect = false;
                    }
                    break;
                }
            }
            container.append(...options);
            this._configMap = configs;

            this.selectionRequiresStep1Change();




            await this.buildMachineListByCredentialIdLocationAndSubnet()
            this.initalLoad = false



        }

        selectionRequiresStep1Change() {
            if (this.active()) {
                const container = this.existingResourcePicker();

                const currentLoc = this.getCurrentLocation();
                const currentCredId = this.getCurrentCredentialId();

                const opt = container.selectedOptions[0];
                const configData = this.getConfigMap().get(opt.value);

                if (configData.location !== currentLoc || configData.credentialid !== currentCredId) {
                    const act = configData.accountdesc + " - " + configData.accountid;
                    let warning;
                    if (configData.location !== currentLoc && configData.credentialid !== currentCredId) {
                        warning = DojoString.substitute(I18NStringResource.machineTypeFinderReloadPageWithNewInformation, [configData.location, act]);
                    } else if (configData.location !== currentLoc) {
                        warning = DojoString.substitute(I18NStringResource.machineTypeFinderReloadPageWithNewInformationLocationOnly, [configData.location]);
                    } else {
                        warning = DojoString.substitute(I18NStringResource.machineTypeFinderReloadPageWithNewInformationAccountOnly, [act]);
                    }

                    const prompt = "";
                    return [warning, prompt];

                }
            }
            return [null, null];
        }
        updateStepsAndNavigate() {
            let [vpc, subnet, machineType] = this.getSelectedData();
            const container = this.existingResourcePicker();
            const opt = container.selectedOptions[0];
            const configData = this.getConfigMap().get(opt.value);

            this.updateSavedStep1Value("cloudLocationSelector", configData.location)
            this.updateSavedStep1Value("credentialSelector", configData.credentialid)

            this.updateSavedStep2ValueOverride("mw-cloud-location", configData.location)
            this.updateSavedStep2ValueOverride("mw-credential-id", configData.credentialid)
            this.updateSavedStep2ValueOverride(this.vpcid, vpc)
            this.updateSavedStep2ValueOverride(this.subnetid, subnet)
            this.updateSavedStep2ValueOverride(this.machinetypeid, machineType)

            this.onStep1Change();

        }

        getConfigMap() {
            return this._configMap;
        }

        getSelectedData() {
            const existingResourceContainer = this.existingResourcePicker();
            const existingResourceMachineTypePicker = this.existingResourceMachineTypePicker()
            const opt = existingResourceContainer.selectedOptions[0];
            const configData = this.getConfigMap().get(opt.value);
            return [configData.vpc, configData.subnet, existingResourceMachineTypePicker.value];

        }


        render() {
            return Lit.html`
            <div class="radioButtonContainer byExistingResource container-fluid">
            <div class="row">
                <div class="col-12 labelContainer">

            <input type="radio" name="findByChoice"
                @change=${this.updateUI}
                @click=${this.logClick}
                data-findby="byExistingResource" value="byExistingResource" id="${'byExistingResource' + this.timestamp}"  />
            <label style="text-align:left" for="${'byExistingResource' + this.timestamp}">${I18NStringResource.machineTypeFinderByExistingResource}</label>
            </div>
            </div

            <div class="sectionContents"><fieldset class="section2Group"><div class="section2InputContainer"  data-findby="byExistingResource" hidden>
                <label for="existingResourcePicker">${I18NStringResource.machineTypeFinderResource}</label>
                <div class="section2InputValidationContainer">
                    <select name="existingResourcePicker" id="existingResourcePicker" disabled data-findby="byExistingResource"
                        @change=${this.buildMachineListByCredentialIdLocationAndSubnet.bind(this)} >

                        <option>${I18NStringResource.machineTypeFinderLoading}</option>
                    </select>
                    <inline-validation elementid="existingResourcePicker" />
                </div>
            </div>

            <div class="sectionContents"><fieldset class="section2Group"><div class="section2InputContainer"  data-findby="byExistingResource" hidden>
                <label for="existingResourceMachineTypePicker">${I18NStringResource.machineTypeFinderMachine}</label>
                <div class="section2InputValidationContainer">
                    <select name="existingResourceMachineTypePicker" id="existingResourceMachineTypePicker" disabled data-findby="byExistingResource"
                        @optionsLoaded=${{ handleEvent: () => this.debouncedOptionsLoaded(), once: true }}
                        @change=${this.toggleApplyButton}
                        >

                        <option>${I18NStringResource.machineTypeFinderLoading}</option>
                    </select>
                    <inline-validation elementid="existingResourceMachineTypePicker" />
                </div>
            </div>
        `

        }

    }


    return ServiceByExistingResource;
}); // require
