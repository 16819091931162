/* jshint esversion: 8 */
define([
    "lit-element/lit-element.bundled",
    "util",
    "mw-ddux/UsageLogger",
    "validation/inlineElementValidation"
], function(
    Lit,
    Util,
    UsageLogger,
    InlineElementValidation
) {

    /* istanbul ignore next */
    function abstractMethod() { throw new Error("Abstract method."); }

    // Abstract base class for Cloud Center custom element.
    // Cannot be directly instantiated.
    class CloudCenterElement extends Lit.LitElement {

        constructor () {
            super(); // super has to come before any usage of 'this'.
            if (this.constructor === CloudCenterElement) {
                throw new Error("Cannot instantiate abstract class.");
            }
            this.cceInitialize();
        }

        render () { abstractMethod(); }

        cceInitialize () {
            Util.consoleLogTrace('CloudCenterElement`, `cceInitialize invoked');
            this.timestamp = `__${new Date().getTime()}`;
            this.elementValidatorTests = {};
            this.dduxenabled = true;
            this.disabled = false;
            this.isMobile = Util.isMobileBrowser();
       }

        getElementValidationTests () {
            return this.elementValidatorTests;
        }

        getElementValidator (elementId) {
            let validator = this.getElementValidationTests()[elementId];
            if (!validator) {
                /* istanbul ignore next */
                validator = function () { return true; };
            }
            return validator;
        }

        appendElementValidationTest (elementId, validator) {
            this.getElementValidationTests()[elementId] = validator;
        }

        setupValidation (elementInfo, inputContainerSelector) {
            const eventMap = new Map();
            const validator = new InlineElementValidation({
                eventMap: eventMap,
                onValidFieldHandler: () => {},
                onInvalidFieldHandler: () => {}
            });
            const inputContainer = this.renderRoot.querySelector(inputContainerSelector);
            validator.setupInlineInputValidation(elementInfo, inputContainer, this.validate.bind(this));
            const size = eventMap.size;
            for (let [selector, data] of eventMap.entries()) {
                let input = this.renderRoot.querySelector(selector);
                if (input) {
                    input.addEventListener("blur", data.onblur, false);
                    input.addEventListener("focus", data.onfocus, false);
                }
            }
        }

        validate (testTargetId, testTargetTag) {
            Util.consoleLogTrace('CloudCenterElement.validate', `validate invoked with testTargetId: ${testTargetId}, testTargetTag: ${testTargetTag}`);
            return this.getElementValidator(testTargetId)();
        }

        getElementSelectionFieldsFromTestTarget (testTargetId, testTargetTag) {
            const elementSelectionInfo = {
                id: testTargetId,
                tagName: testTargetTag,
                type: "",
                selector: `${testTargetTag}#${testTargetId}`
            };
            switch (testTargetTag) {
                case 'number':
                case 'password':
                case 'text':
                    elementSelectionInfo.tagName = 'input';
                    elementSelectionInfo.type = testTargetTag;
                    elementSelectionInfo.selector = `${elementSelectionInfo.tagName}[type="${elementSelectionInfo.type}"]#${elementSelectionInfo.id}`;
                    break;
                default:
                    break;
            }
            return elementSelectionInfo;
        }

        // Avoid using Shadow DOM.  This enables us to share CSS and bootstrap.
        createRenderRoot () {
            return this;
        }

        getCSSClassFromLabelText (labelText) {
            let classname = "";
            if (!labelText) {
                classname = "hiddenLabel";
            }
            return classname;
        }

        _extractBaseIdFromTimestampId (timestampId) {
            let baseId = timestampId;
            if (timestampId && timestampId.indexOf("__") > 0) {
                if (timestampId.indexOf("__") > 0) {
                    const timestampIdRegex = new RegExp("^(\\w+)__(\\d+)$");
                    const pieces = timestampIdRegex.exec(timestampId);
                    if (pieces.length === 3) {
                        baseId = pieces[1];
                    }
                }
            }
            return baseId;
        }

        _logClick (event) {
            if (!event || typeof event !== 'object' || !("currentTarget" in event)) {
                throw new TypeError("Invalid event argument");
            }
            let targetButtonId = event.currentTarget.id;
            if (!targetButtonId && event.target && typeof event.target.closest === 'function') {
                const btn = event.target.closest('button');
                if (btn) {
                    targetButtonId = btn.id;
                }
            }
            if (targetButtonId) {
              targetButtonId = this._extractBaseIdFromTimestampId(targetButtonId);
              try {
                this._logDDUXData({
                  elementid: targetButtonId,
                  elementType: "button",
                  eventType: "clicked"
                });
              } catch (error) {
                Util.consoleLogError(`Util.logDDUXinfoFromClickEvent buttonId:${targetButtonId}`, error);
              }
            }
        }

        _logDDUXData (keyValuePairs) {
            if (this.dduxenabled && UsageLogger.isRunning()) {
                UsageLogger.logData(
                    keyValuePairs,
                    {
                        product: "CLOUDCENTER",
                        appComponent: "CC_UI",
                        eventKey: "CC_SPA"
                    },
                    {
                        verifyParametersOnly: false
                    }
                );
            }
        }

    }

    // Register custom element, even though it's abstract
    // otherwise, constructor would throw "Illegal constructor" error.
    customElements.define('cloudcenter-element', CloudCenterElement);

    return CloudCenterElement;
});
