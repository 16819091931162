/* jshint esversion: 8 */
define([
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "notification/errormsg/errorMessageTranslator",
  "service/datatransform/uiElementInfo",
  "service/datatransform/ruleInfo",
  "service/datatransform/credentialInfo"
], function(I18NStringResource, DojoString, ErrorMessageTranslator,
            UIElementInfo, RuleInfo, CredentialInfo) {

  class DataTransformationUtil {

    static getRulesIdFromConfigData (configData) {
      let rulesId = "";
      if (configData && typeof configData === 'object' &&
          ('cloud_item' in configData) &&
          typeof configData.cloud_item === 'object') {
        rulesId = configData.cloud_item.rulesId;
      }
      return rulesId;
    }

    static configDataIsValid (configData) {
      let isValid = true;
      // look for configData.params.body.params
      if (! (isValid && configData && typeof configData === 'object' &&
            'params' in configData &&
            typeof configData.params === 'object' &&
            'body' in configData.params &&
            typeof configData.params.body === 'object' &&
            'params' in configData.params.body &&
            typeof configData.params.body.params === 'object') ) {
              isValid = false;
            }
      return isValid;
    }

    static sortRules (prop, subprop) {
      return (a, b) => {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        } else {
          if (a[subprop] > b[subprop]) {
            return 1;
          } else if (a[subprop] < b[subprop]) {
            return -1;
          }
        }
        return 0;
      };
    }

    static createCustomSubElementsArray (param) {
      if (param && typeof param === 'object') {
        if (!param.config) {
          param.config = {};
        }
        if (!param.config.ui_element) {
          param.config.ui_element = {};
        }
        if (!param.config.ui_element.custom_element) {
          param.config.ui_element.custom_element = {};
        }
        if (!param.config.ui_element.custom_element.sub_elements) {
          param.config.ui_element.custom_element.sub_elements = [];
        }
      }
    }

    static getCustomElementsMap (bodyParams) {
      const customElements = new Map();
      if (bodyParams && Array.isArray(bodyParams)) {
        for (const param of bodyParams) {
          if (param.type === 'custom_element') {
            DataTransformationUtil.createCustomSubElementsArray(param);
            customElements.set(param.id, param);
          }
        }
      }
      return customElements;
    }

    static getCustomElementIdFromSubElement (param) {
      let parentId = "";
      let parentType = "";
      if (param && typeof param === 'object') {
        if (  (DataTransformationUtil.getUIElementFromParam(param)) &&
        (DataTransformationUtil.customElementHasMatchingType, 'sub_element')
      ) {
          const uiElement = DataTransformationUtil.getUIElementFromParam(param);
          const customElementInfo = uiElement.custom_element || {};
          parentId = customElementInfo.parent_id;
          parentType = customElementInfo.parent_type;
        }
      }
      return [parentId, parentType];
    }

    static getUIElementFromParam (param) {
      let uiElement = null;
      if (  (param && typeof param === 'object') &&
            (param.config && typeof param.config === 'object') &&
            (param.config.ui_element && typeof param.config.ui_element === 'object')
      ) {
        uiElement = param.config.ui_element;
      }
      return uiElement;
    }

    static customElementHasMatchingType (customElementParam, parentType) {
      let doesMatch = false;
      if ( parentType && typeof parentType === 'string' &&
           DataTransformationUtil.getUIElementFromParam(customElementParam)
          ) {
            const customElementData =  DataTransformationUtil.getUIElementFromParam(customElementParam).custom_element || {};
            if (customElementData.type === parentType) {
              doesMatch = true;
            }
          }
      return doesMatch;
    }

    static groupCustomSubElements (bodyParams) {
      const processedParams = [];
      if (bodyParams && Array.isArray(bodyParams)) {
        const customElements = DataTransformationUtil.getCustomElementsMap(bodyParams);
        for (const param of bodyParams) {
          const [parentId, parentType] = DataTransformationUtil.getCustomElementIdFromSubElement(param);
          if (parentId && parentType) {
            if (customElements.has(parentId)) {
              const customElementParam = customElements.get(parentId);
              if (DataTransformationUtil.customElementHasMatchingType(customElementParam, parentType)) {
                const uiElement = DataTransformationUtil.getUIElementFromParam(customElementParam);
                const customElementInfo = uiElement.custom_element;
                customElementInfo.sub_elements.push(param);
              }
            }
          } else {
            processedParams.push(param);
          }
        }
      }
      return processedParams;
    }

    static sortUIRulesBySections (rawData, desiredValues = null) {
      let skipTheseIDs = ["mw-name", "mw-credential-id", "mw-region", "mw-cloud-location", "mw-subscription-id"];
      let uiRules = [];
      let currentSection = {
        title: "",
        elements: []
      };
      let configData = rawData;
      if (! configData || !DataTransformationUtil.configDataIsValid(configData)) {
        configData = {
          params:{
            body:{
              params:[]
            }
          }
        };
      }
      const paramSettings = configData.params.body.settings || {};
      let bodyParams = configData.params.body.params.sort(DataTransformationUtil.sortRules('section', 'order'));
      bodyParams = DataTransformationUtil.groupCustomSubElements(bodyParams);
      for (let param of bodyParams) {
        let title = param.section.match(/\d+:([\w\s]+)/)[1];
        let elementInfo = new UIElementInfo(param, paramSettings, desiredValues);
        if (desiredValues && desiredValues[elementInfo.id]) {
          elementInfo.defaultValue = desiredValues[elementInfo.id];
        }
        if (skipTheseIDs.includes(elementInfo.id)) {
          continue;
        }
        if (title === currentSection.title) {
          currentSection.elements.push(elementInfo);
        } else {
          if (! currentSection.title) {
            currentSection.title = title;
            currentSection.isExpanded = param.section_expanded;
            currentSection.elements.push(elementInfo);
          } else {
            uiRules.push(currentSection);
            currentSection = {
              title: title,
              elements: []
            };
            currentSection.elements.push(elementInfo);
          }
        }
      }
      if (bodyParams.length) {
        uiRules.push(currentSection);
      }

      let networks = [];
      let subnets = {};
      for (const section of uiRules) {
        for (const element of section.elements) {
          if (element.getIsNetwork()) {
            networks.push(element);
          } else if (element.getIsSubnet()) {
            subnets[element.getId()] = element;
          }
        }
      }
      for (const network of networks) {
        let subnetIds = {};
        const depIds = network.getDependentIds();
        for (const subnet of Object.values(subnets)) {
          if (depIds.includes(subnet.getId())) {
            subnetIds[subnet.getId()] = true;
          }
        }
        network.setSubnetIds(subnetIds);
      }

      return uiRules;

    }

    static getRuleInfoArrayFromRules (rawRules, saveAll=false) {
      const rules = [];
      if (rawRules && rawRules.length) {
        for (const rule of rawRules) {
          if (! rule.enabled) {
            continue; // skip it, if disabled
          }
          const p = rule.params;
          const eol = rule.eol;
          if (RuleInfo.paramDataIsValid(p)) {
            const ruleInfo = new RuleInfo(p, rule.entitled, saveAll);
            ruleInfo.id = rule.rules_id;
            ruleInfo.updateEOL(eol);
            rules.push(ruleInfo);
          }
        }
      }
      return rules;
    }

    static getSettingsInfoFromRules (configData) {
      let settingMap = new Map();
      if (DataTransformationUtil.configDataIsValid(configData) &&
          typeof configData.params.body.settings === 'object') {
        let settings = configData.params.body.settings;
        let keys = Object.keys(settings);
        for (let key of keys) {
          let rawValue = settings[key];
          let value = [];
          if (typeof rawValue === 'object' && !Array.isArray(rawValue)) {
            let keys = Object.keys(rawValue);
            for (let key of keys) {
              let obj = {
                value: key,
                text: rawValue[key]
              };
              value.push(obj);
            }
          } else {
            value = rawValue;
          }
          settingMap.set(key, value);
        }
        for (let key of ['template_id','rules_id','config_id']) {
          if (configData[key]) {
            settingMap.set(key, configData[key]);
          }
        }

        for (let key of ['support_private_subnets','support_firewall_modification']) {
          if (configData.params[key]) {
            settingMap.set(key, configData.params[key]);
          }
        }

      }
      return settingMap;
    }

    static getCredentialInfoArray (cloudPlatform, credentialTypeId, rawData) {
      if (! (cloudPlatform && typeof cloudPlatform === 'string')) {
        throw new TypeError("Invalid cloudPlatform argument");
      }
      let credentialInfos = [];
      switch (cloudPlatform) {
        case 'aws':
          credentialInfos = DataTransformationUtil.getCredentialInfoArrayFromAWSData(credentialTypeId, rawData);
          break;
        case 'azure':
          credentialInfos = DataTransformationUtil.getCredentialInfoArrayFromAzureData(credentialTypeId, rawData);
          break;
        default:
          throw new TypeError(`Unsupported cloudPlatorm: ${cloudPlatform}`);
      }
      return credentialInfos;
    }

    static getCredentialInfoArrayFromAWSData (credentialTypeId, rawData) {
      if (! (credentialTypeId && typeof credentialTypeId === 'string')) {
        throw new TypeError("Invalid credentialTypeId argument");
      }
      if (! (rawData && typeof rawData === 'object')) {
        throw new TypeError("Invalid rawData argument");
      }
      let credentialInfos = [];
      let credentialDataKeys = Object.keys(rawData);
      for (let credentialId of credentialDataKeys) {
        let credential = rawData[credentialId];
        let accountNumber = credential.account;
        let description = credential.description;
        let credInfo = new CredentialInfo();
        let displayName = accountNumber;
        if (description) {
          description = description.trim();
          if (description) {
            displayName = `${description} (${accountNumber})`
          }
        }
        credInfo.cloudPlatform = 'aws';
        credInfo.credentialTypeId = credentialTypeId;
        credInfo.credentialId = credentialId;
        credInfo.displayName = displayName;
        credentialInfos.push(credInfo);
      }
      return credentialInfos;
    }

    static getCredentialInfoArrayFromAzureData (credentialTypeId, rawData) {
      // each subscription under an account gets an object of its own
      // combination of account ID + subscrition ID
      if (! (credentialTypeId && typeof credentialTypeId === 'string')) {
        throw new TypeError("Invalid credentialTypeId argument");
      }
      if (! (rawData && typeof rawData === 'object')) {
        throw new TypeError("Invalid rawData argument");
      }
      let credentialInfos = [];
      let credentialDataKeys = Object.keys(rawData);
      for (let credentialId of credentialDataKeys) {
        let credential = rawData[credentialId];
        let description  = credential.description;
        let accountId = credential.accountId;
        let subscriptionId = credential.SubscriptionID

        let credInfo = new CredentialInfo();
        credInfo.cloudPlatform = 'azure';
        credInfo.credentialTypeId = credentialTypeId;
        credInfo.credentialId = accountId;
        credInfo.subscriptionId = subscriptionId;
        credInfo.displayName = `${description}`;
        credentialInfos.push(credInfo);
      }
      return credentialInfos;
    }

  }

  return DataTransformationUtil;
});
