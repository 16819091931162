/* istanbul ignore file */
define([], function() {
  'use strict';

  var browserIsSupported = false;

  /* jshint ignore:start */
  try {
    browserIsSupported = true;
    (function() { // use anonymous closure so everything goes away when done.
      // supports const
      const constTest = 32;

      // supports let
      let letTest = "abc";

      let myArray = new Array();

      // supports lambda
      let lambdaTest = (a, b) => a * b;

      // supports Promise
      function promiseTest() {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve('resolved');
          }, 2000);
        });
      }

      // supports async/await
      const asyncAwaitTest = async function(x) {
        let a = await promiseTest();
        let b = await promiseTest();
        return x + a + b;
      };

      // supports default arg values
      function myFunction(x = 1, y = 2, z = 3) { }

      // supports spread operator
      function myFunction(x, y, z) { }
      let args = [0, 1, 2];
      myFunction(...args);

      // supports rest args
      function spreadTest (arg1, arg2, ...moreArgs) {

      }

    })();
  } catch (error) {
    browserIsSupported = false;
  }
  /* jshint ignore:end */

  var isSupported = function() { return browserIsSupported; };

  return isSupported;
});
