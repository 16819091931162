/* jshint esversion: 8 */
define([
    "underscore",
    "jquery",
    "dojo/i18n!nls/cloudCenterStringResource",
    "util",
    "templates/pageNavHeaderPreload"
  ], function( _, $, I18NStringResource, Util, PageNavigationHeaderPreload ) {
  
    class LoginPageManager {
  
      constructor(options, config) {
        let defaults = {
          authContainerId: "authContainer",
          appContainerId: "appContainer",
          applicationTitle: ""
        };
        // options are either passed in, or use the default values
        if (typeof options == 'object') {
              options = {...defaults, ...options}; // Merge objects: override defaults with any passed in values
          } else {
              options = defaults;
          }
        // config should be provide and have a getLoginURL method -- if not, throw exception
        if (!config || !config.getLoginURL) {
          throw new Error("Config parameter missing or incorrect. Unable to configure login service connection.");
        }
        // the header with application name
        this.pageHeaderTemplate = PageNavigationHeaderPreload;
        // the config object
        this.config = config;
        this.authContainerId = options.authContainerId;
        // The id of the container of the application itself
        this.appContainerId = options.appContainerId;
        // The title of the application to display above the login prompt
        this.appTitle = options.applicationTitle;
        this.loginTextContainerId = options.loginTextContainerId;
        this.loginFooterId = options.loginFooterId;
        this.loginLinkAreaId = options.loginLinkAreaId;
        this.clientString = "cloudcenter"; // default value
      }
  
      /*
       * Getters for the above items
       */
       getAuthContainer() {
         // if it is not set yet
         if (!this.authContainer) {
           // set it
           this.authContainer = $('#' + this.authContainerId); // jQuery ID selector
         }
         return this.authContainer;
       }
       getAppContainer() {
         if (!this.appContainer) {
           this.appContainer = $('#' + this.appContainerId);
         }
         return this.appContainer;
       }
       getAppContainerId() {
         return '#' + this.appContainerId;
       }
       getLoginTextContainerId() {
         return '#' + this.loginTextContainerId;
       }
       getLoginFooterId() {
         return '#' + this.loginFooterId;
       }
       getLoginLinkAreaId() {
         return '#' + this.loginLinkAreaId;
       }
       setClientString(clientString) {
         if (clientString && typeof clientString === "string") {
           this.clientString = clientString;
         }
       }
       getClientString() {
         return this.clientString;
       }
  
      /**
       * Start the embedded login form
       */
      start (clientString, errorText) {
        this.setClientString(clientString);
        window.location.href = this.config.getLoginURL();
      }
      
      hide() {
        let $loginText = $(this.getLoginTextContainerId());
        let $footer = $(this.getLoginFooterId());
        let $linkArea = $(this.getLoginLinkAreaId());
        let $appContainer = $(this.getAppContainerId());
        $loginText.hide();
        $linkArea.hide();
        let pageHeader = this.pageHeaderTemplate({cloudCenterLogoText: I18NStringResource.cloudCenterLogoText});
        $appContainer.html(pageHeader);
        $footer.show();
        this.getAuthContainer().hide();
      }
  
    }
  
    return LoginPageManager;
  }); // require
  