define([
  "computeResource/collections/collectionItem",
  "templates/computeResourceCollections/onlineServer/item",
  "service/cloudStatus",
  "util",
  "supportedProducts",
  "service/landingPageNavButtons",
  "notification/notificationManager",
  "computeResource/computeValueFormatter",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "statusAndCountdown"
], function(
  CollectionItem,
  ResourceCollectionItemTemplate,
  CloudStatus, Util, SupportedProducts, LandingPageNavButtons,
  NotificationManager,ComputeValueFormatter, I18NStringResource,
  DojoString,StatusAndCountdown
) {
  class OnlineServerCollectionItem extends CollectionItem {

    constructor (args) {
      super(args);
    }

    /* istanbul ignore next */
    getResourceCollectionItemTemplate () {
      return ResourceCollectionItemTemplate;
    }

    getConnectButton () {
      return this.getCloudResource().getDashboardConnectButton();
    }

    getDefaultButtonList (allButtons) {
      const btns = {};
      btns.copybtns = [];
      // add CTA first and it will show up first
      for (const [id, btn] of Object.entries(allButtons)) {
        if (id.startsWith("cta-")) {
          btns[id] = { btn: btn, disabled: true };
        } else if (id.startsWith("copy-")) {
          btns.copybtns.push({ id: id, btn: btn });
        }
      }
      // remove copy buttons from original list so they do not get processed twice
      for (const copyBtn of btns.copybtns) {
        const id = copyBtn.id;
        delete allButtons[id];
      }
      btns.clone = { btn: allButtons.clone, disabled: true };
      btns.edit = { btn: allButtons.edit, disabled: true };
      btns.start = { btn: allButtons.resume, disabled: true };
      btns.stop = { btn: allButtons.pause, disabled: true };
      btns.remove = { btn: allButtons["hard-delete"], disabled: true };
      return btns;
    }

    supportsRDP () {
      return false;
    }

    /* istanbul ignore next */
    static createCollectionHeader () {
      const columns = [
        {id:"computeResourcePageColumnHeaderName", class:"header-name", displaytext: true},
        {id:"computeResourcePageColumnNotifications", class: "header-notifications", displaytext: false},
        {id:"computeResourcePageColumnHeaderRelease", class:"header-release", displaytext: true},
        {id:"computeResourcePageColumnHeaderIPAddress", class:"header-ipaddress", displaytext: true},
        {id:"computeResourcePageColumnHeaderOperatingSystem", class:"header-os", displaytext: true},
        {id:"computeResourcePageColumnHeaderCloudProvider", class:"header-provider", displaytext: true},
        {id:"computeResourcePageColumnHeaderCloudLocation", class:"header-location", displaytext: true},
        {id:"computeResourcePageColumnHeaderStatus", class:"header-status", displaytext: true},
        {id:"computeResourcePageColumnHeaderActions", class:"header-actions", displaytext: true},
        {id:"computeResourcePageColumnHeaderMenu", class:"header-menu", displaytext: false}
      ];
      const header = CollectionItem.createCollectionHeader(columns);
      return header;
    }

  }

  return OnlineServerCollectionItem;
});
