/* Copyright 2019-2020 The MathWorks, Inc. */
define([
], function () {
    'use strict';

    class ViewInterface {
    /**
     * Note: Developers do not need to directly implement this interface
     *       (e.g. no need to extend the class).
     *       This code is in place for documentation purposes
    /**
     * getView: This method is expected to create progress indicator of given Size.
     * @return {Object} - Return the HTML Template that is to be displayed.
     */
        getView () {}

        /**
     * Note: Developers do not need to directly implement this interface
     *       (e.g. no need to extend the class).
     *       This code is in place for documentation purposes
    /**
     * getProgress: This method is expected to return the current progress value.
     * @return {Number} - The last known progress value.
     */
        getProgress () {}

        /**
     * Note: Developers do not need to directly implement this interface
     *       (e.g. no need to extend the class).
     *       This code is in place for documentation purposes
    /**
     * update: This method is expected update the view based on parameters after the view is rendered.
     * @param {*} currentProgress - The current updated progress made by the progress indicator.
     * @param {*} viewProperties - The properties that need to be .
     */
        update (currentProgress, viewProperties) {}
    }
    return ViewInterface;
});
