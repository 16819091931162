/* jshint esversion: 8 */
define([
    "lit-element/lit-element.bundled",
    "util",
    "dojo/i18n!nls/cloudCenterStringResource"
], function(
    Lit,
    Util,
    I18NStringResource
) {

    class InlinePopover extends Lit.LitElement {

        constructor () {
            super();
            // Default values
            this.poptext = "";
            this.iswarning = false;
        }

        // Reactive Properties -- updates rendering when values change
        static get properties () {
            return {
                poptext: {type: String},
                iswarning: {type: Boolean}
            };
        }

        // Avoid using Shadow DOM.  This enables us to share CSS and bootstrap.
        createRenderRoot () {
            return this;
        }

        // Called after initial rendering
        firstUpdated () {
            const popoverButtonList = this.renderRoot.querySelectorAll('[data-bs-toggle="popover"]');
            for (let button of popoverButtonList) {
                const helpLink = Util.getPopoverHelpLink();
                button.title = `<div class="cc-popover-title">${I18NStringResource.moreInformation}</div>${helpLink}`;
            }
        }

        render () {
            const touchEventsEnabled = Util.touchEventsEnabled();
            return Lit.html`
                <a role="button" tabindex="0" class="btn btn_color_blue companion_btn toggleDetails"
                    data-bs-toggle="popover"
                    data-bs-html="true"
                    data-bs-trigger=${touchEventsEnabled ? "click" : "manual" }
                    data-bs-content="${this.poptext}"
                    data-bs-placement="top">
                    <span class=${this.iswarning ? "icon-alert-error icon_alert_color" : "icon-info-circle add_icon_color_linkblue"}></span><span class="visually-hidden">
                        ${I18NStringResource.moreInformation}
                    </span>
                </a>`;
        }
    }
    // Register custom element '<inline-popover poptext"some text" />'
    customElements.define('inline-popover', InlinePopover);

    return InlinePopover;
});