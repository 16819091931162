define([
    "util"
], function (
    Util
) {
    class LegacyParallelGlobalService {

        constructor (args) {
            if (!args || typeof args !== "object" || !args.dao) {
              throw new TypeError("Invalid args argument");
            }
            this.dao = args.dao;
        }

        getDAO () { return this.dao; }

        async getAccess() {
            return await this.getDAO().getLegacyParallelAPI("clusterAccess", null, null, null, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async putAccess() {
            return await this.getDAO().putLegacyParallelAPI("clusterAccess", null, null, {}, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async features() {
            return await this.getDAO().putLegacyParallelAPI("features", null, null, null, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }
    }
    return LegacyParallelGlobalService;
});
