define([
    "util"
], function (
    Util
) {
    class AdminService {

        constructor (args) {
            if (!args || typeof args !== "object" || !args.dao) {
              throw new TypeError("Invalid args argument");
            }
            this.dao = args.dao;
        }

        getDAO () { return this.dao; }

        async list () {
            Util.consoleLogTrace("AdminService.list", "called");
            // TODO: This should be "admin" and not "oauth"
            return await this.getDAO().getCCAPI("oauth", "type", undefined, undefined, undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async listTypes () {
            Util.consoleLogTrace("AdminService.listTypes", "called");
            return await this.getDAO().getCCAPI("admincreds", "type", undefined, undefined, undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async listAllUserCreds () {
            return await this.getDAO().getCCAPI("admincreds", "resource", undefined, undefined, undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async listType (credentialId) {
            Util.consoleLogTrace("AdminService.listType", "called");
            if (!credentialId || typeof credentialId !== "string" || !Util.isMD5(credentialId)) {
              throw new TypeError("Invalid credentialId argument");
            }
            return await this.getDAO().getCCAPI("admincreds", "resource", credentialId, undefined, undefined, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

    }
    return AdminService;
});
