define([
    "service/landingPageNavButtons",
    "service/cloudStatus",
    "service/general/ccInternalScripts",
    "util",
    "dojo/i18n!nls/cloudCenterStringResource",
], function (
    LandingPageNavButtons,
    CloudStatus,
    CCInternalScripts,
    Util,
    I18NStringResource
) {

    /*
        When you add a template "output:" cloud link to a workflow template .yaml file
        and it includes a "actionname_" tag and value, create an async function in this
        map with key = "actionname_" value.  It should take 3 args: collection,
        configId, and product.
    */
    // istanbul ignore next
    const ActionHandlers = {
        // matches "actionname_forceresume__" in yaml file -- cmd.id below
        'forceresume':   async (collection, configId, product) => {
                            const force = true;
                            const waitIntervalSeconds = 60;
                            Util.consoleLogTrace(
                            'SpecialCommandsManager',
                            `Calling async anonymous function "forceresume" with product: "${product}" and collection: "${collection.constructor.name}" and configId: "${configId}"`
                            );
                            const restartMgr = new CCInternalScripts(configId, waitIntervalSeconds, force, product, collection);
                            await restartMgr.fnStart();
                        },
        'forceresumeTBD':  async (collection, configId, product) => {
                            const workflowDataService = collection.getDataService().workflow;
                            const clickHandler = workflowDataService.forceResume.bind(workflowDataService);
                            const force = true;
                            return clickHandler(configId, product);
                        }
    };

    class SpecialCommandsManager {

        constructor(args) {
            if (!args || typeof args !== 'object' || (! "productCollection" in args)) {
                throw new TypeError('Invalid args');
            }
            if (!args.productCollection || typeof args.productCollection !== 'object' ||
                !("getDataService" in args.productCollection) ||
                typeof args.productCollection.getDataService !== 'function') {
                    throw new TypeError('Invalid "productCollection" property value in provided args');
            }
            this.collection = args.productCollection;
        }

        getCollection() {
            return this.collection;
        }

        extractSpecialCommandCloudLinks(cloudLinks) {
            const specialCommands = [];
            const keysToDelete = [];
            for (const [key, value] of Object.entries(cloudLinks)) {
                if (value.action_name && value.action_name.length) {
                    // cmd
                    specialCommands.push({ id: value.action_name, data: value, isLifeCycleAction: value.is_lifecycle_action });
                    keysToDelete.push(key);
                }
            }
            for (const key of keysToDelete) {
            delete cloudLinks[key];
            }
            return specialCommands;
        }

        createSpecialCommandButton(cmd, rowObj, config, product, logFn, isLifeCycleAction = false) {
            const i18nBtnPrefix = 'dynamicConnectBtn_';
            const i18nTooltipPrefix = 'dynamicConnectBtnTooltip_';
            const clickHandler = ActionHandlers[cmd.id].bind(this, this.getCollection()); // bind collection argument
            const button = LandingPageNavButtons.createAction(
            I18NStringResource[`${i18nBtnPrefix}${cmd.id}`], // button label
            I18NStringResource[`${i18nTooltipPrefix}${cmd.id}`], // button tooltip
            `${cmd.id}Icon smallIcon`, // button CSS classes
            product, // product name
            clickHandler, // click handler
            `${cmd.id}`, // action type
            rowObj, // row object containing button
            config, // collectionItem (config)
            `${cmd.id}`, // buttonID
            logFn, // logging method pointer
            isLifeCycleAction
            );
            return button;
        }

        addSpecialCommandButtons(specialCmds, buttons, config, product, logFn, rowObj) {
            for (const cmd of specialCmds) {
            // istanbul ignore next
            const btnId = cmd.isLifeCycleAction ? `${cmd.id}` : `cta-${cmd.id}`;
            buttons[btnId] = this.createSpecialCommandButton(cmd, rowObj, config, product, logFn, cmd.isLifeCycleAction);
            CloudStatus.addMenuItem(CloudStatus.Enum.PAUSED, btnId);
            SpecialCommandsManager.addIdToSpecialButtonList(btnId);
            }
        }

        static getSpecialButtonList() {
            return SpecialCommandsManager.SPECIAL_BUTTON_LIST;
        }

        static addIdToSpecialButtonList(buttonId) {
            if (buttonId && typeof buttonId === 'string' && !SpecialCommandsManager.SPECIAL_BUTTON_LIST.includes(buttonId)) {
            SpecialCommandsManager.SPECIAL_BUTTON_LIST.push(buttonId);
            }
        }

    }
    Object.defineProperty(SpecialCommandsManager, 'SPECIAL_BUTTON_LIST', {
        value: [],
        writable: true,
        configurable: true,
        enumerable: true
    });

    return SpecialCommandsManager;
});