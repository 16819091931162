/* jshint esversion: 8 */
define([
  "computeResource/collections/collection"
], function (
  Collection
) {

  class OnlineServerCollection extends Collection {

    constructor (args) {
      super(args);
    }

  }

  return OnlineServerCollection;
});
