
define([
    "dojo/_base/declare",

    "./statemachineimpl/State"
], function (declare, State) {

    //noinspection JSUnusedLocalSymbols
    /**
     * The StateMachine class is the main entry point into the state system. Construct one of these
     * by passing it a declarative structure which defines the states, and transitions of the
     * system. See the StateMachineTest suite of tests for examples of how to declare a state
     * machine.
     *
     * One example would be:
         var stateOptions = {
            context: mockContext,
            name: "widget1",
            namespace: "MW.gui",
            defaultSubstate: "state1",
            substates: {
                "state1": {
                    transitions: {
                        "onEnterState": "enterStateFunction",
                        "onExitState": this.exitStateFunction,
                        "event1": "state2",
                        "subscribe gotoState2": "state2",
                        "subscribe gotoState3": "state3"
                    },
                    data: {
                        enteredState: 0,
                        exitedState: 0
                    }
                },
                "state2": {
                    transitions: {
                        "onEnterState": function (trigger, data) {data.enteredState += 1},
                        "subscribe gotoState3": "state3"
                    },
                    data: {
                        enteredState: 0
                    }
                },
                "state3": {
                    transitions: {
                        "event2": "state1"
                    }
                }
            }
        };
        var stateMachine = new StateMachine(stateOptions);
     *
     */
    return declare([], {
        constructor: function (args) {
            args.stateMachine = this;
            this.state = new State(args);
        },

        start: function () {
            this.state.enter();
        },

        stop: function () {
            this.state.exit();
        },

        _changeState: function (newState, otherArguments) {
            this.state.enter(newState, otherArguments);
        },

        onEnterState: function (newState) {},
        onExitState: function (oldState) {}
    });
});