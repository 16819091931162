/* jshint esversion: 8 */
define([
    "lit-element/lit-element.bundled"
], function(
    Lit
) {

    class InlineComponentValidation extends Lit.LitElement {

        constructor () {
            super();
            // Default values
            this.elementid = "UNSET";
        }

        // Reactive Properties -- updates rendering when values change
        static get properties () {
            return {
                elementid: {type: String}
            };
        }

        // Avoid using Shadow DOM.  This enables us to share CSS and bootstrap.
        createRenderRoot () {
            return this;
        }

        render () {
            return Lit.html`
                <div class="inline_form_error ${this.elementid}" style="display: none;"><div class="inline_form_error_arrow"></div>
                <div class="inline_form_error_msg ${this.elementid}"></div>
                <div class="validationErrorImage form-control-feedback fineprint" aria-hidden="true" style="display: none;"></div>
            `;
        }
    }
    // Register custom element '<inline-validation elementid="myelementid" />'
    customElements.define('inline-validation', InlineComponentValidation);

    return InlineComponentValidation;
});