/* jshint esversion: 8 */
/*
 * This file contains logic for loading machine list picker by location (ie, vpc > subnet > machine)
 */
define([
    "underscore",
    "jquery",
    "lit-element/lit-element.bundled",
    "dojo/i18n!nls/cloudCenterStringResource",
    'dojo/string',
    "util",
    "service/components/abstractMachineTypeChooseWithFinder"
], function (
    _,
    $,
    Lit,
    I18NStringResource,
    DojoString,
    Util,
    MachineTypeWithFinder
) {

    class ServiceByLocation extends MachineTypeWithFinder {
        constructor(args) {
            super(args);
            this.buildMachineTypeList();
        }

        machineTypePicker() {
            return this.root().querySelector(`div.radioButtonContainer.byMachineType select#machineTypeContainer`);
        }
        availabilityListForSelectedMachineTypePicker() {
            return this.root().querySelector(`div.radioButtonContainer.byMachineType select#availabilityListForSelectedMachineType`);
        }

        async buildMachineTypeList() {
            const container = this.machineTypePicker()
            if (!container) {
                setTimeout(() => this.buildMachineTypeList(), 100);
                return;
            }

            let options = [];
            const supportedMachines = await this.listSupportedMachineTypes();
            let ids = [];
            for (const machine of supportedMachines) {
                ids.push(machine.id);
            }
            const builtOpts = await this.getSupportedInstanceTypesAsOptions({ msg: ids });
            if (builtOpts.length === 2) {
                builtOpts[1].selected = true;
            } else {
                for (const opt of builtOpts) {
                    opt.selected = (opt.value === this.machinetypevalue)
                }
            }
            options.push(...builtOpts);


            if (options.length === 0) {
                // clear the whole radio button group since it cannot be used
                this.root().querySelector(`div.radioButtonContainer.byMachineType`).innerHTML = '';
                return;
            }

            container.innerHTML = '';
            container.append(...options);

            await this.updateAvailabilityListForSelectedMachineType();

            this.setInitialLoad();

        }

        async updateAvailabilityListForSelectedMachineType(event) {
            this.toggleApplyButton(false);
            const machineTypePicker = this.machineTypePicker();
            const availableLocationsPicker = this.availabilityListForSelectedMachineTypePicker();

            this.disableAll([machineTypePicker, availableLocationsPicker])
            availableLocationsPicker.innerHTML = '';
            availableLocationsPicker.append(new Option(I18NStringResource.machineTypeFinderLoading, ''));

            const selectedMachineType = machineTypePicker.value;

            // if (!selectedMachineType) {
            //     Util.notify("WARNING", I18NStringResource.machineTypeAvailabilityListForSelectedMachineTypeNoMachineTypeSelected);
            //     await this.buildMachineTypeList(); //there should always be a list ... if it failed 1x, will it keep failing?
            //     machineTypePicker.disabled = !this.active();
            //     if (this.active()) {
            //         machineTypePicker.classList.remove('disabled');
            //         machineTypePicker.focus();
            //     }
            //     return
            // }

            const availableLocations = await this.findMachineType(selectedMachineType);
            if (!availableLocations || availableLocations.availability.length === 0) {
                const msg = DojoString.substitute(I18NStringResource.machineTypeAvailabilityListForSelectedMachineTypeNoLocationFound, [selectedMachineType]);
                Util.notify("WARNING", msg);
                availableLocationsPicker.append(new Option(msg, ''));
                machineTypePicker.disabled = !this.active();
                if (this.active()) {
                    machineTypePicker.classList.remove('disabled');
                    machineTypePicker.focus();
                }
                return;
            }


            let maxLens = {
                Region: 0,
                VPCName: 0,
                VPCID: 0,
                Name: 0,
                ID: 0,
                AvailabilityZone: 0
            };

            let regions = [];
            availableLocations.networks.Region = [{ //heading
                AvailabilityZone: "Avail. Zone",
                ID: "Subnet ID",
                Name: "Subnet Name",
                VPCName: "VPC Name",
                Region: "Region",
                VPCID: "VPC ID",
                Type: "public"
            }]
            for (const region of Object.keys(availableLocations.networks)) {
                if (this.currentPage === "edit") {
                    if (region !== this.getCurrentLocation()) {
                        continue;
                    }
                }
                regions.push(region)
            }
            regions.sort((a, b) => {
                if (a.startsWith("region")) return -1; //push header to top
                if (a.startsWith("us-")) return -1; //push us and eu to top of sort
                if (a.startsWith("eu-")) return -1;
                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                return 0;
            });

            let dataExists = false;
            for (const region of regions) { //calc padding
                const networks = availableLocations.networks[region];
                networks.sort(Util.sortMultipleAttr("VPCName", "Name", "AvailabilityZone", "IPv4"));
                for (const network of networks) {
                    dataExists = true;
                    for (const key of Object.keys(maxLens)) {
                        const val = network[key];
                        if (!val) {
                            network[key] = ""; //set for next loop to pad
                        } else if (maxLens[key] < val.length) {
                            maxLens[key] = val.length;
                        }
                    }
                }
            }

            if (!dataExists) {
                const msg = DojoString.substitute(I18NStringResource.machineTypeAvailabilityListForSelectedMachineTypeNoLocationFound, [selectedMachineType]);
                Util.notify("WARNING", msg);
                availableLocationsPicker.append(new Option(msg, ''));
                machineTypePicker.disabled = !this.active();
                if (this.active()) {
                    machineTypePicker.classList.remove('disabled');
                    machineTypePicker.focus();
                }
                return;
            }

            const spaceChar = '\u00A0'

            availableLocationsPicker.innerHTML = "";
            let first = true;
            let currentRegion = this.getCurrentLocation();
            let options = [];

            for (const region of regions) { //calc padding
                const networks = availableLocations.networks[region];
                networks.sort(Util.sortMultipleAttr("VPCName", "Name", "AvailabilityZone", "IPv4"));
                for (const network of networks) {
                    if (network.Type !== "public") {
                        continue;
                    }

                    const regionName = region.padEnd(maxLens.Region, spaceChar);
                    const vpcName = network.VPCName.padEnd(maxLens.VPCName, spaceChar);
                    const vpcID = network.VPCID.padEnd(maxLens.VPCID, spaceChar);
                    const subnetName = network.Name.padEnd(maxLens.Name, spaceChar);
                    const subnetID = network.ID.padEnd(maxLens.ID, spaceChar);
                    const availabilityZone = network.AvailabilityZone.padEnd(maxLens.AvailabilityZone, spaceChar);

                    let desc = `${regionName} | `;
                    if (maxLens.VPCName > 0) {
                        desc += `${vpcName} | `;
                    }
                    desc += `${vpcID} | `
                    if (maxLens.Name > 0) {
                        desc += `${subnetName} | `;
                    }
                    desc += `${subnetID} | ${availabilityZone}`;

                    let option = new Option(desc, `${region}|${network.VPCID}|${network.ID}`);
                    option.dataset.vpcid = network.VPCID;
                    option.dataset.subnetid = network.ID;
                    option.dataset.region = region;

                    option.style.fontFamily = "monospace";
                    option.textContent = option.textContent.replace(/'\u00A0'/g, '&nbsp;')
                    if (first) {
                        first = false;
                        option.selected = false;
                        option.disabled = true;
                    }
                    if (currentRegion === region) {
                        currentRegion = null;
                        option.selected = true;
                    }

                    options.push(option)

                }
            }

            if (options.length === 2) {
                options[1].selected = true;
            } else {
                let allowSelect = true;
                for (const opt of options) {
                    opt.selected = (allowSelect && opt.textContent.includes(this.vpcvalue) && opt.textContent.includes(this.subnetvalue))
                    if (allowSelect && opt.selected) {
                        allowSelect = false;
                    }
                    break;
                }
            }
            availableLocationsPicker.append(...options);

            if (this.active()) {
                for (let picker of [machineTypePicker, availableLocationsPicker]) {
                    picker.disabled = false;
                    picker.classList.remove('disabled');
                }
                availableLocationsPicker.focus();

                this.toggleApplyButton();

            }
            if (this.isInitialLoad()) {
                this.setInitialLoad(false);
            }

        }

        getSelectedData() {
            const locationPicker = this.availabilityListForSelectedMachineTypePicker();
            const machineTypePicker = this.machineTypePicker()

            const selectedMachineType = machineTypePicker.value
            const selectedOption = locationPicker.selectedOptions[0];
            const selectedVPC = selectedOption.dataset.vpcid;
            const selectedSubnet = selectedOption.dataset.subnetid;

            return [selectedVPC, selectedSubnet, selectedMachineType];

        }

        getSelectedDataAndRegion() {
            const [selectedVPC, selectedSubnet, selectedMachineType] = this.getSelectedData();
            const locationPicker = this.availabilityListForSelectedMachineTypePicker();
            const selectedOption = locationPicker.selectedOptions[0];
            const selectedRegion = selectedOption.dataset.region;
            return [selectedRegion, selectedVPC, selectedSubnet, selectedMachineType]
        }
        selectionRequiresStep1Change() {
            if (this.active()) {
                const currentLoc = this.getCurrentLocation();
                const [selectedRegion, selectedVPC, selectedSubnet, selectedMachineType] = this.getSelectedDataAndRegion();

                if (selectedRegion && selectedVPC && selectedSubnet && selectedRegion !== currentLoc) {
                    const warning = DojoString.substitute(I18NStringResource.machineTypeAvailabilityListForSelectedMachineTypeDifferentRegionPicked, [selectedRegion])
                    const prompt = "";
                    return [warning, prompt];
                }
            }
            return [null, null]
        }
        updateStepsAndNavigate() {
            const [selectedRegion, selectedVPC, selectedSubnet, selectedMachineType] = this.getSelectedDataAndRegion();
            this.updateSavedStep1Value("cloudLocationSelector", selectedRegion)

            this.updateSavedStep2ValueOverride("mw-cloud-location", selectedRegion)
            this.updateSavedStep2ValueOverride(this.vpcid, selectedVPC)
            this.updateSavedStep2ValueOverride(this.subnetid, selectedSubnet)
            this.updateSavedStep2ValueOverride(this.machinetypeid, selectedMachineType)

            this.onStep1Change();


        }

        async findMachineType(desiredMachineType) {
            Util.consoleLogTrace(`findMachineType`, `called`);
            if (!desiredMachineType || typeof desiredMachineType !== 'string') {
                throw new TypeError("Invalid desiredMachineType argument");
            }

            const credId = this.credentialTypeId;
            const rulesId = this.rulesId;

            if (desiredMachineType && credId && rulesId) {
                let searchResults;
                try {
                    const cloudProvider = "aws"; //look up from rules
                    const dataService = this.getDataService().getPlatformDataService(cloudProvider);
                    searchResults = await dataService.findMachineType(credId, rulesId, desiredMachineType);
                    const isValid = this.validateResults(searchResults);
                    if (isValid) {
                        return searchResults.msg;
                    } else {
                        Util.consoleLogWarning('findMachineType', 'Invalid type data received from server');
                    }
                } catch (error) {
                    Util.consoleLogWarning('findMachineType', error);
                }
            }
        }


        validateResults(searchResults) {
            let isValid = false;
            if (searchResults && searchResults.msg && typeof searchResults.msg === 'object') {
                const hasAvailability = searchResults.msg.availability && Array.isArray(searchResults.msg.availability) && searchResults.msg.availability.length > 0;
                const hasMachineType = searchResults.msg.machineType && typeof searchResults.msg.machineType === 'string';
                const hasNetworks = searchResults.msg.networks && typeof searchResults.msg.networks === 'object' && Object.keys(searchResults.msg.networks).length > 0;
                const hasRegions = searchResults.msg.regions && typeof searchResults.msg.regions === 'object' && Object.keys(searchResults.msg.regions).length > 0;
                isValid = hasAvailability && hasMachineType && (hasNetworks || hasRegions)
            }
            return isValid;
        }




        render() {
            return Lit.html`
            <div class="radioButtonContainer byMachineType container-fluid">
                <div class="row">
                    <div class="col-12 labelContainer">

            <input type="radio" name="findByChoice"
                @change=${this.updateUI}
                @click=${this.logClick}
                data-findby="byMachineType" value="byMachineType" id="${'byMachineType' + this.timestamp}"  />
            <label for="${'byMachineType' + this.timestamp}">${I18NStringResource.machineTypeFinderByMachineType}</label>
            </div> <!-- col-12 labelContainer -->
            </div> <!-- row -->

            <div class="sectionContents"><fieldset class="section2Group"><div class="section2InputContainer"  data-findby="byMachineType" hidden>
                <label for="machineTypeContainer">${I18NStringResource.machineTypeFinderMachine}</label>
                <div class="section2InputValidationContainer">
                    <select name="machineTypeContainer" id="machineTypeContainer" disabled data-findby="byMachineType"
                        @change=${this.updateAvailabilityListForSelectedMachineType.bind(this)} >
                    <option>${I18NStringResource.machineTypeFinderLoading}</option>
                    </select>
                    <inline-validation elementid="existingResourcePicker" />
                </div>
            </div>

            <div class="sectionContents"><fieldset class="section2Group"><div class="section2InputContainer"  data-findby="byMachineType" hidden>
                <label for="availabilityListForSelectedMachineType">${I18NStringResource.machineTypeFinderLocation}</label>
                <div class="section2InputValidationContainer">
                    <select name="availabilityListForSelectedMachineType" id="availabilityListForSelectedMachineType" disabled data-findby="byMachineType"
                        @optionsLoaded=${{ handleEvent: () => this.debouncedOptionsLoaded(), once: true }}
                        @change=${this.toggleApplyButton}
                        >
                    <option>${I18NStringResource.machineTypeFinderLoading}</option>
                    </select>
                    <inline-validation elementid="existingResourcePicker" />
                </div>
            </div>
        `

        }

    }


    return ServiceByLocation;
}); // require