/* jshint esversion: 8 */
define([
  "computeResource/collections/collection"
], function (
  Collection
) {

  class CFEDemoCollection extends Collection {

    constructor (args) {
      super(args);
    }

  }

  return CFEDemoCollection;
});
