/* jshint esversion: 8 */
define([
    "bootstrap",
    "lit-element/lit-element.bundled",
    "util",
    "mw-ddux/UsageLogger"
], function(
    Bootstrap,
    Lit,
    Util,
    UsageLogger
) {

    // Abstract base class for CreateNew???Dialog custom elements.
    // Cannot be directly instantiated.
    class CreateNewDialog extends Lit.LitElement {

        constructor () {
            super(); // super has to come before any usage of 'this'.
            if (this.constructor === CreateNewDialog) {
                throw new Error("Cannot instantiate abstract class.");
            }
            this.cceInitialize();
        }

        cceInitialize () {
            Util.consoleLogTrace('CreateNewDialog`, `cceInitialize invoked');
            this.dialogId = "";
            this.timestamp = new Date().getTime();
            this.dduxenabled = true;
            this.dialogTitle = "";
            this.cancelCreateNewButtonLabel = "";
            this.createNewButtonLabel = "";
            this.cancelButtonId = "cancelCreateNewButton";
            this.createButtonId = "createNewButton";
       }

       getDialogId () { return this.dialogId; }

        // Avoid using Shadow DOM.  This enables us to share CSS and bootstrap.
        createRenderRoot () {
            return this;
        }

        async createItem () {
            Util.consoleLogTrace(`CreateNewDialog.createItem`, `invoked`);
            return true;
        }

        _logClick (event) {
            if (event && typeof event === 'object' && ("currentTarget" in event)) {
                let targetButtonId = event.currentTarget.id;
                if (targetButtonId) {
                  try {
                    this._logDDUXData({
                      elementid: targetButtonId,
                      elementType: "button",
                      eventType: "clicked"
                    });
                  } catch (error) {
                    Util.consoleLogError(`Util.logDDUXinfoFromClickEvent buttonId:${targetButtonId}`, error);
                  }
                }
            }
        }

        _logDDUXData (keyValuePairs) {
            if (this.dduxenabled) {
                if (!UsageLogger.isRunning()) {
                    this.startUsageLogger();
                }
                UsageLogger.logData(
                    keyValuePairs,
                    {
                        product: "CLOUDCENTER",
                        appComponent: "CC_UI",
                        eventKey: "CC_SPA"
                    },
                    {
                        verifyParametersOnly: false
                    }
                );
            }
        }

        _onKeydown (e) {
            const code = e.keyCode || e.which;
            if (code == 13) { // Enter key
              e.preventDefault();
              let focusedElement = document.activeElement;
              if (focusedElement.tagName.toUpperCase() === "BUTTON"
                  && (
                    focusedElement.className === 'btn-close'
                  )
              ) {
                focusedElement.click();
              }
            }
        }

        _dragNotAllowed (event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'none';
        }

        _onDialogClose (event) {
            const dialogEl = document.querySelector(`#${this.dialogId}`);
            const modal = Bootstrap.Modal.getOrCreateInstance(dialogEl);
            const modalContainer = this.parentElement;
            modal.dispose();
            modalContainer.removeChild(this);
        }

        _onHandleCancel (event) {
            this._logClick(event);
            const dialogEl = document.querySelector(`#${this.dialogId}`);
            const modal = Bootstrap.Modal.getOrCreateInstance(dialogEl);
            modal.hide();
        }

        async _onHandleCreate (event) {
            this._logClick(event);
            this.disableButtons(true);
            if (await this.createItem()) {
                this._onHandleCancel();
            }
        }

        disableButtons () {
            const createButton = this.renderRoot.querySelector(`button#${this.createButtonId}`);
            if (createButton) {
                createButton.disabled = true;
                createButton.classList.add('disabled');
            }
        }

        enableButtons () {
            const createButton = this.renderRoot.querySelector(`button#${this.createButtonId}`);
            if (createButton) {
                createButton.disabled = false;
                createButton.classList.remove('disabled');
            }
        }

        addListSpinnerToElement (element) {
            if (element && !element.querySelector("div.progressSpinnerContainer")) {
              const buttonText = element.querySelector('span.btnText');
              let loadingSpinner = Util.createSpinner('xsmall');
              if (loadingSpinner) {
                if (buttonText) {
                    buttonText.style.display = 'none';
                }
                element.appendChild(loadingSpinner);
              }
            }
        }

        removeListSpinnerFromElement (element) {
            if (element) {
                let loadingSpinner = element.querySelector("div.progressSpinnerContainer");
                if (loadingSpinner) {
                    const buttonText = element.querySelector('span.btnText');
                    try {
                        element.removeChild(loadingSpinner);
                    } catch (error) {
                        /* ignore */
                    } finally {
                        if (buttonText) {
                            buttonText.style.display = 'inline';
                        }
                    }
                }
            }
        }

        _onDialogOpen (event) {
          this.enableButtons();
        }

        show () {
            const dialogEl = document.querySelector(`#${this.dialogId}`);
            const modal = new Bootstrap.Modal(dialogEl, {backdrop: 'static'});
            modal.show();
        }

        renderModalHeaderContent () {
            return Lit.html`
                <h4 class="modal-title">${this.dialogTitle}</h4>
                <button type="button" id="${'close' + this.dialogId}" class="btn-close" aria-label="Close" @click=${this._onHandleCancel}></button>`;
        }

        renderModalBodyContent () {
            return Lit.html`<h1>Nobody is home</h1>`;
        }

        renderModalFooterContent () {
            return Lit.html`
                <button type="button" id="${this.cancelButtonId}" class="btn btn_color_blue companion_btn add_indent_10 cancelWizardBtn" tabindex="0"
                    @click=${this._onHandleCancel}>
                        <span class="btnText">${this.cancelCreateNewButtonLabel}</span>
                </button>
                <button type="button" id="${this.createButtonId}" class="btn btn_color_blue exitWizardBtn" tabindex="0"
                    @click=${this._onHandleCreate}>
                        <span class="btnText">${this.createNewButtonLabel}</span>
                </button>`;
        }

        render () {
            // istanbul ignore next
            return Lit.html`
                <div id="${this.dialogId}" class="modal fade" tabindex="-1"
                    @keydown=${{handleEvent: (event) => this._onKeydown(event), once: false}}
                    @dragover=${{handleEvent: (event) => this._dragNotAllowed(event), once: false}}
                    @shown.bs.modal=${{handleEvent: () => this._onDialogOpen(), once: true}}
                    @hidden.bs.modal=${{handleEvent: () => this._onDialogClose(), once: true}}>
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                ${this.renderModalHeaderContent()}
                            </div>
                            <div class="modal-body delete-item-body-prompt">
                                ${this.renderModalBodyContent()}
                            </div>
                            <div class="modal-footer">
                                ${this.renderModalFooterContent()}
                            </div>
                        </div>
                    </div>
                </div>`;
        }

    }

    return CreateNewDialog;
});
