define([

], function(

) {

    function abstractMethod() { throw new Error("Abstract method."); }

    class PlatformDataService {

        constructor (args) {
            if (this.constructor === PlatformDataService) {
                throw new Error("Cannot instantiate abstract class.");
            }
            if (!args || typeof args !== "object" || !args.dao) {
                throw new TypeError("Invalid args argument");
            }
            this.dao = args.dao;
        }

        getDAO () { return this.dao; }

        /*
         * Required API
         */

        // Dependent Option Dropdowns
        // cache ui.queryDependentOptionData
        async getDependentOptionFields (queryType, options = {}) { abstractMethod(); }

        // cache ui.queryDependentOptionData
        async getDependentOptionData (queryType, credId, options = {}) { abstractMethod(); }

        // Credentials
        // cache ui.renderCredentialList
        async renderCredentialList (credentialId, popText, description, data = null) { abstractMethod(); }

        // cache ui.listCredentials
        async listCredentials (credentialTypeId, includeSubscriptions = true) { abstractMethod(); }

        // cache ui.deleteCredential
        async deleteCredential (credentialId, credTypeId, configs = []) { abstractMethod(); }

        // cache ui.getCredential
        async getCredential (credentialId) { abstractMethod(); }

        // cache ui.updateCredentialDescription
        async updateCredentialDescription (credentialId, credentialTypeId, description) { abstractMethod(); }

        // cache ui.createCredential
        async createCredential (createCredArgs = {}) { abstractMethod(); }

        // cache ui.importCredential
        async importCredential (importCredArgs = {}) { abstractMethod(); }

        // cache ui.getAccountIdFromConfig
        async getAccountIdFromConfig (config) { abstractMethod(); }

        // cache ui.getCredentialDataFromAccountId
        async getCredentialDataFromAccountId(accountId, credentialTypeId, userCredentials) { abstractMethod(); }
        
        async findMachineType (credId, options = {}) { abstractMethod(); }

    }
    return PlatformDataService;
});
