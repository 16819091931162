/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "backbone",
  "bootstrap",
  "util",
  "templates/outputDataDetailsTemplate",
  "dialogs/inputPromptDialog",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string"
], function (_, $, Backbone, Bootstrap, Util, OutputDataDetailsTemplate,
             InputPromptDialog, I18NStringResource, DojoString) {

  class CloudOutputsManager extends Backbone.View {

    constructor (args) {
      super(args);
      if (!args || typeof args !== 'object') {
        throw new TypeError("Invalid constructor arguments");
      }
      this.template = OutputDataDetailsTemplate;
      if (!args.dataService || typeof args.dataService.workflow !== "object") {
        throw new TypeError("Invalid dataService argument");
      }
      this.dataService = args.dataService;
      this.configId = "";
      if (args.configId && typeof args.configId === 'string') {
        this.configId = args.configId;
      }
    }

    getDataService () { return this.dataService; }
    getConfigId () { return this.configId; }
    getMwName () { return this.mwName; }

    async getStackOutputs (configId) {
      if (!configId || typeof configId !== 'string') {
        throw new TypeError("Invalid configId argument");
      }

      let outputData;
      outputData = await this.getDataService().workflow.outputs(configId);
      if (outputData && typeof outputData === 'object' && outputData.msg &&
          Array.isArray(outputData.msg) && outputData.msg.length) {
        outputData = outputData.msg;
      } else {
        outputData = null;
      }
      return outputData;
    }

    async render () {
      return await this.refreshOutputDetails();
    }

    async refreshOutputDetails () {
      let outputDataHtml = await this.renderHTML();
      if (outputDataHtml) {
        this.$el.empty().html(outputDataHtml);
        this.renderIP();
      }
    }

    async renderHTML () {
      const configId = this.getConfigId();
      let outputData;
      let outputDataHtml = "";
      try {
        outputData = await this.getStackOutputs(configId);
        outputDataHtml = this.template({
          outputData: outputData,
          i18n: I18NStringResource
        });
      } catch (error) {
        Util.consoleLogError("renderHTML", error);
      }
      return outputDataHtml;
    }

  }

  return CloudOutputsManager;
});
