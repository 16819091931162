  /* jshint esversion: 8 */
define([
  "dojo/i18n!nls/cloudCenterStringResource",
  "util",
  "service/datatransform/ruleInfo",
  "config"
], function(I18NStringResource, Util, RuleInfo, Config) {

  class ReleaseInfo {

    constructor(rule) {
      this.product = rule.product;
      this.release = rule.release;
      this.description = rule.description;
      this.providers = {};
      if (rule.eol) {
        this.status = RuleInfo._EOLStatusValues[rule.eol.Status];
        this.sunsetOnDate = Util.formatDateYMD(new Date(rule.eol.SunsetOnDate.seconds * 1000));
      } else {
        this.status = RuleInfo.StatusIntActive;
        this.sunsetOnDate = "";
      }
      if (rule.cloudPlatform && rule.os) {
        this.add(rule.cloudPlatform, rule.os);
      }
    }

    add(provider, os) {
      if (provider && os) {
        if (this.providers[provider]) {
          const p = this.providers[provider];
          /* istanbul ignore next */
          if (!p.osList) {
            p.osList = [];
          }
          if (!p.osList.includes(os)) {
            p.osList.push(os);
            p.osList = p.osList.sort();
          }
        } else {
          this.providers[provider] = { osList: [os] };
        }
      }
    }

    getProviders() {
      return this.providers;
    }

    static getClusterEntries() {
      /* istanbul ignore next */
      const clusterReleases = (typeof Config.getSupportedClusterReleases === 'function') ? Config.getSupportedClusterReleases() : [];
      // We want cluster to appear to be parallel_server so they combine in the display
      const product = "parallel_server";  // "cluster";
      // cluster ia always AWS
      const platform = "aws";
      // cluster is always Linux
      const os = "linux";
      const entries = [];

      for (const item of clusterReleases) {
        const entry = new ReleaseInfo({
          product: product,
          release: item.release,
          description: `${I18NStringResource[`supportedProductDisplayName_${product}`]} ${item.release}`
        });
        if (item.deprecated) {
          entry.status = RuleInfo.StatusIntDeprecated;
          entry.sunsetOnDate = I18NStringResource.clusterDeprecateDate;
        }
        entry.add(platform, os);
        entries.push(entry);
      }

      return entries;
    }

  }

  return ReleaseInfo;
}); // require
