/* jshint esversion: 8 */
define([
  "computeResource/collections/collection"
], function (
  Collection
) {

  class MatlabCollection extends Collection {
    constructor (args) {
      super(args);
    }
  }

  return MatlabCollection;
});
