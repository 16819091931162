/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "pages/abstractPage",
  "templates/credentialsAWSImportRolePage",
  "templates/credentialsAWSImportRolePagePermissionsRow",
  "dialogs/copyToClipboardDialog",
  "dialogs/warningDialog",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "util"
], function( _, $, AbstractPage, PageTemplate, PermissionsRowTemplate, CopyToClipboardDialog, WarningDialog, I18NStringResource, DojoString, Util ) {

  /**
   * This subclass of MatlabDriveApplication uses
   * Mathworks FileBrowser2 widget for the tree controller.
   */
  class CredentialsAWSImportRolePage extends AbstractPage {

    constructor(params) {
      super(params);
      this.pageTemplate = PageTemplate;
      this.events = {
        "click div#awsImportRoleContainerPageView > div.facetBar > ul.facets > li > a#resourceLink" : "goToResource",
        "click div#awsImportRoleContainerPageView > div.facetBar > ul.facets > li > a#credentialLink" : "goToCredential",
        "click div.awsImportRoleContainer form div.dialogFoot > button#closeCreateDialog" : "goToCredential",
        "click div#awsImportRoleContainer button#quickAWSImporting" : "goToAWSConsole",
        "click div#awsImportRoleContainer button#linkToAwsConsole" : "goToCreateRole",
        "click div#awsImportRoleContainer button#linkToAwsConsoleRole" : "goToLinkRole",
        "dragover div.awsImportRoleContainer": "dragoverNotAllowed",
        "keyup #role_arn": "validate",
        "change #role_arn": "validate",
        "change #actual_external_id" : "validate",
        "click #importAwsCredsButton" : "submitRoleARN",
        "click #adminAccountCopy" : "copyMathWorksAccountID",
        "click #externalIdCopy" : "copyExternalID",
        "click button.btn.btn-link.default-link.CredentialsAWSImportRolePage": "goToHome"
      };
      this.awsDataService = this.getDataService().getPlatformDataService('aws');
      // bind 'this' in the listed methods to the current 'this',
      // even when called by other modules.
      _.bindAll(this, "logoutPage", "getAuthManager", "goToResource",
                "goToCredential", "validate", "submitRoleARN", "goToAWSConsole",
                "goToCreateRole", "goToLinkRole");
    }

    getAWSDataService () { return this.awsDataService; }

    goToCredentialsAWSImportRolePage(event) { /* do nothing */ }

    goToAWSConsole (event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      if (event && event.currentTarget) {
        Util.logDDUXinfoFromClickEvent(event, this.getDataService().logging);
      }
      let openAWSConsole = function() {
        window.open("https://aws.amazon.com/console/","_blank",this.getWindowParams()).opener = null;
      };
      let goToAWSConsoleWarningDialog = new WarningDialog({
        title: I18NStringResource.AWSImportRole_warningDialog_step1Title,
        text:  I18NStringResource.AWSImportRole_warningDialog_step1Text,
        actionButtonLabel:  I18NStringResource.AWSImportRole_warningDialog_step1ActionButtonLabel,
        closeButtonLabel:  I18NStringResource.AWSImportRole_warningDialog_step1CloudButtonLabel,
        hideIcon: true,
        showProceedPrompt: true,
        proceedPrompt:  I18NStringResource.AWSImportRole_warningDialog_step1ProceedPrompt,
        actionFn: openAWSConsole.bind(this),
        modalContainerId: "modalContainer",
        classname: "goToAWSConsole"
      });
      goToAWSConsoleWarningDialog.show();
    }

    getWindowParams () {
      let width  = 960;
      let height = screen.width;
      let left   = screen.width - width;
      if (left<0) {
        left = 0;
      }
      let top    = 0;
      var params = 'width='+width+', height='+height;
      params += ', top='+top+', left='+left;
      params += ', directories';
      params += ', location';
      params += ', menubar';
      params += ', resizable';
      params += ', scrollbars';
      params += ', status';
      params += ', toolbar';
      return params;
    }

    goToCreateRole (event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      if (event && event.currentTarget) {
        Util.logDDUXinfoFromClickEvent(event, this.getDataService().logging);
      }
      let openCreateRole = function() {
        let now = this.el.querySelector('input[type="hidden"]#defaultRoleNameExtension').value;
        let url = "https://console.aws.amazon.com/iamv2/home#/roles/create?step=selectEntities";

        window.open(url, "_blank",this.getWindowParams()).opener = null;
      }
      let openCreateRolePageWarningDialog = new WarningDialog({
        title: I18NStringResource.AWSImportRole_warningDialog_step2Title,
        text:  I18NStringResource.AWSImportRole_warningDialog_step2Text,
        actionButtonLabel:  I18NStringResource.AWSImportRole_warningDialog_step2ActionButtonLabel,
        closeButtonLabel:  I18NStringResource.AWSImportRole_warningDialog_step2CloudButtonLabel,
        hideIcon: true,
        showProceedPrompt: true,
        proceedPrompt:  I18NStringResource.AWSImportRole_warningDialog_step2ProceedPrompt,
        actionFn: openCreateRole.bind(this),
        modalContainerId: "modalContainer",
        classname: "goToAWSConsole"
      });
      openCreateRolePageWarningDialog.show();

    }

    goToLinkRole (event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      if (event && event.currentTarget) {
        Util.logDDUXinfoFromClickEvent(event, this.getDataService().logging);
      }
      let openCreatedRole = function() {
        let now = this.el.querySelector('input[type="hidden"]#defaultRoleNameExtension').value;
        let defaultRoleName = I18NStringResource.AWSImportRole_defaultRoleName;
        let url = `https://console.aws.amazon.com/iam/home#/roles/${defaultRoleName}_${now}`;
        window.open(url, "_blank").opener = null;
      }
      let openCreatedRoleWarningDialog = new WarningDialog({
        title: I18NStringResource.AWSImportRole_warningDialog_step3Title,
        text:  I18NStringResource.AWSImportRole_warningDialog_step3Text,
        actionButtonLabel:  I18NStringResource.AWSImportRole_warningDialog_step3ActionButtonLabel,
        closeButtonLabel:  I18NStringResource.AWSImportRole_warningDialog_step3CloudButtonLabel,
        hideIcon: true,
        showProceedPrompt: true,
        proceedPrompt:  I18NStringResource.AWSImportRole_warningDialog_step3ProceedPrompt,
        actionFn: openCreatedRole.bind(this),
        modalContainerId: "modalContainer",
        classname: "goToAWSConsole"
      });
      openCreatedRoleWarningDialog.show();

    }

    startPage (msgHandler) {
      super.startPage();
      if (!msgHandler || typeof msgHandler !== "function") {
        throw new TypeError("Invalid message handler argument");
      }
    }

    stopPage () {
      super.stopPage();
    }

    async render() {
      try {
        let data = await this.getAWSDataService().getIAMService().ccAccountInfo();
        if (data) {
          this.renderPage(data);
        }
      } catch(error) {
        Util.consoleLogError("render", error);
        this.goToCredential();
      }
    }

    async renderPage(data) {
      if (!(data && typeof data === 'object' && 'accountId' in data)) {
        throw new TypeError("Invalid data argument");
      }
      this.awsAccountID = data.accountId;
      let am = this.getAuthManager();
      const touchevents = Util.touchEventsEnabled();
      try {
        this.externalID = await this.getAWSDataService().getIAMService().getExternalID(this.urlArgs.path);
      } catch (error) {
        let message = I18NStringResource.unknownValue;
        if (error && error.message) {
          message = error.message;
        }
        this.externalID = "";
        Util.consoleLogError("renderPage", error);
        Util.notify("ERROR", DojoString.substitute(I18NStringResource.AWSImportRole_errorImportingRole, message));
      }
      var htmlResult = this.pageTemplate({
        headerNavigation: this.renderNavigationHeader(),
        computeLinkText: I18NStringResource.computeLinkText,
        credentialLinkText: I18NStringResource.credentialLinkText,
        credentialsResourcesLabel: I18NStringResource.credentialsPageCredentialsResourcesLabel,
        defaultCredentialsResourcesMsg: I18NStringResource.credentialsPageDefaultCredentialsResourcesMsg,
        createCredential: I18NStringResource.credentialsPageCreateCredentialButtonText,

        addCredsTitle: I18NStringResource.AWSImportRole_addCredsTitle,
        addCredsPurpose: I18NStringResource.AWSImportRole_addCredsPurpose,

        defaultRoleName: I18NStringResource.AWSImportRole_defaultRoleName,
        step1: I18NStringResource.AWSImportRole_step1,
        step1Text: I18NStringResource.AWSImportRole_step1Text,
        step1ButtonText: I18NStringResource.AWSImportRole_step1ButtonLabel,
        infoText: I18NStringResource.AWSImportRole_infoText,
        step2: I18NStringResource.AWSImportRole_step2,
        step2Text1: I18NStringResource.AWSImportRole_step2Text1,
        step2Text2: I18NStringResource.AWSImportRole_step2Text2,
        step2ButtonText: I18NStringResource.AWSImportRole_step2ButtonLabel,
        step3: I18NStringResource.AWSImportRole_step3,
        step3Text1: I18NStringResource.AWSImportRole_step3Text1,
        step4: I18NStringResource.AWSImportRole_step4,
        step4Text: I18NStringResource.AWSImportRole_step4Text,
        step5: I18NStringResource.AWSImportRole_step5,
        step5Text: I18NStringResource.AWSImportRole_step5Text,
        step6: I18NStringResource.AWSImportRole_step6,
        step6Text: I18NStringResource.AWSImportRole_step6Text,
        step6bText: I18NStringResource.AWSImportRole_step6bText,
        step7: I18NStringResource.AWSImportRole_step7,
        step7Text: I18NStringResource.AWSImportRole_step7Text,
        addCredsFooterNote: I18NStringResource.AWSImportRole_addCredsFooterNote,
        ccUserGuide: I18NStringResource.AWSImportRole_ccUserGuide,
        nextBtnText: I18NStringResource.AWSImportRole_nextBtnText,
        step8: I18NStringResource.AWSImportRole_step8,
        step8Text: I18NStringResource.AWSImportRole_step8Text,
        step9: I18NStringResource.AWSImportRole_step9,
        step9Text: I18NStringResource.AWSImportRole_step9Text,
        step9ButtonText: I18NStringResource.AWSImportRole_step9ButtonLabel,
        step9bText: I18NStringResource.AWSImportRole_step9bText,
        step10: I18NStringResource.AWSImportRole_step10,
        step10Text: I18NStringResource.AWSImportRole_step10Text,
        step11: I18NStringResource.AWSImportRole_step11,
        step11Text: I18NStringResource.AWSImportRole_step11Text,
        roleArn: I18NStringResource.AWSImportRole_roleArn,
        desp: I18NStringResource.AWSImportRole_desp,
        acctId: I18NStringResource.AWSImportRole_acctId,
        extId: I18NStringResource.AWSImportRole_extId,
        actualExternalID: I18NStringResource.AWSImportRole_actualExternalID,
        awsIamText: I18NStringResource.AWSImportRole_awsIamText,
        acctIdValue: data.accountId,
        extIdValue: this.externalID,
        copyButton: I18NStringResource.copyButtonTooltip,
        cancelButtonText: I18NStringResource.AWSImportRole_cancelBtnText
      });
      this.$el.empty().html(htmlResult);

      if (!touchevents) {
        $('[data-bs-toggle="tooltip"]').tooltip();
      }

      this.renderMatrix();

      // Render the approp. permissions for this type
      try {
        let data = await this.getAWSDataService().getIAMService().getType(this.urlArgs.path);
        let permissions = document.querySelector("#permissions");
        permissions.innerHTML = "";
        this.policyList = [];

        data.forEach(policy => {
          let policyKey = policy.replaceAll(".","_");
          let policyText = policy;
          let policyI18N = I18NStringResource[policyKey];
          if (policyI18N) {
            this.policyList.push(policyI18N);
            policyText = policyI18N;
          } else {
            this.policyList.push(policyKey);
          }

          var htmlResult = PermissionsRowTemplate({
            id:policyKey,
            label:I18NStringResource.policyName,
            value:policyText,
            tooltip: I18NStringResource.copyButtonTooltip
          });
          permissions.innerHTML += htmlResult;
          permissions.querySelector( `#${policyKey}Copy`).addEventListener("click", function(e) {
            if (e) { e.preventDefault(); }
            let copyToClipboardDialog = new CopyToClipboardDialog();
            copyToClipboardDialog.copyToClipboard(policyText);
            return false;
          }.bind(this), false);
          if (!touchevents) {
            $('[data-bs-toggle="tooltip"]').tooltip();
          }
        });
      } catch(error) {
        Util.consoleLogError("renderPage", error);
        this.goToCredential();
      }
    }


    validate(e) {
      if (e) { e.preventDefault(); }

      let role = "";
      let roleInput = document.getElementById("role_arn");
      if (roleInput && roleInput.value) {
        role = roleInput.value.trim();
      }

      let desc = "";
      let descInput = document.getElementById("description");
      if (descInput && descInput.value) {
        desc = descInput.value.trim();
      }
      //let hasRole = /^arn:(aws[a-zA-Z-]*)?:iam::084313618107:role\/[a-zA-Z0-9-_]*$/.test(role);
      let success = true;
      let submitButton = document.getElementById("importAwsCredsButton");
      if (submitButton) {
        submitButton.disabled = !success;
        if (success) {
          submitButton.classList.remove("disabled");
        } else {
          submitButton.classList.add("disabled");
        }
      }
      return {role:role, desc:desc, success:success};
    }

    async submitRoleARN(event) {
      let clickedButton;
      if (event && event.preventDefault) { event.preventDefault(); }
      if (event && event.currentTarget) {
        Util.logDDUXinfoFromClickEvent(event, this.getDataService().logging);
        if (event.currentTarget.tagName && event.currentTarget.tagName.toUpperCase() === 'BUTTON') {
          clickedButton = event.currentTarget;
        }
      }
      if (!clickedButton) {
        clickedButton = document.getElementById("importAwsCredsButton");
      }
      if (clickedButton) {
        if (clickedButton.disabled) {
          return;
        }
        Util.disableButton(clickedButton);
        let info = this.validate(event);
        if (!info.success) {
          Util.enableButton(clickedButton);
          return;
        }
        try{
          const importCredArgs = {
            credentialId: this.urlArgs.path,
            roleARN: info.role,
            description: info.desc
          };
          await this.getDataService().ui.importCredential('aws', importCredArgs);
          this.goToCredential();
        } catch(err) {
          Util.displayAPIResponseError(err, I18NStringResource.AWSImportRole_errorImportingRole, this.policyList);
        } finally {
          Util.enableButton(clickedButton);
        }
      }
      return false;
    }

    copyMathWorksAccountID(e) {
      if (e) { e.preventDefault(); }

      let text = document.querySelector("#accountId");
      let copyToClipboardDialog = new CopyToClipboardDialog();
      copyToClipboardDialog.copyToClipboard(text.value);
    }

    copyExternalID(e) {
      if (e) { e.preventDefault(); }

      let text = document.querySelector("#externalId");
      let copyToClipboardDialog = new CopyToClipboardDialog();
      copyToClipboardDialog.copyToClipboard(text.value);
    }


  }
  return CredentialsAWSImportRolePage;
}); // require
