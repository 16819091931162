define([
  "computeResource/collections/collectionItem",
  "templates/computeResourceCollections/licenseManager/item",
  "service/cloudStatus",
  "util",
  "supportedProducts",
  "service/landingPageNavButtons",
  "notification/notificationManager",
  "computeResource/computeValueFormatter",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "statusAndCountdown"
], function(
  CollectionItem,
  ResourceCollectionItemTemplate,
  CloudStatus, Util, SupportedProducts, LandingPageNavButtons,
  NotificationManager,ComputeValueFormatter, I18NStringResource,
  DojoString,StatusAndCountdown
) {
  class LicenseManagerCollectionItem extends CollectionItem {

    constructor (args) {
      super(args);
    }

    /* istanbul ignore next */
    getResourceCollectionItemTemplate () {
      return ResourceCollectionItemTemplate;
    }


    supportsRDP () {
      return false;
    }

    /* istanbul ignore next */
    static createCollectionHeader () {
      const columns = [
        {id:"computeResourcePageColumnHeaderName", class:"header-name", displaytext: true},
        {id:"computeResourcePageColumnNotifications", class: "header-notifications", displaytext: false},
        {id:"computeResourcePageColumnHeaderIPAddress", class:"header-ipaddress", displaytext: true},
        {id:"computeResourcePageColumnHeaderOperatingSystem", class:"header-os", displaytext: true},
        {id:"computeResourcePageColumnHeaderCloudProvider", class:"header-provider", displaytext: true},
        {id:"computeResourcePageColumnHeaderCloudLocation", class:"header-location", displaytext: true},
        {id:"computeResourcePageColumnHeaderNetwork", class:"header-network", displaytext: true},
        {id:"computeResourcePageColumnHeaderStatus", class:"header-status", displaytext: true},
        {id:"computeResourcePageColumnHeaderActions", class:"header-actions", displaytext: true},
        {id:"computeResourcePageColumnHeaderMenu", class:"header-menu", displaytext: false}
      ];
      const header = CollectionItem.createCollectionHeader(columns);
      return header;
    }

  }

  return LicenseManagerCollectionItem;
});
