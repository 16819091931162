/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "pages/abstractPage",
  "templates/managementPage",
  "dojo/i18n!nls/cloudCenterStringResource",
  "util",
  "config",
  "service/cachedDataService",
  "lit-element/lit-element.bundled",
  "components/cloudCenterElement",
  "components/managementCloudAccount"
], function( _, $, AbstractPage, PageTemplate,
             I18NStringResource, Util, Config, CachedDataService,
             Lit, CloudCenterElement, ManagementCloudAccount
                ) {

  class ManagementPage extends AbstractPage {
    constructor(params) {
      super(params);
      this.localDataService = this.getDataService();
      this.pageTemplate = PageTemplate;
      this.events = {
        "click div#managementPageView > div.facetBar > ul.facets > li > a#resourceLink" : "goToResource",
        "click div#managementPageView > div.facetBar > ul.facets > li > a#credentialLink" : "goToCredential",
        "click button.btn.btn-link.default-link.ManagementPage": "goToHome"
      };
      // bind 'this' in the listed methods to the current 'this', even when called by other modules.
      _.bindAll(this, "logoutPage", "goToManagement", "getAuthManager", "goToHome", "goToCredential", "goToResource");
    }

    getLocalDataService () { return this.localDataService; }

    goToManagement () { /* do nothing */ }

    startPage (msgHandler) {
      super.startPage();
      if (!msgHandler || typeof msgHandler !== "function") {
        throw new TypeError("Invalid message handler argument");
      }
    }

    stopPage () {
      super.stopPage();
    }

    async render () {
      this.getLocalDataService().clearAll();
      const touchevents = Util.touchEventsEnabled();

      const templateHtml = this.generateHTML();
      this.$el.empty().html(templateHtml);
      if (!touchevents) {
        $('[data-bs-toggle="tooltip"]').tooltip();
      }





      this.renderMatrix();

      this.loadCloudAccounts();
    }

    async loadCloudAccounts () {

      const acctListContainer = document.querySelector("div#cloudAccountList");
      if (!acctListContainer) {
        this.goToResource();
        return;
      }

      try {
        const data = await this.getLocalDataService().cloud.crawlCloudAccounts();
        const element = document.createElement('management-cloud-account');
        const html = element.render();
        acctListContainer.innerHTML = html.getHTML();

      } catch (error) {
        Util.consoleLogError('loadCloudAccounts ', error);
        acctListContainer.innerHTML = I18NStringResource.managementNoAccountsFound + JSON.stringify(error, null, 2)
      }




    }

    generateHTML () {
      let templateParams = {
        headerNavigation : this.renderNavigationHeader(),
        i18n: I18NStringResource,
        config: Config,
        // azure: azure,
        // aws: aws
        // data: products,
      };

      return this.pageTemplate(templateParams);
    }



  }
  return ManagementPage;
}); // require
