/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "pages/aboutPage",
  "pages/managementPage",
  "pages/computeResourcePage",
  "pages/credentialsPage",
  "pages/computeResourceDetailPage",
  "pages/credentialsAWSImportRolePage",
  "pages/credentialsAWSCreateRolePage",
  "util",
  "dojo/i18n!nls/cloudCenterStringResource"
], function(_, $, AboutPage, ManagementPage, ComputeResourcePage, CredentialsPage, ComputeResourceDetailsPage,
  AWSImportRolePage,  AWSCreateRolePage, Util, I18NStringResource) {

  const DEFAULT_ENDPOINT="resource";
  const DEFAULT_EVENT="Resource";
  const DEFAULT_CLIENT_STRING="cloudcenter";

  /*
  To add a new page:
    1) Create the pages/<page>Page.js and templates/<page>Page.html as usual.
       Stick to the convention of Page.js and Page.html
       TIP: If you copy-and-paste an existing Page.js file, be sure to change the class name and goToFoo() method name
    2) Require the pages/<page>Page.js into this file (above)
    3) Create a row in the manifest for the page:
        id: {event:"", endpoint:"", ccwa:"", isFile:true,
                page:{clazz:NewRequiredClass, addNotificationManager:true, allowDnD: true, allowDownloads: true}
            },
        id: lowerCamelCaseIdentifier
            ... this is used to set the Page and and lookup the View: context[`${pageId}Page`] = page;
            ... you must name your view ${pageId}View
            ... this must match your page name passed from the router
        event: CamelCaseIdentifier
            ... this is used to make functions, such as goToResourceDetail() and getResourceDetailPage()
        endpoint: lowercase-with-hyphens
            ... this is the endpoint in the URL of the app. E.g., http://foo/resource-detail
        ccwa: lowercasewithouthyphens
            ... this is used for the APS events.  E.g. resourcedetail
        NewRequiredClass: the item from Require: ], function(_, $, ComputeResourcePage, CredentialsPage, ComputeResourceDetailsPage, I18NStringResource) {
            ... e.g., ComputeResourcePage or CredentialsPage or ComputeResourceDetailsPage etc.
        TIP: Be sure to avoid the Page suffix when copy-and-pasting to the JSON
    4) Update TestHelpers.js -> TestPageHelper to benefit from standard tests
    5) Update Router (endpoint (x2) and method to call auth)
    6) Add endpoint (x2) and page (x1) to gruntfile
    7) Update CSS

  This class will create generic navifcation and application methods for these pages.
  You can still add custom events, etc. to the pages/<page>Page.js

  */

  const MANIFEST = {
            about:          {event: "About", endpoint: "about", ccwa:"about", isFile:true,  page: {clazz:AboutPage, addNotificationManager:true, pageName: "AboutPage"}},
            management:          {event: "Management", endpoint: "manage", ccwa:"management", isFile:true,  page: {clazz:ManagementPage, addNotificationManager:true, pageName: "ManagementPage"}},
            resource:       {event: "Resource", endpoint: "resource", ccwa:"resource", isFile:true,  page: {clazz:ComputeResourcePage, addNotificationManager:true, pageName: "ComputeResourcePage"}},
            credential:     {event: "Credential", endpoint: "credential", ccwa:"credential", isFile:true, page: {clazz:CredentialsPage, addNotificationManager:true, pageName: "CredentialsPage"}},
            resourceDetail: {event: "ResourceDetail", endpoint: "resource-detail", ccwa:"resourcedetail", isFile:true, page: {clazz:ComputeResourceDetailsPage, addNotificationManager:true, pageName: "ComputeResourceDetailsPage"}},
            credentialsAWSImportRole: {event: "AWSImportRole", endpoint: "aws-import-role", ccwa:"awsimportrole", isFile:true, page: {clazz:AWSImportRolePage, addNotificationManager:true, pageName: "CredentialsAWSImportRolePage"}},
            credentialsAWSCreateRole: {event: "AWSCreateRole", endpoint: "aws-create-role", ccwa:"awscreaterole", isFile:true, page: {clazz:AWSCreateRolePage, addNotificationManager:true, pageName: "CredentialsAWSCreateRolePage"}}
            };
  const MANIFEST_BY_ENDPOINT = {};
  for (let [pageId, pageInfo] of Object.entries(MANIFEST)) {
    pageInfo.id = pageId;
    MANIFEST_BY_ENDPOINT[pageInfo.endpoint] = pageInfo;
  }


  class AppStructure {
        constructor(context){
            this.context = context;

            this.MANIFEST=MANIFEST;
            this.MANIFEST_BY_ENDPOINT = MANIFEST_BY_ENDPOINT;

            this.PAGE_DEFAULT={event:DEFAULT_EVENT, endpoint:DEFAULT_ENDPOINT};
        }


        StartCCWA() {
            let context = this.context;
            let appCtx = this;
            for (let [pageId, pageInfo] of Object.entries(appCtx.MANIFEST)) {
                $(document).on(`changeto${pageInfo.ccwa}page:ccwa`, function(e, data) { // jshint ignore:line
                    if (e && e.preventDefault) { e.preventDefault(); }
                    context[`goTo${pageInfo.event}`](data);
                });
                $(document).on(`start${pageInfo.ccwa}page:ccwa`, function(e) { // jshint ignore:line
                    if (e && e.preventDefault) { e.preventDefault(); }
                    context[`get${pageInfo.event}Page`]().startPage(context.messageHandler);
                });
                $(document).on(`stop${pageInfo.ccwa}page:ccwa`, function(e) { // jshint ignore:line
                    if (e && e.preventDefault) { e.preventDefault(); }
                    context[`get${pageInfo.event}Page`]().stopPage();
                });
                $(document).on(`resethistory${pageInfo.endpoint}page:ccwa`, function (e, data) { // jshint ignore:line
                  if (e && e.preventDefault) { e.preventDefault(); }
                  context[`resethistory${pageInfo.event}Page`](data.view, data.data)
                })
            }
        }

        StopCCWA() {
            let appCtx = this;
            for (let [pageId, pageInfo] of Object.entries(appCtx.MANIFEST)) {
                try {
                 $(document).off(`changeto${pageInfo.ccwa}page:ccwa`);
                 $(document).off(`resethistory${pageInfo.endpoint}page:ccwa`);
               } catch (error) {
                  Util.consoleLogError("StopCCWA", error);
                    throw e;
                }
            }
        }

        StopAllPages() {
            let context = this.context;
            let appCtx = this;
            for (let [pageId, pageInfo] of Object.entries(appCtx.MANIFEST)) {
                context[`get${pageInfo.event}Page`]().getAuthManager().getDAO().setSessionId(null);
            }
        }

        SetOriginId(originId) {
            let context = this.context;
            let appCtx = this;
            for (let [pageId, pageInfo] of Object.entries(appCtx.MANIFEST)) {
                context[`get${pageInfo.event}Page`]().setOriginId(originId);
            }
        }

        BuildPages() {
            let events = [];
            let context = this.context;
            let appCtx = this;
            for (let [pageId, pageInfo] of Object.entries(appCtx.MANIFEST)) {
                let endpoint = pageInfo.endpoint;
                let opts = appCtx.DefaultPageOpts(context);
                opts.pageOptions.pageName = pageInfo.page.pageName;
                if (pageInfo.page.allowDnD===true) {
                    opts.pageOptions.allowDnD = context.options.allowDnD;
                }
                if (pageInfo.page.allowDownloads===true) {
                    opts.pageOptions.allowDownloads = context.options.allowDownloads;
                }

                let page = new pageInfo.page.clazz(opts);

                if (pageInfo.page.addNotificationManager===true) {
                    page.setNotificationManager(context.getNotificationManager());
                }
                context[`${pageId}Page`] = page;

                context[`get${pageInfo.event}Page`] = function() { // jshint ignore:line
                    return context[`${pageId}Page`];
                };

                events.push(`goTo${pageInfo.event}`);
                context[`goTo${pageInfo.event}`] = function(data) { // jshint ignore:line
                    $("BODY").removeClass("mwLogin");
                    // DefaultPageOpts
                    let path = "";
                    let doTrigger = true;
                    let doReplace = false;
                    if (data && typeof data === 'object') {
                      if (data.path && typeof data.path === 'string' && data.path !== "/") {
                        path = `${data.path}`;
                        if (path[0] === "/") {
                            path = path.substring(1);
                        }
                      }
                      if (("trigger" in data) && typeof data.trigger === 'boolean') {
                        doTrigger = data.trigger;
                      }
                      if (("replace" in data) && typeof data.replace === 'boolean') {
                        doReplace = data.replace;
                      }
                    }
                    context.router.navigate(`${pageInfo.endpoint}/${path}`, {trigger: doTrigger, replace: doReplace});
                };

                events.push(`handleApsRefresh${pageInfo.event}`);
                context[`handleApsRefresh${pageInfo.event}`] = function() { // jshint ignore:line
                     _.debounce(function(e, data) { context._handleApsRefreshFunctionality(e, data); }, 1000);
                };

                events.push(`navigateTo${pageInfo.event}`);
                context[`navigateTo${pageInfo.event}`] = function(args) { // jshint ignore:line
                    let url = pageInfo.endpoint + "/";
                    // defaults
                    let doTrigger = false;
                    let doReplace = true;
                    if (args && typeof args === "object") {
                        if (args.trigger && typeof args.trigger === 'boolean') {
                          doTrigger = args.trigger;
                        }
                        if (args.replace && typeof args.replace === 'boolean') {
                          doReplace = args.replace;
                        }
                        if (args.path) {
                          if (args.product) {
                            url += Util.convertProductToUserFriendlyProduct(args.product) + '/';
                          }
                          url += args.path;
                        }
                        if (args.params && typeof args.params === "object") {
                            const queryString = Util.convertStringPropertiesToQueryParams(args.params, []);
                            if (queryString) {
                                url = `${url}${queryString}`;
                            }
                        }
                    }
                    context.router.navigate(url, {trigger: doTrigger, replace: doReplace});
                };

                context[`resethistory${pageInfo.event}Page`] = function(view, args) {
                  if (!(view && typeof view === 'string')) {
                    throw new TypeError("Invalid view argument");
                  }
                  let page = appCtx.MANIFEST_BY_ENDPOINT[view];
                  if (!page) {
                    throw new Error("Unrecognized view specified");
                  }
                  if (!(args && typeof args === 'object' && 'path' in args)) {
                    throw new TypeError("Invalid args argument");
                  }
                  let navParams = {
                    path: args.path,
                    trigger: false,
                    replace: true
                  };
                  context[`navigateTo${page.event}`](navParams);
                };

                /**
                * Starting point for rendering application content.
                * If you are logged in, you would go here next.
                */
                context[`start${pageInfo.event}Page`] = function(args) { // jshint ignore:line
                    let page = context[`get${pageInfo.event}Page`]();
                    page.urlArgs=args;
                    if (document.getElementById(`${pageId}View`) === null) {
                        this.$appContainer.show();  // Enable the display of the application content container
                        page.setElement(this.$appContainer); // set the base element of this view.
                        page.startPage(this.messageHandler);
                        page.render();
                    } else {
                        page.startPage(this.messageHandler);
                    }
                    document.title = I18NStringResource.loggedInPageTitle;
                };

            }

            events.push("sessionSuccess");
            context.sessionSuccess = function(view) {
                //view == page.endpoint
                let page = appCtx.MANIFEST_BY_ENDPOINT[view];
                if (page) {
                    let navFunc = context[`navigateTo${page.event}`];
                    if (navFunc) {
                        navFunc();
                        context.startAPS();
                        return;
                    }
                }
                context[`navigateTo${appCtx.PAGE_DEFAULT.event}`]();
                context.startAPS();
            };

            context.startView = function(view, args) {
                let am = context.getAuthManager();
                context.startAPS();
                //view == page.endpoint
                let page = appCtx.MANIFEST_BY_ENDPOINT[view];
                if (page) {
                    am.hide();
                    $("BODY").removeClass("mwLogin");
                    for (let [pageId, pageInfo] of Object.entries(appCtx.MANIFEST)) {
                        if (pageInfo.endpoint !== view) {
                            context[`get${pageInfo.event}Page`]().stopPage();
                        }

                    }
                    context[`start${page.event}Page`](args);
                    context[`navigateTo${page.event}`](args);
                    return;
                }

                throw new TypeError("Invalid \"view\" value");

            };


            context.getClientStringForView = function(view) {
                //view == page.endpoint
                let page = appCtx.MANIFEST_BY_ENDPOINT[view];
                if (page && page.clientstring) {
                    return page.clientstring;
                }
                return DEFAULT_CLIENT_STRING;  // default pageInfo

            };


            context.getCurrentPage = function(doNavigation) {
                let currentPage = null;
                let view = context.router.getCurrentRoutePage();
                let page = appCtx.MANIFEST[view];
                if (page) {
                    if (doNavigation) {
                        context.router.navigate(`${page.endpoint}/`, {trigger: true});
                    }
                    return context[`get${page.event}Page`]();
                }

                if (view==="login") {
                    return;
                }

                throw new Error("Unsupported router page value: " + view);
            };

            events.push("handleApsRefresh");
            context.handleApsRefresh = function(e, data) {
                if (data && data.page) {
                    let page = appCtx.MANIFEST_BY_ENDPOINT[data.page];
                    if (page) {
                        this[`handleApsRefresh${page.event}`](e, data);
                    } else if (data.page==="both") {
                        this.handleApsRefreshBoth(e, data);
                    }
                    return;
                }

                this.handleApsRefreshNoPage(e, data);

            };

            return events;
        }


        DefaultPageOpts(context) {
            return {
                pageOptions: {
                    authManager: context.getAuthManager(),
                    treecontroller: null
                },
                config: context.config
                };
        }

    }
  return AppStructure;

}); // require
