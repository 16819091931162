define([
  "underscore",
  "jquery",
  "backbone",
  "bootstrap",
  "util",
  "cloudCenterRouter",
  "authentication/authManager",
  "notification/notificationManager",
  "configAppStructure",
  "apsClient",
  "dojo/i18n!nls/cloudCenterStringResource"
], function( _, $, Backbone, Bootstrap, Util, CloudCenterRouter, AuthManager, NotificationManager, AppStructure, APSClient, I18NStringResource ) {

  const _VALID_APP_IDS = [ "" ];

  /**
   * Application UI controller
   * Contains an embedded login manager
   * Handles communicating with CloudCenter
   *
   * Note: this is now an abstract class. Do not instantiate.
   * Rather use the subclasses: cloudCenterWebApp.
   */
  class CloudCenterApplication extends Backbone.View {

    constructor (args) {
      super({
        // map event handlers to events that are for THIS component (not intercomponent)
        events: {
          "click ul.dropdown-menu li.logoutContainer a#menu-logout" : ["logoutHandler"],
          "click ul.dropdown-menu li.aboutMenuContainer a#menu-about" : ["aboutHandler"],
          "click ul.dropdown-menu li.managementMenuContainer a#menu-management" : ["managementHandler"]
        }
      });

      this.initializeParams(args);

      // BuildPages parses the pages and sets up generic nav methods
      this.appStructure = new AppStructure(this);
      this.pageEvents = this.appStructure.BuildPages();

      // bind 'this' in the listed methods to the current 'this',
      // even when called by other modules.
      _.bindAll(this, ...this.pageEvents, "logoutHandler", "aboutHandler", "managementHandler", "messageHandler",
                      "sessionFailure", "refreshPage",  "getCurrentPage",
                      "stopCurrentPage",
                      "handleApsStorageRefresh",  "_handleApsRefreshFunctionality",
                      "_handleApsStorageRefreshFunctionality",
                      "handleApsRefreshBoth",
                      "handleApsRefreshNoPage", "logout",
                      "handleApsNotification", "updateDropdown");

    }

    initializeParams (args){
      let context = this;
      let params = this.processInitializeArguments(args);
      if (!params || !params.options || !params.config) {
        throw new Error("Bad results from processInitializeArguments");
      }
      this.options = params.options;
      this.config = params.config;
      this.externalChanges = [];
      this.externalChangeCount = 0;
      /*
       * Load module controllers
       */
      this.authMgr = new AuthManager(this.options,this.config);
      this.appContainerId = this.options.appContainerId;  // html element's id attribute value
      this.$appContainer = $('#' + this.appContainerId); // jQuery ID selector
      this.setElement(this.$appContainer); // set the base element of this view.
      this.notificationMgr = new NotificationManager(this.options);


      /**
      *  Router that handles bookmarks and address bar in browser
      *  Set pushState to false for servers that don't
      *  support HTML5 pushstate (like glassFish)
      */
      this.router = new CloudCenterRouter(
        {
          parent: this,
          useHtml5PushState: this.config.useHtml5PushState(),
          routerBase: this.config.getRouterBase(),
        }
      );
    }

    processInitializeArguments (params) {
      if (!params || typeof params !== "object") {
        throw new TypeError("Invalid params argument");
      }
      let options = params.applicationOptions;
      let config = params.config;
      // options are either passed in, or use the default values
      let defaults = {
        appContainerId: 'app',
        authContainerId: 'authContainer',
        loginIframeId: 'loginIframe',
        notificationAreaId: 'matlabErrors',
        allowDnD: true
      };
      if (typeof options === 'object') {
        options = {...defaults, ...options}; // Merge objects: override defaults with any passed in values
      } else {
        options = defaults;
      }
      // config should be provide and have a getMicroServiceURL method -- if not, throw exception
      if (!config || typeof config != 'object' || !config.getMicroServiceURL || !config.getDAOClassName || !config.getAWSAccountID) {
        throw new TypeError("Invalid config argument");
      }
      return({options: options, config: config});
    }

    getExternalChangeCount () {
      return this.externalChangeCount;
    }

    getExternalChanges () {
      return this.externalChanges;
    }

    incrementExternalChangeCount (msg) {
      ++this.externalChangeCount;
      this.externalChanges.push(msg);
    }

    getAuthManager () {
      return this.authMgr;
    }

    getNotificationManager () {
      return this.notificationMgr;
    }

    handleCcwaMessage (event, data) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      if (data && typeof data === "object" &&
          "severity" in data && "message" in data) {
        if (
          data.severity === 'NORMAL' ||
          data.severity === 'SEVERE' ||
          data.severity === 'ERROR' ||
          data.severity === 'WARNING' ||
          data.severity === 'INFO' ||
          data.severity === 'RESOURCE_ERROR' ||
          data.severity === 'RESOURCE_WARNING') {
          this.messageHandler(data);
        }
      }
    }

    /*
    * Callback functions for inter-module communication
    */
    messageHandler (data) {
     if (!data || typeof data !== "object" || !data.message || typeof data.message !== "string" || (data.severity && typeof data.severity !== "string")) {
       throw new TypeError("Invalid message data argument");
     }
     let msg = data.message;
     let doLogout = data.severity === 'SEVERE';
     if (data.severity === 'ERROR' || data.severity === 'SEVERE') {
       this.getNotificationManager().notifyUserOfError(data.message, doLogout);
     } else if (data.severity === 'RESOURCE_ERROR') {
       this.getNotificationManager().notifyUserOfResourceError(data.message, false);
     } else if (data.severity === 'RESOURCE_WARNING') {
       this.getNotificationManager().notifyUserOfResourceWarning(data.message, false);
     } else if (data.severity === 'WARNING') {
       this.getNotificationManager().notifyUserOfWarning(data.message, false);
     } else if (data.severity === 'NORMAL') {
       if (data.linkData && typeof data.linkData === "object" && data.linkData.callback && typeof data.linkData.callback === "function" && data.linkData.text && typeof data.linkData.text === "string") {
         this.getNotificationManager().notifyUserOfSuccessWithLink(data.message, data.linkData.text, data.linkData.callback);
       } else {
         this.getNotificationManager().notifyUserOfSuccess(data.message, false);
       }
     } else if (data.severity === 'INFO') {
       this.getNotificationManager().notifyUserOfInfo(data.message, false);
     } else {
       throw new TypeError("Invalid severity value");
     }
    }



    // On failure, if the error is authentication, redirect to
    // embedded login form. Otherwise, log error message.
    sessionFailure (sessionError) {
      if (sessionError && typeof sessionError === "object") {
        let notifier = this.getNotificationManager();
        if (sessionError.errorCode !== "AUTHENTICATION_ERROR") {
          if (notifier) {
            let msg = Util.getErrorMessageTranslator().getTranslatedMessage(sessionError, "cloudCenterWebApp.sessionFailure");
            notifier.notifyUserOfError(msg, true);
          }
        }
      }
      this.logout();
    }

    doLogin (view, errorText) {
      let context = this;
      let am = this.getAuthManager();
      let loginPromise = am.startLogin(this.getClientStringForView(view), errorText);
      $('body').addClass("mwLogin");
      this.router.navigate("login", {trigger: false});
      if (loginPromise) {
        loginPromise.then(function(result) {
          $('body').removeClass("mwLogin");
          if (view === context.appStructure.PAGE_DEFAULT.endpoint) {
            context.router.navigate(context.appStructure.PAGE_DEFAULT.endpoint, {trigger: true, replace: true});
          } else {
            context.router.navigate(view, {trigger: true, replace: true});
          }
        });
      }
      return loginPromise;
    }

    /**
     * Determine whether to do a login, handshake, or go straight to the app
     */
    async authCheck (view, args) {
      view = view || this.appStructure.PAGE_DEFAULT.endpoint;
      // if there already is a sessionID and profile info, show the app UI
      const am = this.getAuthManager();
      // if we have a valid session, use it
      if (am.isValidSession()) {
        this.startView(view, args);
      } else {
        try {
          await am.validateLogin();
          await this.authCheck(view, args);
        } catch (error) {
          this.getAuthManager().hide();
          this.doLogin(view);
        }
      }
    }

    stopCurrentPage () {
      let currentPage = this.getCurrentPage(false);
      if (currentPage) {
        currentPage.stopPage();
      }
    }

    refreshPage () {
      let currentPage = this.getCurrentPage(false);
      if (currentPage) {
        currentPage.refreshPage();
      }
    }

    /* istanbul ignore next */
    _handleApsRefreshFunctionality (e, data) {

    }

    /* istanbul ignore next */
    _handleApsStorageRefreshFunctionality (e, data) {

    }



    /* istanbul ignore next */
    handleApsRefreshBoth (e, data) { _.debounce(function(e, data) { this._handleApsRefreshFunctionality(e, data); }, 1000); }
    /* istanbul ignore next */
    handleApsRefreshNoPage (e, data) { _.debounce(function(e, data) { this._handleApsRefreshFunctionality(e, data); }, 1000); }
    /* istanbul ignore next */
    handleApsStorageRefresh (e, data) { _.debounce(function(e, data) { this._handleApsStorageRefreshFunctionality(e, data); }, 1000); }

    /* istanbul ignore next */
    handleApsNotification (e, data) {
      if (e && typeof e === "object") {
        e.preventDefault();
      }
      if (data && typeof data === "object") {
        if (data.severity === 'SEVERE' || data.severity === 'ERROR' || data.severity === 'INFO') {
          this.messageHandler(data);
        } else if (data.severity === 'NORMAL') {
          this.incrementExternalChangeCount(data.message);
        }
      }
    }

    /* istanbul ignore next */
    startAPS () {
      if (this.aps && this.aps.isAlive()) {
        return;
      }
      if (this.config.isAPSEnabled && this.config.isAPSEnabled()) {
        // setup message listeners for APS client messages
        let context = this;
        // Message telling us to refresh our grid because data has changed.
        // The use of debounce is to prevent quick recalling of the same method.
        // So, this says to call the debounced-method 1000ms after no more calls
        // to it have occurred.
        $(document).on("refresh:aps", this.handleApsRefresh);
        $(document).on("refreshstorage:aps", this.handleApsStorageRefresh);
        // message tellings us what changed or other notification info such as error.
        $(document).on("notification:aps", this.handleApsNotification);

        this.aps = new APSClient(this.config); // no longer pass UserID
        if (this.aps) {
          let originId = this.aps.getInstanceGUID();
          if (originId) {
            this.getAuthManager().getDAO().setOriginId(originId);
            this.appStructure.SetOriginId(originId);
          }
          if (this.getAuthManager().getLoginData().accessToken) {
            this.aps.initialize(this.getAuthManager().getLoginData().accessToken);
          } else {
            this.aps.initialize(this.getAuthManager().getLoginData().token);
          }
        }
      }
    }

    /* istanbul ignore next */
    stopAPS () {
      if (this.config.isAPSEnabled && this.config.isAPSEnabled()) {

        // Remove message listeners
        $(document).off("refresh:aps");
        $(document).off("refreshstorage:aps");
        $(document).off("notification:aps");

        if (this.aps) {
          this.aps.stop();
          this.getAuthManager().getDAO().setOriginId(null);
          this.aps = null;
        }
      }
    }

    /* istanbul ignore next */
    restartAPS () {
      this.stopAPS();
      this.startAPS();
    }

    closeAnyPopup () {
      $('div.modal-backdrop.fade.in').remove();
    }

    updateDropdown () {
      this.getCurrentPage(false).updateSettings();
      this.getCurrentPage(false).updateUserQuota();
    }


    /**
     * The Application's starting point.
     */
    startApplication () {
      let am = this.getAuthManager();
      let router = this.router;
      let notifier = this.getNotificationManager();
      let context = this;
      notifier.start(this.logoutHandler);
      notifier.start(this.aboutHandler);
      notifier.start(this.managementHandler);

      am.start();
      if (this.config.isQualarooEnabled && this.config.isQualarooEnabled()) {
        let qualarooScript = document.createElement('script');
        qualarooScript.type= "text/javascript";
        qualarooScript.src = "/{{ASSETS_FOLDER}}/js/analytics/qualaroo.js";
        document.body.appendChild(qualarooScript);
      }
      if (this.config.isAdobeAnalyticsEnabled && this.config.isAdobeAnalyticsEnabled()) {
        let adobeHeadScript = document.createElement('script');
        adobeHeadScript.type= "text/javascript";
        adobeHeadScript.src = "//assets.adobedtm.com/d0cc0600946eb3957f703b9fe43c3590597a8c2c/satelliteLib-e8d23c2e444abadc572df06537e2def59c01db09.js";
        document.head.appendChild(adobeHeadScript);
        adobeHeadScript.onload = function() {
          let adobeBodyScript = document.createElement('script');
          adobeBodyScript.type= "text/javascript";
          adobeBodyScript.src = "/{{ASSETS_FOLDER}}/js/analytics/adobe.js";
          document.body.appendChild(adobeBodyScript);
        };
      }
      $(window).on('online',  function(e) {context.restartAPS();});

      this.appStructure.StartCCWA();

      $(document).on("message:ccwa", this.handleCcwaMessage.bind(this));

      $(document).on('exitApplication:ccwa', function(e) {
        if (e) {
          e.preventDefault();
        }
        context.logoutHandler();
      });
      $(document).on('navupdate:ccwa', function(e, data) {
        if (e && e.preventDefault) {
          e.preventDefault();
        }
        if (data && data.path && data.path.length) {
          context.updateNavAddress(data.path);
        }
      });
      $(window).on('popstate', this.closeAnyPopup);

      if (this.config.isOpenWithEnabled && this.config.isOpenWithEnabled()) {
        // load OpenWith service
        let scriptElement = document.createElement("script");
        scriptElement.type = "application/javascript";
        scriptElement.src = this.config.getOpenWithURL();
        document.body.appendChild(scriptElement);
      }

      // router must start last
      // start the router
      router.start();
    }

    /**
     * A method to removed event listens created by the login manager.
     * (Not currently called)
     */
    stopApplication () {
      let am = this.getAuthManager();
      let notifier = this.getNotificationManager();
      // remove event listeners
      am.stop();
      notifier.stop();
      this.appStructure.StopCCWA();
      $(document).off("message:ccwa");

      $(document).off('exitApplication:ccwa');
      $(document).off('navupdate:ccwa');
      $(window).off('online');
      $(window).off('popstate', this.closeAnyPopup);
      // router doesn't stop
    }

    async cleanLoginCookies () {
      let am = this.getAuthManager();
      let f = document.createElement('iframe');
      f.style.width = "1px";
      f.style.height = "1px";
      f.style.background = "white";
      const deferred = Util.generateDeferredPromise();
      const deferredPromise = deferred.promise;
      const deferredResolve = deferred.resolve;
      const deferredReject = deferred.reject;
      f.onload = function() {
          setTimeout(function() {
            try {
              document.body.removeChild(f);
              f.src = "";
              deferredResolve();
            } catch (error) {
              deferredReject(error);
            }
          }, 0);
      };
      f.src = am.getLogoutURL();
      document.body.appendChild(f);
      return deferredPromise;
    }

    aboutHandler (event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      $.event.trigger("changetoaboutpage:ccwa");
    }

    managementHandler (event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      $.event.trigger("changetomanagementpage:ccwa");
    }

    async logoutHandler (event) {
      if (event) {
        event.preventDefault();
      }
      await this.getCurrentPage(false).logoutPage();
      await this.logout();
    }

    async logout () {
      let am = this.getAuthManager();
      let notifier = this.getNotificationManager();
      let router = this.router;

      // remove any remaining dialog backdrops when going to login page
      let dialogBackdrops = document.getElementsByClassName('modal-backdrop fade in');
      if (dialogBackdrops && dialogBackdrops.length) {
        for (let backdropIndex = 0; backdropIndex < dialogBackdrops.length; backdropIndex++) {
          dialogBackdrops[backdropIndex].parentNode.removeChild(dialogBackdrops[backdropIndex]);
        }
      }

      this.stopAPS();
      this.stopCurrentPage();
      am.logout(false);
    }

    updateNavAddress (path) {
      let encodedPath;
      try {
        encodedPath = encodeURI(path);
      } catch (e) {
        encodedPath = path;
      }
      path = encodedPath;
      this.router.navigate("resource" + path, {trigger: false, replace: false});
    }

  }
  return CloudCenterApplication;
}); // require
