define([
    'dojo/_base/lang',
    'dojo/aspect',
    'dojo/on',

    './messageserviceimpl/MessageServiceAsync',
    './messageserviceimpl/MessageServiceBase',
    './messageserviceimpl/MessageServiceReqResp',
    'mw-remote/Remote'
], function (lang,
    aspect, on, MessageServiceAsync, MessageServiceBase, MessageServiceReqResp, Remote) {
    let service = new MessageServiceBase();
    let MessageService = {
        messageService: service,

        subscribe: lang.hitch(service, 'subscribe'),
        unsubscribe: lang.hitch(service, 'unsubscribe'),
        publish: lang.hitch(service, 'publish'),

        start: lang.hitch(service, 'start'),
        stop: lang.hitch(service, 'stop'),

        isRunning: lang.hitch(service, 'isStarted'), // backwards compatibility isRunning==isStarted
        isStarted: lang.hitch(service, 'isStarted'),
        isConnected: lang.hitch(service, 'isConnected'),
        isFatallyDisconnected: lang.hitch(service, 'isFatallyDisconnected'),
        isDisconnected: lang.hitch(service, 'isDisconnected'),

        // for testing/debugging purposes
        setLatency: lang.hitch(service, 'setLatency'),
        setErrorRate: lang.hitch(service, 'setErrorRate'),
        getTransport: lang.hitch(service, 'getTransport'),

        onConnected: function () {},
        onDisconnected: function () {},
        onReconnected: function () {},
        onFatallyDisconnected: function () {},
        onNotConnected: function () {},

        on: function (event, handler) {
            return on(this.messageService, event, handler);
        },

        handleMessage: lang.hitch(service, 'handleMessage'),

        // Used in MotwApplication to turn off start until Worker is Available.
        internalMotwDisableStart: function () {
            this._startFunction = this.start;
            this.start = function () {};
        },
        // Used in MotwApplication to turn on start once Worker is Available.
        internalMotwEnableStart: function () {
            if (this._startFunction) {
                this.start = this._startFunction;
                this._startFunction = null;
            }
        }
    };

    aspect.after(service, 'onConnected', lang.hitch(MessageService, 'onConnected'), true);
    aspect.after(service, 'onDisconnected', lang.hitch(MessageService, 'onDisconnected'), true);
    aspect.after(service, 'onReconnected', lang.hitch(MessageService, 'onReconnected'), true);
    aspect.after(service, 'onFatallyDisconnected', lang.hitch(MessageService,
        'onFatallyDisconnected'), true);
    aspect.after(service, 'onNotConnected', lang.hitch(MessageService, 'onNotConnected'), true);

    // Hook into the remote module's enable/disable methods to load the correct message
    // service implementation and allow for swapping with correct start/stop behavior.

    // See g1219276 for refactor details
    let originalEnable = Remote.enableServerOnWorkerAsync;
    Remote.enableServerOnWorkerAsync = function () {
        originalEnable();
        service.setDelegate(new MessageServiceAsync(Remote));
    };

    let originalDisable = Remote.disableServerOnWorkerAsync;
    Remote.disableServerOnWorkerAsync = function () {
        originalDisable();
        service.setDelegate(new MessageServiceReqResp(Remote, service));
    };

    // This appears always to be "True" - See g1219276 for refactor details
    if (Remote.isServerOnWorkerAsyncEnabled()) {
        Remote.enableServerOnWorkerAsync();
    } else {
        Remote.disableServerOnWorkerAsync();
    }

    return MessageService;
});
