/*
  This was contents of index.html.  Moved here for Webpack
*/
define([
  "underscore",
  "jquery",
], function(_, $) {
  // jquery uses underscore.
  // jquery needs to be loaded before using bootstrap
  // Bootstrap looks for "jQuery" in the environment.
  window._ = _;
  window.jQuery = $;
  const _isMacSafari = ( navigator && (navigator.userAgent.indexOf('Safari') != -1 &&
                         navigator.userAgent.indexOf('Chrome') == -1 &&
                         !navigator.userAgent.match(/iP(ad|hone)/i))) ? true : false;
  const adownload = ("download" in document.createElement("a"));
  const touchevents = (('ontouchstart' in window ) ||
                       ("DocumentTouch" in window &&
                        window.DocumentTouch &&
                        document instanceof window.DocumentTouch));
  const ASSETS_FOLDER = getAssetsFolderName();
  // Browser check 1 is performed in index.html via a script tag calling advancedBrowserSupportCheck.
  // Browser check 2
  const ERROR_URL = "/" + ASSETS_FOLDER + "/errors/incompatibleBrowser.html";
  define(["mw-browser-utils/BrowserCheck"], function(BrowserCheck) {
    BrowserCheck.load(
      ERROR_URL,
      require,
      function() {
        // Browser check 3
        define (["validation/advancedBrowserSupportCheck"], function() {
          let isSupported = require("validation/advancedBrowserSupportCheck");
          if (isSupported && typeof isSupported === 'function' && isSupported()) {
            // All browser checks passed.  Load application
            define ([
              "cloudCenterWebApp"
            ], function() {
              $(document).ready(function() {
                $('html').removeClass('no-js');
                $('html').addClass('js');
                if (touchevents) {
                    $('html').addClass("touchevents");
                  }
                  require([
                      "dojo/i18n!nls/cloudCenterStringResource",
                      "dojo/string",
                      "cloudCenterWebApp",
                      "config",
                      "service/cloudCenterDataService", 
                      "dao/cloudCenterDao",
                      "dao/cloudCenterFetchDao",
                      "dao/mockDao",
                      "util",
                  ], function (
                    I18NStringResource, 
                    DojoString, 
                    CloudCenterWebApp, 
                    GCONFIG, 
                    CloudCenterDataService, 
                    CloudCenterDAO, 
                    CloudCenterFetchDAO,
                    MockDAO,
                    Util
                  ) {

                    const _amdClosureArgs = {
                      "CloudCenterDAO": CloudCenterDAO,
                      "CloudCenterFetchDAO": CloudCenterFetchDAO,
                      "MockDAO": MockDAO
                    };
                  
                    // set i18n strings
                    const daoClassname = GCONFIG.getDAOClassName();
                    const daoClass = _amdClosureArgs[daoClassname];
                    const cloudCenterDao = new daoClass(GCONFIG);
                    const dataService = new CloudCenterDataService({dao: cloudCenterDao});
                    dataService.cloud.refreshCloudData().then().catch(error => Util.consoleLogError('Failed refreshing cloud data cache', error));
                    document.title = I18NStringResource.indexPageTitle;
                    const cloudCenterApp = new CloudCenterWebApp({
                        applicationOptions: {
                            appContainerId: 'cloudCenterApp',
                            authContainerId: 'loginContainer',
                            loginIframeId: 'loginIframe',
                            notificationAreaId: 'notificationArea',
                            loginTextContainerId: 'loginTextContainer',
                            loginFooterId: 'ccFooter',
                            loginLinkAreaId: 'loginLinkArea',
                            allowDnD: (!touchevents) ? true : false,
                            allowDownloads: ((adownload) || (navigator && (navigator.msSaveBlob || _isMacSafari))) ? true: false
                        }, config: GCONFIG });
                    cloudCenterApp.startApplication();
                    const mobileLogins = document.querySelectorAll('#header_mobile_login .navbar-header span.visually-hidden');
                    mobileLogins.forEach((mobileLogin) => { mobileLogin.textContent = I18NStringResource.footerPatents;});
                    const patentLinks = document.querySelectorAll('div#ccFooter ul.footernav li.footernav_patents > a');
                    patentLinks.forEach((patentLink) => { patentLink.textContent = I18NStringResource.footerPatents;});
                    const trademarkLinks = document.querySelectorAll('div#ccFooter ul.footernav li.footernav_trademarks > a');
                    trademarkLinks.forEach((tmLink) => {tmLink.textContent = I18NStringResource.footerTrademarks;});
                    const privacyLinks = document.querySelectorAll('div#ccFooter ul.footernav li.footernav_privacy > a');
                    privacyLinks.forEach((privacyLink) => { privacyLink.textContent = I18NStringResource.footerPrivacy;});
                    const piracyLinks = document.querySelectorAll('div#ccFooter ul.footernav li.footernav_piracy > a');
                    piracyLinks.forEach((piracyLink) => { piracyLink.textContent = I18NStringResource.footerPiracy;});
                    const cloudCenterLogos = document.querySelectorAll('.cloudCenterLogo');
                    cloudCenterLogos.forEach((logo) => {logo.textContent = I18NStringResource.cloudCenterLogoText;});
                    const cloudCenterLogins = document.querySelectorAll('.cloudCenterLogo.cloudCenterLogin');
                    cloudCenterLogins.forEach((login) => { login.textContent = I18NStringResource.cloudCenterLoginText;});
                    if (!touchevents) {
                      $('[data-bs-toggle="tooltip"]').tooltip();
                    }
                  });
              });
              let matrixScript = document.createElement('script');
              matrixScript.type = 'text/javascript';
              matrixScript.src = "/" + ASSETS_FOLDER + "/lib/matrix/main.js";
              document.body.appendChild(matrixScript);
              sessionStorage.removeItem("externalIPv4");

            });
          } else {
            window.location.replace(ERROR_URL);
          }
        });
      }
    );
  });
});
