/* Copyright 2019-2021 The MathWorks, Inc. */

define(['mw-widget-api/defineWidget',
    'mw-widget-api/WidgetBase',
    'mw-progress-indicator/view/ViewFactory',
    'mw-progress-indicator/enums/SizeEnum',
    'mw-progress-indicator/enums/TypeEnum',
    'mw-progress-indicator/util/ProgressIndicatorUtils'
], function (defineWidget, WidgetBase, ViewFactory, SizeEnum, TypeEnum, ProgressIndicatorUtils) {
    'use strict';
    const baseClass = 'mw-progress-indicator';
    const defaultColor = 'var(--mw-backgroundColor-info, #0095ff)';
    /**
     * Progress Indicator widget can be created in 3 ways:
     * <pre>
     *  1. Using the ProgressIndicator class
     *  2. Using createElement
     *  3. Using HTML
     *
     * </pre>
     * 1. Using ProgressIndicator class:
     * Progress Indicator can be created by passing in an object to set its
     * properties.
     *
     * NOTE: DO NOT use new operator while creating the Progress Indicator object
     * <pre>
     * config = {
     *    type : 'spinner',
     *     value : 0
     * }
     * let progressIndicator= ProgressIndicator(config);
     * document.body.appendChild(progressIndicator);
     * </pre>
     *
     * 2. Using createElement
     * Progress Indicator can also be created using createElement in the code
     * <pre>
     * let progressIndicator = document.createElement('mw-progress-indicator');
     * progressIndicator.size='xsmall';
     * document.body.appendChild(progressIndicator);
     * </pre>
     *
     * 3. Using HTML
     * Progress Indicator  can be added as a tag in the html
     *<pre>
     * &lt;mw-progress-indicator size=”small” &gt;&lt;/mw-progress-indicator&gt;
     *</pre>
     *
     *
     * @param {Object} config An object which can be used to configure the properties.
     * <pre>
     * config = {
     *   size: 'small',
     *   type: 'Spinner'
     * }
     * let progressIndicator= ProgressIndicator(config);
     * document.body.appendChild(progressIndicator);
     * </pre>
     *
     */

    class ProgressIndicator extends WidgetBase {
        static get properties () {
            return {
                value:
              { type: Number, reflect: true },
                size:
              { type: String, reflect: true },
                type:
              { type: String, reflect: true },
                color:
              { type: String, reflect: true },
                border:
              { type: String, reflect: true }
            };
        }

        constructor () {
            super();
            this.baseClass = baseClass;
            this.strokeDash = 0;
            this.strokeGap = 0;
            this.customSize = undefined;
            this.currentProgress = 0;
            this._view = null;
            this.strokeClass = 'mw-stroke-default-color';
        }

        /**
         * Set 'type' attribute on the Progress Indicator. This attribute take value:
         * <pre>
         * 1. Spinner
         * </pre>
         *
         * If the type is not defined, the default type will be Spinner.
         *
         * @type {String}
         * @default spinner
         * @example
         * config = {
         *    type : 'spinner',
         *     value : 0
         * }
         * let progressIndicator= ProgressIndicator(config);
         *
         */
        set type (value) {
            ProgressIndicatorUtils.validate(value, 'type');
            value = value.toLowerCase();
            this.setAttribute('type', value);
        }

        get type () {
            return this.getAttribute('type');
        }

        /**
         * Set 'size' attribute on the Progress Indicator. This attribute can take 5 values:
         * <pre>
         * 1. xsmall
         * 2. small
         * 3. medium
         * 4. large
         * 5. xlarge
         * </pre>
         *
         * 'size' attribute can also take an object to support custom size progress-indicator of any given size
         *  size can take an object with customSize property and its value should be number, the given number will create progress-indicator of given size in pixels,
         *  Note: customSize property in size API is not supported through HTML creation method
         *  @param {Number} size.customSize
         *
         * @example
         *
         * let progressIndicator= ProgressIndicator();
         * progressIndicator.size= {customSize:100};
         *
         * If the size is not defined, the default size will be small.
         *
         * @type {String || object}
         * @default small
         * @example
         *
         *
         * let progressIndicator= ProgressIndicator();
         * progressIndicator.size= medium;
         *
         *
         */
        set size (value) {
            // value will be null if size attribute is removed
            if (typeof value === 'object' && value !== null) {
                ProgressIndicatorUtils.validate(value.customSize, 'customSize');
                const styles = {
                    'width': value.customSize + 'px',
                    'height': value.customSize + 'px'
                };
                ProgressIndicatorUtils.addStyles(this, styles);
                this.customSize = value.customSize;
                this.removeAttribute('size');
            } else if (value !== null) {
                ProgressIndicatorUtils.validate(value, 'size');
                value = value.toLowerCase();
                // removing style will remove custom size and width
                ProgressIndicatorUtils.removeStyles(this, ['width', 'height']);
                this.customSize = undefined;
                this.setAttribute('size', value);
            }
            if (this._view) {
                this._view.update(this);
                this.requestUpdate();
            }
        }

        get size () {
            return this.getAttribute('size');
        }

        /**
     * Set 'border' attribute on the Progress Indicator. This attribute take value:
     * <pre>
     * 1. true
     * 2. false
     * </pre>
     *
     * If the border is not defined, the default type will be false.
     * When background-color of container is same as progress-indicator path color, it's hard to see the progress indicator.
     * having a border would helps in making progress indicator visible. This API would add border around progress-indicator.
     * @type {Boolean}
     * @default false
     * @example
     * config = {
      *    type : 'spinner',
      *     value : 0,
      *    border: true
      * }
      * let progressIndicator= ProgressIndicator(config);
      *
      */
        set border (value) {
            ProgressIndicatorUtils.validate(value, 'border');
            this.setAttribute('border', value);
            if (this._view) {
                this._view.update(this);
                this.requestUpdate();
            }
        }

        get border () {
            const value = this.getAttribute('border');
            return (value === 'false') ? false : (value === 'true');
        }

        /**
         * 'value' attribute of the Progress Indicator determines whether the progress indicator
         * is determinate or indeterminate.
         * If the value attribute is set, then the progress indicator will be determinate and the
         * value attribute holds the current progress value.
         * The value attribute has to be updated to show the progress.
         *
         * If the value is not defined, the progress indicator will be indeterminate
         *
         *
         * @type {number}
         * @example
         *
         *
         * config = {
         *     value : 0
         * }
         * let progressIndicator= ProgressIndicator(config);
         * progressIndicator.value = 10;
         */
        set value (newValue) {
            ProgressIndicatorUtils.validate(newValue, 'value');
            this.setAttribute('value', newValue);
            this.setAttribute('aria-valuenow', newValue);
            this.removeAttribute('indeterminate');
            if (this._view) {
                this._view.update(this);
                this.requestUpdate();
            }
        }

        get value () {
            let val = this.getAttribute('value');
            // value will be null in inDeterminate progress indicator
            return (val === null) ? null : parseFloat(val);
        }

        /**
     * Set 'color' attribute on the Progress Indicator. This attribute takes value:
     * <pre>
     * 1. any valid CSS color of 6 digit hex code or 3 digit hex code only like #ABC'.
     * </pre>
     * This attribute will change the indication color in progress-indicator.
     * error will be thrown on invalid color format or any other css color format.
     *
     * @type {String}
     * @example
     * config = {
     *    type : 'spinner',
     *     value : 0,
     *     color: '#BBB'
     * }
     * let progressIndicator= ProgressIndicator(config);
     *
     */
        set color (newValue) {
            ProgressIndicatorUtils.validate(newValue, 'color');
            this.setAttribute('color', newValue);
            this.strokeClass = 'mw-stroke-custom-color';
            if (this._view) {
                this._view.update(this);
                this.requestUpdate();
            }
        }

        get color () {
            let val = this.getAttribute('color');
            return (!val) ? defaultColor : val;
        }

        render () {
            this.type = (this.type) ? this.type : TypeEnum.SPINNER;
            this.size = (this.size) ? this.size : (this.customSize) ? { 'customSize': this.customSize } : SizeEnum.SMALL;
            this._view = ViewFactory.createView.call(this);
            this.currentProgress = this._view.getProgress();
            return this._view.getView();
        }

        shouldUpdate () {
            return true;
        }

        firstUpdated () {
            const attributeList = {
                'role': 'progressbar',
                'tabindex': 0
            };
            if (!this.hasAttribute('value')) {
                this.setAttribute('indeterminate', '');
            }
            ProgressIndicatorUtils.addAttributes(this, attributeList);
            this._view.update(this);
            this.requestUpdate();
        }
    }
    return defineWidget({
        name: 'mw-progress-indicator',
        widgetClass: ProgressIndicator
    });
});
