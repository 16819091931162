
/* Copyright 2019-2020 The MathWorks, Inc. */
define([
    'mw-widget-api/facade/svg',
    'mw-progress-indicator/view/ViewStore',
    'mw-progress-indicator/view/ViewInterface',
    'mw-progress-indicator/enums/SizeEnum',
    'mw-progress-indicator/enums/TypeEnum'

], function (svg, ViewStore, ViewInterface, SizeEnum, TypeEnum) {
    class SpinnerView extends ViewInterface {
        constructor (properties) {
            super();
            this.properties = properties;
        }

        getProgress () {
            super.getProgress();
            const sizeType = this.properties.customSize || this.properties.size;
            let size = 0;
            switch (sizeType) {
                case SizeEnum.XSMALL:
                    size = 16;
                    break;
                case SizeEnum.SMALL:
                    size = 24;
                    break;
                case SizeEnum.MEDIUM:
                    size = 32;
                    break;
                case SizeEnum.LARGE:
                    size = 48;
                    break;
                case SizeEnum.XLARGE:
                    size = 64;
                    break;
                default:
                    size = this.properties.customSize;
                    break;
            }
            // this will not change size to be 2px when given as in 1px but making size = 2 will help
            // in displaying the SVG for 1px, since SVG display is vector based and size = 1 return
            // few calculation in negative which in turn doesn't create SVG
            size = (size <= 1) ? 2 : size;

            this.outerRadius = size / 2;
            const strokeWidth = (this.outerRadius / 4) + 1;
            const halfStrokeWidth = (strokeWidth / 2);
            this.innerRadius = this.outerRadius - halfStrokeWidth;
            this.circleAttrs = {
                radius: this.innerRadius,
                strokeWidth: strokeWidth,
                cx: 0,
                cy: 0
            };
            this.innerRadius = this.outerRadius - strokeWidth;
            const currentProgress = 2 * Math.PI * this.circleAttrs.radius;
            return currentProgress;
        }

        update () {
            super.update();
            this.properties.currentProgress = this.getProgress();
            this.properties.strokeGap = this.properties.currentProgress;
            let isIndeterminate = this.properties.hasAttribute('indeterminate');
            const indeterminatePercentageCompletion = 25;
            this.properties.strokeDash = isIndeterminate ? (this.properties.strokeGap * indeterminatePercentageCompletion / 100)
                : ((this.properties.value * this.properties.currentProgress) / 100);
        }

        getView () {
            super.getView();
            return (this.properties.value < 100) ? svg`
      <svg class="progressIndicatorSvg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="${-(this.outerRadius)} ${-(this.outerRadius)} ${2 * (this.outerRadius)} ${2 * (this.outerRadius)}">
      <g>
      <circle class="${this.properties.baseClass}-gutter" r="${this.circleAttrs.radius}" cx="${this.circleAttrs.cx}" cy="${this.circleAttrs.cy}" stroke-width="${this.circleAttrs.strokeWidth}"></circle>
      <circle class="${this.properties.baseClass}  ${this.properties.strokeClass}" r="${this.circleAttrs.radius}" cx="${this.circleAttrs.cx}" cy="${this.circleAttrs.cy}" stroke-width="${this.circleAttrs.strokeWidth}"
              stroke-dasharray="${this.properties.strokeDash}, ${this.properties.strokeGap}" stroke="${this.properties.color}"></circle>
      <circle class="${this.properties.baseClass} ${this.properties.baseClass}-border " r="${this.circleAttrs.radius}" cx="${this.circleAttrs.cx}" cy="${this.circleAttrs.cy}" stroke-width="${this.circleAttrs.strokeWidth}"
              stroke-dasharray="${1}, ${this.properties.strokeDash - 1}, ${1}, ${this.properties.strokeGap - 1}"></circle>
      </g>
      ${this.properties.border
        ? svg`<g class="outer-group">
      <path d="M 0 ${-this.outerRadius} A ${this.outerRadius} ${this.outerRadius} 0 1 0 0 ${this.outerRadius} A ${this.outerRadius} ${this.outerRadius} 0 1 0 0 ${-this.outerRadius} Z M 0 ${-this.outerRadius + 1} A ${this.outerRadius - 1} ${this.outerRadius - 1} 0 1 1 0 ${this.outerRadius - 1} A ${this.outerRadius - 1} ${this.outerRadius - 1} 0 1 1 0 ${-this.outerRadius + 1} Z"></path>
      <path d="M 0 ${-this.innerRadius} A ${this.innerRadius} ${this.innerRadius} 0 1 0 0 ${this.innerRadius} A ${this.innerRadius} ${this.innerRadius} 0 1 0 0 ${-this.innerRadius} Z M 0 ${-this.innerRadius + 1} A ${this.innerRadius - 1} ${this.innerRadius - 1} 0 1 1 0 ${this.innerRadius - 1} A ${this.innerRadius - 1} ${this.innerRadius - 1} 0 1 1 0 ${-this.innerRadius + 1} Z"></path>
      </g>` : ``}
      </svg>`
                : svg`
      <svg class="progressIndicatorSvg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="${-(this.outerRadius)} ${-(this.outerRadius)} ${2 * (this.outerRadius)} ${2 * (this.outerRadius)}">
      <g>
      <circle class="${this.properties.baseClass}-gutter" r="${this.circleAttrs.radius}" cx="${this.circleAttrs.cx}" cy="${this.circleAttrs.cy}" stroke-width="${this.circleAttrs.strokeWidth}"></circle>
      <circle class="${this.properties.baseClass} ${this.properties.strokeClass}" r="${this.circleAttrs.radius}" cx="${this.circleAttrs.cx}" cy="${this.circleAttrs.cy}" stroke-width="${this.circleAttrs.strokeWidth}"
              stroke-dasharray="${this.properties.strokeDash}, ${this.properties.strokeGap}" stroke="${this.properties.color}"></circle>
      <circle class="${this.properties.baseClass} ${this.properties.baseClass}-border " r="${this.circleAttrs.radius}" cx="${this.circleAttrs.cx}" cy="${this.circleAttrs.cy}" stroke-width="${this.circleAttrs.strokeWidth}"
              stroke-dasharray="${0}, ${this.properties.strokeDash}, ${0}, ${this.properties.strokeGap}" ></circle>
      </g>
      ${this.properties.border
        ? svg`<g class="outer-group">
          <path d="M 0 ${-this.outerRadius} A ${this.outerRadius} ${this.outerRadius} 0 1 0 0 ${this.outerRadius} A ${this.outerRadius} ${this.outerRadius} 0 1 0 0 ${-this.outerRadius} Z M 0 ${-this.outerRadius + 1} A ${this.outerRadius - 1} ${this.outerRadius - 1} 0 1 1 0 ${this.outerRadius - 1} A ${this.outerRadius - 1} ${this.outerRadius - 1} 0 1 1 0 ${-this.outerRadius + 1} Z"></path>
          <path d="M 0 ${-this.innerRadius} A ${this.innerRadius} ${this.innerRadius} 0 1 0 0 ${this.innerRadius} A ${this.innerRadius} ${this.innerRadius} 0 1 0 0 ${-this.innerRadius} Z M 0 ${-this.innerRadius + 1} A ${this.innerRadius - 1} ${this.innerRadius - 1} 0 1 1 0 ${this.innerRadius - 1} A ${this.innerRadius - 1} ${this.innerRadius - 1} 0 1 1 0 ${-this.innerRadius + 1} Z"></path>
          </g>` : ``}
      </svg>`;
        }
    }

    ViewStore.setView(TypeEnum.SPINNER, SpinnerView);
    return SpinnerView;
});
