/* Copyright 2020 The MathWorks, Inc. */
define([
], function () {
    'use strict';
    const ViewCollection = {};

    class ViewStore {
        static getViewInstance () {
            const isValid = !!(this.type && ViewCollection[this.type] &&
            ViewCollection[this.type].call && ViewCollection[this.type].apply);

            return (isValid) ? new ViewCollection[this.type](this) : null;
        }

        static setView (type, viewFn) {
            if (type && viewFn) {
                ViewCollection[type] = viewFn;
            }
        }
    }

    return ViewStore;
});
