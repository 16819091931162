/* jshint esversion: 8 */
define([
    "lit-element/lit-element.bundled",
    "components/cloudCenterElement",
    "validation/inlineComponentValidation",
    "components/inlinePopover",
    "components/createNewSSLCertificateDialog",
    "dojo/i18n!nls/cloudCenterStringResource",
    "util",
    "dialogs/createNewItemDialog",
    "config",
    "service/cloudCenterDataService", 
    "dao/cloudCenterDao"
], function(
    Lit,
    CloudCenterElement,
    InlineValidation,
    InlinePopover,
    CreateNewSSLCertificateDialog,
    I18NStringResource,
    Util,
    CreateNewItemDialog,
    GCONFIG,
    CloudCenterDataService,
    CloudCenterDao
) {

    class SSLCertificateChooser extends CloudCenterElement {

        constructor () {
            super();
        }

        cceInitialize () {
            super.cceInitialize();
            Util.consoleLogTrace('SSLCertificateChooser`, `cceInitialize invoked');
            // Default values
            this.elementlabel = "";
            this.poptext = "";
            this.dduxenabled = false;
            this.sslselectid = "";
            this.sslselectlabel = "";
            this.sslselectpoptext = "";
            this.sslselectplaceholder = "";
            this.sslselectvalue = "";
            const cloudCenterDao = new CloudCenterDao(GCONFIG);
            this._dataService = new CloudCenterDataService({dao: cloudCenterDao});
            /* istanbul ignore next */
            this.getCredentialTypeId = () => "";
            /* istanbul ignore next */
            this.getLocation = () => "";
        }

        // Reactive Properties -- updates rendering when values change
        static get properties () {
            return {
                elementlabel: {type: String},
                poptext: {type: String},
                dduxenabled: {type: Boolean},
                sslselectid: {type: String},
                sslselectlabel: {type: String},
                sslselectpoptext: {type: String},
                sslselectplaceholder: {type: String},
                sslselectvalue: {type: String}
            };
        }

        _getDataService () {
            return this._dataService;
        }

        _onClick (event) {
            this._logClick(event);
            const createNewItemDialog = new CreateNewItemDialog({
                modalContainerId: 'modalContainer',
                customElementTag: CreateNewSSLCertificateDialog.customElementTag,
                createNewItemMethod: this._createSSLCertificate.bind(this)
            });
            createNewItemDialog.show();
        }

        async _createSSLCertificate (args) {
            Util.consoleLogTrace(`_createSSLCertificate`, `called`);
            if (!args || typeof args !== 'object') {
                throw new TypeError("Invalid args");
            }
            if (!args.organization || typeof args.organization !== 'string') {
                throw new TypeError("Invalid organization argument");
            }
            if (!args.country || typeof args.country !== 'string' || args.country.length !== 2) {
                throw new TypeError("Invalid country argument");
            }
            const organizationName = args.organization;
            const countryISOCode = args.country;
            const credId = this.getCredentialTypeId();
            const region = this.getRegion();
            if (organizationName && countryISOCode && credId && region) {
                let certificateData;
                try {
                    const awsDataService = this._getDataService().getPlatformDataService('aws');
                    certificateData = await awsDataService.getEC2Service().createSSLCertificate(credId, region, organizationName, countryISOCode);
                    const [isValid, arn, fileName, mimeType, fileLength, fileContent] = this._validateAndGetCertificateDataFields(certificateData);
                    if (isValid) {
                        this._updateARNList(arn);
                        this._downloadCertificate(mimeType, fileContent, fileName);
                    } else {
                        Util.consoleLogWarning('_createSSLCertificate', 'Invalid certificate data received from server');
                    }
                } catch (error) {
                    Util.consoleLogWarning('_createSSLCertificate', error);
                }
            }
        }

        _validateAndGetCertificateDataFields (certificateData) {
            let isValid = true;
            let arn, fileName, mimeType, fileLength, fileContent;
            if (certificateData) {
                arn = certificateData.certificate_arn;
                fileName = certificateData.filename;
                mimeType = certificateData.mime_type;
                fileLength = certificateData.file_content_length;
                try {
                    fileContent = atob(certificateData.file_content);
                } catch (error) {
                    Util.consoleLogWarning(`_getCertificateDataFields`, error);
                    fileContent = "";
                    isValid = false;
                }
                if (!arn || !fileName || !mimeType || !fileContent) {
                    isValid = false;
                }
            } else {
                isValid = false;
            }
            return [isValid, arn, fileName, mimeType, fileLength, fileContent];
        }

        _updateARNList (arn) {
            if (arn && typeof arn === 'string') {
                let uuid = Util.extractUUIDFromARN(arn);
                if (!uuid) {
                    uuid = arn;
                }
                const select = this.renderRoot.querySelector(`select#${this.sslselectid}`);
                if (select) {
                    const option = new Option(uuid, arn, true, true);
                    select.add(option);
                } else {
                    Util.consoleLogWarning(`_updateARNList`, `Unable to find select element with id ${this.sslselectid}`);
                }
            } else {
                Util.consoleLogWarning('_updateARNList', 'Invalid arn argument');
            }
        }

        _downloadCertificate (mimeType, fileContent, fileName) {
            if (!mimeType || typeof mimeType !== 'string') {
                throw new TypeError("Invalid mimeType argument");
            }
            if (!fileContent) {
                throw new TypeError("Invalid fileContent argument");
            }
            if (!fileName || typeof fileName !== 'string') {
                throw new TypeError("Invalid fileName argument");
            }
            const tmpLink = document.createElement('a');
            tmpLink.setAttribute('href', `data:${mimeType};charset=utf-8,` + encodeURIComponent(fileContent));
            tmpLink.setAttribute('download', fileName);
            tmpLink.style.display = 'none';
            document.body.appendChild(tmpLink);
            tmpLink.click();
            document.body.removeChild(tmpLink);
        }

        // Render element template
        render () {
            return Lit.html`
                <div class="labelContainer">
                    <label for="${'sslCertificateChooserFieldset' + this.timestamp}">${this.elementlabel}</label>
                    <span>(</span><button type="button" class="btn customElementCreateNewButton" @click=${this._onClick}>${I18NStringResource.cloudCenterElementCreateNew}</button><span class="buttonSpacer">)</span>
                </div>
                <fieldset class="sslCertificateChooserFieldset" id="${'sslCertificateChooserFieldset' + this.timestamp}">
                    <div class="sslCertificateInputContainer">
                        <div class="labelContainer" class="${this.getCSSClassFromLabelText(this.sslselectlabel) || Lit.nothing}">
                            <label for="${this.sslselectid}">${this.sslselectlabel}</label>
                        </div>
                        <div class="section2InputValidationContainer">
                            <select name="${this.sslselectid}" id="${this.sslselectid}" >
                                <option value="" disabled hidden ?selected=${!this.sslselectvalue}>${this.sslselectplaceholder}</option>
                            </select>
                            <inline-validation elementid="${this.sslselectid}" />
                        </div>
                        <inline-popover poptext="${this.sslselectpoptext}" />
                    </div>
                </fieldset>`;
        }
    }
    // Register custom element '<ssl-certificate />'
    customElements.define('ssl-certificate', SSLCertificateChooser);

    return SSLCertificateChooser;
});
