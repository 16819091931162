/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "backbone",
  "bootstrap",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "service/cloudCenterDataService",
  "util",
  "templates/pageNavHeader"
], function( _, $, Backbone, Bootstrap, I18NStringResource, DojoString, CloudCenterDataService, Util, PageNavigationHeader ) {

  var abstractMethod = function () { throw new Error("Abstract method"); };

  class AbstractPage extends Backbone.View {
    static InitProductInfo(){
      if (!AbstractPage.productMap) {
        AbstractPage.productMap = new Map();
      }
    }
    static GetProductInfo(product) {
      AbstractPage.InitProductInfo();
      return AbstractPage.productMap.get(product);
    }
    static SetProductInfo(product, enabled) {
      AbstractPage.InitProductInfo();
      if (!AbstractPage.GetProductInfo(product)) {
        AbstractPage.productMap.set(product, enabled);
      }
    }

    constructor(params) {
      super();
      this.navigationTemplate = PageNavigationHeader;
      if(this.constructor === AbstractPage) {
        throw new Error("Cannot instantiate abstract class.");
      } else {
        this.initializeParams(params);
      }
    }

    initializeParams(params) {
      if (!params || typeof params !== "object" || Object.keys(params).length === 0) {
        throw new TypeError("Invalid initialize argument");
      }
      if (!params.config || typeof params.config !== "object" || Object.keys(params.config).length === 0) {
        throw new TypeError("Invalid config argument in initialize params");
      }
      let config = params.config;
      if (!params.pageOptions || typeof params.pageOptions !== "object") {
        throw new TypeError("Invalid initialize argument: invalid pageOptions");
      }
      let options = params.pageOptions;
      if (!options.authManager || typeof options.authManager !== "object" || !options.authManager.getDAO || typeof options.authManager.getDAO !== "function") {
        throw new TypeError("Invalid authorization manager argument in pageOptions");
      }
      this.authMgr = options.authManager;
      this.pageName = options.pageName;
      this.originId = null;
      this.config = config;
      this.options = options;
      this.notificationMgr = null;
      this.matrixEnabled = (config.isMatrixEnabled && typeof config.isMatrixEnabled === 'function') ? config.isMatrixEnabled() : false;
      this.matrixHost = (config.getMatrixHost && typeof config.getMatrixHost === 'function') ? config.getMatrixHost() : "https://www-integ1.mathworks.com";
      this.matrixEnvironment = (config.getMatrixEnv && typeof config.getMatrixEnv === 'function') ? config.getMatrixEnv() : "integ1";
      this.dataService = new CloudCenterDataService({dao:this.authMgr.getDAO()});
      if (this.config.getLegacyParallelServicesURL && typeof this.config.getLegacyParallelServicesURL !== "function") {
        throw new TypeError("Invalid config.getLegacyParallelServicesURL argument");
      }
      this.legacyParallelServicesURL = this.config.getLegacyParallelServicesURL();
      if (this.config.getLegacyParallelURL && typeof this.config.getLegacyParallelURL !== "function") {
        throw new TypeError("Invalid config.getLegacyParallelURL argument");
      }
      this.legacyParallelURL = this.config.getLegacyParallelURL();
      if (this.config.getProxyURL && typeof this.config.getProxyURL !== "function") {
        throw new TypeError("Invalid config.getProxyURL argument");
      }
      this.proxyURL = this.config.getProxyURL();
    }

    getMatrixHost () { return this.matrixHost; }

    getMatrixEnvironment () { return this.matrixEnvironment; }

    getDataService () {
      return this.dataService;
    }
    setOriginId (originId) {
      this.originId = originId;
    }

    getOriginId () {
      return this.originId;
    }

    setAuthManager (authMgr) {
      this.authMgr = authMgr;
    }

    getAuthManager () {
      return this.authMgr;
    }

    getConfig () {
      return this.config;
    }

    getLegacyParallelURL () {
      return this.legacyParallelURL;
    }

    getLegacyParallelServicesURL () {
      return this.legacyParallelServicesURL;
    }

    setNotificationManager (mgr) {
      if (mgr && typeof mgr !== "object") {
        throw new TypeError("Invalid notification handler argument");
      }
      this.notificationMgr = mgr;
    }

    getNotificationManager () {
      return this.notificationMgr;
    }

    dragoverNotAllowed (event) {
      let className = event.target.className;
      if (className !== "computeContainer indicateDropZone") {
        event.preventDefault();
        event.originalEvent.dataTransfer.dropEffect = 'none';
      }
    }

    logEvent (event) {
      if (event && event.currentTarget) {
        // Register button click with DDUX if we have a button ID
        Util.logDDUXinfoFromClickEvent(event, this.getDataService().logging);
      }
    }

    processEvent (event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      this.logEvent(event);
    }

    /* istanbul ignore next */
    goToHome (event) {
      //goToHome is overridden on some pages
      if (event && event.preventDefault) { event.preventDefault(); }
      this.logEvent(event);
      $.event.trigger("changetoresourcepage:ccwa");
    }

    /* istanbul ignore next */
    goToResource (event, data) {
      if (event && event.preventDefault) { event.preventDefault(); }
      this.logEvent(event);
      $.event.trigger("changetoresourcepage:ccwa", data);
    }

    /* istanbul ignore next */
    goToCredential (event) {
      if (event && event.preventDefault) { event.preventDefault(); }
      this.logEvent(event);
      $.event.trigger("changetocredentialpage:ccwa");
    }

    /* istanbul ignore next */
    goToResourceDetail (event, data) {
      if (event && event.preventDefault) { event.preventDefault(); }
      this.logEvent(event);
      let path;
      if (data && typeof data === 'object' && data.path && typeof data.path === 'string') {
        path = data.path;
      } else {
        if (event && event.target && event.target.dataset && event.target.dataset.id) {
          if (event.target.dataset.resourcetype) {
            path = event.target.dataset.resourcetype + '/';
          }
          path += event.target.dataset.id;
        }
      }
      $.event.trigger("changetoresourcedetailpage:ccwa", {path: path});
    }

    /* istanbul ignore next */
    resetHistoryURL (view, data) {
      if (!(view && typeof view === 'string')) {
        throw new TypeError("Invalid view argument");
      }
      if (!(data && typeof data === 'object' && 'path' in data)) {
        throw new TypeError("Invalid data argument");
      }
      let params = {
        view: view,
        data: data
      };
      $.event.trigger(`resethistory${view}page:ccwa`, params);
    }

    logoutPage () {
      this.stopPage();
    }

    handleLogDDUXEvent (event, data) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      if (data && typeof data === "object" &&
          "elementId" in data && "elementType" in data &&
          data.elementId && typeof data.elementId === 'string' &&
          data.elementType && typeof data.elementType === 'string' &&
          (data.elementType === 'button' || data.elementType === 'link')) {
            this.getDataService().logging.logData({
              elementId: data.elementId,
              elementType: data.elementType,
              eventType: "clicked"
            });
      }
    }

    startPage () {
      this.stopPage(); // stop any previoius listeners before starting new listeners
      Util.hidePopovers();
      $(document).on("logdduxevent:ccwa", this.handleLogDDUXEvent.bind(this));
      $(document).on("keydown", this.onKeydown.bind(this));
    }

    stopPage () {
      $(document).off("logdduxevent:ccwa");
      $(document).off("keydown");
      this.getDataService().abortOutstandingRequests();
    }

    renderNavigationHeader () {
      let loginData = this.getAuthManager().getLoginData();
      let userName = "";
      if (loginData && loginData.firstName) {
        userName = `${loginData.firstName} ${loginData.lastName}`;
      }
      let templateParameters = {
        cloudCenterLogoText: I18NStringResource.cloudCenterLogoText,
        toggleNavigationLabel: I18NStringResource.pageToggleNavigation,
        aboutLabel: I18NStringResource.pageAboutLabel,
        managementLabel: I18NStringResource.pageManagementLabel,
        logoutLabel: I18NStringResource.pageLogoutLabel,
        helpLabel: I18NStringResource.pageHelpLabel,
        /* istanbul ignore next */
        myAccountOrNameLabel: (userName ? userName : I18NStringResource.myAccountLabel),
        myAccountLabel: I18NStringResource.myAccountLabel,
        logoButtonClasses: `btn btn-link default-link ${this.pageName}`
      };
      let headerNavHTML = this.navigationTemplate(templateParameters);
      return headerNavHTML;
    }

    /* istanbul ignore next */
    renderMatrix () {
      if (this.matrixEnabled) {
        if (window.matrix && typeof matrix === 'object' &&
            "show" in matrix && typeof matrix.show === 'function') {
          matrix.show({
            largeId: "headernav_matrix",
            smallId: ["matrix_affordance_container_mobile", "matrix_menu_container_mobile"],
            env: this.getMatrixEnvironment(),
            tier: "PRODUCT",
            lang: navigator.language,
            host: this.getMatrixHost()
          });

          // replace Bootstrap 3 classes and attributes and replace with Bootstrap 5
          const mobileMatrixButton = document.querySelector('button#matrix_waffle_small');
          mobileMatrixButton.setAttribute('data-bs-target', '#matrix_collapse');
          mobileMatrixButton.removeAttribute('data-target');
          mobileMatrixButton.setAttribute('data-bs-toggle', 'collapse');
          mobileMatrixButton.removeAttribute('data-toggle');
          mobileMatrixButton.classList.add('navbar-toggler');
          mobileMatrixButton.classList.remove('navbar-toggle');

          const matrixCollapse = document.querySelector('#matrix_collapse');
          matrixCollapse.addEventListener('show.bs.collapse', function (event) {
            const topnavCollapse = document.querySelector('#topnav_collapse');
            if (topnavCollapse.classList.contains('show')) {
              const collapse = Bootstrap.Collapse.getOrCreateInstance(topnavCollapse);
              collapse.toggle();
            }
          });

          const topnavCollapse = document.querySelector('#topnav_collapse');
          topnavCollapse.addEventListener('show.bs.collapse', function (event) {
            const matrixCollapse = document.querySelector('#matrix_collapse');
            if (matrixCollapse.classList.contains('show')) {
              const collapse = Bootstrap.Collapse.getOrCreateInstance(matrixCollapse);
              collapse.toggle();
            }
          });
        }
      }
    }

    render () { abstractMethod(); }

    toggleViewPassword (event) {
      this.logEvent(event);
      Util.toggleViewPassword(event);
    }

    onKeydown (event) {
      const code = event.keyCode || event.which;
      if (code == 13) { // Enter key
        event.preventDefault();
        let focusedElement = document.activeElement;
        if (focusedElement.tagName.toUpperCase() === "BUTTON"
            || focusedElement.tagName.toUpperCase() === "A"
        ) {
          focusedElement.click();
        }
      }
      if (code == 27) {
        event.preventDefault();
        Util.hidePopovers();
      }
    }

  }

  return AbstractPage;
}); // require
