/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "pages/abstractPage",
  "templates/credentialsAWSCreateRolePage",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "util"
], function( _, $, AbstractPage, PageTemplate, I18NStringResource, DojoString, Util ) {

  /**
   * This subclass of MatlabDriveApplication uses
   * Mathworks FileBrowser2 widget for the tree controller.
   */
  class CredentialsAWSCreateRolePage extends AbstractPage {

    constructor(params) {
      super(params);
      this.pageTemplate = PageTemplate;
      this.events = {
        "click div#awsCreateRoleContainerPageView > div.facetBar > ul.facets > li > a#resourceLink" : "goToResource",
        "click div#awsCreateRoleContainerPageView > div.facetBar > ul.facets > li > a#credentialLink" : "goToCredential",
        "click div.awsCreateRoleContainer form div.dialogFoot > button#closeCreateDialog" : "goToCredential",
        "dragover div.awsCreateRoleContainer": "dragoverNotAllowed",
        "keyup #access_key": "validate",
        "keyup #secret_access_key" : "validate",
        "keyup #session_token" : "validate",
        "change #access_key": "validate",
        "change #secret_access_key" : "validate",
        "change #session_token" : "validate",
        "click #addAwsCredsButton" : "createRoleARN",
        "click button.btn.btn-link.default-link.CredentialsAWSCreateRolePage": "goToHome"
      };

      // bind 'this' in the listed methods to the current 'this',
      // even when called by other modules.
      _.bindAll(this, "logoutPage", "getAuthManager", "goToResource", "goToCredential", "validate", "createRoleARN");
    }

    goToCredentialsAWSCreateRolePage (event) { /* do nothing */ }

    startPage (msgHandler) {
      super.startPage();
      if (!msgHandler || typeof msgHandler !== "function") {
        throw new TypeError("Invalid message handler argument");
      }
    }

    stopPage () {
      super.stopPage();
    }

    async render () {
      try{
        this.renderPage();
      } catch(error) {
        Util.consoleLogError("render", error);
        this.goToCredential();
      }

    }

    renderPage () {
      const touchevents = Util.touchEventsEnabled();
      let htmlResult = this.pageTemplate({
        headerNavigation: this.renderNavigationHeader(),
        computeLinkText: I18NStringResource.computeLinkText,
        credentialLinkText: I18NStringResource.credentialLinkText,
        credentialsResourcesLabel: I18NStringResource.credentialsPageCredentialsResourcesLabel,
        defaultCredentialsResourcesMsg: I18NStringResource.credentialsPageDefaultCredentialsResourcesMsg,
        createCredential: I18NStringResource.credentialsPageCreateCredentialButtonText,

        addCredsTitle: I18NStringResource.AWSCreateRole_addCredsTitle,
        requiredPermissions: I18NStringResource.AWSCreateRole_requiredPermissions,
        awsAccessKey: I18NStringResource.AWSCreateRole_awsAccessKey,
        awsSecretAccessKey: I18NStringResource.AWSCreateRole_awsSecretAccessKey,
        awsSessionToken: I18NStringResource.AWSCreateRole_awsSessionToken,
        awsSessionTokenOptional: I18NStringResource.AWSCreateRole_awsSessionTokenOptional,
        awsIAMRequirement1: I18NStringResource.AWSCreateRole_awsIAMRequirement1,
        awsIAMRequirement2: I18NStringResource.AWSCreateRole_awsIAMRequirement2,
        awsIAMRequirement3: I18NStringResource.AWSCreateRole_awsIAMRequirement3,
        awsIAMRequirement4: I18NStringResource.AWSCreateRole_awsIAMRequirement4,
        storageNote: I18NStringResource.AWSCreateRole_storageNote,
        storageNoteBLOT: I18NStringResource.AWSCreateRole_storageNoteBLOT,
        singleSignOnNote1: I18NStringResource.AWSCreateRole_singleSignOnNote1,
        singleSignOnNote2: I18NStringResource.AWSCreateRole_singleSignOnNote2,
        singleSignOnNote3: I18NStringResource.AWSCreateRole_singleSignOnNote3,
        singleSignOnNote4: I18NStringResource.AWSCreateRole_singleSignOnNote4,
        singleSignOnNote5: I18NStringResource.AWSCreateRole_singleSignOnNote5,
        nextBtnText: I18NStringResource.AWSCreateRole_nextBtnText,
        cancelButtonText: I18NStringResource.AWSCreateRole_cancelBtnText

      });
      this.$el.empty().html(htmlResult);
      if (!touchevents) {
        $('[data-bs-toggle="tooltip"]').tooltip();
      }
      this.renderMatrix();
    }

    validate (event) {
      if (event && event.preventDefault) { event.preventDefault(); }
      let accessKeyId = "";
      let accessKeyInput = this.el.querySelector('#access_key');
      if (accessKeyInput && accessKeyInput.value) {
        accessKeyId = accessKeyInput.value.trim();
      }
      let secretAccessKey = "";
      let secretAccessKeyInput = this.el.querySelector("#secret_access_key");
      if (secretAccessKeyInput && secretAccessKeyInput.value) {
        secretAccessKey = secretAccessKeyInput.value.trim();
      }
      let sessionToken = "";
      let sessionTokenInput = this.el.querySelector("#session_token");
      if (sessionTokenInput && sessionTokenInput.value) {
        sessionToken = sessionTokenInput.value.trim();
      }
      let validAccessKeyId = /^[A-Z0-9]{20}(?![A-Z0-9])$/.test(accessKeyId);
      let validSecretAccessKey = /^[A-Za-z0-9/+=]{40}(?![A-Za-z0-9/+=])$/.test(secretAccessKey);
      let validSessionToken = true;
      if (sessionToken) {
        validSessionToken = /^[A-Za-z0-9/+=]{1,1000}(?![A-Za-z0-9/+=])$/.test(sessionToken);
      }
      let success = (validAccessKeyId && validSecretAccessKey && validSessionToken);
      let button = this.el.querySelector("button#addAwsCredsButton");
      if (button) {
        button.disabled = false;
      }
      return {accessKeyId:accessKeyId, secretAccessKey:secretAccessKey, sessionToken:sessionToken, success:success};
    }

    async createRoleARN (event) {
      let clickedButton;
      if (event && event.preventDefault) { event.preventDefault(); }
      if (event && event.currentTarget) {
        Util.logDDUXinfoFromClickEvent(event, this.getDataService().logging);
        if (event.currentTarget.tagName && event.currentTarget.tagName.toUpperCase() === 'BUTTON') {
          clickedButton = event.currentTarget;
        }
      }
      if (!clickedButton) {
        clickedButton = document.getElementById("addAwsCredsButton");
      }
      if (clickedButton) {
        if (clickedButton.disabled) {
          return;
        }
        Util.disableButton(clickedButton);
        let info = this.validate();
        if (!info.success) {
          Util.notify("ERROR", I18NStringResource.AWSCreateRole_invalidTokens);
          Util.enableButton(clickedButton);
          return;
        }
        try {
          const createCredArgs = {
            credentialId: this.urlArgs.path,
            accessKeyId: info.accessKeyId,
            secretAccessKey: info.secretAccessKey,
            sessionToken: info.sessionToken
          };
          await this.getDataService().ui.createCredential('aws', createCredArgs);
          this.goToCredential();
        } catch(error) {
          Util.displayAPIResponseError(error, I18NStringResource.AWSCreateRole_errorCreatingRole);
          Util.consoleLogError("createRoleARN", JSON.stringify(error));
        } finally {
          Util.enableButton(clickedButton);
        }
      }
      return false;
    }
  }
  return CredentialsAWSCreateRolePage;
}); // require
