/* jshint esversion: 8 */
define([
    "underscore",
    "lit-element/lit-element.bundled",
    "components/cloudCenterElement",
    "util",
    "dojo/i18n!nls/cloudCenterStringResource",
    "validation/inlineComponentValidation",
    "components/inlinePopover"
], function(
    _,
    Lit,
    CloudCenterElement,
    Util,
    I18NStringResource,
    InlineComponentValidation,
    InlinePopover
) {

    class NetworkAccess extends CloudCenterElement {

        constructor () {
            super();
        }

        cceInitialize () {
            super.cceInitialize();
            Util.consoleLogTrace('NetworkAccess`, `cceInitialize invoked');
            // Default values
            this.elementid = "";
            this.elementlabel = " ";
            this.poptext = "";
            this.dduxenabled = false;
            this.networkpickerid = "";
            this.networkpickerlabel = "";
            this.networkpickerpoptext = "";
            this.networkpickerplaceholder = "";
            this.networkpickervalue = "";
            this.vpcid = "";
            this.vpclabel = "";
            this.vpcpoptext = "";
            this.vpcplaceholder = "";
            this.vpcvalue = "";
            this.subnetid = "";
            this.subnetlabel = "";
            this.subnetpoptext = "";
            this.subnetplaceholder = "";
            this.subnetvalue = "";
            // setup debounced event handler so it only gets called once per flurry of same events
            // Both vpc and subnet selects will receive "optionsLoaded" events. This tries to
            // update the UI once after the last event (assuming they all come in within a second).
            this._debouncedOptionsLoaded = _.debounce(this._onOptionsLoaded, 1000).bind(this);
        }

        // Reactive Properties -- updates rendering when values change
        static get properties () {
            return {
                elementid: {type: String},
                elementlabel: {type: String},
                poptext: {type: String},
                dduxenabled: {type: Boolean},
                networkpickerid: {type: String},
                networkpickerlabel: {type: String},
                networkpickerpoptext: {type: String},
                networkpickerplaceholder: {type: String},
                networkpickervalue: {type: String},
                vpcid: {type: String},
                vpclabel: {type: String},
                vpcpoptext: {type: String},
                vpcplaceholder: {type: String},
                vpcvalue: {type: String},
                subnetid: {type: String},
                subnetlabel: {type: String},
                subnetpoptext: {type: String},
                subnetplaceholder: {type: String},
                subnetvalue: {type: String}
            };
        }

        // Called after initial rendering
        firstUpdated () {
            Util.consoleLogTrace(`NetworkAccess.firstUpdated`, `invoked`);
        }

        validate (testTargetId, testTargetTag) {
            Util.consoleLogTrace(`NetworkAccess.validate`, `invoked with ${testTargetTag}#${testTargetId}`);
            let isValid = true;
            const choice = this.renderRoot.querySelector('input[name="netInfoChoice"]:checked').value;
            const existingNetworkPicker = this.renderRoot.querySelector(`select#${this.networkpickerid}`);
            const vpcPicker = this.renderRoot.querySelector(`select#${this.vpcid}`);
            const subnetPicker = this.renderRoot.querySelector(`select#${this.subnetid}`);
            if (choice === "sameNetwork") {
                if (!existingNetworkPicker.value || !this.getElementValidator(testTargetId)()) {
                    if (testTargetId === existingNetworkPicker.id) {
                        isValid = false;
                    }
                }
                if (isValid) {
                    if ((!vpcPicker.value || !this.getElementValidator(this.vpcid)()) || (!subnetPicker.value || !this.getElementValidator(this.subnetid)())) {
                        isValid = false;
                    }
                }
            } else {
                if (!vpcPicker.value || !subnetPicker.value) {
                    if (testTargetId === vpcPicker.id && (!vpcPicker.value || !this.getElementValidator(testTargetId)())) {
                        isValid = false;
                    }
                    if (testTargetId === subnetPicker.id && (!subnetPicker.value || !this.getElementValidator(testTargetId)())) {
                        isValid = false;
                    }
                }
            }
            return isValid;
        }

        _onChangeHandler (event) {
            const existingNetworkPicker = this.renderRoot.querySelector(`select#${this.networkpickerid}`);
            const vpcPicker = this.renderRoot.querySelector(`select#${this.vpcid}`);
            const subnetPicker = this.renderRoot.querySelector(`select#${this.subnetid}`);
            const selectedIndex = existingNetworkPicker.selectedIndex;
            vpcPicker.selectedIndex = selectedIndex;
            subnetPicker.selectedIndex = selectedIndex;
        }

        _onOptionsLoaded (event) { 
            Util.consoleLogTrace(`_onOptionsLoaded`, `calling _updateUI`); 
            this._updateUI(); 
        }

        _updateUI (event) {
            const choice = this.renderRoot.querySelector('input[name="netInfoChoice"]:checked').value;
            const existingNetworkPicker = this.renderRoot.querySelector(`select#${this.networkpickerid}`);
            const vpcPicker = this.renderRoot.querySelector(`select#${this.vpcid}`);
            const subnetPicker = this.renderRoot.querySelector(`select#${this.subnetid}`);
            if (choice === "sameNetwork") {
                existingNetworkPicker.disabled = false;
                existingNetworkPicker.classList.remove('disabled');
                existingNetworkPicker.value = "";
                // Cause the inline validation to clear/hide
                vpcPicker.dispatchEvent(new Event("focus"));
                vpcPicker.value = "";
                vpcPicker.disabled = true;
                vpcPicker.classList.add('disabled');
                // Cause the inline validation to clear/hide
                subnetPicker.dispatchEvent(new Event("focus"));
                subnetPicker.value = "";
                subnetPicker.disabled = true;
                subnetPicker.classList.add('disabled');
                existingNetworkPicker.dispatchEvent(new Event("focus"));
            } else {
                // Cause the inline validation to clear/hide
                existingNetworkPicker.dispatchEvent(new Event("focus"));
                existingNetworkPicker.value = "";
                existingNetworkPicker.disabled = true;
                existingNetworkPicker.classList.add('disabled');
                vpcPicker.disabled = false;
                vpcPicker.classList.remove('disabled');
                subnetPicker.disabled = false;
                subnetPicker.classList.remove('disabled');
                vpcPicker.dispatchEvent(new Event("focus"));
            }
        }

        // Render element template
        render () {
            return Lit.html`
                <div class="labelContainer">
                    <label for="${'networkAccessFieldset' + this.timestamp}">${this.elementlabel}</label>
                </div>
                <fieldset class="networkAccessFieldset" id="${'networkAccessFieldset' + this.timestamp}">
                    <div class="radioButtonContainer">
                        <input type="radio" name="netInfoChoice" @change=${this._updateUI} @click=${this._logClick} value="sameNetwork" id="${'sameNetwork' + this.timestamp}" checked />
                        <label for="${'sameNetwork' + this.timestamp}">${I18NStringResource.networkAccessRunInSameNetworkRadioLabel}</label>
                        <div class="networkAccessInputContainer">
                            <div class="labelContainer" class="${this.getCSSClassFromLabelText(this.networkpickerlabel) || Lit.nothing}">
                                <label for="${this.networkpickerid}">${this.networkpickerlabel}</label>
                            </div>
                            <div class="section2InputValidationContainer">
                                <select name="${this.networkpickerid}" id="${this.networkpickerid}" @change=${this._onChangeHandler} >
                                    <option value="" disabled hidden selected>${this.networkpickerplaceholder}</option>
                                    <option value="network1">Net1</option>
                                    <option value="network2">Net2</option>
                                    <option value="network3">Net3</option>
                                    <option value="network4">Net4</option>
                                </select>
                                <inline-validation elementid="${this.networkpickerid}" />
                            </div>
                            <inline-popover poptext="${this.networkpickerpoptext}" />
                        </div>
                    </div>
                    <div class="radioButtonContainer">
                        <input type="radio" name="netInfoChoice" @change=${this._updateUI} @click=${this._logClick} value="specifyManually" id="${'specifyManually' + this.timestamp}" />
                        <label for="${'specifyManually' + this.timestamp}">${I18NStringResource.networkAccessSpecifyManuallyRadioLabel}</label>
                        <div class="networkAccessInputContainer">
                            <div class="labelContainer">
                                <label for="${this.vpcid}">${this.vpclabel}</label>
                            </div>
                            <div class="section2InputValidationContainer">
                                <select name="${this.vpcid}" id="${this.vpcid}" class="disabled" disabled>
                                    <option value="" disabled hidden selected>${this.vpcplaceholder}</option>
                                </select>
                                <inline-validation elementid="${this.vpcid}" />
                            </div>
                            <inline-popover poptext="${this.vpcpoptext}" />
                        </div>
                        <div class="networkAccessInputContainer">
                            <div class="labelContainer">
                                <label for="${this.subnetid}">${this.subnetlabel}</label>
                            </div>
                            <div class="section2InputValidationContainer">
                                <select name="${this.subnetid}" id="${this.subnetid}" class="disabled" disabled @optionsLoaded=${{handleEvent: () => this._debouncedOptionsLoaded(), once: true}}>
                                    <option value="" disabled hidden selected>${this.subnetplaceholder}</option>
                                </select>
                                <inline-validation elementid="${this.subnetid}" />
                            </div>
                            <inline-popover poptext="${this.subnetpoptext}" />
                        </div>
                    </div>
                </fieldset>`;
        }
    }
    // Register custom element '<network-access />'
    customElements.define('network-access', NetworkAccess);

    return NetworkAccess;
});
