/* jshint esversion: 8 */
/*
 * Inline Validation
*/
define([
    "util",
    "dojo/i18n!nls/cloudCenterStringResource"
], function(
    Util,
    I18NStringResource
) {
    class InlineElementValidation {

      constructor (args) {
        if (!this.argsAreValid(args)) { throw new TypeError("Invalid args"); }
        this.eventMap = args.eventMap;
        this.onValidFieldHandler = args.onValidFieldHandler;
        this.onInvalidFieldHandler = args.onInvalidFieldHandler;
      }

      argsAreValid (args) {
        let isValid = true;
        if (!args || typeof args !== 'object') { isValid = false; }
        if (isValid && (!args.eventMap || typeof args.eventMap !== 'object')) { isValid = false; }
        if (isValid && (!args.onValidFieldHandler || typeof args.onValidFieldHandler !== 'function')) { isValid = false; }
        if (isValid && (!args.onInvalidFieldHandler || typeof args.onInvalidFieldHandler !== 'function')) { isValid = false; }
        return isValid;
      }

      setupInlineInputValidation (elementInfo, inputContainer, customElementValidator) {
        if (elementInfo && elementInfo.validation && inputContainer) {
          //
          // create HTML elements
          //
          if (!elementInfo.isCustomElement) {
            this.createInlineValidationElements(elementInfo, inputContainer);
          }
  
          //
          // Setup validation event handling
          //
          this.createValidationEventMapEntry(elementInfo, customElementValidator, undefined);
        }
      }
    
      createInlineValidationElements (elementInfo, inputContainer) {
        if (elementInfo && inputContainer) {
          const inlineValidation = document.createElement('inline-validation');
          inlineValidation.setAttribute("elementid", elementInfo.id);
          inputContainer.appendChild(inlineValidation);
        }
      }
    
      createRegexValidator (elementInfo, inputSelector) {
        const validator = function (showError) {
          let isValid = true;
          let selector = inputSelector;
          let input = document.querySelector(selector);
          if (!input) {
            throw new Error(`Element not found: ${selector}`);
          }
          let value = input.value;
          let regexStr = elementInfo.validation.regex;
          let regExp = new RegExp(regexStr);
          isValid = regExp.test(value);
          if (showError && !isValid) {
            let section2InputValidationContainer = input.parentElement;
            while (section2InputValidationContainer && !section2InputValidationContainer.classList.contains("section2InputValidationContainer")) {
              section2InputValidationContainer = section2InputValidationContainer.parentElement;
            }
            if (!section2InputValidationContainer) {
              throw new Error(`Element not found: parentElement of ${selector}`);
            }
            let errorMsg = elementInfo.validation.errorMessage ? elementInfo.validation.errorMessage: I18NStringResource.cmlWizardStep2InvalidItem;
            Util.displayValidationError(section2InputValidationContainer, errorMsg, true);
          }
          return isValid;
        };
        return validator;
      }
    
      createMatchValueWithValidator (elementInfo, inputSelector) {
        const validator = function (showError) {
          let isValid = true;
          let selector = inputSelector;
          let input = document.querySelector(selector);
          if (!input) {
            throw new Error(`Element not found: ${selector}`);
          }
          let value = input.value;
          let idToMatch = elementInfo.validation.matchValueWith;
          let otherInput = document.querySelector('input#' + idToMatch);
          if (!otherInput) {
            throw new Error(`Element not found: input#${idToMatch}`);
          }
          let otherValue = otherInput.value;
          isValid = (value == otherValue);
          if (showError && !isValid) {
            let section2InputValidationContainer = input.parentElement;
            while (section2InputValidationContainer && !section2InputValidationContainer.classList.contains("section2InputValidationContainer")) {
              section2InputValidationContainer = section2InputValidationContainer.parentElement;
            }
            if (!section2InputValidationContainer) {
              throw new Error(`Element not found: parentElement of ${selector}`);
            }
            let errorMsg = elementInfo.validation.errorMessage ? elementInfo.validation.errorMessage: I18NStringResource.cmlWizardStep2InvalidItem;
            Util.displayValidationError(section2InputValidationContainer, errorMsg, true);
          }
          return isValid;
        };
  
        return validator;
      }
    
      createRangeValidator (elementInfo, inputSelector) {
        const validator = function (showError) {
          let isValid = true;
          let selector = inputSelector;
          let input = document.querySelector(selector);
          if (!input) {
            throw new Error(`Element not found: ${selector}`);
          }
          let value = (isNaN(input.value) || !Number.isInteger(Number.parseFloat(input.value))) ? NaN : parseInt(input.value);
          isValid = !isNaN(value);
          if (isValid) {
            let min = (elementInfo.validation.range.min && !isNaN(elementInfo.validation.range.min)) ? parseInt(elementInfo.validation.range.min) : NaN;
            isValid = (isNaN(min) || value >= min);
          }
          if (isValid) {
            let max = (elementInfo.validation.range.max && !isNaN(elementInfo.validation.range.max)) ? parseInt(elementInfo.validation.range.max) : NaN;
            isValid = (isNaN(max) || value <= max);
          }
          if (showError && !isValid) {
            let section2InputValidationContainer = input.parentElement;
            while (section2InputValidationContainer && !section2InputValidationContainer.classList.contains("section2InputValidationContainer")) {
              section2InputValidationContainer = section2InputValidationContainer.parentElement;
            }
            if (!section2InputValidationContainer) {
              throw new Error(`Element not found: parentElement of ${selector}`);
            }
            let errorMsg = elementInfo.validation.errorMessage ? elementInfo.validation.errorMessage: I18NStringResource.cmlWizardStep2InvalidItem;
            Util.displayValidationError(section2InputValidationContainer, errorMsg, true);
          }
          return isValid;
        };
        return validator;
      }
    
      createCustomElementValidatorTest (elementInfo, inputSelector, customElementValidator) {
        if (!elementInfo || typeof elementInfo !== 'object') {
          throw new TypeError("Invalid elementInfo argument");
        }
        if (inputSelector && typeof inputSelector !== 'string') {
          throw new TypeError("Invalid inputSelector argument");
        }
        if (!customElementValidator || typeof customElementValidator !== 'function') {
          throw new TypeError("Invalid customElementValidator argument");
        }
        const validator = function(showError) {
          const testTargetId = elementInfo.id;
          const testTargetTag = elementInfo.elementType;
          let isValid = true;
          let selector = elementInfo.isCustomElement ? `${elementInfo.customElementInfo.type}#${elementInfo.id}` : inputSelector;
          let input = document.querySelector(selector);
          if (!input) {
            throw new Error(`Element not found: ${selector}`);
          }
          isValid = !!customElementValidator(testTargetId, testTargetTag);
          if (showError && !isValid) {
            let section2InputValidationContainer = input.parentElement;
            while (section2InputValidationContainer && !section2InputValidationContainer.classList.contains("section2InputValidationContainer")) {
              section2InputValidationContainer = section2InputValidationContainer.parentElement;
            }
            if (!section2InputValidationContainer) {
              throw new Error(`Element not found: parentElement of ${selector}`);
            }
            let errorMsg = elementInfo.validation.errorMessage ? elementInfo.validation.errorMessage: I18NStringResource.cmlWizardStep2InvalidItem;
            Util.displayValidationError(section2InputValidationContainer, errorMsg, true);
          }
          return isValid;
        };
        return validator;
      }
    
      createOnBlurValidator (elementInfo, data) {
        const validator = function (event) {
          let isValid = data.test(true);
          let section2InputValidationContainer = event.target.parentElement;
          while (section2InputValidationContainer && !section2InputValidationContainer.classList.contains("section2InputValidationContainer")) {
            section2InputValidationContainer = section2InputValidationContainer.parentElement;
          }
          if (!section2InputValidationContainer) {
            throw new Error(`Element not found: parentElement`);
          }
          let errorMsg = elementInfo.validation.errorMessage ? elementInfo.validation.errorMessage: I18NStringResource.cmlWizardStep2InvalidItem;
          if (!isValid) {
            Util.displayValidationError(section2InputValidationContainer, errorMsg, true);
          }
          return isValid;
        };
        return validator;
      }
    
      createOnFocusValidationCleanupMethod () {
        const validator = function (event) {
          let section2InputValidationContainer = event.target.parentElement;
          while (section2InputValidationContainer && !section2InputValidationContainer.classList.contains("section2InputValidationContainer")) {
            section2InputValidationContainer = section2InputValidationContainer.parentElement;
          }
          if (!section2InputValidationContainer) {
            throw new Error(`Element not found: parentElement`);
          }
          Util.hideValidationError(section2InputValidationContainer);
          this.onValidFieldHandler();
          return true;
        }.bind(this);
        return validator;
      }
    
      createValidationTester (data) {
        if (!data || typeof data !== 'object') {
            throw new TypeError('Invalid arguments');
        }
        const tester = function (showErrors){
          let isValid = true;
          try {
            data.tests.forEach(validation => isValid = isValid && validation(showErrors));
          } catch (error) {
            Util.consoleLogError('createValidationTester', error);
            isValid = false;
          }
          if (isValid) {
            this.onValidFieldHandler();
          } else {
            this.onInvalidFieldHandler();
          }
          return isValid
        }.bind(this);
        return tester;
      }
    
      setupCustomElementValidation (elementInfo, inputSelector, data, customElement, customElementValidator) {
        if ((elementInfo.isCustomElement || elementInfo.isCustomSubElement) && typeof customElementValidator === 'function') {
          data.tests.push(this.createCustomElementValidatorTest(elementInfo, inputSelector, customElementValidator).bind(this));
          if (elementInfo.isCustomSubElement && typeof customElement === 'object') {
            if (elementInfo.validation.regex) {
              // make validation test function
              customElement.appendElementValidationTest(elementInfo.id, this.createRegexValidator(elementInfo, inputSelector).bind(this));
            }
            if (elementInfo.validation.matchValueWith) {
              customElement.appendElementValidationTest(elementInfo.id, this.createMatchValueWithValidator(elementInfo, inputSelector).bind(this));
            }
            if ((elementInfo.validation.range && typeof elementInfo.validation.range === 'object') &&
                        (elementInfo.validation.range.min && !isNaN(elementInfo.validation.range.min))) {
              customElement.appendElementValidationTest(elementInfo.id, this.createRangeValidator(elementInfo, inputSelector).bind(this));
            }
          }
        }
      }
    
      setupStandardElementValidation (elementInfo, inputSelector, data) {
        if (elementInfo.validation.regex) {
          // make validation test function
          data.tests.push(this.createRegexValidator(elementInfo, inputSelector).bind(this));
        }
        if (elementInfo.validation.matchValueWith) {
          data.tests.push(this.createMatchValueWithValidator(elementInfo, inputSelector).bind(this));
        }
        if ((elementInfo.validation.range && typeof elementInfo.validation.range === 'object') &&
                    (elementInfo.validation.range.min && !isNaN(elementInfo.validation.range.min))) {
            data.tests.push(this.createRangeValidator(elementInfo, inputSelector).bind(this));
        }
      }
    
      createValidationEventMapEntry (elementInfo, customElementValidator, customElement) {
        if (elementInfo && elementInfo.validation && typeof elementInfo.validation === 'object') {
          // make selector
          let inputType = elementInfo.elementType;
          if (["text", "number", "radio", "checkbox", "hidden"].indexOf(inputType) >= 0) {
            inputType = "input[type=\"" + inputType + "\"]";
          } else if (["password"].indexOf(inputType) >= 0) {
            inputType = "input.password";
          }
          let inputSelector = "";
          const isRadio = elementInfo.elementType === "radio"
          const isRadioOrCheckbox = isRadio || elementInfo.elementType === "checkbox"
          if (inputType && elementInfo.id) {
            if (isRadio) {
              inputSelector = `input[type="radio"][name="${elementInfo.name}"]`;
            } else {
              inputSelector = `${inputType}#${elementInfo.id}`;
            }            
          }
  
          // make data object
          let data = {};
          data.tests = [];
  
          if (elementInfo.isCustomElement || elementInfo.isCustomSubElement) {
            this.setupCustomElementValidation (elementInfo, inputSelector, data, customElement, customElementValidator);
          } else {
            this.setupStandardElementValidation (elementInfo, inputSelector, data);
          }
  
          data.test = this.createValidationTester(data).bind(this);
          // make blur event handler
          data.onblur = this.createOnBlurValidator(elementInfo, data).bind(this);
          // if radio button or checkbox, should also validate on click to enable button
          if (isRadioOrCheckbox) {
            data.oninput = data.onblur
          }
          // make focus event handler
          data.onfocus = this.createOnFocusValidationCleanupMethod().bind(this);
  
          // register in event map
          if (inputSelector && data.test && data.onblur && data.onfocus) {
            this.eventMap.set(inputSelector, data);
          }
        }
      }
    
      validateInputs (showErrors) {
        let allInputsAreValid = true;
          for (let [selector, data] of this.eventMap.entries()) {
            allInputsAreValid = data.test(showErrors);
            if (!allInputsAreValid) {
              break;
            }
          }
        return allInputsAreValid;
      }
    
      validateHiddenFieldData (hiddenFieldData) {
        let isValid = false;
        if (hiddenFieldData && typeof hiddenFieldData === 'object' &&
            "id" in hiddenFieldData && typeof hiddenFieldData.id === 'string' && hiddenFieldData.id.length &&
            "name" in hiddenFieldData && typeof hiddenFieldData.name === 'string' && hiddenFieldData.name.length &&
            "value" in hiddenFieldData && typeof hiddenFieldData.value === 'string') {
          isValid = true;
        }
        return isValid;
      }
    
    }

    return InlineElementValidation;  
});
  