define([
    "components/createNewSSLCertificateDialog",
    "util"
  ], function(CreateNewSSLCertificateDialog, Util) {

    const _amdClosureArgs = [
      CreateNewSSLCertificateDialog.customElementTag
    ];

    class CreateNewItemDialog {

      constructor (args) {
        if (!this.validateArgs(args)) {
          throw new TypeError("Invalid args");
        }
        this.modalContainerId = args.modalContainerId;
        this.customElementTag = args.customElementTag;
        // istanbul ignore next
        this.createNewItemMethod = () => {};
        if (args.createNewItemMethod && typeof args.createNewItemMethod === 'function') {
          this.createNewItemMethod = args.createNewItemMethod;
        }
      }

      static isKnownCustomElementTag (tag = "") {
        const isKnown = _amdClosureArgs.includes(tag);
        return isKnown;
      }

      // istanbul ignore next
      getModalContainer () { return document.querySelector(`#${this.modalContainerId}`);}

      validateArgs (args) {
        let isValid = true;
        if (!args || typeof args !== 'object') {
          isValid = false;
        }
        if (isValid && (!args.modalContainerId || typeof args.modalContainerId !== 'string')) {
          isValid = false;
        }
        if (isValid && (!args.customElementTag ||
                        typeof args.customElementTag !== 'string' ||
                        !CreateNewItemDialog.isKnownCustomElementTag(args.customElementTag))) {
                          isValid = false;
        }
        return isValid;
      }

      // istanbul ignore next
      createCustomElement (tagName) { return document.createElement(tagName); }

      show () {
        const modalContainer = this.getModalContainer();
        if (modalContainer) {
          const diag = this.createCustomElement(this.customElementTag);
          diag.createNewItemMethod = this.createNewItemMethod;
          modalContainer.innerHTML = '';
          Util.clearAllBootstrapBackdrops();
          modalContainer.appendChild(diag);
          diag.updateComplete.then(function() {
              diag.show();
          }.bind(this));
        }
      }

    }

    return CreateNewItemDialog;
  });
