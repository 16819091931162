/* jshint esversion: 8 */
define([
  "service/datatransform/dependentOptionInfo",
  "service/datatransform/queryDependentOptionDataArgument",
  "util",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string"
], function (DependentOptionInfo, QueryDependentOptionDataArgument, Util,
             I18NStringResource, DojoString) {

  class DependentOptionDataBuilder {

    constructor (args) {
      this.checkValidity(args);
      this.getCredentialTypeIdFn = args.getCredentialTypeId;
      this.getCloudLocationFn = args.getCloudLocation;
      for (const id of ["getVPC", "getSubnet", "getImage"]) {
        if (args[id] && typeof args[id] === 'function') {
          this[id+"Fn"] =  args[id];
        } else {
          this[id+"Fn"] = () => { };
        }
      }
      this.getSavedValuesFn = args.getSavedValues;
      if (!this.getSavedValuesFn) { this.getSavedValuesFn = () => {return {};} }
      this.getUpdateSummaryFn = args.getUpdateSummary;
      this.dataService = args.dataService;
      this.previousDependentOptionValues = {};
    }

    getDataService () { return this.dataService; }

    checkValidity (args) {
      if (!args || typeof args !== 'object') {
        throw new TypeError("Invalid args. Must be object");
      }
      if (!args.getCredentialTypeId || typeof args.getCredentialTypeId !== 'function') {
        throw new TypeError("Invalid getCredentialTypeId argument");
      }
      if (!args.getCloudLocation || typeof args.getCloudLocation !== 'function') {
        throw new TypeError("Invalid getCloudLocation argument");
      }
      if (!args.getVPC || typeof args.getVPC !== 'function') {
        throw new TypeError("Invalid getVPC argument");
      }
      if (!args.getSubnet || typeof args.getSubnet !== 'function') {
        throw new TypeError("Invalid getSubnet argument");
      }
      if (!args.getImage || typeof args.getImage !== 'function') {
        throw new TypeError("Invalid getImage argument");
      }
      if (!args.dataService || typeof args.dataService !== 'object') {
        throw new TypeError("Invalid dataService argument");
      }
      if (!args.dataService.ui || typeof args.dataService.ui !== 'object') {
        throw new TypeError("Invalid dataService argument");
      }
      if (!args.dataService.ui.queryDependentOptionData ||
          typeof args.dataService.ui.queryDependentOptionData !== 'function') {
        throw new TypeError("Invalid dataService argument");
      }
    }

    getCredentialTypeId () {
      return this.getCredentialTypeIdFn();
    }

    getCloudLocation () {
      return this.getCloudLocationFn();
    }

    getVPCFn () {}
    getVPC () {
      return this.getVPCFn();
    }

    getSubnetFn () { }
    getSubnet () {
      return this.getSubnetFn();
    }

    getImageFn () { }
    getImage () {
      return this.getImageFn();
    }

    getSavedValues () {
      let savedValues = this.getSavedValuesFn();
      if (!savedValues) { savedValues = {}; }
      if (savedValues.visible && typeof savedValues.visible === 'object') {
        savedValues = savedValues.visible;
      }
      return savedValues;
    }

    getPreviousDependentOptionValues () { return this.previousDependentOptionValues; }

    rememberPreviousDependentOptionValues (elementId, value) {
      this.previousDependentOptionValues[elementId] = {
        value: value,
        tagName: 'select',
        type: '',
        classes: ''
      };
    }

    async getOptionData (dependentOptionInfo, settingsMap) {
      let depOpInfValid = (dependentOptionInfo &&
                           dependentOptionInfo instanceof DependentOptionInfo);
      let settingsValid = (settingsMap && settingsMap instanceof Map);
      if (!depOpInfValid) {
        throw new TypeError("Invalid dependentOptionInfo argument");
      }
      if (!settingsValid) {
        throw new TypeError("Invalid settingsMap argument");
      }

      for (const id of ["getVPCFn", "getSubnetFn", "getImageFn"]) {
        if (typeof dependentOptionInfo[id] === "function") {
          this[id] = dependentOptionInfo[id];
        }
      }

      let credId = this.getCredentialTypeId();
      let location = this.getCloudLocation();
      let vpc = this.getVPC();
      let subnet = this.getSubnet();
      let image = this.getImage();
      let templateId = settingsMap.get("template_id");
      let ruleId = settingsMap.get("rules_id");
      let supportPrivateSubnets = settingsMap.get("support_private_subnets");
      let supportFirewallModification = settingsMap.get("support_firewall_modification");
      let product = settingsMap.get("product");
      let release = settingsMap.get("release");

      let hasIncludeData = (Boolean(dependentOptionInfo.getSettingsIncludeKey()) &&
                            settingsMap.has(dependentOptionInfo.getSettingsIncludeKey()));
      let includeData;
      let hasExcludeData = (Boolean(dependentOptionInfo.getSettingsExcludeKey()) &&
                            settingsMap.has(dependentOptionInfo.getSettingsExcludeKey()));
      let excludeData;
      if (hasIncludeData) {
        includeData = settingsMap.get(dependentOptionInfo.getSettingsIncludeKey());
      }
      if (hasExcludeData) {
        excludeData = settingsMap.get(dependentOptionInfo.getSettingsExcludeKey());
      }
      // get new data and enable
      let optionData = [];
      if (!credId && !location) {
        // left the wizard
        return optionData;
      }
      try {
        const queryArgs = new QueryDependentOptionDataArgument({
          credentialId: credId,
          cloudLocation: location,
          vpc: vpc,
          subnet: subnet,
          templateId: templateId,
          ruleId: ruleId,
          image: image,
          supportPrivateSubnets: supportPrivateSubnets,
          supportFirewallModification: supportFirewallModification,
          product: product,
          release: release
        });
        optionData = await this.getDataService().ui.queryDependentOptionData(dependentOptionInfo, queryArgs);
        if (hasIncludeData) {
          let includeValues = [];
          for (let iDat of optionData) {
            includeValues.push(iDat.value);
          }
          includeData = includeData.filter(
            i => includeValues.includes(i.value)
          );
          optionData = includeData;
        }
        if (hasExcludeData) {
          let excludeValues = [];
          for (let eDat of excludeData) {
            excludeValues.push(eDat.value);
          }
          optionData = optionData.filter(i => !excludeValues.includes(i.value));
        }
      } catch (error) {
        document.dispatchEvent(new CustomEvent("dependentOptionError:ccwa", {
          detail: {
            queryType: dependentOptionInfo.queryType,
            errorMessage: error.message || ""
          }
        }));
        /* do nothing because this could happen when the use selects the
            prompt of a parent input that cause a query on a dependent input */
      }
      return optionData;
    }

    async loadDependentOptions (dependentOptionInfo, settingsMap) {
      if (dependentOptionInfo.isDependent()) {
        let selector = dependentOptionInfo.selector;
        let queryType = dependentOptionInfo.queryType;
        let element = document.querySelector(selector);
        if (element) {
          this.rememberPreviousDependentOptionValues(element.id, element.value);
          // remove previous data and disable
          while (element.lastChild && element.lastChild.value) {
            element.lastChild.remove();
          }
          element.selectedIndex = 0;
          let container = element.parentElement;
          if (container) {
            Util.hideValidationError(container);
          }
          element.disabled = true;
          element.classList.add("disabled");
        }

        let optionData = await this.getOptionData(dependentOptionInfo, settingsMap);

        if (element && optionData) {
          const defaultValue = this.getUsableDefaultValue(element.id,  dependentOptionInfo.suggestedDefault, optionData);
          this.buildOptions(element, optionData, defaultValue);
          element.disabled = false;
          element.classList.remove("disabled");
          // notify whether there are options data or not. Use setTimeout to allow element to be created.
          setTimeout(function() {this.sendEventToElement(element, "optionsLoaded"); }.bind(this), 0);
        }
      }
    }

    async loadNonDependentOptions (map, settingsMap, values) {
      let elementsToNotify = [];
      if (map && map.size) {
        let entries = map.entries();
        let entry = entries.next();
        while (!entry.done) {
          let elementId = entry.value[0];
          let dependentOptionInfo = entry.value[1];
          if (!dependentOptionInfo.isDependent()) {
            let selector = dependentOptionInfo.selector;
            let element = document.querySelector(selector);

            let optionData = await this.getOptionData(dependentOptionInfo, settingsMap);
            const defaultValue = this.getUsableDefaultValue(elementId,  dependentOptionInfo.suggestedDefault, optionData);

            if (element) {
              element.dataset.subnet = dependentOptionInfo.getIsSubnet();
              element.dataset.network = dependentOptionInfo.getIsNetwork();
              element.dataset.image = dependentOptionInfo.getIsImage();
              element.dataset.optional = dependentOptionInfo.getIsOptional();
              element.dataset.prompt = dependentOptionInfo.getPrompt();
              element.dataset.message = dependentOptionInfo.getMessage();

              this.buildOptions(element, optionData, defaultValue);
              if (values && values[elementId]) {
                element.value = values[elementId];
              }
              elementsToNotify.push(selector);
            }
          }
          entry = entries.next();
        }
      }
      elementsToNotify.forEach(elementSelector => {
        const element = document.querySelector(elementSelector);
        // notify whether there are options data or not. Use setTimeout to allow element to be created.
        setTimeout(function() {this.sendEventToElement(element, "nonDependentOptionsLoaded"); }.bind(this), 0);
      });
      return elementsToNotify;
    }

    notifyElementsOfChangedValue (elementsToNotify) {
      if (elementsToNotify && elementsToNotify.length) {
        let selector;
        while ((selector = elementsToNotify.pop())) {
          let element = document.querySelector(selector);
          if (element) {
            // put on event queue
            setTimeout(function() {this.sendEventToElement(element, "change");}.bind(this), 0);
          }
        }
      }
    }

    getUsableDefaultValue (selectId, suggestedValue, optionData) {
      let defaultValue = "";
      try {
        // merge the two objects into one...
        const savedValues = {...(this.getSavedValues() || {}), ...(this.getPreviousDependentOptionValues())};
        const savedDefaultEntry = savedValues[selectId] || {};
        let savedDefault = "";
        if (savedDefaultEntry && savedDefaultEntry.value) {
          savedDefault = savedDefaultEntry.value;
        }
        if (savedDefault) {
          for (let row of optionData) {
            if (savedDefault === row.value) {
              defaultValue = savedDefault;
              break;
            }
          }
        }
        let cleanedValue;
        if (typeof suggestedValue === 'string') {
          cleanedValue = suggestedValue;
        } else if (typeof suggestedValue === 'object' && suggestedValue.value && typeof suggestedValue.value === 'string') {
          cleanedValue = suggestedValue.value;
        } else if (typeof suggestedValue === 'object') {
          Util.consoleLogWarning(
            'getUsableDefaultValue',
            new Error('getUsableDefaultValue: suggestedValue is not a string or object with value property'),
            suggestedValue
          );
        }

        if (!defaultValue) {
          for (let row of optionData) {
            if (cleanedValue === row.value) {
              defaultValue = cleanedValue;
              break;
            }
          }
        }
      } catch (error) {
        Util.consoleLogWarning('getUsableDefaultValue', error, suggestedValue);
        defaultValue = suggestedValue;
      }
      return defaultValue;
    }

    buildOptions (selectElement, optionData, defaultValue) {
      if (!selectElement) {
        return
      }
      const hasOptionData = (Array.isArray(optionData) && optionData.length);

      //dataset is string, not typed
      //this if block is all about adding the first line to the select, in the case where the select is optional
      if (selectElement.dataset.optional === 'true') { //if optional, add a blank option based on IDL prompt
        // if the "message" exists, use it. Otherwise, use the prompt.
        // message is set in UIElementInfo from the IDL
        let prompt = selectElement.dataset.message || selectElement.dataset.prompt;
        if (!hasOptionData) {
          // if there's a prompt, use it, otherwise use the default ... these are dependent dropdowns, so they should have data
          prompt = prompt || DojoString.substitute(I18NStringResource.cmlWizardStep2OptionalFieldWithNoData, []);
          selectElement.disabled = true;
          selectElement.classList.add("disabled");
        } else {
          prompt = ""; //prompt is blank because this is optional, and there's data, so the end user can use it.
        }
        let option = new Option(prompt, "");
        if (!hasOptionData) {
          option.selected = true;
          option.disabled = true;
          option.classList.add("disabled");
        }
        selectElement.add(option, undefined);
      }

      if (hasOptionData) {
        let cmpFnc;
        let example = optionData[0];
        if (typeof example === 'object' && "text" in example && "value" in example) {
          cmpFnc = (a,b) => {
              if (a.text.toUpperCase() < b.text.toUpperCase()) {
                return -1;
              }
              if (a.text.toUpperCase() > b.text.toUpperCase()) {
                return 1;
              }
              return 0;
          };
        } else {
          throw new TypeError("Invalid optionData argument");
        }
        optionData = optionData.sort(cmpFnc);
        // special case for Subnet.
        // Don't want private to come first as we want to pick public as a default
        if (selectElement.dataset.subnet === "true") { //dataset is string
          let cmp2 = (a,b) => {
            if (a.text.toUpperCase().indexOf("| PUBLIC |") && b.text.toUpperCase().indexOf("| PRIVATE |")) {
              return -1;
            }
            if (a.text.toUpperCase().indexOf("| PRIVATE |") && b.text.toUpperCase().indexOf("| PUBLIC |")) {
              return 1;
            }
            return 0;
          };
          optionData = optionData.sort(cmp2);
        }
        // remove any previous data
        while (selectElement.lastChild && selectElement.lastChild.value) {
          selectElement.lastChild.remove();
        }
        // add new data
        let foundMatch=false;
        for (let row of optionData) {
          // Option(text, value, defaultSelected, selected);
          let option = new Option(row.text, row.value);
          if ( defaultValue && (row.value === defaultValue) ) {
            option.selected = true;
            foundMatch = true;
          }
          if (row.disabled) {
            option.setAttribute("disabled","disabled");
            if (foundMatch && option.selected) { //cannot select a disabled value
              foundMatch = false;
            }
          }
          selectElement.add(option, undefined);
        }
        if (!foundMatch) {
          Util.consoleLogInfo("getOptionData", "NO MATCH!", selectElement, "defaultValue", defaultValue);
          Util.selectFirstNonPromptOption(selectElement);
        }
        if (this.getUpdateSummaryFn && typeof this.getUpdateSummaryFn === 'function') {
          this.getUpdateSummaryFn(selectElement.id, selectElement.value);
        }
      }
    }

    sendEventToElement (element, eventName) {
      if (element && eventName && typeof eventName === 'string') {
        element.dispatchEvent(new Event(eventName));
        if (eventName !== "change") {
          element.dispatchEvent(new Event("change"));
        }
      }
    }

    updateDependentOptions (map) {
      if (map && map.size) {
        let entries = map.entries();
        let entry = entries.next();
        // If the non-dependent select has a single option value,
        // it was selected before the dependent option existed.
        // So, find all non-dependent selects that have a single option
        // and send a "change" event to update the dependent options, now
        // that they exist.
      while (!entry.done) {
          let elementId = entry.value[0];
          let dependentOptionInfo = entry.value[1];
          if (!dependentOptionInfo.isDependent()) {
            let selector = dependentOptionInfo.selector;
            let element = document.querySelector(selector);
            if (element) {
              let options = element.options;
              if ( (options.length === 1 && options[0].value) ||
                   (options.length === 2 && !options[0].value && element.selectedIndex === 1) ) {
                 element.dispatchEvent(new Event("change"));
              }
            }
          }
          entry = entries.next();
        }
      }
    }

    forceLoadOfDependentOptions (map) {
      if (map && map.size) {
        let entries = map.entries();
        let entry = entries.next();
        while (!entry.done) {
          let elementId = entry.value[0];
          let dependentOptionInfo = entry.value[1];
          if (!dependentOptionInfo.isDependent()) {
            let selector = dependentOptionInfo.selector;
            let element = document.querySelector(selector);
            if (element) {
              element.dispatchEvent(new Event("change"));
            }
          }
          entry = entries.next();
        }
      }
    }

    setDependentOptionValues (map, values) {
      if (map && map.size && values) {
        let entries = map.entries();
        let entry = entries.next();
        while (!entry.done) {
          let elementId = entry.value[0];
          let dependentOptionInfo = entry.value[1];
          if (dependentOptionInfo.isDependent()) {
            let selector = dependentOptionInfo.selector;
            let element = document.querySelector(selector);
            let currentValue = values[elementId];
            if (element && currentValue) {
              element.value = currentValue;
            }
          }
          entry = entries.next();
        }
      }
    }

  }

  return DependentOptionDataBuilder;
});
