/* jshint esversion: 8 */
define([
  "service/productConnect/connectionButtonGenerator",
  "service/landingPageNavButtons",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "constants"
], function (
  ConnectionButtonGenerator,
  LandingPageNavButtons,
  I18NStringResource, DojoString,
  Constants
) {

  class OnlineServerConnectionButtonGenerator extends ConnectionButtonGenerator {

    constructor (args) {
      super(args);
    }

  }

  return OnlineServerConnectionButtonGenerator;
});
