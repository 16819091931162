define([
  "underscore",
  "jquery",
  "backbone",
  "util",
  "supportedProducts"
], function( _, $, Backbone, Util, SupportedProducts) {

  /**
   * Backbone.js router used by Cloud Center UI.
   * html5 push state is configurable
   */
  class CloudCenterRouter extends Backbone.Router {
    constructor (options) {
      if (!options || typeof options !== "object") {
        throw new TypeError("Invalid options argument");
      }
      if (!options.parent || !options.parent.authCheck || typeof options.parent.authCheck !== "function") {
        throw new TypeError("Invalid options.parent argument");
      }
      options.routes = {
        "": "index",
        "login(/)": "login",
        "about(/)": "about",
        "manage(/)": "manage",
        "resource(/*args)(/)(?*queryString)": "resource",
        "credential(/)": "credential",
        "aws-import-role(/*args)": "credentialsAWSImportRole",
        "aws-create-role(/*args)": "credentialsAWSCreateRole",
        "resource-detail(/*args)(/)(?*queryString)": "resourceDetail",
        "*path": "notFound"
      };
      super(options);
      this.cloudCenterApp = options.parent;
      this.pushState = options.useHtml5PushState;
      this.root = (options.routerBase ? options.routerBase : "/");
    }

    start () {
      if (!Backbone.History.started) {
        Backbone.history.start({pushState: this.pushState, root: this.root});
      }
    }

    stop () {
      if (Backbone.History.started) {
        Backbone.history.stop();
      }
    }

    index () {
      this.navigate('login', {trigger: true, replace: true});
    }

    login () {
     this.cloudCenterApp.authCheck();
    }

    about () {
     this.cloudCenterApp.authCheck('about');
    }
    
    manage () {
      this.cloudCenterApp.authCheck('manage');
    }

    resource (path, queryString) {
      if (Util.isCreateDuplicateURLEnabled()) {
       this.cloudCenterApp.authCheck('resource', this.getArgs(path, queryString));
      } else {
        this.cloudCenterApp.authCheck('resource');
      }
    }

    credential () {
      this.cloudCenterApp.authCheck('credential');
    }

    credentialsAWSImportRole (path) {
      try {
        this.cloudCenterApp.authCheck('aws-import-role', this.getArgs(path, null));
      } catch (error) {
        Util.consoleLogError('credentialsAWSImportRole', error);
        this.credential();
      }
    }

    credentialsAWSCreateRole (path) {
      try {
        this.cloudCenterApp.authCheck('aws-create-role', this.getArgs(path, null));
      } catch (error) {
        Util.consoleLogError('credentialsAWSCreateRole', error);
        this.credential();
      }
    }

    resourceDetail (path, queryString) {
      try {
        this.cloudCenterApp.authCheck('resource-detail', this.getArgs(path, queryString));
      } catch (error) {
        Util.consoleLogError('resourceDetail', error);
        this.resource();
      }
    }

    notFound () {
      this.login();
    }

    getCurrentRoutePage () {
       let baseRoutes = {
        "": "index",
        "login": "login",
        "about": "about",
        "manage": "manage",
        "resource": "resource",
        "credential": "credential",
        "aws-import-role": "credentialsAWSImportRole",
        "aws-create-role": "credentialsAWSCreateRole",
        "resource-detail": "resourceDetail"
      };
      let frag = "";
      if (Backbone.History.started) {
        frag = Backbone.history.getFragment();
        if (frag) {
           let splitTokens = frag.split("/");
           let validApplicationIDs = [""];//Add additional application to the list in future
          frag = (splitTokens[0] && validApplicationIDs.indexOf(splitTokens[0]) > -1) ? splitTokens[1] : splitTokens[0];
        }
      }
      return baseRoutes[frag];
    }

    getArgs (path, queryString) {
      const re = /^\w+(\-\w+)*\/\w+$/;
      let product;
      let processedPath;
      if (re.test(path)) {
        const pieces = path.split('/');
        product = Util.convertUserFriendlyProductToProduct(pieces[0]);
        if (!SupportedProducts.isValidProduct(product)) {
          throw new Error(`Unsupported product: ${pieces[0]}`);
        }
        processedPath = pieces[1];
      } else {
        processedPath = path;
      }
      const args = { path: processedPath ? processedPath : "", params: this.parseQueryString(queryString) };
      if (product) {
        args.product = product;
      }
      return args;
    }

    parseQueryString (queryString) {
      let params = {};
      if(queryString){
        params = Util.convertQueryParamsToStringProperties(queryString);
      }
      return params;
    }

  }

  return CloudCenterRouter;
}); // require
