
define([
    "dojo/_base/declare",
    "dojo/_base/lang",

    "mw-log/Log",
    "mw-remote/Remote",
    "./Trigger"
], function (declare, lang, Log, Remote, Trigger) {

    return declare(Trigger, {
        constructor: function () {
            var nameParts = this.name.split(" ");
            if (nameParts.length < 2 || (nameParts[0] !== "response" && nameParts[0] !== "fault")) {
                Log.error("Invalid trigger name for a RemoteTrigger. " +
                    "Could not parse out the type of handler.");
            }
            if (!Remote.registerResponseHandler || !Remote.registerFaultHandler) {
                Log.error("The remote package is not available, " +
                    "this type of trigger will not work.");
            }
            this.messageId = nameParts[1] || "";
            this.handlerType = nameParts[0];
        },

        enable: function () {
            if (this.handlerType === "response") {
                Remote.registerResponseHandler(this.messageId,
                    lang.hitch(null, this.triggerHandler, this.name));
            } else {
                Remote.registerFaultHandler(this.messageId,
                    lang.hitch(null, this.triggerHandler, this.name));
            }
        },

        disable: function () {
            if (this.handlerType === "response") {
                Remote.deregisterResponseHandler(this.messageId);
            } else {
                Remote.deregisterFaultHandler(this.messageId);
            }
        }
    });
});