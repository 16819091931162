
define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/topic",

    "mw-log/Log",
    "./Trigger"
], function (declare, lang, topic, Log, Trigger) {

    return declare(Trigger, {
        constructor: function () {
            var nameParts = this.name.split(" ");
            if (nameParts.length < 2 || nameParts[0] !== "subscribe") {
                Log.error("Invalid trigger name for a MessageTrigger. " +
                              "Could not parse out the message name.");
            }
            this.messageId = nameParts[1] || "";
            this.subscrition = {};
        },

        enable: function () {
            this.subscription = topic.subscribe(this.messageId,
                lang.hitch(null, this.triggerHandler, this.name));
        },

        disable: function () {
            this.subscription.remove();
        }
    });
});