define([
    "util"
], function (
    Util
) {
    class MathworksService {

        constructor (args) {
            if (!args || typeof args !== "object" || !args.dao) {
              throw new TypeError("Invalid args argument");
            }
            this.dao = args.dao;
        }

        getDAO () { return this.dao; }

        async getMWEntitlements(product, release) {
            switch (product) {
                case "matlab":
                    product = "ML";
                    break;
                case "parallel_server":
                    product = "DW";
                    break;
                default:
                    product = "";
                    break;
            }

            // backend call to get user's entitlement information
            let entitlementData = await this.getDAO().getCCAPI("mathworks", "type", "entitlement", "", { "includetoken": false, "product": product, "release": release, "excludeexpired": false }, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
            if (entitlementData && entitlementData[release] && entitlementData[release].entitlements && entitlementData[release].entitlements.entitlement) {
                entitlementData = { "msg": entitlementData[release].entitlements.entitlement };
            }
            return entitlementData;
        }

    }
    return MathworksService;
});
