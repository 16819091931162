define ([], function () {
  /*
    Argument for call to ui.queryDependentOptionData
  */
  class QueryDependentOptionDataArgument {
    constructor (args) {
      if ( !args || typeof args !== 'object') {
        throw new TypeError("Invalid constructor argument");
      }
      this.credentialId = args.credentialId;
      this.cloudLocation = args.cloudLocation;
      this.vpc = args.vpc;
      this.subnet = args.subnet;
      this.templateId = args.templateId;
      this.ruleId = args.ruleId;
      this.supportPrivateSubnets = args.supportPrivateSubnets;
      this.supportFirewallModification = args.supportFirewallModification;
      this.product = args.product;
      this.release = args.release;
    }

    // Needed for creating unique cache hashID
    toString () {
      return `credentialId:${this.credentialId},cloudLocation:${this.cloudLocation},vpc:${this.vpc},subnet:${this.subnet},templateId:${this.templateId},ruleId:${this.ruleId},privateSubnets:${this.supportPrivateSubnets},firewallMod:${this.supportFirewallModification}`;
    }
  }

  return QueryDependentOptionDataArgument;

});
