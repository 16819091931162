define ([
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string"
], function(I18NStringResource, DojoString) {

  const Enum = Object.freeze({
    DETAILS_PAGE: Symbol("details"),
    RESOURCE_PAGE: Symbol("resource")
  });

  class EditSource {
    static isValidEditSource (editSource) {
      return !!(editSource && typeof editSource === 'string' && !!EditSource.Enum[editSource]);
    }
    static valueOf (name) {
      let value = undefined;
      if (name && typeof name === 'string') {
        value = EditSource.Enum[name];
      }
      return value;
    }
  }

  Object.defineProperty(EditSource, "Enum", {
    value: Enum,
    writable: false,
    configurable: false,
    enumerable: true
  });

  return EditSource;
});
