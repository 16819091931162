/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "bootstrap",
  "templates/connectCredentialsDialogTemplate",
  "util",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "constants"
], function (_, $, Bootstrap, ConnectCredentialsDialogTemplate, Util, I18NStringResource, DojoString, CCWA_Constants) {

  class ConnectCredentialsDialog {

    constructor(args) {
      if (!this.validateArgs(args)) {
        throw new TypeError("Invalid args");
      }
      this.dialogTitle = I18NStringResource.connectCredentialsDialogTitle;
      this.usernameLabel = I18NStringResource.connectCredentialsDialogUsernameLabel;
      this.passwordLabel = I18NStringResource.connectCredentialsDialogPasswordLabel;
      this.username = args.username;
      this.password = args.password;
      this.os = args.os; //linux
      this.platform = args.platform;
      this.access = args.access; // NICE DCV, RDP
      this.currentAccess = args.currentAccess; //"", NICE DCV, RDP
      this.connectToDCV = I18NStringResource.connectCredentialsDialogNiceDCV;
      // istanbul ignore next
      this.connectToExternalExplanation = args.connectToExternalExplanation ? args.connectToExternalExplanation : I18NStringResource.connectCredentialsDialogNiceDCVExplanation;
      this.connectToRDPExplanation = '';//I18NStringResource.connectCredentialsDialogExplanationRDP;
      this.downloadDCVFileExplanation = '';
      this.connectToRDP = I18NStringResource.connectCredentialsDialogRDP;
      this.downloadDCV = I18NStringResource.connectCredentialsDialogDCV;
      this.connectActionFn = args.connectAction;
      this.footer = I18NStringResource.connectCredentialsDialogFooterDCV;
      this.footerLabel = I18NStringResource.connectCredentialsDialogFooterLabel;
      this.explanation = DojoString.substitute(I18NStringResource.connectCredentialsDialogExplanation, [I18NStringResource.connectCredentialsDialogCopyButtonLabel]);
      this.copyButtonLabel = I18NStringResource.connectCredentialsDialogCopyButtonLabel;
      if (this.access === "RDP") {
        this.footer = I18NStringResource.connectCredentialsDialogFooterRDP;
        this.explanation = DojoString.substitute(I18NStringResource.connectCredentialsDialogExplanationRDP, [I18NStringResource.connectCredentialsDialogCopyAndContinueButtonLabel]);
        this.copyButtonLabel = I18NStringResource.connectCredentialsDialogCopyAndContinueButtonLabel;
      } else if (this.access === "DCV_FILE") {
        this.footer = "";
        this.explanation = DojoString.substitute(I18NStringResource.connectCredentialsDialogExplanationDCV, [I18NStringResource.connectCredentialsDialogCopyAndContinueButtonLabel]);
        this.copyButtonLabel = I18NStringResource.connectCredentialsDialogCopyAndContinueButtonLabel;
      } else if (this.access && this.access.startsWith(CCWA_Constants.CLOUD_LINK_CUSTOM_ACCESS_PREFIX)) {
        // There can be double cloud links that are the same, except one contains "_direct" in the description to indicate bypassing the proxy.
        // We want to use the same access ID for both so, we strip the direct operand out.
        const nlsBaseId = this.access.replace(CCWA_Constants.CLOUD_LINK_DIRECT_CONNECT_OPERAND, "");
        const footerId = `${nlsBaseId}_footer`;
        const explanationId = `${nlsBaseId}_explanation`;
        const copyButtonLabelId = `${nlsBaseId}_copyButtonLabel`;
        this.footer = I18NStringResource[footerId] || '';
        this.explanation = I18NStringResource[explanationId] || '';
        this.copyButtonLabel = I18NStringResource[copyButtonLabelId] || I18NStringResource.connectCredentialsDialogCopyAndContinueButtonLabel;
      } else if (this.username.startsWith("mabuser")) {
        this.footer = '';
        this.connectToExternalExplanation = I18NStringResource.connectCredentialsDialogGenericExplanation;
        this.explanation = DojoString.substitute(I18NStringResource.connectCredentialsDialogGenericExplanationLine2, [I18NStringResource.connectCredentialsDialogCopyAndContinueButtonLabel]);
        this.copyButtonLabel = I18NStringResource.connectCredentialsDialogCopyAndContinueButtonLabel;
      }
      this.cancelButtonLabel = I18NStringResource.connectCredentialsDialogCancelButtonLabel;
      this.modalContainerId = "#modalContainer";
      this.dialogElementSelector = 'div#connectCredentialsDialog';
      this.resourceRowData = null;
      this.removeRowSpinner = false;
    }

    getDialogElement() { return document.querySelector(this.dialogElementSelector); }

    setResourceRowData(rowData) {
      this.resourceRowData = rowData;
      this.removeRowSpinner = true;
    }

    getResourceRowData() { return this.resourceRowData; }

    shouldRemoveRowSpinner() { return this.removeRowSpinner; }

    getCancelButton() {
      let cancelButton;
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        cancelButton = dialogEl.querySelector('#cancelButton');
      }
      return cancelButton;
    }

    getCopyButton() {
      let copyButton;
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        copyButton = dialogEl.querySelector('#copyButton');
      }
      return copyButton;
    }

    getErrorContainer() {
      let errorContainer;
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        errorContainer = dialogEl.querySelector('div.errorTextContainer');
      }
      return errorContainer;
    }

    getErrorText() {
      let textElement;
      const errorContainer = this.getErrorContainer();
      if (errorContainer) {
        textElement = errorContainer.querySelector('p.errorText');
      }
      return textElement;
    }

    validateArgs(args) {
      let isValid = false;
      if (args && typeof args === 'object') {
        if (('username' in args) && typeof args.username === 'string' &&
          ('password' in args) && typeof args.password === 'string' &&
          args.connectAction && typeof args.connectAction === 'function') {
          isValid = true;
        }
      }
      return isValid;
    }

    dragNotAllowed(event) {
      event.preventDefault();
      event.originalEvent.dataTransfer.dropEffect = 'none';
    }

    clearError() {
      const errorContainer = this.getErrorContainer();
      const errorText = this.getErrorText();
      errorText.innerHTML = "";
      // istanbul ignore next
      if (errorContainer && errorContainer.style) {
        errorContainer.style.visibility = 'hidden';
      }
    }

    // istanbul ignore next
    setFocusHandler(input) {
      input.addEventListener('focus', this.clearError.bind(this));
    }

    setup() {
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        dialogEl.querySelectorAll('form input').forEach(this.setFocusHandler.bind(this));
        const passwordInput = dialogEl.querySelector("input#password");
        if (passwordInput) {
          passwordInput.select();
          passwordInput.setSelectionRange(0, 99999); /* For mobile devices */
        }
        const viewPwdButton = dialogEl.querySelector('button.viewPassword');
        if (viewPwdButton) {
          viewPwdButton.addEventListener('click', this.hideShowPassword.bind(this));
        }
        const copyButton = dialogEl.querySelector('button#copyButton');
        if (copyButton) {
          copyButton.addEventListener('click', this.doCopy.bind(this));
          copyButton.focus();
        }
        const cancelButton = dialogEl.querySelector('button#cancelButton');
        if (cancelButton) {
          // istanbul ignore next
          cancelButton.addEventListener('click', function (event) {
            if (this.getResourceRowData() && this.shouldRemoveRowSpinner()) {
              Util.removeSpinnerFromRow(this.getResourceRowData().rowElement, this.getResourceRowData().originalContent);
            }
          }.bind(this), false);
        }
      }
    }

    hideShowPassword() {
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        const passwordInput = dialogEl.querySelector('input#password');
        const type = passwordInput.getAttribute('type');
        if (type === 'password') {
          passwordInput.setAttribute('type', 'text');
        } else {
          passwordInput.setAttribute('type', 'password');
        }
      }
    }

    async doConnect(spinner) {
      if (this.getCopyButton() && spinner) {
        this.getCopyButton().replaceWith(spinner);
      }
      try {
        await this.connectActionFn();
      } catch (error) {
        Util.consoleLogError("doCopy:connectActionFn", error);
      }
    }

    doCopy(event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      $.event.trigger("logdduxevent:ccwa", { elementId: 'copyButton', elementType: 'button' });
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        const passwordInput = dialogEl.querySelector('input#password');
        this.removeRowSpinner = false;
        if (passwordInput) {
          const pText = passwordInput.value;
          const spinner = this.makeSpinner();
          // istanbul ignore next
          const onSuccessFn = async function () {
            await this.doConnect(spinner);
            if (this.getResourceRowData()) {
              Util.removeSpinnerFromRow(this.getResourceRowData().rowElement, this.getResourceRowData().originalContent);
            }
          }.bind(this);
          // istanbul ignore next
          const onFailureFn = function (error) {
            Util.consoleLogError("doCopy navigator.clipboard.writeText", error);
            Util.notify('ERROR', I18NStringResource.copyToClipboardDialogError);
            onSuccessFn(); //instead of erroring out, open the link
          }.bind(this);
          Util.copyToClipboard(pText, onSuccessFn, onFailureFn);
        }
        const cancelButton = this.getCancelButton();
        if (cancelButton) {
          cancelButton.click();
        }
      }
    }

    makeSpinner() {
      let spinner = document.createElement("div");
      spinner.id = "copyButtonSpinner";
      spinner.classList.add("add_intent_10");
      spinner.innerHTML = `<mw-progress-indicator style="" size="small" type="spinner" indeterminate="" role="progressbar" tabindex="0">
            <svg class="progressIndicatorSvg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="-16 -16 32 32">
              <g>
              <circle class="mw-progress-indicator-gutter" r="13.5" cx="0" cy="0" stroke-width="5"></circle>
              <circle class="mw-progress-indicator  mw-stroke-default-color" r="13.5" cx="0" cy="0" stroke-width="5" stroke-dasharray="21.205750411731103, 84.82300164692441" stroke="var(--mw-backgroundColor-info, #0095ff)"></circle>
              <circle class="mw-progress-indicator mw-progress-indicator-border " r="13.5" cx="0" cy="0" stroke-width="5" stroke-dasharray="1, 20.205750411731103, 1, 83.82300164692441"></circle>
              </g>
              <!--?lit$367334828$-->
            </svg>
          </mw-progress-indicator>`;
      return spinner;
    }

    async show() {
      if (this.access === CCWA_Constants.NO_CRED_PROMPT) {
        try {
          await this.connectActionFn();
        } catch (error) {
          Util.consoleLogError("connectActionFn", error);
        }
        return
      }

      const html = ConnectCredentialsDialogTemplate({
        dialogTitle: this.dialogTitle,
        explanation: this.explanation,
        footer: this.footer,
        footerLabel: this.footerLabel,
        usernameLabel: this.usernameLabel,
        username: this.username,
        passwordLabel: this.passwordLabel,
        password: this.password,
        showHidePasswordText: I18NStringResource.showOrHidePassword,
        copyButtonLabel: this.copyButtonLabel,
        cancelButtonLabel: this.cancelButtonLabel,
        os: this.os, //linux
        access: this.access, //NICE DCV, RDP
        currentAccess: this.currentAccess, //"", NICE DCV, RDP
        connectToDCV: this.connectToDCV,
        connectToDCVExplanation: this.connectToExternalExplanation,
        connectToRDPExplanation: this.connectToRDPExplanation,
        connectToRDP: this.connectToRDP,
        downloadDCVFileExplanation: this.downloadDCVFileExplanation,
        downloadDCV: this.downloadDCV
      });
      const modalContainer = document.querySelector(this.modalContainerId);
      modalContainer.innerHTML = html;
      Util.clearAllBootstrapBackdrops();
      const dialogEl = this.getDialogElement();
      if (dialogEl) {
        dialogEl.addEventListener('shown.bs.modal', this.setup.bind(this));
        //  istanbul ignore next
        dialogEl.addEventListener('hidden.bs.modal', function () {
          const dialogEl = document.querySelector(this.dialogElementSelector);
          if (dialogEl) {
            const modal = Bootstrap.Modal.getOrCreateInstance(dialogEl);
            modal.dispose();
          }
        }.bind(this));
        dialogEl.addEventListener('dragover', this.dragNotAllowed);
        dialogEl.addEventListener('keydown', this.onKeydown.bind(this));
        const modal = new Bootstrap.Modal(dialogEl, { backdrop: 'static' });
        modal.show();
      }
    }

    onKeydown(e) {
      if (e) {
        const code = e.keyCode || e.which;
        if (code == 13) { // Enter key
          if (e.preventDefault) {
            e.preventDefault();
          }
          let focusedElement = document.activeElement;
          // istanbul ignore next
          if (focusedElement &&
            focusedElement.tagName.toUpperCase() === "BUTTON" && (
              focusedElement.className === 'close'
              || focusedElement.id === "cancelButton" ||
              focusedElement.id === "copyButton"
            )
          ) {
            focusedElement.click();
          }
        }
      }
    }

  }

  return ConnectCredentialsDialog;
});
