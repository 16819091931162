/*
 * Convert server JSON to usable entity
 *
 * Example input data
    {
      "cloud_location":{
        "us-east-1":"US East 1 (N. Virginia)"
      },
      "cloud_network_type":"existing",
      "cloud_provider":"aws",
      "credential_type":{
        "ee8ac4214f8d9465c87a45c7c8d7ad15":"Amazon AWS (Administrator)"
      },
      "description":"MATLAB R2020b Windows",
      "highlighted_features":"",
      "license_type":"online",
      "operating_system":"windows",
      "product":"matlab",
      "version":"R2020b"
    }
 *
 */
define([

], function() {
  class RuleInfo {

    constructor (data, isEntitled, saveAll=false) {
      if (! RuleInfo.paramDataIsValid(data)) {
        throw new TypeError("Invalid input data argument");
      }
      this.includeDetails = false;
      this.fields = [];
      if (data) {
        if (saveAll) {
          for (const [key, value] of Object.entries(data)) {
            this.fields.push(key);
            this[key]=value;
          }
        }
        this.release = data.version;
        this.product = data.product;
        this.cloudPlatform = data.cloud_provider;
        this.cloudLocations = data.cloud_location;
        this.networkType = data.cloud_network_type;
        this.description = data.description;
        this.featureHighlights = data.highlighted_features;
        this.os = data.operating_system;
        this.credentialType = data.credential_type;
        this.entitled = isEntitled;
        this.eol = null;
      } else {
        this.release = undefined;
        this.product = undefined;
        this.cloudPlatform = undefined;
        this.cloudLocations = undefined;
        this.networkType = undefined;
        this.description = undefined;
        this.featureHighlights = undefined;
        this.os = undefined;
        this.credentialType = undefined;
        this.entitled = false;
        this.eol = null;
      }
      Object.defineProperty(this, "_ruleId", {
        enumerable: false,
        value: "",
        writable: true
      });
    }

    get id () { return this._ruleId; }
    set id (id) { this._ruleId = id; }

    setAllFromExisting (ruleInfo) {
      this._ruleId = ruleInfo.id;
      this.setRelease(ruleInfo.release);
      this.setProduct(ruleInfo.product);
      this.setCloudPlatform(ruleInfo.cloudPlatform);
      this.setCloudLocations(ruleInfo.cloudLocations);
      this.setNetworkType(ruleInfo.networkType);
      this.setDescription(ruleInfo.description);
      this.setFeatureHighlights(ruleInfo.featureHighlights);
      this.setOS(ruleInfo.os);
      this.setCredentialType(ruleInfo.credentialType);
    }

    getRelease () { return this.release; }
    setRelease (release) { this.release = release; }

    getProduct () { return this.product; }
    setProduct (product) { this.product = product; }

    getCloudPlatform () { return this.cloudPlatform; }
    setCloudPlatform (cloudPlatform) { this.cloudPlatform = cloudPlatform; }

    getCloudLocations () { return this.cloudLocations; }
    setCloudLocations (cloudLocations) { this.cloudLocations = cloudLocations; }

    getNetworkType () { return this.networkType; }
    setNetworkType (networkType) { this.networkType = networkType; }

    getDescription () { return this.description; }
    setDescription (description) { this.description = description; }

    getFeatureHighlights () { return this.featureHighlights; }
    setFeatureHighlights (featureHighlights) { this.featureHighlights = featureHighlights; }

    getOS () { return this.os; }
    setOS (os) { this.os = os; }

    getCredentialType () { return this.credentialType; }
    setCredentialType (credentialType) { this.credentialType = credentialType; }

    shouldIncludeDetails () { return !!this.includeDetails; }
    addDetails (addDetails) { this.includeDetails = addDetails; }

    isEntitled () { return (this.entitled === true); }
    setEntitled(isEntitled) { this.entitled = (isEntitled === true); }

    updateEOL(data) {
      if (data && typeof data === 'object') {
        this.eol = data;
        this.eol.Status = RuleInfo._EOLStatusNames[data.Status] || RuleInfo._EOLStatusNames[RuleInfo.StatusIntNotSet];
      }
    }

    get finalChoiceDescription () { return `${this.getDescription()}-${this.id}`; }

    static paramDataIsValid (data) {
      return (!data || (data && typeof data === 'object' && "version" in data &&
             typeof data.version === 'string' && "product" in data &&
             typeof data.product === 'string'));
    }

    static convertToNative (ruleInfo) {
      let nativeData;
      if (ruleInfo && ruleInfo instanceof RuleInfo) {
        nativeData = {
          cloud_location: ruleInfo.getCloudLocations() ? ruleInfo.getCloudLocations() : "",
          cloud_network_type: ruleInfo.getNetworkType() ? ruleInfo.getNetworkType() : "",
          credential_type: ruleInfo.getCredentialType() ? ruleInfo.getCredentialType() : "",
          description: ruleInfo.getDescription() ? ruleInfo.getDescription() :"",
          operating_system: ruleInfo.getOS() ? ruleInfo.getOS() : "",
          product: ruleInfo.getProduct() ? ruleInfo.getProduct() : "",
          version: ruleInfo.getRelease() ? ruleInfo.getRelease() : "",
          cloud_provider: ruleInfo.getCloudPlatform() ? ruleInfo.getCloudPlatform() : "",
          highlighted_features: ruleInfo.getFeatureHighlights() ? ruleInfo.getFeatureHighlights() : ""
        };
        if (ruleInfo.shouldIncludeDetails()) {
          nativeData.body = "";
        }
        if (ruleInfo.eol) {
          nativeData.eol = ruleInfo.eol;
          nativeData.eol.Status = RuleInfo._EOLStatusValues[ruleInfo.eol.Status];
        }
      }
      return nativeData;
    }
  }
  Object.defineProperty(RuleInfo,  "_EOLStatusNames", {
    value:[
      "EOL_NOT_SET",
      "ACTIVE",
      "DEPRECATED",
      "SUNSET"
    ],
    writable: false,
    configurable: false,
    enumerable: false
  });
  Object.defineProperty(RuleInfo,  "_EOLStatusValues", {
    value:{
      "EOL_NOT_SET": 0,
      "ACTIVE": 1,
      "DEPRECATED": 2,
      "SUNSET": 3
    },
    writable: false,
    configurable: false,
    enumerable: false
  });
  Object.defineProperty(RuleInfo,  "StatusIntNotSet", {
    value: 0,
    writable: false,
    configurable: false,
    enumerable: true
  });
  Object.defineProperty(RuleInfo,  "StatusIntActive", {
    value: 1,
    writable: false,
    configurable: false,
    enumerable: true
  });
  Object.defineProperty(RuleInfo,  "StatusIntDeprecated", {
    value: 2,
    writable: false,
    configurable: false,
    enumerable: true
  });
  Object.defineProperty(RuleInfo,  "StatusIntSunsetted", {
    value: 3,
    writable: false,
    configurable: false,
    enumerable: true
  });

  return RuleInfo;
});
