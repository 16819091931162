/* jshint esversion: 8 */
/*
 * This file contains logic for resource states and event
 */
define([
  "underscore",
  "jquery",
  'service/cloudStatus',
  "service/countdown",
  'service/landingPageNavButtons',
  "service/productConnect/connectionButtonGeneratorFactory",
  "templates/downloadRDPTemplate",
  "templates/downloadDCVTemplate",
  "templates/countdown",
  "dialogs/copyToClipboardDialog",
  "dialogs/connectCredentialsDialog",
  "dojo/i18n!nls/cloudCenterStringResource",
  'dojo/string',
  "util",
  "supportedProducts",
  "constants"
], function (
  _, $, CloudStatus, CountdownService, LandingPageNavButtons,
  ConnectionButtonGeneratorFactory, DownloadRDPTemplate, DownloadDCVTemplate, 
  CountdownTemplate, CopyToClipboardDialog, ConnectCredentialsDialog, 
  I18NStringResource, DojoString, Util, SupportedProducts, Constants
) {

  class CloudResource {

    static addTimer (timer) {
      if (!CountdownService.COUNTDOWN_TIMERS) {
        CountdownService.COUNTDOWN_TIMERS = [];
      }
      CountdownService.COUNTDOWN_TIMERS.push(timer);
    }

    static stopAll () {
      if (!CountdownService.COUNTDOWN_TIMERS) {
        CountdownService.COUNTDOWN_TIMERS = [];
      }
      while (CountdownService.COUNTDOWN_TIMERS.length) {
        CountdownService.COUNTDOWN_TIMERS.pop().stop();
      }
    }

    static isConfigValid (config) {
      let isValid = true;
      if (!config || typeof config !== 'object') {
        isValid = false;
      }
      if (isValid) {
        const requiredProperties = ["id", "params", "cloud"]; // other pops optional
        for (let prop of requiredProperties) {
          if (!(prop in config)) {
            isValid = false;
            break;
          }
        }
      }
      if (isValid) {
        if (typeof config.cloud !== 'object' || !("state" in config.cloud)) {
          isValid = false;
        }
      }
      return isValid;
    }

    // args: { dataService, config, proxyURL, fnStopResource }
    constructor (args) {
      this.DIRECT_MATLAB = true;
      let config = args.config || {};
      this.initializePropertiesFromConfig(config);
      this.countdownService = null;
      this.dataService = args.dataService;
      this.fnStopResource = args.fnStopResource;
      this.proxyURL = args.proxyURL;
      this.downloadRDPTemplate = DownloadRDPTemplate;
      this.downloadDCVTemplate = DownloadDCVTemplate;
    }

    initializePropertiesFromConfig (config) {
      if (!CloudResource.isConfigValid(config)) {
        throw new TypeError('Invalid config object argument');
      }
      this.config = config;
      this.configId = config.id;
      this.setStatus(this.getStatusFromConfig(config));
      this.setStatusTransitionPercentage(this.getStatusTransitionPercentageFromConfig(config));
      this.operatingSystem = "linux";
      if (config.params.operating_system) {
         this.operatingSystem = config.params.operating_system;
      }
      if (config.params.cloud_provider) {
        this.platform = config.params.cloud_provider;
      }
      this.supportsAccessProtocol = false;
      this.supportsEnableNiceDcv = false;
      if (this.getAccessProtocolParam() in this.config.params) {
        this.supportsAccessProtocol = true;
      } else if ("enableNiceDcv" in this.config.params) {
        this.supportsEnableNiceDcv = true;
      }
      this.currentAccessProtocol = "";
      let rawAccessProtocol = "";
      if (config.params[this.getAccessProtocolParam()]) {
        rawAccessProtocol = config.params[this.getAccessProtocolParam()];
      } else if (this.supportsEnableNiceDcv) { //azure
        rawAccessProtocol = config.params.enableNiceDcv;
      }
      this.currentAccessProtocol = Util.getCanonicalizedAccessProtocol(rawAccessProtocol, this.getPlatform());
      this.accessGroupId = "";
      if (config.cloud_access
          && typeof config.cloud_access === 'object'
          && config.cloud_access.length
          && typeof config.cloud_access[0] === 'object') {
         this.accessGroupId = config.cloud_access[0].id
      }
      this.setName(config.params["mw-name"]);
      this.setProduct(config.params["product"]);
      this.countdownInfo = {};
      if (config.cloud.termination_policy) {
        const termPol = config.cloud.termination_policy;
        this.countdownInfo = {
          type: termPol.type,
          endDate: new Date(termPol.end_date),
          maxDuration: termPol.max_duration_hours, //hours
          refreshInterval: Constants.COUNTDOWN_REFRESH_INTERVAL //seconds
        }
      }
      this.setUserNameProperty(config.params); //sets this.username, chooses between mw-username, Username, and ""
      this.setPasswordProperty(config.params); //sets this.password
      this.setIPAddressProperties(config.cloud_machines); //sets this.ip, this.publicIP, this.privateIP, and this.isPublic
    }

    getConfigId () { return this.configId; }

    getConfig () { return this.config; }

    getOperatingSystem () { return this.operatingSystem; }

    getPlatform () { return this.platform; }

    accessProtocolIsSupported () { return this.supportsAccessProtocol; }

    enableNiceDcvIsSupported () { return this.supportsEnableNiceDcv; }

    getDataService () { return this.dataService; }

    getStatus () { return this.status; }
    setStatus (status) {
      if (!CloudStatus.isValidStatus(status) && status !== null) {
        throw new TypeError("Invalid status: " + status);
      }
      this.status = status;
    }

    getStatusTransitionPercentage () { return this.statusTransitionPercentage; }
    setStatusTransitionPercentage (percentage) { this.statusTransitionPercentage = percentage; }

    getProduct () { return this.product; }
    setProduct (product) {
      this.product = SupportedProducts.getDefaultProductName();
      if (product || product === null) {
        this.product = product;
      }
    }

    getName () { return this.name; }
    setName (name) { this.name = name; }

    getCountdownService () { return this.countdownService; }
    setCountdownService (countdownService) { this.countdownService = countdownService; }

    getAccessProtocolParam () {
      if (!this.accessProtocolParam) {
        this.accessProtocolParam = "AccessProtocol";
        if ("accessProtocol" in this.getConfig().params ) {
          this.accessProtocolParam = "accessProtocol";
        }        
      }
      return this.accessProtocolParam;
    }
    setCurrentAccessProtocol (currentAccessProtocol) {
      //only set if it was set before, meaning it exists on the config
      if (this.currentAccessProtocol && this.getConfig() && this.getConfig().params) {
        if (this.getAccessProtocolParam() in this.getConfig().params) {
          this.getConfig().params[this.getAccessProtocolParam()] = currentAccessProtocol;
        }
      }
      this.currentAccessProtocol = currentAccessProtocol;
    }

    getCurrentAccessProtocol () { return this.currentAccessProtocol; }

    getTermPolicy () {
      if (this.countdownInfo) {
        if (this.countdownInfo.type === "fixed") {
          if (this.countdownInfo.maxDuration > 0) {
            return this.countdownInfo.maxDuration;
          }
        } else {
          return this.countdownInfo.type==="on_idle"?"on_idle":"never"; //preserve "never" as default
        }
      }
      return "2";
    }

    stopCountdown (containerSelector) {
      if (!(containerSelector && typeof containerSelector === 'string')) {
        throw new TypeError("Invalid containerSelector argument");
      }
      let container = document.querySelector(containerSelector);
      if (container) {
        if (this.getCountdownService() && !this.getCountdownService().isStopped()) {
          this.getCountdownService().stop();
        }
        let countdownDiv = container.querySelector("div.resource-countdown");
        if (countdownDiv && countdownDiv.classList.contains("resource-countdown")) {
          countdownDiv.innerHTML = "";
        }
      } else {
        Util.consoleLogWarning("stopCountdown", `Unable to find specified countdown element: ${containerSelector}`);
      }
    }

    async startCountdown (containerSelector) {
      if (!(containerSelector && typeof containerSelector === 'string')) {
        throw new TypeError("Invalid containerSelector argument");
      }
      const outerContainer = document.querySelector('div.timeoutDropdownContainer');
      if (outerContainer) {
        outerContainer.style.display = 'inline-block';
      }
      let container = document.querySelector(containerSelector);
      if (container) {
        let cdInfo = this.getCountdownInfo();
        let countdownDiv = container.querySelector("div.resource-countdown");
        let existingSelector = container.querySelector("div.countdown");
        let countdownSelector = `${containerSelector} div.resource-countdown`;

        if (cdInfo && cdInfo.type === "fixed" && this.getStatus() === CloudStatus.Enum.RUNNING && countdownDiv) {
          countdownDiv.style.display = 'block';
          countdownDiv.innerHTML = CountdownTemplate({terminationPolicyWarning:I18NStringResource.terminationPolicyWarning});
          this.setCountdownService(new CountdownService(this.fnStopResource, countdownSelector, cdInfo.endDate, cdInfo.maxDuration, cdInfo.refreshInterval));
          this.getCountdownService().start();
          countdownDiv.querySelector("div.countdown").title += ": " + this.getCountdownService().getEndDate();
          CloudResource.addTimer(this.getCountdownService());
          return;
        } else if (countdownDiv) {
          countdownDiv.style.display = "none";
        }

        if (existingSelector && (this.getStatus() !== CloudStatus.Enum.RUNNING || (cdInfo && cdInfo.type !== "fixed"))) {
          existingSelector.parentNode.innerHTML = "";
        }
      } else {
        Util.consoleLogWarning('startCountdown', `Unable to find specified countdown element: ${containerSelector}`);
      }
    }

    getCountdownInfo () {
      return this.countdownInfo;
    }

    _downloadRDP () {
      let username = (this.getUsername() ? this.getUsername() : "");
      if (this.getOperatingSystem() === "windows" && this.ip) {
        username = this.ip + "\\" + username;
      }
      const contents = this.downloadRDPTemplate({
        username: username,
        ip: this.ip
      });
      const url = LandingPageNavButtons.stringToURL(contents, 'application/x-rdp');
      const fileName = `${this.getName()} Remote Desktop (IP ${this.ip}).rdp`;
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return false;
    }

    _getRDPListener () {
      return async function (event) {
        try {
          let doConnect = async function () {
            await this._updateAccessProtocol('RDP');
            await this._addAccessPort('tcp', Constants.RDP_PORT, Constants.RDP_PORT);
            this._downloadRDP();
            return false;
          }.bind(this);
          let connectCredentialsDialog = new ConnectCredentialsDialog({
            username: this.getUsername(),
            password: this.getPassword(),
            os: this.getOperatingSystem(),
            platform: this.getPlatform(),
            access: "RDP",
            currentAccess: this.getCurrentAccessProtocol(),
            connectAction: doConnect.bind(this)
          });
          connectCredentialsDialog.show();
        } catch (error) {
          Util.consoleLogError("_getRDPListener", error);
        }
      }.bind(this);
    }

    _downloadDCV () {
      let username = (this.getUsername() ? this.getUsername() : "");
      if (this.getOperatingSystem() === "windows" && this.ip) {
        username = this.ip + "\\" + username;
      }
      const contents = this.downloadDCVTemplate({
        username: username,
        ip: this.ip,
        password: this.password
      });
      const url = LandingPageNavButtons.stringToURL(contents, 'application/octet-stream');
      const fileName = `${this.getName()} Nice DCV (IP ${this.ip}).dcv`;
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return false;
    }

    _getDCVListener () {
      return async function (event) {
        try {
          let doConnect = async function () {
            this._downloadDCV();
            return false;
          }.bind(this);
          let connectCredentialsDialog = new ConnectCredentialsDialog({
            username: this.getUsername(),
            password: this.getPassword(),
            os: this.getOperatingSystem(),
            platform: this.getPlatform(),
            access: "DCV",
            currentAccess: this.getCurrentAccessProtocol(),
            connectAction: doConnect.bind(this)
          });
          connectCredentialsDialog.show();
        } catch (error) {
          Util.consoleLogError("_getRDPListener", error);
        }
      }.bind(this);
    }

    async _updateAccessProtocol (protocol) {
      if (this.accessProtocolIsSupported() || this.enableNiceDcvIsSupported()) {
        await this.getDataService().workflow.updateAccessProtocol(
          this.getConfigId(),
          this.getOperatingSystem(),
          this.getPlatform(),
          this.getAccessProtocolParam(),
          this.getCurrentAccessProtocol(),
          protocol,
          this.getProduct(),
          this.enableNiceDcvIsSupported()
        );
        this.setCurrentAccessProtocol(protocol);
      } else {
        Util.consoleLogWarning('_updateAccessProtocol', `updateAccessProtocol not supported for config ID: ${this.getConfigId()}`);
      }
    }

    async _addAccessPort (protocol, fromPort, toPort) {
      try {
        const currentIPForUser = await this.getDataService().ui.externalIp();
        await this.getDataService().workflow.addCloudAccess(
          this.getConfigId(),
          undefined,
          currentIPForUser,
          fromPort,
          toPort,
          protocol,
          false,
          I18NStringResource.computeResourcePageAddAccessText
        );
      } catch (error) {
        Util.consoleLogError("_addAccessPort", error);
      }
    }

    setIPAddressProperties (machineData) {
      if (machineData && machineData[0]) {
        this.publicIP = machineData[0].public_ip;
        this.privateIP = machineData[0].private_ip;
        this.publicDNS = machineData[0].public_dns;
        this.privateDNS = machineData[0].private_dns;
        this.ip = this.publicIP;
        this.dns = this.publicDNS;
        this.isPublic = true;
        if (!this.ip) {
          this.isPublic = false;
          this.ip = this.privateIP;
          this.dns = this.privateDNS;
        }
      }
    }

    setUserNameProperty (configDetails) {
      this.username = Util.getUsernameFromConfigDetailData(configDetails);
    }

    getUsername () { return this.username; }

    setPasswordProperty (configDetails) {
      this.password = Util.getPasswordFromConfigDetailData(configDetails);
    }

    getPassword () { return this.password; }

    /*
        Public Buttons
    */
    getConnectButton () {
      return this.getDashboardConnectButton();
    }

    getDashboardConnectButton () {
      return this.setupConnectButton(I18NStringResource.computeResourcePageActionConnectDashboard);
    }

    getLicenseManagerConnectButton () {
      return this.setupConnectButton(I18NStringResource.computeResourcePageActionConnectLicenseServer);
    }

    getGenericConnectButton (btnText, btnId) {
      return this.setupConnectButton(btnText, btnId);
    }


    setupConnectButton (btnText, btnId) {
      const isRunning = (((this.ip && this.isPublic)|| this.getConfig().cloud_links) &&  this.getStatus() === CloudStatus.Enum.RUNNING);
      const connectButtonContainer = this.createConnectButton(btnText, isRunning, btnId);
      if (isRunning) {
        const connectButton = connectButtonContainer.querySelector('button');
        connectButton.id = 'connectCtaBtn'; // change ID
        if (connectButton) {
          connectButton.disabled = false;
          connectButton.classList.remove("disabled");
        }
      } else {
        const connectButton = connectButtonContainer.querySelector('button');
        const dataset = new Map();
        if (connectButton) {
          for (const [k, v] of Object.entries(this.getConfig().cloud_links)) {
            dataset["cta-"+v.description] = v;
          }
          if (dataset && dataset[btnId] && !dataset[btnId].always_available) {
            connectButton.disabled = true;
            connectButton.classList.add("disabled");
          }
        }
      }
      return connectButtonContainer;
    }

    getAccessPortManager () {
      const mgr = {
        getCurrentAccessProtocol: this.getCurrentAccessProtocol.bind(this),
        addAccessPort: this._addAccessPort.bind(this),
        updateAccessProtocolProtocol: this._updateAccessProtocol.bind(this)
      };
      return mgr;
    }

    createConnectButton (btnText, isRunning, btnId) {
      let cloudLinks = this.getConfig().cloud_links;
      let btnCloudLink;
      if (cloudLinks) {
        for (const [id, cloudLink] of Object.entries(cloudLinks)) {
          switch (cloudLink.port_from+"") { // int to string
            case Constants.RDP_PORT: {
              cloudLink.fnClick = this._downloadRDP.bind(this);
              break;
            }
            case Constants.NICE_DCV_PORT: {
              if (cloudLink.description === 'nicedcvfile') {
                cloudLink.fnClick = this._downloadDCV.bind(this);
                break;
              }
              let directLink = cloudLink.link;
              let startPort = cloudLink.port_from;
              if (directLink) {
                if (directLink.indexOf("//")<0 && directLink.indexOf(".")) {
                  directLink = "https://"+directLink;
                }
                if (directLink.indexOf("//") && startPort && directLink.indexOf(startPort+"")<0) {
                  directLink = directLink + ":" + startPort;
                }
                if (directLink.indexOf("mwCloudResourceId") < 0) {
                  let password = this.getPassword();
                  if (!password) {
                    password = Util.getPasswordFromConfigDetailData(this.getConfig().params);
                  }
                  if (password.indexOf('%') >= 0) {
                    password = "";
                  }
                  let username = this.getUsername();
                  if (!username) {
                    username = Util.getUsernameFromConfigDetailData(this.getConfig().params);
                  }
                  directLink += `?product=${this.getProduct()}&mwCloudResourceId=${this.getConfig().id}"&username=${username}`;
                  if (password) {
                    directLink += `&password=${password}`;
                  }
                }
                cloudLink.link = directLink;
              }
              break;
            }
          }

          if (cloudLink.auto_manage_access) {
            // keep port_from, port_to as some products make API calls but still need a port to reference
            // for example, port_from and port_to are overridden in the parallel server generator
            cloudLink.firewallPortFrom = cloudLink.port_from+"";
            cloudLink.firewallPortTo = cloudLink.port_to+"";
          }

          if (`cta-${cloudLink.description}` === btnId) {
            btnCloudLink = cloudLink;
          }
        }
      }

      const connectionButtonGenerator = ConnectionButtonGeneratorFactory.getConnectButtonGeneratorByProduct(
        this.getProduct(),
        {
          DIRECT_MATLAB: this.DIRECT_MATLAB,
          product: this.getProduct(),
          configId: this.getConfigId(),
          dns: this.dns,
          dataService: this.getDataService(),
          name: this.getName(),
          username: this.getUsername(),
          password: this.getPassword(),
          operatingSystem: this.getOperatingSystem(),
          platform: this.getPlatform(),
          defaultProxyURL: this.proxyURL,
          accessPortManager: this.getAccessPortManager(),
          btnId: btnId,
          config: this.getConfig(),
          cloudLink: btnCloudLink,
        }
      );

      const connectButtonContainer = connectionButtonGenerator.createConnectButton(btnText, isRunning, btnId);
      return connectButtonContainer;
    }

    buttonCopyIP () {
      if (this.ip) {
        let button = LandingPageNavButtons.createLink(I18NStringResource.computeResourcePageActionCopy, I18NStringResource.computeResourcePageActionCopyTooltip, 'copyTextIcon smallIcon', false, 'resourceCopyIPBtn');
        let text = this.ip;
        let logFn = this.getDataService().logging.logData.bind(this.getDataService().logging);
        LandingPageNavButtons.addClickListener(button, function (event) {
          let copyToClipboardDialog = new CopyToClipboardDialog();
          copyToClipboardDialog.copyToClipboard(text);
          return false;
        }.bind(this), logFn);
        return button;
      }
    }

    buttonRDP () {
      let logFn = this.getDataService().logging.logData.bind(this.getDataService().logging);
      let rdp = LandingPageNavButtons.createLink(I18NStringResource.computeResourcePageActionDownloadRDP, I18NStringResource.computeResourcePageActionDownloadRDPTooltip, 'downloadRDPIcon smallIcon', false, 'resourceDownloadRDPBtn');
      rdp.setAttribute('data-ip', this.ip);
      rdp.setAttribute('data-username', this.getUsername());
      if (this.ip) {
        LandingPageNavButtons.addClickListener(rdp, this._getRDPListener(), logFn);
      }
      return rdp;
    }

    buttonDCV () {
      const logFn = this.getDataService().logging.logData.bind(this.getDataService().logging);
      const dcv = LandingPageNavButtons.createLink(I18NStringResource.computeResourcePageActionDownloadDCV, I18NStringResource.computeResourcePageActionDownloadDCVTooltop, 'downloadRDPIcon smallIcon', false, 'resourceDownloadDCVBtn');
      dcv.setAttribute('data-ip', this.ip);
      dcv.setAttribute('data-username', this.getUsername());
      if (this.ip) {
        LandingPageNavButtons.addClickListener(dcv, this._getDCVListener(), logFn);
      }
      return dcv;
    }

    displayIP () {
      if (this.ip) {
        let ipDiv = document.createElement("div");
        let ipSpan = document.createElement("span");
        ipSpan.className = "ip horizontalTextAndIconContainer";
        ipSpan.textContent = this.ip;
        ipDiv.innerHTML = "";
        ipDiv.appendChild(ipSpan);
        let button = this.buttonCopyIP();
        ipDiv.getElementsByClassName("ip")[0].appendChild(button);
        return ipDiv;
      }

      let naSpan = document.createElement("span");
      naSpan.classList.add("no-ip-address");
      naSpan.textContent = I18NStringResource.computeResourcePageIPNotApplicable;
      return naSpan;
    }

    getOptions () {
      let options = {
        statusClassName:"",
        menuItems: [],
        autoRefresh: false,
        connect: false,
        ip: null,
      };
      options.statusClassName = CloudStatus.getStatusClass(this.getStatus());
      options.menuItems = CloudStatus.getMenuItems(this.getStatus());
      switch (this.getStatus()) {
        case CloudStatus.Enum.PAUSING:
        case CloudStatus.Enum.TERMINATING:
        case CloudStatus.Enum.RESUMING:
        case CloudStatus.Enum.STARTED:
        case CloudStatus.Enum.STARTING:
        case CloudStatus.Enum.UNKNOWN:
        case CloudStatus.Enum.WAITING_FOR_RESOURCES:
        case CloudStatus.Enum.REMOVING_RESOURCES:
        case CloudStatus.Enum.ERROR_OCCURRED:
          options.autoRefresh = true;
        break;

        case CloudStatus.Enum.RUNNING:
          options.ip = this.ip;
          options.connect = this.isPublic;
        break;
      }

      switch (this.getStatus()) {
        case CloudStatus.Enum.RESUMING:
        case CloudStatus.Enum.STARTED:
        case CloudStatus.Enum.STARTING:
          options.ip = "TBD";
        break;
      }
      return options;
    }

    getStatusFromConfig (config) {
      let status = CloudStatus.Enum.UNKNOWN;
      if (CloudResource.isConfigValid(config)) {
        if (config && config.cloud && config.cloud.state) {
          status = CloudStatus.getStatus({ state: config.cloud.state });
        }
        if (this.resourceTerminatedWithErrors()) {
          const configErrors = config.cloud.error;
          if (configErrors[0].reason !== "Resource creation cancelled") {
            if (status !== CloudStatus.Enum.TERMINATED) {
              status = CloudStatus.Enum.PAUSING;
            }
          }
        }
      }
      return status;
    }

    getStatusTransitionPercentageFromConfig (config) {
      let statusTransitionPercentage = 0;
      if (CloudResource.isConfigValid(config)) {
        const status = this.getStatusFromConfig(config);
        if (config.cloud.state_percent && !isNaN(parseFloat(config.cloud.state_percent))) {
          statusTransitionPercentage = (parseFloat(config.cloud.state_percent) * 100);
        }
        if (!statusTransitionPercentage && CloudStatus.isTransitionStatus(status)) {
          statusTransitionPercentage = 1;
        }
      }
      return statusTransitionPercentage;
    }

    resourceTerminatedWithErrors () {
      let terminatedResourceHadErrors = false;
      const config = this.getConfig();
      if (config && config.cloud
                && config.cloud.state === "TERMINATED"
                && config.cloud.error
                && config.cloud.error.length)
      {
        terminatedResourceHadErrors = true;
      }
      return terminatedResourceHadErrors;
    }

    async refreshConfigData (providedConfig) {
      let config = providedConfig;
      try {
        if (!config) {
          config = await this.getDataService().ui.getLatestConfigData(this.getConfigId());
        }
        this.initializePropertiesFromConfig(config);
      } catch (error) {
        Util.consoleLogError("refreshData", error);
      }
    }

  }

  return CloudResource;
}); // require
