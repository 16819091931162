
define([
    'dojo/_base/declare',
    'dojo/Deferred',
    'mw-log/Log'
], function (declare, Deferred, Log) {
    return declare([], {

        pollDelay: 50,

        constructor: function (remote, messageService) {
            this.remote = remote;
            this.messageService = messageService;

            this.pending = 0;

            remote.registerDefaultMessageFactory('OpaqueMessage', function () {
                this.pending += 1;
                return { opaqueMessageDOs: [] };
            }, this);

            remote.registerResponseHandler('OpaqueMessageResponse', function (responses) {
                this.pending -= 1;
                responses.forEach(function (response) {
                    try {
                        if (response.opaqueMessageDOs) {
                            response.opaqueMessageDOs.forEach(messageService.handleMessage,
                                messageService);
                        }
                    } catch (e) {
                        Log.error('Error while handling message: ' + e);
                    }
                });
                this.poll();
            }, this);
        },

        onMessage: function (message) {},

        onConnectionError: function (/* error */) {},

        connect: function () {
            this.enabled = true;
            this.poll();
            return (new Deferred()).resolve();
        },

        disconnect: function () {
            this.enabled = false;
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            return (new Deferred()).resolve();
        },

        cleanup: function () {},

        poll: function () {
            if (this.enabled && this.pending === 0) {
                let that = this;
                this.timeout = setTimeout(function () {
                    that.pending += 1;

                    that.remote.sendMessage({ 'OpaqueMessage': { opaqueMessageDOs: [] } });
                    that.timeout = null;
                }, this.pollDelay);
            }
        },

        doPublish: function (channel, data) {
            clearTimeout(this.timeout);
            this.timeout = null;

            this.pending += 1;

            this.remote.sendMessage({ 'OpaqueMessage': {
                opaqueMessageDOs: [{ channel: channel, data: data }]
            } });
        },

        doSubscribe: function (channel) {},
        doUnsubscribe: function (channel) {},
        doStartBatch: function () {},
        doEndBatch: function () {}

    });
});
