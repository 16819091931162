/* jshint esversion: 8 */
define([
    "lit-element/lit-element.bundled",
    "components/cloudCenterElement",
    "util",
    "dojo/i18n!nls/cloudCenterStringResource",
    "validation/inlineComponentValidation",
    "components/inlinePopover"
], function(
    Lit,
    CloudCenterElement,
    Util,
    I18NStringResource,
    InlineComponentValidation,
    InlinePopover
) {

    class LicenseManager extends CloudCenterElement {

        constructor () {
            super();
        }

        cceInitialize () {
            super.cceInitialize();
            Util.consoleLogTrace('LicenseManager`, `cceInitialize invoked');
             // Default values
             this.elementlabel = "";
             this.poptext = "";
             this.dduxenabled = false;
             this.lminputid = "";
             this.lminputplaceholder = "";
             this.lminputvalue = "";
        }

        // Reactive Properties -- updates rendering when values change
        static get properties () {
            return {
                elementlabel: {type: String},
                poptext: {type: String},
                dduxenabled: {type: Boolean},
                lminputid: {type: String},
                lminputplaceholder: {type: String},
                lminputvalue: {type: String}
            };
        }

        // Called after initial rendering
        firstUpdated () {
            Util.consoleLogTrace(`LicenseManager.firstUpdated`, `invoked`);
            this._updateUI();
        }

        validate (testTargetId, testTargetTag) {
            Util.consoleLogTrace(`LicenseManager.validate`, `invoked with ${testTargetTag}#${testTargetId}`);
            let isValid = true;
            const choice = this.renderRoot.querySelector('input[name="lmChoice"]:checked').value;
            const input = this.renderRoot.querySelector(`input#${this.lminputid}`);
            if (choice !== "onlineLicensing" && (input.value === "" || !this.getElementValidator(testTargetId)() )) {
                isValid = false;
            }
            return isValid;
        }

        _updateUI (event) {
            const choice = this.renderRoot.querySelector('input[name="lmChoice"]:checked').value;
            const input = this.renderRoot.querySelector(`input#${this.lminputid}`);
            if (choice === "onlineLicensing") {
                input.value = "";
                // Cause the inline validation to clear/hide
                input.dispatchEvent(new Event("focus"));
                // Cause the input to validate again with the new value, which will update the UI buttons
                input.dispatchEvent(new Event("blur"));
                input.disabled = true;
                input.classList.add('disabled');
            } else {
                input.disabled = false;
                input.classList.remove('disabled');
                input.focus();
            }
        }

        // Render element template
        render () {
            return Lit.html`
                <div class="labelContainer">
                    <label for="${'licenseManagerFieldset' + this.timestamp}">${this.elementlabel}</label>
                </div>
                <fieldset class="licenseManagerFieldset" id="${'licenseManagerFieldset' + this.timestamp}">
                    <div class="radioButtonContainer">
                        <input type="radio" name="lmChoice" @change=${this._updateUI} @click=${this._logClick} value="onlineLicensing" id="${'onlineLicensing' + this.timestamp}" ?checked=${!this.lminputvalue} />
                        <label for="${'onlineLicensing' + this.timestamp}">${I18NStringResource.licenseManagerOnlineRadioLabel}</label>
                    </div>
                    <div class="radioButtonContainer">
                        <input type="radio" name="lmChoice" @change=${this._updateUI} @click=${this._logClick} value="networkLicenseMgr" id="${'networkLicenseMgr' + this.timestamp}" ?checked=${this.lminputvalue} />
                        <label for="${'networkLicenseMgr' + this.timestamp}">${I18NStringResource.licenseManagerNetworkRadioLabel}</label>
                    </div>
                    <div class="section2InputValidationContainer">
                        <input type="text" id="${this.lminputid}" name="${this.lminputid}" placeholder="${this.lminputplaceholder}" value="${this.lminputvalue}">
                        <inline-validation elementid="${this.lminputid}" />
                    </div>
                </fieldset>
                <inline-popover poptext="${this.poptext}" />`;
        }
    }
    // Register custom element '<license-manager elementlabel="mylabel", poptext="popover text" dduxenabled="true" />'
    customElements.define('license-manager', LicenseManager);

    return LicenseManager;
});
