define([
    "util",
    "mw-ddux/UsageLogger"
], function(
    Util,
    UsageLogger
) {
    class LoggingService {

        constructor () { }

        initialize () {
            this.startUsageLogger();
        }

        logData (keyValuePairs) {
            if (Util.isClientDDUXEnabled()) {
                if (!UsageLogger.isRunning()) {
                    this.startUsageLogger();
                }
                UsageLogger.logData(
                    keyValuePairs,
                    {
                        product: "CLOUDCENTER",
                        appComponent: "CC_UI",
                        eventKey: "CC_SPA"
                    },
                    {
                        verifyParametersOnly: false
                    }
                );
            }
        }

        startUsageLogger () {
            if (Util.isClientDDUXEnabled()) {
                let startParams = {
                    product: "CLOUDCENTER",
                    release: Util.getAppVersion(),
                    appComponent: "CC_UI",
                    eventKey: "CC_SPA",
                    sendDirect: true,
                    sendToDev: false,
                    applicationName: "CLOUD_CENTER",
                    applicationVersion: Util.getAppVersion(),
                    applicationKey: "fdefc29f-b188-499a-890d-30a4f7e20292"
                };
                if (Util.getDDUXEnv()) {
                    startParams.endPointNumber = Util.getDDUXEnv();
                    startParams.sendToDev = true;
                }
                UsageLogger.start(startParams);
            }
        }
      
    }
    return LoggingService;
});