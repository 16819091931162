/* Copyright 2020 The MathWorks, Inc. */
define([
    'mw-progress-indicator/view/ViewStore',
    'mw-progress-indicator/view/ViewInterface',
    'mw-progress-indicator/view/SpinnerView'
], function (ViewStore) {
    'use strict';

    class ViewFactory {
        static createView () {
            const viewInstance = ViewStore.getViewInstance.call(this);
            return viewInstance;
        }
    }

    return ViewFactory;
});
