define([
], function () {
    'use strict';
    const SIZE = Object.freeze({
        XSMALL: 'xsmall',
        SMALL: 'small',
        MEDIUM: 'medium',
        LARGE: 'large',
        XLARGE: 'xlarge'
    });

    return SIZE;
});
