/* jshint esversion: 8 */
define([
  "service/productConnect/connectionButtonGenerator"
], function (
  ConnectionButtonGenerator
) {

  class ClusterConnectionButtonGenerator extends ConnectionButtonGenerator {

    constructor (args) {
      super(args);
    }

    _getConnectButtonListener (outputName, port, connectDialogExplanation) {
      return function (event) {
        if (event) {
          event.preventDefault();
        }
          
        return false;
      }.bind(this);
    }
  }

  return ClusterConnectionButtonGenerator;
});
