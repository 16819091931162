/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "pages/abstractPage",
  "templates/credentialsPage",
  "dialogs/selectCredentialTypeDialog",
  "dialogs/warningDialog",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "util",
  "constants",
  "service/cachedDataService",
  "service/landingPageNavButtons",
  "dialogs/inputPromptDialog",
  "components/inlinePopover",
  "components/editableText"
], function( _, $, AbstractPage, PageTemplate, SelectCredentialTypeDialog,
             WarningDialog, I18NStringResource, DojoString, Util, CCWA_Constants,
             CachedDataService, LandingPageNavButtons, InputPromptDialog,
             InlinePopover, EditableText ) {

  /**
   * This subclass of MatlabDriveApplication uses
   * Mathworks FileBrowser2 widget for the tree controller.
   */
  class CredentialsPage extends AbstractPage {
    constructor(params) {
      super(params);
      if (Util.clientCachingIsEnabled()) {
        this.localDataService = new CachedDataService({
          baseService: this.getDataService()
        });
        this.initLocalDataService();
      } else {
        this.localDataService = this.getDataService();
      }
      this.autoCancelUpdateCredentialListMethod = Util.makeAutoCancel(this.updateCredentialList.bind(this));
      this.pageTemplate = PageTemplate;
      this.useEditableText = true;
      this.events = {
        "click div#credentialsPageView > div.facetBar > ul.facets > li > a#resourceLink": "goToResource",
        "dragover div.computeContainer": "dragoverNotAllowed",
        "click a#createAzureRefreshTokenCredential" : "createAzureRefreshTokenCredential",
        "click a#createAWSRoleCredential" : "goToCreateAWSCredentialPage",
        "click a#importAWSRoleCredential" : "goToImportAWSCredentialPage",
        "click #addCredentialsButton" : "openCredTypeSelectionDialog",
        "click button.btn.btn-link.default-link.CredentialsPage": "goToHome",
        "click button#credEditButton": "editCredentialDescription",
        "click button#credDeleteButton": "deleteCredential",
        "click button#unshareButton": "unshareCredential"
      };
      // bind 'this' in the listed methods to the current 'this',
      // even when called by other modules.
      _.bindAll(this, "logoutPage", "getAuthManager", "goToResource",
      "createAzureRefreshTokenCredential",
      "goToCreateAWSCredentialPage", "goToImportAWSCredentialPage", "goToHome",
      "editCredentialDescription", "deleteCredential", "unshareCredential");
    }

    getLocalDataService () { return this.localDataService; }

    initLocalDataService () {
      this.getLocalDataService().init(
        [
          "ui",
          "admin",
          "logging",
          "legacy_parallel/credential",
          "sharing"
        ], // components to add to localDataService
        // APIs to cache
        [
          {
            set: "admin/listAllUserCreds",
            clear: [
              "ui/updateCredentialDescription",
              "ui/deleteCredential",
              "ui/createCredential",
              "ui/importCredential"
            ]
          }
        ]
      );
    }

    goToCredential (event) { /* do nothing */ }

    startPage (msgHandler) {
      super.startPage();
      if (!msgHandler || typeof msgHandler !== "function") {
        throw new TypeError("Invalid message handler argument");
      }
    }

    stopPage () {
      super.stopPage();
    }

    render () {
      this.getLocalDataService().clearAll();
      let am = this.getAuthManager();
      const touchevents = Util.touchEventsEnabled();
      let templateParams = {
        headerNavigation : this.renderNavigationHeader(),
        computeLinkText : I18NStringResource.computeLinkText,
        credentialLinkText : I18NStringResource.credentialLinkText,
        credentialsResourcesLabel : I18NStringResource.credentialsPageCredentialsResourcesLabel,
        defaultCredentialsResourcesMsg : I18NStringResource.credentialsPageDefaultCredentialsResourcesMsg,
        createCredential : I18NStringResource.credentialsPageCreateCredentialButtonText,
        createAzureCredential : I18NStringResource.credentialsPageCreateAzureCredentialText,
        createAzureRefreshTokenCredential : I18NStringResource.credentialsPageCreateAzureRefreshTokenCredentialText,
        createAWSCredential : I18NStringResource.credentialsPageCreateAWSCredentialButtonText,
        createAWSRoleCredential : I18NStringResource.credentialsPageCreateAWSRoleCredentialText,
        importAWSRoleCredential : I18NStringResource.credentialsPageImportAWSRoleCredentialText,
        credTypeHeading: I18NStringResource.credentialsPageCredTypeHeading,
        credName : I18NStringResource.credentialsPageTableAccount,
        credAccountIdentifier : I18NStringResource.credentialsPageTableDescription,
        credPlatform : I18NStringResource.credentialsPageTablePlatform,
        credCredentialType : I18NStringResource.credentialsPageTableCredentialType,
        credEditLabel : I18NStringResource.credentialsPageMenuEditLabel,
        credDeleteLabel : I18NStringResource.credentialsPageMenuDeleteLabel,
        credUnshareLabel : I18NStringResource.credentialsPageMenuUnshareLabel,
        awsCredType: I18NStringResource.credentialsPageAWSCredentialType,
        oauthCredType: I18NStringResource.credentialsPageAzureCredentialType,
        platformAmazon: I18NStringResource.credentialsPagePlatformAmazon,
        awsAccountName : I18NStringResource.credentialsPageAWSAccountName,
        platformMicrosoft: I18NStringResource.credentialsPagePlatformMicrosoft,
        credMoreOptionsTitle: I18NStringResource.credentialsPageMoreOptionTitle,
        wizardCredTypeHeader: I18NStringResource.credentialsPageAddCredentialDialogTitle,
        AddWizardButtonLabel: I18NStringResource.credentialsPageAddButtonLabel,
        cancelWizardButtonLabel: I18NStringResource.credentialsPageCloseButtonLabel,
        editDialogTitle: I18NStringResource.credentialsPageEditDialogTitle,
        editDialogExplanation: I18NStringResource.credentialsPageEditDialogExplanation,
        editDialogConfirmButtonLabel: I18NStringResource.credentialsPageEditDialogConfirmButtonLabel,
        editDialogCancelButtonLabel: I18NStringResource.credentialsPageEditDialogCancelButtonLabel,
        editDialogDescriptionInputLabel: I18NStringResource.credentialsPageEditDialogDescriptionInput,
        noResourcesFoundMessage: I18NStringResource.credentialsPageNoCredentialsFound,
        credentialsPageReservedOwnerAction: I18NStringResource.credentialsPageReservedOwnerAction,
        useEditableText: this.useEditableText
      };

      let htmlResult = this.pageTemplate(templateParams);
      this.$el.empty().html(htmlResult);
      if (!touchevents) {
        $('[data-bs-toggle="tooltip"]').tooltip();
      }
      this.renderMatrix();
      this.getLocalDataService().legacy_parallel.credential.syncCred(); // Call CC1's cred sync. No need to await since CC1 would return a response and work on cred sync in a async manner.
      try{
        this.autoCancelUpdateCredentialList();
      } catch(error) {
        Util.notify("ERROR", DojoString.substitute(I18NStringResource.credentialsPageErrorLoadingCredentials, [JSON.stringify(error)]));
      }
    }

    // a Generator method that can be cancelled on a yield
    * updateCredentialList () {
      const adminService = this.getLocalDataService().admin;
      const creds = yield this.spinnerAroundFunc(adminService.listAllUserCreds.bind(adminService));
      yield this.renderList(creds);
    }

    autoCancelUpdateCredentialList () {
      return this.autoCancelUpdateCredentialListMethod();
    }

    getMessageFromError (error) {
      let message = "";
      if (error && typeof error === 'object') {
        if (!message && error.message) {
          message = error.message;
        }
        if(!message && error.status) {
          message = error.status;
        }
      } else if (error && typeof error === 'string') {
        message = error;
      }
      if (!message) {
        message = I18NStringResource.unknownValue;
      }
      return message;
    }

    async spinnerAroundFunc (func, ...params) {
      if (!func || typeof func !== 'function') {
        throw new TypeError("Invalid func argument");
      }
      let res;
      try {
        this.addSpinnerToCredList();
        res = await func(...params);
      } catch (error) {
        Util.consoleLogError("spinnerAroundFunc", error);
        const message = this.getMessageFromError(error);
        Util.notify('ERROR', DojoString.substitute(I18NStringResource.credentialsPageErrorLoadingCredentials, [message]));
      } finally {
        this.removeSpinnerFromCredList();
      }
      return res;
    }

    addSpinnerToCredList (targetElement) {
      let element = targetElement;
      if (!element) {
        element = this.el.querySelector("div.credentialInstanceContainer > ol#credentialInstanceList.collection-container");
      }
      let spinner = Util.createSpinner();
      if (element && spinner) {
        element.appendChild(spinner);
      }
    }

    removeSpinnerFromCredList (targetElement) {
      let element = targetElement;
      if (!element) {
        element = this.el.querySelector("div.credentialInstanceContainer > ol#credentialInstanceList.collection-container");
      }
      if (element) {
        let spinners = element.querySelectorAll("div.progressSpinnerContainer");
        let spinner;
        if (spinners && spinners.length === 1) {
          element.removeChild(spinners[0]);
        }
      }
    }

    appendEmptyTableMessageRow () {
      let container = this.el.querySelector("div.credentialInstanceContainer");
      let credList;
      if (container) {
        credList = container.querySelector("ol#credentialInstanceList");
      }
      if (credList) {
        let rowCount = credList.querySelectorAll('li.item-container-for-cred-page:not(.emptyTable)').length;
        if (rowCount === 1) {
          Util.showNoCredentialsMessage();
          this.openCredTypeSelectionDialog();
        }
      }
    }

    removeEmptyTableMessageRow () {
      let container = this.el.querySelector("div.credentialInstanceContainer");
      let credList;
      if (container) {
        credList = container.querySelector("ol#credentialInstanceList");
      }
      if (credList) {
        let emptyTableRow = credList.querySelector('li.emptyTable');
        if (emptyTableRow) {
          credList.removeChild(emptyTableRow);
        }
      }
    }

    sortCredentialList () {
      let container = this.el.querySelector("div.credentialInstanceContainer");
      let credList;
      if (container) {
        credList = container.querySelector("ol#credentialInstanceList");
      }
      if (credList) {
        while (true) {
            const li = credList.getElementsByTagName("li");
            let found = false;
            let i;
            for (i = 1; i < (li.length - 1); i++) {
                let firstLi = "";
                let nextLi = "";

                if (this.useEditableText) {
                  let selector = "div.attribute-credential-page[data-name='Provider']";
                  let firstLiEle = li[i].querySelector(selector);
                  if (firstLiEle) {
                    firstLi += firstLiEle.innerHTML.toLowerCase();
                  }
                  let nextLiEle = li[i+1].querySelector(selector);
                  if (nextLiEle) {
                    nextLi += nextLiEle.innerHTML.toLowerCase();
                  }
                  selector = "div.attribute-credential-page[data-name='Description'] editable-text";
                  firstLiEle = li[i].querySelector(selector);
                  if (firstLiEle) {
                    firstLi += firstLiEle.resourcenamevalue.toLowerCase();
                  }
                  nextLiEle = li[i+1].querySelector(selector);
                  if (nextLiEle) {
                    nextLi += nextLiEle.resourcenamevalue.toLowerCase();
                  }
                } else {
                  for (const selector of ["div.attribute-credential-page[data-name='Provider']", "div.attribute-credential-page[data-name='Description']"]) {
                    const firstLiEle = li[i].querySelector(selector);
                    if (firstLiEle) {
                      firstLi += firstLiEle.innerHTML.toLowerCase();
                    }
                    const nextLiEle = li[i+1].querySelector(selector);
                    if (nextLiEle) {
                      nextLi += nextLiEle.innerHTML.toLowerCase();
                    }
                  }
                }

                if (firstLi > nextLi) {
                    found = true;
                    break;
                }
            }
            if (found) {
                li[i].parentElement.insertBefore(li[i + 1], li[i]);
                continue;
            }
            break;
        }
      }
    }

    toggleCredentialList (show = true) {
      let container = this.el.querySelector("div.credentialInstanceContainer");
      let credList;
      if (container) {
        credList = container.querySelector("ol#credentialInstanceList");
      }
      if (credList) {
        let first = true;
        for (const li of credList.querySelectorAll("li")) {
          if (first) { //header row
            first = false;
            continue;
          }
          li.style.display = show ? "" : "none"; //cred li default display is "" so setting back to ""
        }
      }
    }

    emptyCredentialList () {
      let container = this.el.querySelector("div.credentialInstanceContainer");
      let credList;
      if (container) {
        credList = container.querySelector("ol#credentialInstanceList");
      }
      if (credList) {
        let rows = credList.querySelectorAll('li.item-container-for-cred-page:not(.credential-list-header)');
        if (rows && rows.length) {
          for (let i = 0; i < rows.length; i++) {
            credList.removeChild(rows[i]);
          }
        }
      }
    }

    // Render credential list for the same credential type.
    // The credentials under the same credential type are rendered in this function
    async renderList (data) {
      // Get the containers and init variables
      let container = this.el.querySelector("div.credentialInstanceContainer");
      let credList;
      if (container) {
        credList = container.querySelector("ol#credentialInstanceList");
      }
      this.emptyCredentialList();
      if (credList && data && typeof data === 'object') {
        Util.hideNoCredentialsMessage();
        // For each credential under the credType(e.g. AWS, Azure ...), add a row to the list to show the credential
        for(let credentialId in data) {
          await this.renderCredentialRow(credList, credentialId, data);
        }
        this.appendEmptyTableMessageRow();
      }
      this.sortCredentialList();
      this.toggleCredentialList(true);

      // put at end of event queue to allow inline-popover to render first.
      setTimeout(function() {Util.initializePopovers();}, 0);
    }

    async renderCredentialRow (credList, credentialId, data) {
      // get account info
      let dispPlatform = data[credentialId].platform;
      let dispCredType = data[credentialId].credtype;
      let dispAccount = data[credentialId].account;
      let dispDescription = data[credentialId].description;
      let isOwner = data[credentialId].owner;
      let isSubscriptionWarning = false;
      const platform = Util.getPlatformFromCredentialPlatform(dispPlatform);
      if (platform === CCWA_Constants.UNKNOWN_CREDENTIAL_TYPE_ID) {
        Util.consoleLogWarning(`renderCredentialRow`, `Unable to render row because of unknown dispPlatform value: ${dispPlatform}`);
        return;
      }
      let popText = ``;
      popText = await this.getLocalDataService().ui.renderCredentialList(platform, credentialId, popText, dispDescription, data);
      if (popText.indexOf("credPopoverWarningIcon") > 0) {
        isSubscriptionWarning = true;
      }
      if (isOwner !== false) {
        isOwner = true; // legacy isOwner calls can have null isOwner. Those are actual owners.
      }

      let i18nPlatform = I18NStringResource[`cloudAccount_${dispPlatform}`];
      if (i18nPlatform) {
        dispPlatform = i18nPlatform;
      }
      let i18nCredType = I18NStringResource[`cloudAccount_${dispCredType}`];
      if (i18nCredType) {
        dispCredType = i18nCredType;
      }

      // clone the <li> from html and assign values
      this.liEnum = {
        hiddenCredID: 0,
        dispAccount: 1,
        dispDescription: 2,
        dispPlatform: 3,
        dispCredType: 4
      };
      let liToBeCopied = credList.querySelector(".item-container-for-cred-page.credential-list-header");
      if (liToBeCopied) {
        let newLi = liToBeCopied.cloneNode(true);
        newLi.style.display = "none";
        newLi.classList.remove("credential-list-header");
        newLi.children[this.liEnum.hiddenCredID].value = credentialId;
        newLi.children[this.liEnum.hiddenCredID].id = credentialId;
        newLi.children[this.liEnum.hiddenCredID].dataset.typeId = data[credentialId]['credtype-id'];
        newLi.children[this.liEnum.hiddenCredID].dataset.type = data[credentialId].credtype;
        newLi.children[this.liEnum.dispAccount].children[0].textContent = dispAccount;
        const popover = document.createElement('inline-popover');
        popover.poptext = popText;
        popover.iswarning = isSubscriptionWarning;
        popover.id = "popover_" + credentialId;
        newLi.children[this.liEnum.dispAccount].appendChild(popover);

        if (this.useEditableText) {
          const credDescriptionContainer = newLi.children[this.liEnum.dispDescription].children[0];
          credDescriptionContainer.classList.add("editableText");
          credDescriptionContainer.textContent = "";
          const editableText = document.createElement('editable-text');
          editableText.resourcenamevalue = dispDescription;
          editableText.dduxenabled = true;
          editableText.inputfieldname = "Description";
          editableText.validator = "^\\w+.*$";
          editableText.readonly = !isOwner;
          editableText.maxlength = 75;
          editableText.editbuttontitle = I18NStringResource.credentialDescriptionEditButtonTitle;
          editableText.updateMethod = async (data) => {
            let result = false;
            const rowChildren = credDescriptionContainer.parentElement.parentElement.children;
            const credTypeId = rowChildren[0].dataset.typeId;
            const credId = credentialId;
            const description = data.params["Description"];
            try {
              await this.getLocalDataService().ui.updateCredentialDescription(credId, credTypeId, description);
              result = true;
            } catch (error) {
              Util.consoleLogWarning('updateMethod', error);
            }
            return result;
          };
          credDescriptionContainer.appendChild(editableText);
        } else {
          newLi.children[this.liEnum.dispDescription].children[0].textContent = dispDescription;
        }

        newLi.children[this.liEnum.dispPlatform].children[0].textContent = dispPlatform;
        newLi.children[this.liEnum.dispCredType].children[0].textContent = dispCredType;
        const credentialButtonsContainer = newLi.querySelector('div.credentialButtonsContainer');
        const credEditButton = credentialButtonsContainer.querySelector('button#credEditButton');
        const credDeleteButton = credentialButtonsContainer.querySelector('button#credDeleteButton');
        const unshareButton = credentialButtonsContainer.querySelector('button#unshareButton');
        if (credEditButton) {
          credEditButton.style.display = 'inline-block';
        }
        if (isOwner) {
          unshareButton.style.display = 'none';
          credDeleteButton.style.display = 'inline-block';
        } else {
          if (credEditButton) {
            credEditButton.style.visibility = 'hidden';
          }
          credDeleteButton.style.display = 'none';
          unshareButton.style.display = 'inline-block';
        }
        credList.appendChild(newLi);
      }
    }

    openCredTypeSelectionDialog (event) {
      if (event && event.preventDefault) { event.preventDefault(); }
      if (event && event.currentTarget) {
        // Register button click with DDUX if we have a button ID
        this.logEvent(event);
      }
      let clickedButton = document.getElementById("addCredentialsButton");
      if (clickedButton) {
        if (clickedButton.disabled) {
          return;
        }
        clickedButton.disabled = true;
        clickedButton.classList.add("disabled");
      }
      //show modal dialog for confirmation
      let selectCredentialTypeDialog = new SelectCredentialTypeDialog();
      selectCredentialTypeDialog.show();

      if (clickedButton) {
        clickedButton.disabled = false;
        clickedButton.classList.remove("disabled");
      }
    }

    async createAzureRefreshTokenCredential (event) {
      let clickedButton;
      if (event && event.preventDefault) { event.preventDefault(); }
      if (event && event.currentTarget) {
        // Register button click with DDUX if we have a button ID
        this.logEvent(event);
        if (event.currentTarget.tagName && event.currentTarget.tagName.toUpperCase() === 'BUTTON') {
          clickedButton = event.currentTarget;
        }
      }
      if (!clickedButton) {
        clickedButton = document.getElementById("createAzureRefreshTokenCredential");
      }
      if (clickedButton) {
        if (clickedButton.disabled) {
          return;
        }
        Util.disableButton(clickedButton);
        try {
          await this.getLocalDataService().ui.createCredential('azure', {});
        } catch(err) {
          Util.notify("ERROR", DojoString.substitute(I18NStringResource.credentialsPageErrorGettingOAuthURL, [JSON.stringify(err)]));
        } finally {
          Util.enableButton(clickedButton);
        }
      }
      return false;
    }

    goToCreateAWSCredentialPage (event) {
      let clickedButton;
      if (event && event.preventDefault) { event.preventDefault(); }
      if (event && event.currentTarget) {
        // Register button click with DDUX if we have a button ID
        this.logEvent(event);
        if (event.currentTarget.tagName && event.currentTarget.tagName.toUpperCase() === 'BUTTON') {
          clickedButton = event.currentTarget;
        }
      }
      if (!clickedButton) {
        clickedButton = document.getElementById("createAWSRoleCredential");
      }
      if (clickedButton) {
        if (clickedButton.disabled) {
          return;
        }
        Util.disableButton(clickedButton);
        $('#wizardCredTypeDialog').on('hidden.bs.modal', function() {  // since the dialog closing animation takes time, do redirection after animation finishes.
          $.event.trigger("changetoawscreaterolepage:ccwa", {path: `${CCWA_Constants.AMAZON_AWS_CREATE_ROLE_CREDENTIAL_ID}`});
        });
        Util.enableButton(clickedButton);
      }
    }

    goToImportAWSCredentialPage (event) {
      let clickedButton;
      if (event && event.preventDefault) { event.preventDefault(); }
      if (event && event.currentTarget) {
        // Register button click with DDUX if we have a button ID
        this.logEvent(event);
        if (event.currentTarget.tagName && event.currentTarget.tagName.toUpperCase() === 'BUTTON') {
          clickedButton = event.currentTarget;
        }
      }
      if (!clickedButton) {
        clickedButton = document.getElementById("importAWSRoleCredential");
      }
      if (clickedButton) {
        if (clickedButton.disabled) {
          return;
        }
        Util.disableButton(clickedButton);
        $('#wizardCredTypeDialog').on('hidden.bs.modal', function() {
          $.event.trigger("changetoawsimportrolepage:ccwa", {path: `${CCWA_Constants.AMAZON_AWS_CREATE_ROLE_CREDENTIAL_ID}`});
        });
        Util.enableButton(clickedButton);
      }
    }

    editCredentialDescription (event) {
      let rowChildren = null;
      if (event && event.currentTarget) {
        // Register button click with DDUX if we have a button ID
        this.logEvent(event);
        rowChildren = event.currentTarget.parentElement.parentElement.children;
      }
      if (rowChildren) {
        let credID = rowChildren[0].value;
        let credTypeId = rowChildren[0].dataset.typeId;
        let desc = rowChildren[2].textContent.trim();
        if (desc) {
          // description is user facing and is set by user so escape
          desc = _.escape(desc);
        }
        this.openEditCredDialog(credID, credTypeId, desc);
      }
    }

    async doCredentialDeletion (parentRow, credID) {
      /*
          Needed to be bound via bind():
            parentRow
            credID
      */
      let returnValue;
      const parentRowArgIsValid = (
        parentRow
        && typeof parentRow === 'object'
        && typeof parentRow.remove === 'function'
      );
      const credIDArgIsValid = (credID && typeof credID === 'string');
      if (!parentRowArgIsValid) {
        Util.consoleLogError("doCredentialDeletion", "Invalid parentRow argument");
        return returnValue;
      }
      if (!credIDArgIsValid) {
        Util.consoleLogError("doCredentialDeletion", "Invalid credID argument");
        return returnValue;
      }
      let credTypeId = (parentRow && parentRow.children && parentRow.children.length) ? parentRow.children[0].dataset.typeId : "";
      if (credTypeId) {
        const btnContainer = parentRow.querySelector(`div.credentialButtonsContainer`);
        let delBtn;
        if (btnContainer) {
          delBtn = btnContainer.querySelector('button#credDeleteButton');
        }
        try {
          if (delBtn) {
            delBtn.style.display = 'none';
          }
          if (btnContainer) {
            this.addSpinnerToCredList(btnContainer);
          }
          returnValue = await this.getLocalDataService().ui.deleteCredential(credID, credTypeId);
          if (btnContainer) {
            this.removeSpinnerFromCredList(btnContainer);
          }
          if (delBtn) {
            delBtn.style.display = 'block';
          }
          parentRow.remove();
          AbstractPage.SetProductInfo("matlab", false);
          try {
            this.getLocalDataService().legacy_parallel.credential.syncCred();
          } catch (error) {
            Util.consoleLogError("Error syncing with legacy Cloud Center", error);
          }
        } catch (error) {
          this.removeSpinnerFromCredList(btnContainer);
          if (delBtn) {
            delBtn.style.display = 'block';
            Util.enableButton(delBtn);
          }
          Util.consoleLogError("doCredentialDeletion", error);
          if (typeof returnValue === 'undefined') {
            parentRow.style.visibility = 'visible';
            Util.notify("ERROR", I18NStringResource.credentialPageErrorDeleting);
          } else {
            parentRow.remove();
          }
        } finally {
          let credList = this.el.querySelector('ol#credentialInstanceList.collection-container');
          if (credList) {
            let rows = credList.querySelectorAll('li.item-container-for-cred-page:not(.credential-list-header)');
            if (rows && rows.length === 0) {
                this.appendEmptyTableMessageRow();
            }
          }
        }
      }
      return returnValue;
    }

    deleteCredential (event) {
      let parentRow = null;
      if (event && event.currentTarget) {
        if (event.currentTarget.tagName === 'BUTTON') {
          const deleteButton = event.currentTarget;
          deleteButton.disabled = true;
          deleteButton.classList.add('disabled');
        }
        // Register button click with DDUX if we have a button ID
        this.logEvent(event);
        parentRow = event.currentTarget.parentElement.parentElement;
      }
      if (!parentRow) {
        Util.consoleLogWarning("deleteCredential", "Could not determine parentRow from event target. Unable to proceed");
        return;
      }
      let credID = parentRow.children[0].value;
      if (!credID) {
        Util.consoleLogWarning("deleteCredential", "Could not determine credID value from event target. Unable to proceed");
      }
      //show modal dialog for confirmation
      let deleteWarningDialog = new WarningDialog({
        title: I18NStringResource.credentialsPageDeleteCredentialDialogTitle,
        text: I18NStringResource.credentialsPageDeleteCredentialDialogText,
        actionButtonLabel: I18NStringResource.wizardWarningDialogProceedButtonLabel,
        closeButtonLabel: I18NStringResource.wizardWarningDialogCancelButtonLabel,
        hideIcon: true,
        showProceedPrompt: true,
        proceedPrompt: I18NStringResource.credentialsPageDeleteCredentialDialogPrompt,
        actionFn: this.doCredentialDeletion.bind(this, parentRow, credID),
        modalContainerId: "modalContainer",
        classname: "deleteCredential"
      });
      deleteWarningDialog.show();
    }

    async doUnshareCredential (parentRow, credID) {
      /*
          Needed to be bound via bind():
            parentRow
            credID
      */
            let returnValue;
            const parentRowArgIsValid = (
              parentRow
              && typeof parentRow === 'object'
              && typeof parentRow.remove === 'function'
            );
            const credIDArgIsValid = (credID && typeof credID === 'string');
            if (!parentRowArgIsValid) {
              Util.consoleLogError("doUnshareCredential", "Invalid parentRow argument");
              return returnValue;
            }
            if (!credIDArgIsValid) {
              Util.consoleLogError("doUnshareCredential", "Invalid credID argument");
              return returnValue;
            }
            try {
              parentRow.style.visibility = 'hidden';
              this.addSpinnerToCredList();
              returnValue = await this.getLocalDataService().sharing.declineSharedCredential(credID);
              parentRow.remove();
              AbstractPage.SetProductInfo("matlab", false);
              this.removeSpinnerFromCredList();
              try {
                this.getLocalDataService().legacy_parallel.credential.syncCred();
              } catch (error) {
                Util.consoleLogError("Error syncing with legacy Cloud Center", error);
              }
            } catch (error) {
              this.removeSpinnerFromCredList();
              Util.consoleLogError("doUnshareCredential", error);
              if (typeof returnValue === 'undefined') {
                parentRow.style.visibility = 'visible';
                Util.notify("ERROR", I18NStringResource.credentialPageErrorUnsharing);
              } else {
                parentRow.remove();
              }
            } finally {
              let credList = this.el.querySelector('ol#credentialInstanceList.collection-container');
              if (credList) {
                let rows = credList.querySelectorAll('li.item-container-for-cred-page:not(.credential-list-header)');
                if (rows && rows.length === 0) {
                    this.appendEmptyTableMessageRow();
                }
              }
            }
            return returnValue;
          }

    unshareCredential (event) {
      let parentRow = null;
      if (event && event.currentTarget) {
        // Register button click with DDUX if we have a button ID
        this.logEvent(event);
        parentRow = event.currentTarget.parentElement.parentElement;
      }
      if (!parentRow) {
        Util.consoleLogWarning("unshareCredential", "Could not determine parentRow from event target. Unable to proceed");
        return;
      }
      let credID = parentRow.children[0].value;
      if (!credID) {
        Util.consoleLogWarning("unshareCredential", "Could not determine credID value from event target. Unable to proceed");
      }
      //show modal dialog for confirmation
      let unshareWarningDialog = new WarningDialog({
        title: I18NStringResource.credentialsPageUnshareCredentialDialogTitle,
        text: I18NStringResource.credentialsPageUnshareCredentialDialogText,
        actionButtonLabel: I18NStringResource.wizardWarningDialogProceedButtonLabel,
        closeButtonLabel: I18NStringResource.wizardWarningDialogCancelButtonLabel,
        hideIcon: true,
        showProceedPrompt: true,
        proceedPrompt: I18NStringResource.credentialsPageUnshareCredentialDialogPrompt,
        actionFn: this.doUnshareCredential.bind(this, parentRow, credID),
        modalContainerId: "modalContainer",
        classname: "unshareCredential"
      });
      unshareWarningDialog.show();
    }

    openEditCredDialog (credID, credTypeId, existingDesc) {
      let inputPromptDialog = new InputPromptDialog({
        dialogTitle: I18NStringResource.credentialsPageEditDialogTitle,
        explanation: I18NStringResource.credentialsPageEditDialogExplanation,
        actionButtonLabel: I18NStringResource.credentialsPageEditDialogConfirmButtonLabel,
        cancelButtonLabel: I18NStringResource.credentialsPageEditDialogCancelButtonLabel,
        actionFn: this.updateCredDescription.bind(this),
        dduxLogger: this.getDataService().logging,
        hiddenArray: [
          {id: "credID", value: credID},
          {id: "credTypeId", value: credTypeId}
        ],
        inputArray: [
          {label: I18NStringResource.credentialsPageEditDialogDescriptionInput, id: "descriptionInput", type: "input", value: existingDesc, maxlength: 50},
        ]
      });
      inputPromptDialog.show();
    }

    /* istanbul ignore next */
    updateDescriptionOfSpecifiedRow (credID, description) {
      const credRow = document.getElementById(credID);
      if (!credRow) {
        throw new Error(`Unable to locate element with ID ${credID}`);
      }

      if (this.useEditableText) {
        // credRow.parentElement.children[this.liEnum.dispDescription].children[0].textContent = description;
        const editableText = credRow.parentElement.querySelector('editable-text');
        if (editableText) {
          editableText.resourcenamevalue = description;
        }
      } else {
        credRow.parentElement.children[this.liEnum.dispDescription].children[0].textContent = description;
      }
    }

    async updateCredDescription (values) {
      if (values) {
        // FormData is already escaped so unescape to get real values.
        let description = _.unescape(values.get('descriptionInput'));
        let credId = _.unescape(values.get('credID'));
        let credTypeId = _.unescape(values.get('credTypeId'));
        try {
          await this.getLocalDataService().ui.updateCredentialDescription(credId, credTypeId, description);
          this.updateDescriptionOfSpecifiedRow(credId, description);
          Util.notify("NORMAL", I18NStringResource.credentialsPageEditUpdated);
        } catch (error) {
          Util.notify("ERROR", DojoString.substitute(I18NStringResource.credentialsPageErrorUpdateFailed, [JSON.stringify(error)]));
        }
      }
    }

  }
  return CredentialsPage;
}); // require
