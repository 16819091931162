/* jshint esversion: 8 */
define([
  "service/datatransform/uiElementInfo",
  "service/datatransform/uiSubElementInfo",
  "service/datatransform/dependentOptionInfo",
  "util"
], function(UIElementInfo, UISubElementInfo, DependentOptionInfo, Util) {

  class DependentOptionManager {

    constructor (args) {
      if (! (args && typeof args === 'object' &&
          "dependentOptionUpdateMethod" in args &&
          typeof args.dependentOptionUpdateMethod === 'function')) {
        throw new TypeError("Invalid args.dependentOptionUpdateMethod argument");
      }
      this.settingsMap = args.settingsMap || new Map();
      this.dependentOptionUpdateMethod = args.dependentOptionUpdateMethod;
      this.optionsQueryMap = new Map();
      this.finalized = false;
      let uiElementsById = {}
      if (args.uiSections) {
        for (const section of args.uiSections) {
          for (const element of section.elements) {
            uiElementsById[element.id] = element;
          }
        }
      }
      this.uiElementsById = uiElementsById;
      this.changeHandler = function () { };
    }

    getUIElementsById () { return this.uiElementsById; }

    getSettingsMap () { return this.settingsMap; }

    getFinalizedMap () {
      if (!this.finalized) {
        throw new Error("optionsQueryMap has not been finalized.");
      }
      return this.getQueryMap();
    }

    getQueryMap () {
      return this.optionsQueryMap;
    }

    add (uiElementInfo) {
      if (!uiElementInfo || !(uiElementInfo instanceof UIElementInfo || uiElementInfo instanceof UISubElementInfo)) {
        throw new TypeError("Invalid uiElementInfo argument");
      }
      let dependentOptionInfo = new DependentOptionInfo(uiElementInfo);
      if (dependentOptionInfo && uiElementInfo.id) {
        if (! this.getQueryMap().has(uiElementInfo.id)) {
          this.getQueryMap().set(uiElementInfo.id, dependentOptionInfo);
        } else {
          throw new Error(`optionsQueryMap already has an entry with ID ${uiElementInfo.id}`);
        }
      }
    }

    getDependentIds () {
      let dependentIds = [];
      let entries = this.getQueryMap().entries();
      let entry = entries.next();
      while (!entry.done) {
        let elementId = entry.value[0];
        let data = entry.value[1];
        if (data && data.dependentIds && Array.isArray(data.dependentIds)) {
          for (let depId of data.dependentIds) {
            dependentIds.push(depId);
          }
        }
        entry = entries.next();
      }
      return dependentIds;
    }

    finalize () {
      if (!this.finalized) {
        this.updateDependentStatus();
        this.finalized = true;
      }
    }

    updateDependentStatus () {
      if (this.getQueryMap().size) {
        let dependentIds = this.getDependentIds();
        for (let id of dependentIds) {
          if (this.getQueryMap().has(id)) {
            let dependentOptionInfo = this.optionsQueryMap.get(id);
            dependentOptionInfo.setDependent(true);
            this.getQueryMap().set(id, dependentOptionInfo);
          } else {
            throw new Error(`ID ${id} not found in optionsQueryMap`);
          }
        }
      }
    }

    makeDependentOptionEventHandler (parentId, childId, doAdd) {
      if (!this.getQueryMap().has(parentId)) {
        throw new TypeError(`Unabled to find parentId ${parentId} in map`);
      }
      if (!this.getQueryMap().has(childId)) {
        throw new TypeError(`Unabled to find childId ${childId} in map`);
      }
      let parentDependentOptionInfo = this.getQueryMap().get(parentId);
      let parentSelector = parentDependentOptionInfo.selector;
      let parentElement = document.querySelector(parentSelector);
      let childDependentOptionInfo = this.getQueryMap().get(childId);
      let settings = this.getSettingsMap();
      let handler = async function (event) {
        if (event && event.preventDefault) { event.preventDefault(); }
        let dependentOptionInfo = childDependentOptionInfo;
        if (event.target) {
          const paramID = event.target.id;
          const paramValue = event.target.value;
          const uiElsById = this.getUIElementsById()
          const uiEl = uiElsById[paramID];
          if (uiEl && uiEl.getIsNetwork()) {
            dependentOptionInfo.getVPCFn = function () { return paramValue; }
          } else if (uiEl && uiEl.getIsSubnet()) {
            dependentOptionInfo.getSubnetFn = function () { return paramValue; }
          }
        }
        return await this.dependentOptionUpdateMethod(dependentOptionInfo, settings);
      }.bind(this);
      let func;
      if (doAdd) {
        func = function () {
          parentElement.addEventListener("change", handler, false);
        }.bind(this);
      } else {
        func = function () {
          parentElement.removeEventListener("change", handler, false);
        };
      }
      return func;
    }

    getDependentOptionEvents () {
      let results = {
        startFunctions: [],
        stopFunctions: []
      };
      if (this.getQueryMap().size) {
        try {
          let entries = this.getQueryMap().entries();
          let entry = entries.next();
          while (!entry.done) {
            let parentId = entry.value[0];
            let dependentOptionInfo = entry.value[1];
            let parentSelector = dependentOptionInfo.selector;
            let parentElement = document.querySelector(parentSelector);
            if (!parentElement) {
              throw new Error(`Unable to find ${parentSelector}`);
            }
            if (dependentOptionInfo && dependentOptionInfo.dependentIds) {
              for (let childId of dependentOptionInfo.dependentIds) {
                results.startFunctions.push(this.makeDependentOptionEventHandler(parentId, childId, true));
                results.stopFunctions.push(this.makeDependentOptionEventHandler(parentId, childId, false));
              }
            }
            entry = entries.next();
          }
        } catch (error) {
          /* istanbul ignore next */
          const errInfo = error.message ? error.message : error;
          Util.consoleLogWarning('getDependentOptionEvents', errInfo);
          results = {
            startFunctions: [],
            stopFunctions: []
          };
        }
      }
      return results;
    }

    getDependentOptions () {
      let dependentOptions = [];
      const map = this.getFinalizedMap();
      let entries = map.values();
      let entry = entries.next();
      let dependentOptionInfo = entry.value;
      while (!entry.done) {
        let dependentOptionInfo = entry.value;
        if (dependentOptionInfo.isDependent()) {
          dependentOptions.push(dependentOptionInfo);
        }
        entry = entries.next();
      }
      return dependentOptions;
    }

    disableDependentOptions () {
      let depOps = this.getDependentOptions();
      for (let dependentOptionInfo of depOps) {
        let selector = dependentOptionInfo.selector;
        let element = document.querySelector(selector);
        if (element) {
          element.classList.add("disabled");
          element.disabled = true;
        }
      }
    }

  }

  return DependentOptionManager;
});
