define([
    "util"
], function(
    Util
) {
    class LegacyParallelCredentialService {

        constructor (args) {
            if (!args || typeof args !== "object" || !args.dao) {
              throw new TypeError("Invalid args argument");
            }
            this.dao = args.dao;
        }

        getDAO () { return this.dao; }

        async delete () {
            return await this.getDAO().getLegacyParallelAPI("service/v1/delete-aws-credentials", null, null, null, {fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout()});
        }

        async syncCred () {
            fetch(this.getDAO().getLegacyParallelServicesURL() + "/service/v1/synchronize-aws-credentials", {
                credentials: "include"
            }).then(response => {
              if (!response.ok) {
                Util.consoleLogError("Calling CC1 syncCred, error response", response);
              }
            }).catch(error => {
              Util.consoleLogError("Calling CC1 syncCred, error", error);
            });
        }
        // async get(uuid) {
        //   return await this.getDAO().getLegacyParallelAPI("cluster", uuid);
        // },
        // async delete(uuid) {
        //   return await this.getDAO().deleteLegacyParallelAPI("cluster", uuid);
        // },
        // async getAccess(uuid) {
        //   return await this.getDAO().getLegacyParallelAPI("cluster", uuid, "access");
        // },
        // async putAccess(uuid) {
        //   return await this.getDAO().putLegacyParallelAPI("cluster", uuid, "access");
        // },
        // async deleteAccess(uuid) {
        //   return await this.getDAO().deleteLegacyParallelAPI("cluster", uuid, "access");
        // },
        // async getAuthorize(uuid) {
        //   return await this.getDAO().getLegacyParallelAPI("cluster", uuid, "authorize");
        // },
        // async putAuthorize(uuid, authzToken) {
        //   return await this.getDAO().putLegacyParallelAPI("cluster", uuid, "authorize", {authzToken: authzToken});
        // },
        // async getCertificate(uuid) {
        //   return await this.getDAO().getLegacyParallelAPI("cluster", uuid, "certificate");
        // },
        // async stop(uuid) {
        //   return await this.getDAO().putLegacyParallelAPI("cluster", uuid, "stop");
        // },
        // async start(uuid) {
        //   return await this.getDAO().putLegacyParallelAPI("cluster", uuid, "start");
        // },
        // async update(uuid, params) {
        //   let opts = {};
        //   ["durationInSeconds", "enableAutoAssociateIp", "sharedState", "sharedWith", "terminationPolicy", "totalNumWorkers"].forEach(
        //     field => {
        //     if (params[field]) {
        //       opts[field] = params[field];
        //     }
        //   });
        //   return await this.getDAO().putLegacyParallelAPI("cluster", uuid, "update", opts);
        // }

    }
    return LegacyParallelCredentialService;
});
