/* jshint esversion: 8 */
define([
  "jquery",
  "util",
  "config",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "constants"
], function($, Util, Config, I18NStringResource, DojoString, CC_Constants) {

  class SupportedProducts {

    static getDefaultProductName () {
      return 'matlab';
    }

    static getSupportedProducts () {
      return SupportedProducts.SUPPORTED_PRODUCTS;
    }

    static initializeSupportedProducts (providedProductNames) {
      const supportedProducts = {};
      // allow passed in array but fall back to using config.
      const productNames = providedProductNames || Config.getSupportedProductList();
      productNames.forEach(product => {
        const productData = SupportedProducts.generateSupportedProductData(product);
        supportedProducts[product] = productData;
      });
      return supportedProducts;
    }

    static getProductNameList () {
      const list = Object.getOwnPropertyNames(SupportedProducts.getSupportedProducts());
      // temporary force order.
      let sortedList = [];
      sortedList.push(list[list.indexOf('matlab')]);
      list.splice(list.indexOf('matlab'), 1);
      sortedList.push(list[list.indexOf('cluster')]);
      list.splice(list.indexOf('cluster'), 1);
      sortedList.push(list[list.indexOf('parallel_server')]);
      list.splice(list.indexOf('parallel_server'), 1);
      sortedList.push(list[list.indexOf('production_server')]);
      list.splice(list.indexOf('production_server'), 1);
      sortedList.push(list[list.indexOf('web_app_server')]);
      list.splice(list.indexOf('web_app_server'), 1);
      sortedList.push(list[list.indexOf('online_server')]);
      list.splice(list.indexOf('online_server'), 1);
      sortedList.push(list[list.indexOf('license_manager')]);
      list.splice(list.indexOf('license_manager'), 1);
      sortedList.push(list[list.indexOf('cfe_demo')]);
      list.splice(list.indexOf('cfe_demo'), 1);
      sortedList.push(list[list.indexOf('cloud_network')]);
      list.splice(list.indexOf('cloud_network'), 1);
      // append any other products that may be left
      sortedList = sortedList.concat(list);
      return sortedList;
    }

    static productSupportsDuplicateURL (product) {
      let isSupported = false;
      if (SupportedProducts.isValidProduct(product)) {
        if (Config.getSupportsDuplicateURLProductList().includes(product)) {
          isSupported = true;
        }
      }
      return isSupported;
    }

    static isValidProduct (product) {
      let isValid = true;
      if (!product || typeof product !== 'string' || !SupportedProducts.getProductNameList().includes(product)) {
        isValid = false;
      }
      return isValid;
    }

    static getImageSelectIdByProduct (detailData) {
      for (const section of detailData) {
        for (const uiElement of section.elements) {
          if (uiElement.getIsImage()) {
            return uiElement.getId();
          }
        }
      }
    }

    static getNetworkSelectIdsByProduct (detailData) {

      // this could (should?) return an array of subnet IDs for products that require >1
      // but for now, just 1
      let vpcId, subnetId, subnetIds;
      networkSearch: for (const section of detailData) {
        for (const uiElement of section.elements) {
          if (uiElement.getIsNetwork()) {
            vpcId = uiElement.getId();
            subnetIds = uiElement.getSubnetIds();
            for (const sId of Object.keys(subnetIds)) {
              subnetId = sId;
              break networkSearch;
            }
          }
        }
      }
      return [vpcId, subnetId];
    }

    // TODO:  Move this logic to config.js
    static productSupportsOutputs (product) {
      if (!SupportedProducts.isValidProduct(product)) {
        throw new TypeError("Invalid product argument");
      }
      let supportsOutputs = true;
      if (product === 'matlab' || product === 'online_server' || product === 'cluster' || product === 'cloud_network') {
        supportsOutputs = false;
      }
      return supportsOutputs;
    }

    static getSupportedProductDisplayName (product) {
      if (!product || typeof product !== 'string') {
        throw new TypeError("Invalid product argument");
      }
      let displayName = "Unknown Product";
      const index = `supportedProductDisplayName_${product}`;
      displayName = I18NStringResource[index];
      return displayName;
    }

    static generateDisplayNoSupportedProductMessageMethod (product, isVisible) {
      if (!product || typeof product !== 'string') {
        throw new TypeError("Invalid product argument");
      }
      const displayFn = () => { SupportedProducts.toggleProductMessage(product, isVisible); };
      return displayFn;
    }

    static generateSupportedProductData (product) {
      if (!product || typeof product !== 'string') {
        throw new TypeError("Invalid product argument");
      }
      const camelCaseProduct = Util.underscoreToTitleCase(product, true);
      const productData = {
        msg: {
          hide: SupportedProducts.generateDisplayNoSupportedProductMessageMethod(product, false),
          show: SupportedProducts.generateDisplayNoSupportedProductMessageMethod(product, true),
          containerClass: `${camelCaseProduct}Container`,
          noMessageClass: `no${Util.underscoreToTitleCase(product)}MessageContainer`
        },
        btnSelector: `button#startNew${Util.underscoreToTitleCase(product)}Button`,
        defaultProductName: SupportedProducts.getSupportedProductDisplayName(product)
      };
      return productData;
    }

    static getStartWizardButtonId (product) {
      if (!product || typeof product !== 'string') {
        throw new TypeError("Invalid product argument");
      }
      return `startNew${Util.underscoreToTitleCase(product)}Button`;
    }

    static getStartWizardButtonSelector (product) {
      if (!product || typeof product !== 'string') {
        throw new TypeError("Invalid product argument");
      }
      return `button#startNew${Util.underscoreToTitleCase(product)}Button`;
    }

    static toggleProductMessage (product, visible) {
      if (!product || typeof product !== 'string') {
        throw new TypeError("Invalid product argument");
      }
      const details = SupportedProducts.getSupportedProducts()[product]
      let instanceContainer = document.querySelector(`div.computeContainer > div.resourceContainer > div.${details.msg.containerClass}`);
      let noDataMsgContainer = document.querySelector(`div.computeContainer > div.resourceContainer > div.${details.msg.noMessageClass}`);
      if (!(instanceContainer && noDataMsgContainer)) {
        throw new Error("Unable to locate container elements");
      }
      if (visible) {
        instanceContainer.style.display = 'none';
        noDataMsgContainer.style.display = 'flex';
      } else {
        noDataMsgContainer.style.display = 'none';
        instanceContainer.style.display = 'block';
      }
    }

    static showNoProductResourcesMessage (product) {
      if (!product || typeof product !== 'string') {
        throw new TypeError("Invalid product argument");
      }
      const productData = SupportedProducts.getSupportedProducts()[product];
      if (productData && productData.msg && productData.msg.show) {
        productData.msg.show();
      } else {
        throw new Error(`Unsupported product: ${product}`);
      }
    }

    static hideNoProductResourcesMessage (product) {
      if (!product || typeof product !== 'string') {
        throw new TypeError("Invalid product argument");
      }
      const productData = SupportedProducts.getSupportedProducts()[product];
      if (productData && productData.msg && productData.msg.hide) {
        productData.msg.hide();
      } else {
        throw new Error(`Unsupported product: ${product}`);
      }
    }

  }
  Object.defineProperty(SupportedProducts, "SUPPORTED_PRODUCTS", {
    value: SupportedProducts.initializeSupportedProducts(),
    writable: false,
    configurable: false,
    enumerable: true
  });

  return SupportedProducts;
});
