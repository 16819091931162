/* jshint esversion: 8 */
define([
  "computeResource/collections/collectionItem",
  "templates/computeResourceCollections/cluster/item",
  "service/cloudStatus",
  "service/cloudResource",
  "util",
  "supportedProducts",
  "service/landingPageNavButtons",
  "notification/notificationManager",
  "computeResource/computeValueFormatter",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "statusAndCountdown"
], function(
  CollectionItem,
  ResourceCollectionItemTemplate,
  CloudStatus, CloudResource, Util, SupportedProducts, LandingPageNavButtons,
  NotificationManager,ComputeValueFormatter, I18NStringResource,
  DojoString,StatusAndCountdown
) {
  class ClusterCollectionItem extends CollectionItem {

    constructor (args) {
      super(args);
    }

    /* istanbul ignore next */
    getResourceCollectionItemTemplate () {
      return ResourceCollectionItemTemplate;
    }

    /* istanbul ignore next */
    getResourceCollectionItemTemplate () {
      return ResourceCollectionItemTemplate;
    }

    /* istanbul ignore next */
    async updateStatus (cachedData, iteration = 1) {
      Util.consoleLogTrace("CollectionItem", "updateStatus called with iteration " + iteration);
      let rowElement = this.getElement();
      let oldLink = rowElement.querySelector(".goToResourceDetail");
      if (oldLink && cachedData) {
        let newLink = document.createElement("a");
        newLink.href = this.getCollection().getLegacyParallelURL() + "/cluster/view/" + cachedData.id;
        newLink.text = oldLink.text
        oldLink.replaceWith(newLink);

        rowElement.querySelector(".resource-ip-address").textContent = "";
        rowElement.querySelector(".resource-cloud-provider").textContent = rowElement.querySelector(".resource-cloud-provider").textContent.replace(' ()', '');
        if (cachedData.params && cachedData.params.version) {
          rowElement.querySelector(".resource-release").textContent = ComputeValueFormatter.getRelease(cachedData.params.version);
        }

        if (cachedData.cloud && cachedData.cloud.state) {
          let newStatus = document.createElement("span");
          newStatus.classList.add("resource-status-parent");
          let newStatusChild = document.createElement("div");
          newStatus.appendChild(newStatusChild);
          newStatusChild.classList.add("resource-status");
          let progressbar = document.createElement("div");
          progressbar.classList.add("progressbar");
          newStatusChild.appendChild(progressbar);
          let statusNameContainer = document.createElement('div');
          statusNameContainer.classList.add("statusNameContainer");
          newStatusChild.appendChild(statusNameContainer);
          switch (cachedData.cloud.state) {
            case "STOPPED":
            case "STOPPING":
              newStatusChild.classList.remove("status-running");
              newStatusChild.classList.remove("status-stopped");
              newStatusChild.classList.add("status-paused");
              break;
            case "READY":
            case "STARTING":
            case "WAITING_FOR_RESOURCES":
              newStatusChild.classList.remove("status-stopped");
              newStatusChild.classList.remove("status-paused");
              newStatusChild.classList.add("status-running");
              break;
            default:
              newStatusChild.classList.remove("status-running");
              newStatusChild.classList.remove("status-paused");
              newStatusChild.classList.add("status-stopped");
              break;
          }
          statusNameContainer.textContent = cachedData.cloud.state ? Util.toTitleCase(cachedData.cloud.state) : "";
          rowElement.querySelector(".resource-status > .progressSpinnerContainer").replaceWith(newStatus);
        }
        rowElement.querySelector(".resource-primary-cta").textContent="";
        rowElement.querySelector(".resource-menu").textContent="";
      }
      rowElement.dataset.updating = "false";
    }

    /* istanbul ignore next */
    createMenuItems (buttons, ctaButtons) { }

    /* istanbul ignore next */
    static createCollectionHeader () {
      const columns = [
        {id:"computeResourcePageColumnHeaderName", class:"header-name", displaytext: true},
        {id:"computeResourcePageColumnHeaderRelease", class:"header-release", displaytext: true},
        {id:"computeResourcePageColumnHeaderOperatingSystem", class:"header-os", displaytext: true},
        {id:"computeResourcePageColumnHeaderCloudProvider", class:"header-provider", displaytext: true},
        {id:"computeResourcePageColumnHeaderStatus", class:"header-status", displaytext: true},
      ];
      const header = CollectionItem.createCollectionHeader(columns);
      return header;
    }

  }

  return ClusterCollectionItem;
});
