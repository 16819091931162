define([
  "util",
  "wizard/ruleManager",
  "supportedProducts",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string"
], function(Util, RuleManager, SupportedProducts, I18NStringResource, DojoString) {

  class ComputeValueFormatter {

    constructor () {
      this.setMap(this.createMethodMap());
    }

    getMap () {
      return this.elementIdToMethodMap;
    }

    setMap (map) {
      this.elementIdToMethodMap = map;
    }

    createMethodMap () {
      let map = new Map();
      map.set("mlName", ComputeValueFormatter.getMLName);
      map.set("productName", ComputeValueFormatter.getProductName);
      map.set("releaseSelector", ComputeValueFormatter.getRelease);
      map.set("cloudLocationSelector", ComputeValueFormatter.getCloudLocation);
      map.set("osChoice", ComputeValueFormatter.getOperatingSystem);
      map.set("osName", ComputeValueFormatter.getOperatingSystem);
      map.set("osChoiceLinuxInput", ComputeValueFormatter.getOperatingSystem);
      map.set("osChoiceWindowsInput", ComputeValueFormatter.getOperatingSystem);
      map.set("cloudChoice", ComputeValueFormatter.getCloudProvider);
      map.set("cloudChoiceAwsInput", ComputeValueFormatter.getCloudProvider);
      map.set("cloudChoiceAzureInput", ComputeValueFormatter.getCloudProvider);
      map.set("cloudPlatform", ComputeValueFormatter.getCloudProvider);
      map.set("InstanceType", ComputeValueFormatter.getMachineType);
      map.set("RootVolumeSize", ComputeValueFormatter.getStorageSize);
      map.set("credentialSelector", ComputeValueFormatter.getCredentialId);
      return map;
    }

    getDisplayValue (elementId, rawValue) {
      let displayText = rawValue;
      if (this.getMap().has(elementId)) {
        displayText = this.getMap().get(elementId)(rawValue);
      }
      return displayText;
    }

    static getCloudProvider (cloudProvider) {
      let displayText;
      if (cloudProvider && typeof cloudProvider === 'string') {
        displayText = cloudProvider;
        if (I18NStringResource[`cloudProviderDisplayName_${cloudProvider.toLowerCase()}`] ) {
          displayText = I18NStringResource[`cloudProviderDisplayName_${cloudProvider.toLowerCase()}`];
        }
      } else {
        displayText = "";
      }
      return displayText;
    }

    static getCloudLocation (cloudLocation) {
      let displayText;
      if (cloudLocation && typeof cloudLocation === 'string') {
        displayText = cloudLocation.toUpperCase();
      } else {
        displayText = "";
      }
      return displayText;
    }

    static getMLName (mlName) {
      return mlName;
    }

    static getCredentialId (credentialData) {
      let displayText = "";
      if (credentialData && typeof credentialData === 'string') {
        displayText = credentialData;
      } else if (credentialData && typeof credentialData === 'object' && credentialData.account_id) {
        if (credentialData.description) {
          displayText = `${credentialData.description} (${credentialData.account_id})`;
        } else {
          displayText = `${credentialData.account_id}`;
        }
      }
      return displayText;
    }

    static getOperatingSystem (operatingSystem) {
      let displayText;
      if (operatingSystem && typeof operatingSystem === 'string') {
        displayText = Util.toTitleCase(operatingSystem);
      } else {
        displayText = "";
      }
      return displayText;
    }

    static getProductName (product, version) {
      let displayText;
      if (product && typeof product === 'string' && SupportedProducts.getProductNameList().includes(product)) {
        displayText = SupportedProducts.getSupportedProductDisplayName(product);
        if (version && typeof version === 'string') {
          displayText = `${displayText} ${version.substring(3)}`
        }
      } else {
        displayText = "";
      }
      return displayText;
    }

    static getRelease (release) {
      let displayText = "";
      if (release && typeof release === 'string') {
        displayText = release;
        /* istanbul ignore next */
        if (RuleManager.hasEntitlementEntry(release)) {
          let isEntitled = RuleManager.isEntitledToUseRelease(release);
          if (!isEntitled) {
            displayText = `${release} ${I18NStringResource.notEntitled}`;
          }
        }
      }
      return displayText;
    }

    static getMachineType (machineType) {
      let displayText;
      if (machineType && typeof machineType === 'string') {
        displayText = machineType;
      } else {
        displayText = "";
      }
      return displayText;
    }

    static getStorageSize (storageSize) {
      let displayText;
      let units = "GiB";
      if (storageSize && !isNaN(storageSize)) {
        displayText = `${storageSize} ${units}`;
      } else {
        if (typeof storageSize === 'string') {
          displayText = storageSize;
        } else {
          displayText = "";
        }
      }
      return displayText;
    }


  }
  return ComputeValueFormatter;
});
