/*jslint regexp: false */
define([
    "dojo/_base/window",
    "dojo/_base/event",
    "dojo/io-query"
], function (win, dojoEvent, ioQuery) {

    return {

        HYPERLINK_ESCAPE_REGEXP: /<a\s+href\s*=\s*"[^"]*"[^>]*>.*?<\/a>/g,
        HYPERLINK_WITH_PARENTHESIS_ESCAPE_REGEXP: /<a\s+href\s*=\s*"([^"]*)"[^>]*>(.*?)<\/a>/g,

        STRONG_ESCAPE_REGEXP: /<strong>|<\/strong>/g,

        /**
         * Parses query string and returns an object with each name value pair on it. This routine
         * will also decode query values that have been encoded with %bytes per the contract of
         * {@code io-Query.queryToObject}
         */
        parseQueryString: function () {
            var queryString = win.global.location.search.substr(1,
                win.global.location.search.length);

            return this._parseQueryString(queryString);
        },

        /**
         * Utility method with explicit query string parsing functionality, broken out for testing
         * purposes.
         *
         * @private
         */
        _parseQueryString: function (queryString){
            return ioQuery.queryToObject(queryString);
        },


        /**
         * Parses url into parts
         *
         * @param url {string} valid url, if URL is empty will implicitly use window.location
         * @returns {object} with protocol, hostname, port, pathname, search, and hash.
         */
        parseUrl: function (url) {
            // Switch to URL Object when tech stablizes.
            // https://developer.mozilla.org/en-US/docs/Web/API/URL
            var a = document.createElement("a");
            a.display = "none";
            a.href = url || window.location.href;  
            a.href = a.href; // IE will expand any relative hrefs when calling the href "getter"
                             // Therefore this reassigns the href with the fully qualified version
            var parsedUrl = {
                protocol: a.protocol,
                hostname: a.hostname,
                port: a.port,
                pathname: a.pathname,
                search: a.search,
                hash: a.hash
            };
            
            if ((parsedUrl.protocol ==="https:" || parsedUrl.protocol ==="http:") && 
                parsedUrl.pathname.indexOf("/") !== 0) {
                    //IE does not include "/" in start of pathname, normalize to other browsers
                    parsedUrl.pathname = "/" + parsedUrl.pathname;  
            }
            if (parsedUrl.protocol ==="https:" && parsedUrl.port === "443" && 
                url.indexOf("https://" + parsedUrl.hostname + ":443") !== 0)  {
                // IE returns 443 as port when using HTTPS even if port 
                // was not specified in URL, normalize to other browsers
                parsedUrl.port = "";
            }
            
            return parsedUrl;
        },

        /**
         * Transates the given string into one where all HTML entity characters have been escaped.
         * E.g., "this > that" becomes "this &gt; that".
         *
         * Also replaces Unicode characters with their character code.
         * char(135) =  becomes &#135; this allows teh browser to display the character correctly.
         *
         * @param string the string to escape
         */
        escapeHtml: function (string) {
            var div = win.global.document.createElement("div");
            var text = win.global.document.createTextNode(string);
            div.appendChild(text);
            var stringHTML = div.innerHTML;
            var newString = "";

            // Escape and insert unicode character codes for characters outside
            // the standard range to increase character display in browser.
            // g1103331
            for (var i = 0; i < stringHTML.length; i = i + 1) {
                var code = stringHTML.charCodeAt(i);
                if (code > 128) {
                    newString = newString + '&#' + code + ';';
                } else {
                    newString = newString + stringHTML.charAt(i);
                }
            }

            return newString;
        },

        /**
         * This function prevents the links from being escaped out.
         * @param {string} string - the string to perform escaping on
         * @param {boolean} escapeLinks - if this parameter is set to false, this function prevents escaping the links.
         * @returns {string} newString - a new string after escaping html and without escaping links and strong tags
         * g1249908 - Get rid of the previous string replacement method,
         *            using string match and split, and manipulate by index instead.
         */
        escapeLinksAndHtml : function (string, escapeLinks) {
            var newString = "";
            var normalStrings = string.split(this.HYPERLINK_ESCAPE_REGEXP);
            var aTags = string.match(this.HYPERLINK_ESCAPE_REGEXP);
            var newHyperLink = "";
            var that = this;
            var i = 0;
            var regexpReplaceFunction = function (match, linkUrl, linkLabel) {
                var protocol = that.parseUrl(linkUrl).protocol;
                // Only allow linkUrl that has designated protocol, otherwise replace the url with "#"
                if (protocol !== "http:" && protocol !== "https:" && protocol !== "matlab:") {
                    linkUrl = "#";
                }
                linkUrl = that.escapeHtml(linkUrl);
                linkLabel = that._strongNotEscaped(linkLabel);
                newHyperLink = "<a href=\"" + linkUrl + "\">" + linkLabel  + "</a>";
            };

            if (!escapeLinks) {
                if (normalStrings[0] !== ""){
                    newString = this._strongNotEscaped(normalStrings[0]);
                }

                if (aTags !== null && aTags.length !== 0) {
                    for (i = 0; i < aTags.length; i = i + 1) {
                        aTags[i].replace(this.HYPERLINK_WITH_PARENTHESIS_ESCAPE_REGEXP, regexpReplaceFunction);
                        if (normalStrings[i + 1] !== "") {
                            normalStrings[i + 1] = this._strongNotEscaped(normalStrings[i + 1]);
                        }

                        newString += newHyperLink + normalStrings[i + 1];
                    }
                }
            } else {
                newString = this.escapeHtml(string);
                newString = this.escapeWhitespace(newString);
            }

            return newString;
        },

        /**
         * This function prevents the strong tag from being escaped out.
         * @param {string} string - the string to perform escaping on
         * @returns {string} newString - a new string escape html and whitespaces except strong tags
         */
        _strongNotEscaped : function(string) {
            var newString = "";
            var strongMatches = string.match(this.STRONG_ESCAPE_REGEXP);
            var strongSplits = string.split(this.STRONG_ESCAPE_REGEXP);
            var i = 0;
            if (strongMatches !== null && strongMatches.length !== 0) {
                newString = this.escapeWhitespace(this.escapeHtml(strongSplits[0]));
                for (i = 0; i < strongMatches.length ; i = i + 1) {
                    newString += strongMatches[i] + this.escapeWhitespace(this.escapeHtml(strongSplits[i + 1]));
                }
            } else {
                newString = this.escapeWhitespace(this.escapeHtml(string));
            }

            return newString;
        },

        /* escape whitespace */
        escapeWhitespace : function (string) {
            string = string || "";

            return string.replace(/\n/g, "<br>").replace(/ {2}/g, "&nbsp;&nbsp;");
        },

        /**
         *  This function handles the anchor tags. If the link is Matlab help, the callback function is invoked.
         * A call back function is responsible for the special handling for helpLinks.
         * For example, the commmandModule and VariableEditorModule pass the command to executionService.
         * The executionService intercepts the request and opens the help documentation in a separate window.
         * @param helpCallback
         * @param event
         */
        handleAnchors : function (helpCallback, event) {
            // Check if href attribute is available
            if (event.target.href) {
                dojoEvent.stop(event);
                var href = event.target.href;

                if (href.indexOf("matlab:") !== -1) {
                    var sub = href.substring(0, 7);
                    if (sub === "matlab:") {
                        var command = event.target.href.substring(7);
                        helpCallback(win.global.unescape(command));
                    }
                } else {
                    win.global.open(event.target.href);
                }
            }
        },

        /**
         * Checks if DOM element content is overflown
         * @param element
         * @returns {boolean}
         */
        isElementOverflown: function (element) {
            return (element.scrollHeight > element.clientHeight) || (element.scrollWidth > element.clientWidth);
        }
    };
});
