/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "backbone",
  "wizard/abstractWizardStep",
  "util",
  "supportedProducts",
  "templates/createMatlabWizardStep1",
  "wizard/ruleManager",
  "wizard/inputSequenceMap",
  "service/datatransform/ruleInfo",
  "service/datatransform/uiElementInfo",
  "service/datatransform/dependentOptionInfo",
  "service/datatransform/credentialInfo",
  "wizard/sequenceMapElement",
  "computeResource/computeValueFormatter",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "constants",
  "validation/inlineComponentValidation"
], function( _, $, Backbone, AbstractWizardStep, Util, SupportedProducts,
             Step1Template, RuleManager, InputSequenceMap, RuleInfo,
             UIElementInfo, DependentOptionInfo, CredentialInfo,
             SequenceMapElement, ComputeValueFormatter,
             I18NStringResource, DojoString, CCWA_Constants,
             InlineComponentValidation) {

  class CreateMATLABWizardStep1 extends AbstractWizardStep {

    constructor (args) {
      super(args);
      this.events = {
        "change div#selectReleaseContainer select#releaseSelector": "onSelectionChanged",
        "change select#cloudChoice": "onSelectionChanged",
        "change select#credentialSelector": "onSelectionChanged",
        "change select#osChoice": "onSelectionChanged",
        "change div#descriptionContainer select#descriptionSelector": "onSelectionChanged",
        "change div#nameContainer > input[type=\"text\"]#mlNameInput": "onInputComplete",
        "change div#selectReleaseContainer.stepComponentContainer select#releaseSelector": "onInputComplete",
        "change select#cloudChoice": "onInputComplete", //NOSONAR
        "change select#osChoice": "onInputComplete", //NOSONAR
        "change div#descriptionContainer.stepComponentContainer select#descriptionSelector": "onInputComplete",
        "change select#credentialSelector": "onInputComplete", //NOSONAR
        "change div#cloudLocationSelectorContainer select#cloudLocationSelector": "onInputComplete",
        "blur div#nameContainer > input[type=\"text\"]#mlNameInput": "onMlNameInputBlur",
        "focus div#nameContainer > input[type=\"text\"]#mlNameInput": "onMlNameInputFocus",
        "click button.warning-notification": "onWarningNotificationButtonClick"
      };
      this.template = Step1Template;
      this.sequenceMap = new InputSequenceMap();
      this.dataService = args.dataService;
      this.rulesManager = args.rulesManager;

      this.autoComplete = args.autoComplete;
      this.selectedRuleId = "";
      this.defaultRule = args.defaultRule;
      this.platform = '';
      this.platformChanged = false;
      this.setFirstInputOnFormSelector(`div#nameContainer.stepComponentContainer > input#mlNameInput`);
      this.savedStepValues = {visible: {}, hidden: {}};
      _.bindAll(this, "onMlNameInputFocus", "onSelectionChanged",
                "onInputComplete", "onMlNameInputBlur", "emptySection1Content");
    }

    /*
     * selector-based element getters
     */
    // internal to this view
    getMLNameInput () { return this.el.querySelector("div#nameContainer > input[type=\"text\"]#mlNameInput"); }
    getReleaseSelect () { return this.el.querySelector("select#releaseSelector"); }
    getCloudProviderSelect () { return this.el.querySelector("select#cloudChoice"); }
    getCredentialSelect () { return this.el.querySelector('select#credentialSelector'); }
    getOperatingSystemSelect () { return this.el.querySelector("select#osChoice"); }
    getLocationSelect () { return this.el.querySelector("div#cloudLocationSelectorContainer select#cloudLocationSelector"); }
    getDescriptionContainer () { return this.el.querySelector('div#descriptionContainer.stepComponentContainer'); }
    getDescriptionSelect () { return this.el.querySelector("select#descriptionSelector"); }
    // external to this view
    getConfigSummaryList () { return document.querySelector('div.configInfoContainer > div.matlabConfigurationBox > ul');}
    getLicenseWarningContainer () { return document.querySelector("div.wizardStepsContainer > div.releaseLicenseWarningContainer"); }
    getComputeResourceInstanceContainer (product) {
      let containerElement = null;
      let productResourceContainerSelector = "";
      if (SupportedProducts.getProductNameList().includes(product)) {
        const camelCaseProduct = Util.underscoreToTitleCase(product, true);
        productResourceContainerSelector = `div#computeResourcePageView  > div#computeContainer div#resourceContainer > div.${camelCaseProduct}Container`;
        containerElement = document.querySelector(productResourceContainerSelector);
      }
      return containerElement;
    }
    getAllComputeResourceNames (product) {
      let allNames = [];
      const instanceContainer = this.getComputeResourceInstanceContainer(product);
      if (instanceContainer) {
        const machineCollection = instanceContainer.querySelector("ol.collection");
        if (machineCollection) {
          allNames = machineCollection.querySelectorAll("li.item.item-container > div.attribute.resource-machine-name");
        }
      }
      return allNames;
    }

    updateSavedStep1Value (elementId, fieldValue) { 
      const savedValues = this.getSavedStepValues(); // These are Step 2 saved values

      if (!savedValues) {
        Util.consoleLogWarning("updateSavedStep1Value", 'this.getSavedStepValues() not found');
        return;
      }

      for (const key of ["hidden", "visible"]) {
        const values = savedValues[key];
        for (const savedElementId of Object.keys(values)) {
          if (savedElementId === elementId) {
            // savedValues[key][type][typeKey]= fieldValue;
            values[savedElementId] = fieldValue;
            if (elementId === "credentialSelector") {
              const credSel = document.querySelector(`select#${elementId}`);
              let setOne = false;
              for (const option of credSel.options) {
                if (!setOne && option.value && option.value.includes(fieldValue)) { // cred has concatenated value
                  setOne = true;
                  option.selected = true;
                } else {
                  option.selected = false;
                }
              }

            }  else {
              this.setFieldValue(`select#${elementId}`, fieldValue);
            }
            
            return;
          }        
        }
      }
      
      Util.consoleLogError("updateStepValues", "Unable to find element id ", elementId, " in ", savedValues);
    
    }
    updateSavedStep1ValueMethod () { return this.updateSavedStep1Value.bind(this); }
    getSavedStep1ValuesMethod () { return this.getSavedStepValues.bind(this); }
    
    /*
     * property getters
     */
    getSavedStepValues () { return this.savedStepValues; }
    getPlatform () { return this.platform; }
    setPlatform (platform) { this.platform = platform; }
    setPlatformChanged (hasChanged) { this.platformChanged = hasChanged; }
    hasPlatformChanged () { return this.platformChanged; }
    getDataService () { return this.dataService; }
    getSelectedRuleId () { return this.selectedRuleId; }
    setSelectedRuleId (ruleId) { this.selectedRuleId = ruleId; }
    getDefaultRule () { return this.defaultRule; }
    setDefaultRule (rule) { this.defaultRule = rule; }
    getDefaultParamValue (paramId) {
      let defaultValue = "";
      const rule = this.getDefaultRule();
      let matches = [];
      if (rule && rule.body && rule.body.params && rule.body.params.length) {
        matches = rule.body.params.filter((param) => param.id === paramId);
        if (matches && matches.length) {
          const param = matches[0];
          if (param.config && param.config.default) {
            defaultValue = param.config.default;
          }
        }
      }
      return defaultValue;
    }

    emptySection1Content () {
      this.$el.empty();
    }

    /*
     * property setters
     */
    async setDefaultProductName () {
      let baseName;
      let name;
      let productNameInput = this.getMLNameInput();
      let currentProductNameValue = productNameInput ? productNameInput.value : "";
      if (!currentProductNameValue) {
        if (this.getSelectedRuleId()) {
          const searchCriteria = new RuleInfo();
          searchCriteria.id = this.getSelectedRuleId();
          let release = await this.getRuleManager().searchRules(searchCriteria, false, "release");
          let platform = await this.getRuleManager().searchRules(searchCriteria, false, "cloudPlatform");
          release = release[0];
          platform = platform[0].toUpperCase();
          let product = await this.getRuleManager().searchRules(searchCriteria, false, "product");
          product = product[0].toLowerCase();
          baseName = CreateMATLABWizardStep1.getResourceNameFromProduct(product);
          name = this.generateUniqueMachineName(product, baseName);
        }
        if (productNameInput && name) {
          productNameInput.value = name;
          this.getConfigInfoSummary().setSummaryTargetValue("mlNameInput", name);
        }
      }
    }

    onWarningNotificationButtonClick(event) {
      const selectReleaseContainer = this.el.querySelector("div#selectReleaseContainer");
      const selectElement = selectReleaseContainer.querySelector('select#releaseSelector');
      const selectedOption = selectElement.options[selectElement.selectedIndex];
      const data = selectedOption.dataset;
      const status = data.status || "";
      const releaseName = selectedOption.value;
      if (status === "Sunsetted" || status === "Deprecated") {
        const sunsetDate = Util.extractMonthYearString(data.sunsetDate);
        const msg = (status === "Deprecated") ?
          DojoString.substitute(I18NStringResource.releaseIsDeprecated, [sunsetDate]) :
          DojoString.substitute(I18NStringResource.releaseIsSunsetted, [sunsetDate]);
        Util.notify("WARNING", msg);
      }
    }

    checkNameIsAlreadyUsed (name, existingNames) {
      let inUse = false;
      let nameIsValid = (name && typeof name === 'string');
      let existingNamesIsValid = (existingNames && (Array.isArray(existingNames) || existingNames instanceof NodeList));
      if (nameIsValid && existingNamesIsValid) {
        existingNames.forEach(nameItem => {
          let existingName = nameItem.textContent.trim();
          if (name === existingName) {
            inUse = true;
          }
        });
      } else {
        throw new TypeError("Invalid arguments");
      }
      return inUse;
    };

    extractNameAndUpdateMatchCount (nameItem, baseName) {
      let count = 0;
      if (!nameItem || typeof nameItem !== 'object') {
        throw new TypeError("Invalid nameItem argument");
      }
      if (typeof baseName !== 'string') {
        throw new TypeError("Invalid baseName argument");
      }
      // \S means not a white-space character (includes Japanese, etc.),
      // followed by any string ending with another non-white-space character.
      // All of that has to be followed by white-space and "(dd...d)", where
      // "d" is a digit 0-9.  So, "MATLAB (original)" does not match but,
      // "MATLAB (2)" does.
      // The grouping is: $1 would be "MATLAB" and $2 would be " (2)", using
      // the above example.
      const hasCountRe = /(\S.*\S)(\s+\(\d+\)$)/;
      let name = nameItem.textContent.trim();
      const rootName = name.replace(hasCountRe, "$1");
      // if regex has no matches, and so, $1 is empty,
      // the original string is returned.
      if (rootName) {
        if (rootName.trim() === baseName) {
          count++;
        }
      }
      return count;
    }

    getSameNameCount (baseName, existingNames) {
      let count = 0;
      if (existingNames) {
        // count is modified
        existingNames.forEach(nameItem => { count += this.extractNameAndUpdateMatchCount(nameItem, baseName); });
      }
      return count;
    }

    determineUniqueName (baseName, existingNames, count) {
      let name;
      if (count === 0) {
        name = baseName;
      } else {
        name = baseName;
        if (!this.checkNameIsAlreadyUsed(name, existingNames)) {
          return name;
        }
        count = 1; // reset to 1 and try to use unused number
        name = `${baseName} (${new Number(count).toLocaleString('en-US', { // don't care about locale here
          minimumIntegerDigits: 1,
          useGrouping: false
        })})`;

        let nameInUse = this.checkNameIsAlreadyUsed(name, existingNames);
        while (nameInUse) {
          ++count;
          name = `${baseName} (${new Number(count).toLocaleString('en-US', { // don't care about locale here
            minimumIntegerDigits: 1,
            useGrouping: false
          })})`;
          nameInUse = this.checkNameIsAlreadyUsed(name, existingNames);
        }
      }
      return name;
    }

    generateUniqueMachineName (product, baseName) {
      if (!product || !SupportedProducts.getProductNameList().includes(product)) {
        throw new TypeError(`Invalid product argument: ${product}`);
      }
      if (!(baseName && typeof baseName === 'string')) {
        throw new TypeError("Invalid baseName argument");
      }
      // get the list of resources in this product's table on this client.
      // Note: we are not polling the server so, we do not necessarily know
      // about externally created resources not yet reflected in this UI.
      const existingNames = this.getAllComputeResourceNames(product);

      const count = this.getSameNameCount(baseName, existingNames);

      const name = this.determineUniqueName(baseName, existingNames, count);
      return name;
    }

    /*
     * Rules
     */
    getRuleManager () { return this.rulesManager; }

    /*
     * Summary
     */
     initializeInputToSummaryFieldMap () {
       // mapInputIdToSummaryId (inputId, summaryId, isRequired, isHighlighted, labelText, initialValue)
       this.getConfigInfoSummary().mapInputIdToSummaryId("mlNameInput", "productName", false, true, I18NStringResource.cmlWizardStep1ConfigProductLabel, this.getProductName().toUpperCase());
       this.getConfigInfoSummary().mapInputIdToSummaryId("mlNameInput", "productNickname", true, false, I18NStringResource.cmlWizardStep1ConfigProductNicknameLabel, undefined);
       this.getConfigInfoSummary().mapInputIdToSummaryId("releaseSelector", "productRelease", true, false, I18NStringResource.cmlWizardStep1ConfigReleaseLabel, undefined);
       this.getConfigInfoSummary().mapInputIdToSummaryId("cloudChoice", "cloudPlatform", true, false, I18NStringResource.cmlWizardStep1ConfigCloudPlatformLabel, undefined);
       this.getConfigInfoSummary().mapInputIdToSummaryId("credentialSelector", "credential", true, false, I18NStringResource.cmlWizardStep1ConfigAuthenticatedAsLabel, undefined);
       this.getConfigInfoSummary().mapInputIdToSummaryId("osChoice", "osName", true, false, I18NStringResource.cmlWizardStep1ConfigOsLabel, undefined);
       this.getConfigInfoSummary().mapInputIdToSummaryId("cloudLocationSelector", "cloudLocationName", true, false, I18NStringResource.cmlWizardStep1ConfigRegionLabel, undefined);
     }

     /*
      * Sequence
      */
      getSequenceMap () {
        return this.sequenceMap;
      }

      initializeStep1SequenceMap () {
        const sequenceMap = this.getSequenceMap();

        let releaseSelectorElement = new SequenceMapElement("releaseSelector");
        releaseSelectorElement.setDefaults = function () {
          let selectElement = this.getReleaseSelect();
          if (selectElement) {
            while (selectElement.lastChild && selectElement.lastChild.value) { selectElement.lastChild.remove(); }
            selectElement.selectedIndex = 0;
            this.getConfigInfoSummary().setSummaryTargetDefaultValue(selectElement.id);
          }
        }.bind(this);
        releaseSelectorElement.getAdjacentData = async function () {
          try {
            let searchCriteria = new RuleInfo();
            let selectedRelease = this.getFieldValue("select#releaseSelector");
            if (this.isAutoCompleteOn() && !selectedRelease) {
              throw new Error("Missing release choice");
            }
            searchCriteria.setProduct(this.getRuleManager().getProduct());
            if (this.autoComplete && this.getDefaultRule() && this.getDefaultRule().release) {
              selectedRelease = this.getDefaultRule().release;
              this.setFieldValue("select#releaseSelector", selectedRelease);
              this.getConfigInfoSummary().setSummaryTargetValue("releaseSelector", selectedRelease);
            }
            searchCriteria.setRelease(selectedRelease);
            let availableChoicesArray = await this.getRuleManager().searchRules(searchCriteria, false, "cloudPlatform");

            // filter out choices that the user does not have a credential for and thus could not use.
            const unsupportedChoices = [];
            for (const choice of availableChoicesArray) {
              if (!this.getRuleManager().hasCredentialFor(choice)) {
                unsupportedChoices.push(choice)
              }
            }
            availableChoicesArray = availableChoicesArray.filter((choice) => !unsupportedChoices.includes(choice));

            if (this.autoComplete && this.getDefaultRule() && this.getDefaultRule().cloud_provider) {
              this.initializeCloudChoiceSelector(availableChoicesArray, this.getDefaultRule().cloud_provider);
            } else {
              let defaultCloudChoice;
              if (this.getDefaultRule() && this.getDefaultRule().cloud_provider) {
                defaultCloudChoice = this.getDefaultRule().cloud_provider;
              }
              if (this.getSavedStepValues() && this.getSavedStepValues()["cloudChoice"]) {
                defaultCloudChoice = this.getSavedStepValues()["cloudChoice"];
              }
              this.initializeCloudChoiceSelector(availableChoicesArray, defaultCloudChoice);
            }
            const lostFeatures = await this.getRuleManager().getLostFeatures(searchCriteria);
            this.getConfigInfoSummary().addSelectionInfoListItems(lostFeatures);
          } catch (error) {
            this.displayUIOnAutoCompleteError(error);
          }
          return { "select#cloudChoice" : false };
        }.bind(this);
        releaseSelectorElement.adjacentSelectors = async function () {
          return ['select#cloudChoice'];
        }.bind(this);
        releaseSelectorElement.nextSequenceStep = "cloudChoice";
        sequenceMap.set(releaseSelectorElement);

        let cloudChoiceElement = new SequenceMapElement("cloudChoice");
        cloudChoiceElement.setDefaults = function () {
          let selectElement = this.getCloudProviderSelect();
          if (selectElement) {
            this.setPlatform(selectElement.value);
            while (selectElement.lastChild && selectElement.lastChild.value) { selectElement.lastChild.remove(); }
            selectElement.selectedIndex = 0;
            this.getConfigInfoSummary().setSummaryTargetDefaultValue(selectElement.id);
          }
        }.bind(this);
        cloudChoiceElement.getAdjacentData = async function () {
          try {
            const searchCriteria = new RuleInfo();
            let selectedRelease = this.getFieldValue("select#releaseSelector");
            let selectedCloudPlatform = this.getFieldValue('select#cloudChoice');
            // only compare if both are defined, non-empty strings
            if (selectedCloudPlatform && this.getPlatform()) {
              this.setPlatformChanged(selectedCloudPlatform !== this.getPlatform());
            }
            if (this.isAutoCompleteOn() && !selectedCloudPlatform) {
              throw new Error("Missing cloud platform choice");
            }

            const credTypeID = Util.getCredentialTypeIdFromRule(this.getDefaultRule());
            searchCriteria.setProduct(this.getRuleManager().getProduct());
            searchCriteria.setRelease(selectedRelease);
            searchCriteria.setCloudPlatform(selectedCloudPlatform);
            let credentialTypeData = await this.getRuleManager().searchRules(searchCriteria, false, "credentialType");
            let defaultCredID = "";
            if (!credentialTypeData && this.getDefaultRule() && this.getDefaultRule().credential_type) {
              credentialTypeData = [this.getDefaultRule().credential_type];
            }
            if (this.getDefaultRule()) {
              const credentialInfo = new CredentialInfo();
              credentialInfo.cloudPlatform = selectedCloudPlatform;
              credentialInfo.subscriptionId = this.getDefaultParamValue('mw-subscription-id');
              credentialInfo.crendentialTypeId = credTypeID;
              credentialInfo.credentialId = this.getDefaultParamValue('mw-credential-id');
              credentialInfo.displayName = "";
              defaultCredID = credentialInfo.optionValue.split(':')[2];
            }
            if (this.getSavedStepValues() && this.getSavedStepValues()["credentialSelector"]) {
              defaultCredID = this.getSavedStepValues()["credentialSelector"];
            }

            const lostFeatures = await this.getRuleManager().getLostFeatures(searchCriteria);
            this.getConfigInfoSummary().addSelectionInfoListItems(lostFeatures);
            let credentialSelectorElement = this.getCredentialSelect();
            if (credentialSelectorElement) {
              while (credentialSelectorElement.lastChild && credentialSelectorElement.lastChild.value) {
                credentialSelectorElement.lastChild.remove();
              }
              let foundDefaultCred = false;
              for (let credentialType of credentialTypeData) {
                let credentialTypeId = Object.keys(credentialType)[0];
                // get the credential list from the ruleManager, which will get fresh data if expired.
                let credentialInfoArray = await this.getRuleManager().getCredentials(selectedCloudPlatform, credentialTypeId);
                credentialInfoArray.sort(Util.sortMultipleAttr("name"));
                for (let credInfo of credentialInfoArray) {
                  let option = new Option(credInfo.displayName, credInfo.optionValue);
                  credentialSelectorElement.add(option, undefined);
                  const optionCombo = credInfo.optionValue.split(':')[2];
                  if ( optionCombo === defaultCredID) {
                    foundDefaultCred = true;
                    //credentialSelectorElement.selectedIndex = credentialSelectorElement.options.length - 1;
                    option.defaultSelected = true;
                    option.selected = true;
                  }
                }
                credentialSelectorElement.disabled = (credentialSelectorElement.options.length===2);
                if (credentialSelectorElement.disabled) {
                  credentialSelectorElement.classList.add("disabled");
                } else {
                  credentialSelectorElement.classList.remove("disabled");
                }
                this.checkForAndHideEmptySelectorElements(credentialSelectorElement);
              }
              if (!foundDefaultCred) {
                if (Util.selectFirstNonPromptOption(credentialSelectorElement)) {
                  // must use jQuery event because of Backbone listeners
                  $(credentialSelectorElement).change();
                } else {
                  throw new Error("Missing credential choice");
                }
              } else {
                $(credentialSelectorElement).change();
              }
            }
          } catch (error) {
            this.displayUIOnAutoCompleteError(error);
          }
          return { 'select#credentialSelector': false };
        }.bind(this);
        cloudChoiceElement.adjacentSelectors = function () {
          return ['select#credentialSelector'];
        }.bind(this);
        cloudChoiceElement.nextSequenceStep = "credentialSelector";
        sequenceMap.set(cloudChoiceElement);

        let credElement = new SequenceMapElement("credentialSelector");
        credElement.setDefaults = function () {
          let select = this.getCredentialSelect();
          if (select) {
            while (select.lastChild && select.lastChild.value) {
              select.lastChild.remove();
            }
            select.selectedIndex = 0;
            this.getConfigInfoSummary().setSummaryTargetDefaultValue(select.id);
          }
        }.bind(this);
        credElement.getAdjacentData = async function () {
          try {
            const searchCriteria = new RuleInfo();
            let selectedRelease = this.getFieldValue("select#releaseSelector");
            let selectedCloudPlatform = this.getFieldValue('select#cloudChoice');
            searchCriteria.setProduct(this.getRuleManager().getProduct());
            searchCriteria.setRelease(selectedRelease);
            searchCriteria.setCloudPlatform(selectedCloudPlatform);
            this.getConfigInfoSummary().setSummaryTargetValue("cloudChoice", selectedCloudPlatform);
            let selectedCredentialTypeId = this.getCredInfoFieldFromSelectedOption('select#credentialSelector', 'credentialTypeId');
            let selectedCredential = {};
            selectedCredential[selectedCredentialTypeId] = "";
            searchCriteria.setCredentialType(selectedCredential);
            const availableChoicesArray = await this.getRuleManager().searchRules(searchCriteria, false, "os");
            let defaultOSChoice = this.getDefaultRule().operating_system;
            if (this.getSavedStepValues() && this.getSavedStepValues().visible["osChoice"]) {
              defaultOSChoice = this.getSavedStepValues().visible["osChoice"];
            }
            if (defaultOSChoice && availableChoicesArray.includes(defaultOSChoice)) {
              this.initializeOSChoiceSelector(availableChoicesArray, defaultOSChoice);
            } else {
              this.initializeOSChoiceSelector(availableChoicesArray, "");
            }
            const lostFeatures = await this.getRuleManager().getLostFeatures(searchCriteria);
            this.getConfigInfoSummary().addSelectionInfoListItems(lostFeatures);
          } catch (error) {
            this.displayUIOnAutoCompleteError(error);
          }
          return { "select#osChoice" : false };
        }.bind(this);
        credElement.adjacentSelectors = async function () {
          return ['select#osChoice'];
        }.bind(this);
        credElement.nextSequenceStep = "osChoice";
        sequenceMap.set(credElement);

        let osChoiceElement = new SequenceMapElement("osChoice");
        osChoiceElement.setDefaults = function () {
          let selectElement = this.getOperatingSystemSelect();
          if (selectElement) {
            while (selectElement.lastChild && selectElement.lastChild.value) { selectElement.lastChild.remove(); }
            selectElement.selectedIndex = 0;
            this.getConfigInfoSummary().setSummaryTargetDefaultValue(selectElement.id);
          }
        }.bind(this);
        osChoiceElement.getAdjacentData = async function () {
          try {
            const searchCriteria = new RuleInfo();
            let selectedRelease = this.getFieldValue("select#releaseSelector");
            let selectedCloudPlatform = this.getFieldValue('select#cloudChoice');
            searchCriteria.setProduct(this.getRuleManager().getProduct());
            searchCriteria.setRelease(selectedRelease);
            searchCriteria.setCloudPlatform(selectedCloudPlatform);
            let selectedCredentialTypeId = this.getCredInfoFieldFromSelectedOption('select#credentialSelector', 'credentialTypeId');
            let selectedCredential = {};
            selectedCredential[selectedCredentialTypeId] = "";
            searchCriteria.setCredentialType(selectedCredential);
            let selectedOS = this.getFieldValue('select#osChoice');
            searchCriteria.setOS(selectedOS);
            let data = await this.getRuleManager().searchRules(searchCriteria, false, "cloudLocations");
            const lostFeatures = await this.getRuleManager().getLostFeatures(searchCriteria);
            this.getConfigInfoSummary().addSelectionInfoListItems(lostFeatures);
            let cloudLocationSelectorElement = this.getLocationSelect();
            if (cloudLocationSelectorElement) {
              while (cloudLocationSelectorElement.lastChild) {
                cloudLocationSelectorElement.lastChild.remove();
              }
              let values = {}; // deduplicate region data because aggregated across multiple templates/rules
              for (let cloudLocation of data) {
                let keys = Object.keys(cloudLocation);
                for (let key of keys) {
                  const desc = cloudLocation[key];
                  values[desc] = key; // use desc as key so can sort by it later
                }
              }
              let cloudLocations = [];
              for (const [desc,locId] of Object.entries(values)) {
                cloudLocations.push({key:locId,desc:desc});
              };
              cloudLocations.sort(Util.sortMultipleAttr("desc"))

              for (const loc of cloudLocations) {
                let option = new Option(loc.desc, loc.key);
                cloudLocationSelectorElement.add(option, undefined);
              }
              cloudLocationSelectorElement.disabled = (cloudLocationSelectorElement.options.length===1); //no header here
              if (cloudLocationSelectorElement.disabled) {
                cloudLocationSelectorElement.classList.add("disabled");
              } else {
                cloudLocationSelectorElement.classList.remove("disabled");
              }
              this.checkForAndHideEmptySelectorElements(cloudLocationSelectorElement);

              let defaultCloudLocation;
              const defaultRule = this.getDefaultRule();
              if (defaultRule && defaultRule.cloud_provider === selectedCloudPlatform) {
                defaultCloudLocation = this.getDefaultParamValue('mw-cloud-location');
              }
              if (this.getSavedStepValues() && this.getSavedStepValues()['mw-cloud-location']) {
                defaultCloudLocation = this.getSavedStepValues()['mw-cloud-location'];
              }
              if (!defaultCloudLocation) {
                defaultCloudLocation = Util.getDefaultCloudLocation(selectedCloudPlatform);
              }

              if (defaultCloudLocation &&
                cloudLocationSelectorElement.querySelector(`option[value="${defaultCloudLocation}"]`)) {
                cloudLocationSelectorElement.querySelector(`option[value="${defaultCloudLocation}"]`).selected = true;
                cloudLocationSelectorElement.options[cloudLocationSelectorElement.selectedIndex].scrollIntoView();
                // must use jQuery event because of Backbone listeners
                $(cloudLocationSelectorElement).change();
              } else if (Util.selectFirstNonPromptOption(cloudLocationSelectorElement)) {
                cloudLocationSelectorElement.options[cloudLocationSelectorElement.selectedIndex].scrollIntoView();
                // must use jQuery event because of Backbone listeners
                $(cloudLocationSelectorElement).change();
              } else {
                if (this.isAutoCompleteOn()) {
                  throw new Error("Missing cloud location choice");
                }
              }
            }
          } catch (error) {
            this.displayUIOnAutoCompleteError(error);
          }
          return {
            'select#cloudLocationSelector': false
          };
        }.bind(this);
        osChoiceElement.adjacentSelectors = function () {
          return ['select#cloudLocationSelector'];
        }.bind(this);
          osChoiceElement.nextSequenceStep = "cloudLocationSelector";
          sequenceMap.set(osChoiceElement);

          let cloudLocationElement = new SequenceMapElement("cloudLocationSelector");
          cloudLocationElement.setDefaults = function () {
          let select = this.getLocationSelect();
          if (select) {
            while (select.lastChild) {
              select.lastChild.remove();
            }
            select.selectedIndex = -1;
            this.getConfigInfoSummary().setSummaryTargetDefaultValue(select.id);
          }
        }.bind(this);
        cloudLocationElement.getAdjacentData = async function () {
          try {
            const searchCriteria = new RuleInfo();
            let selectedRelease = this.getFieldValue("select#releaseSelector");
            let selectedCloudPlatform = this.getFieldValue('select#cloudChoice');
            searchCriteria.setProduct(this.getRuleManager().getProduct());
            searchCriteria.setRelease(selectedRelease);
            searchCriteria.setCloudPlatform(selectedCloudPlatform);
            let selectedCredentialTypeId = this.getCredInfoFieldFromSelectedOption('select#credentialSelector', 'credentialTypeId');
            let selectedCredential = {};
            selectedCredential[selectedCredentialTypeId] = "";
            searchCriteria.setCredentialType(selectedCredential);
            let selectedOS = this.getFieldValue('select#osChoice');
            searchCriteria.setOS(selectedOS);
            this.getConfigInfoSummary().setSummaryTargetValue("osChoice", selectedOS);
            let selectedCloudLocation  = this.getFieldValue("div#cloudLocationSelectorContainer select#cloudLocationSelector");
            searchCriteria.setCloudLocations(selectedCloudLocation);
            let matchingRulesCount = await this.getRuleManager().searchRules(searchCriteria, true);
            const lostFeatures = await this.getRuleManager().getLostFeatures(searchCriteria);
            this.getConfigInfoSummary().addSelectionInfoListItems(lostFeatures);
            let descriptionContainer = this.getDescriptionContainer();
            let selectElement = this.getDescriptionSelect();
            if (matchingRulesCount > 1) {
              if (descriptionContainer) {
                let descriptions = await this.getRuleManager().searchRules(searchCriteria, false, "finalChoiceDescription");
                descriptionContainer.style.display = 'block';
                if (!this.isAutoCompleteOn()) {
                  const step2Container = document.querySelector('div#step2Container');
                  if (step2Container) {
                    step2Container.style.display = 'none';
                  }
                }
                if (selectElement) {
                  while (selectElement.lastChild && selectElement.lastChild.value) { selectElement.lastChild.remove(); }
                }
                let descItems = [];
                for (let description of descriptions) {
                  let pieces = description.split("-");
                  let id = pieces.pop();
                  description = pieces.join("-");
                  descItems.push({key:id,desc:description})
                }
                descItems.sort(Util.sortMultipleAttr("desc"));
                for (let item of descItems) {
                  let option = new Option(item.desc, item.key);
                  selectElement.add(option, undefined);
                }
                // The defaultRule.description is the TEXT that should match the option's text content, e.g., "MATLAB R2023a";
                // The saved step value is the VALUE of the option, e.g., "c68ad0e0d35009723f9b2d2e261ef287"
                let defaultDescription = ""
                let tempDefault;
                // "description" field is the text; _ruleId is the value of the option that matches it.
                if (this.getDefaultRule() && this.getDefaultRule()._ruleId) {
                  tempDefault = this.getDefaultRule()._ruleId;
                }
                if (this.getSavedStepValues() && this.getSavedStepValues()["descriptionSelector"]) {
                  tempDefault = this.getSavedStepValues()["descriptionSelector"];
                }
                if (selectElement.querySelector(`option[value="${tempDefault}"]`)) {
                  defaultDescription = tempDefault;
                }
                if (defaultDescription) {
                  for (let i = 0; i < selectElement.options.length; i++) {
                    if (selectElement.options[i].value === defaultDescription || selectElement.options[i].text === defaultDescription) {
                      selectElement.selectedIndex = i;
                      $(selectElement).change();
                      break;
                    }
                  }
                } else {
                  if (Util.selectFirstNonPromptOption(selectElement)) {
                    // must use jQuery event because of Backbone listeners
                    $(selectElement).change();
                  } else {
                    if (this.isAutoCompleteOn()) {
                      throw new Error("Missing rule choice");
                    }
                  }
                }
                if (!this.isAutoCompleteOn()) {
                  setTimeout(function() { selectElement.focus(); }, 500);
                }
              }
              $.event.trigger("step1incomplete:createwizard:ccwa");
              return {
                'select#descriptionSelector': false
              };
            } else {
              let rules = await this.getRuleManager().searchRules(searchCriteria, false);
              let ruleId = rules[0].id;
              this.setSelectedRuleId(ruleId);
              const lostFeatures = await this.getRuleManager().getLostFeatures(searchCriteria);
              this.getConfigInfoSummary().addSelectionInfoListItems(lostFeatures);
              if (descriptionContainer) {
                descriptionContainer.style.display = 'none';
              }
              await this.setDefaultProductName();
              $.event.trigger("step1complete:createwizard:ccwa");
              return {
                'select#descriptionSelector': true
              };
            }
          } catch (error) {
            this.displayUIOnAutoCompleteError(error);
          }
          return {'select#descriptionSelector': false};
        }.bind(this);
        cloudLocationElement.adjacentSelectors = function () {
          return ['select#descriptionSelector'];
        }.bind(this);
        cloudLocationElement.nextSequenceStep = "descriptionSelector";
        sequenceMap.set(cloudLocationElement);

        let descriptionElement = new SequenceMapElement("descriptionSelector");
        descriptionElement.setDefaults = function () {
          let descriptionContainer = this.getDescriptionContainer();
          let selectElement = this.getDescriptionSelect();
          if (selectElement) {
            while (selectElement.lastChild && selectElement.lastChild.value) { selectElement.lastChild.remove(); }
            selectElement.selectedIndex = 0;
            selectElement.disabled = true;
            selectElement.classList.add("disabled");
          }
          if (descriptionContainer) {
            descriptionContainer.style.display = 'none';
          }
        }.bind(this);
        descriptionElement.getAdjacentData = async function () {
          try {
            const searchCriteria = new RuleInfo();
            let selectedRelease = this.getFieldValue("select#releaseSelector");
            let selectedCloudPlatform = this.getFieldValue('select#cloudChoice');
            searchCriteria.setProduct(this.getRuleManager().getProduct());
            searchCriteria.setRelease(selectedRelease);
            searchCriteria.setCloudPlatform(selectedCloudPlatform);
            let selectedCredentialTypeId = this.getCredInfoFieldFromSelectedOption('select#credentialSelector', 'credentialTypeId');
            let selectedCredential = {};
            selectedCredential[selectedCredentialTypeId] = "";
            searchCriteria.setCredentialType(selectedCredential);
            let selectedOS = this.getFieldValue('select#osChoice');
            searchCriteria.setOS(selectedOS);
            let selectedCloudLocation  = this.getFieldValue("div#cloudLocationSelectorContainer select#cloudLocationSelector");
            searchCriteria.setCloudLocations(selectedCloudLocation);
            let ruleId = this.getFieldValue('div#descriptionContainer.stepComponentContainer select#descriptionSelector');
            searchCriteria.id = ruleId;
            let matchingRulesCount = await this.getRuleManager().searchRules(searchCriteria, true);
            const lostFeatures = await this.getRuleManager().getLostFeatures(searchCriteria);
            this.getConfigInfoSummary().addSelectionInfoListItems(lostFeatures);
            let disableNextButton = matchingRulesCount !== 1;
            if (matchingRulesCount === 1) {
              this.setSelectedRuleId(searchCriteria.id);
            }
            if (disableNextButton) {
              $.event.trigger("step1incomplete:createwizard:ccwa");
              if (this.isAutoCompleteOn()) {
                throw new Error("Unable to reduce criteria to a single rule.");
              }
            } else {
              await this.setDefaultProductName();
              $.event.trigger("step1complete:createwizard:ccwa");
            }
          } catch (error) {
            this.displayUIOnAutoCompleteError(error);
          }
          return null;
        }.bind(this);
        descriptionElement.adjacentSelectors = async function () {
          return [];
        }.bind(this);
        descriptionElement.nextSequenceStep = "";
        sequenceMap.set(descriptionElement);

        //start cascade of default selectors
        $(this.getReleaseSelect()).change();
      }

      checkForAndHideEmptySelectorElements (selector) {
        let selectorParent = selector.closest("div.stepComponentContainer");
        if (!selectorParent) {
          return
        }
        let hidden = (selector.disabled && selector.value && selector.value.toLowerCase() === "none");
        if (hidden) {
          selectorParent.style.display="none";
        } else {
          selectorParent.style.display="block";
        }
      }

     /*
      * Event handlers
      */
     onInputComplete (event) {
       if (event && typeof event === 'object' && event.target) {
         let target = event.target;
         if (target && target.id) {
           try {
             this.getSequenceMap().disableAllInputsFollowingTargetId(target.id);
             this.getSequenceMap().enableNextSetOfInputs(target.id);
             let value = target.value;
             if (target.id === "releaseSelector") {
               const selectedOption = target.options[target.selectedIndex];
               const status = selectedOption.dataset.status;
               const selectReleaseContainer = this.el.querySelector("div#selectReleaseContainer");
               const depIcon = selectReleaseContainer ? selectReleaseContainer.querySelector("button.warning-notification.deprecated") : null;
               const sunIcon = selectReleaseContainer ? selectReleaseContainer.querySelector("button.warning-notification.sunsetted") : null;
               depIcon.style.display = "none";
               sunIcon.style.display = "none";
               if (status === "Sunsetted" || status === "Deprecated") {
                 const releaseName = selectedOption.value;
                 const sunsetDate = Util.extractMonthYearString(selectedOption.dataset.sunsetDate);
                 const deprecateDate = selectedOption.dataset.deprecateDate;
                 const msg = (status === "Deprecated") ?
                   DojoString.substitute(I18NStringResource.releaseIsDeprecated, [sunsetDate]) :
                   DojoString.substitute(I18NStringResource.releaseIsSunsetted, [sunsetDate]);
                 Util.notify("WARNING", msg);
                 if (status === "Deprecated") {
                   depIcon.style.display = "inline-block";
                   depIcon.title = DojoString.substitute(I18NStringResource.deprecatedTooltip, [sunsetDate]);
                 }
                 if (status === "Sunsetted") {
                   sunIcon.style.display = "inline-block";
                   sunIcon.title = DojoString.substitute(I18NStringResource.sunsettedTooltip, [sunsetDate]);
                 }
                }
             }
             if (target.id === "credentialSelector") {
               value = CredentialInfo.convertOptionValue(value).displayName;
             }
             this.getConfigInfoSummary().setSummaryTargetValue(target.id, value);
           } catch (error) {
             this.getConfigInfoSummary().setSummaryTargetDefaultValue(target.id);
             this.displayUIOnAutoCompleteError(error);
           }
         }
       }
     }

     onMlNameInputBlur (event) {
       if (event && typeof event === 'object') {
         if ("relatedTarget" in event) { //NOSONAR
           // if the blur was generated by a click,
           // check if it was a click on the cancel button.
           // If it is, forget about the error message.
           let relatedTarget = event.relatedTarget;
           if (relatedTarget && relatedTarget.id) {
             let id = relatedTarget.id;
             if (id === 'cancelStep1Button') {
               return;
             }
           }
         }
         let regex = new RegExp("^(\\w+).*$");
         if (event.target) {
           let mlNameInput = event.target;
           let value = mlNameInput.value;
           let isValid = regex.test(value);
           if (!isValid) {
             let stepContainer = mlNameInput.parentElement;
             Util.displayValidationError(stepContainer, I18NStringResource.cmlWizardStep2InvalidItem, false);
             this.onInvalidField();
           } else {
             this.onValidField();
           }
         }
       }
     }

     onMlNameInputFocus (event) {
       if (event && typeof event === 'object' && event.target) {
         let stepContainer = event.target.parentElement;
         if (stepContainer) {
           Util.hideValidationError(stepContainer);
         }
       }
     }

     getSummaryElements () {
       let configSummaryList = this.getConfigSummaryList();
       let summaryElements = {
         configNameContainer: configSummaryList.querySelector('span#mlName'),
         nameContainer: configSummaryList.querySelector('span#productName'),
         nicknameContainer: configSummaryList.querySelector('span#productNickname'),
         releaseContainer: configSummaryList.querySelector('span#productRelease'),
         cloudPlatformContainer: configSummaryList.querySelector('span#cloudPlatform'),
         osNameContainer: configSummaryList.querySelector('span#osName'),
         cloudLocationContainer: configSummaryList.querySelector('span#cloudLocationName'),
         credentialContainer: configSummaryList.querySelector('span#credential')
       };
       return summaryElements;
     }

     async validateAllFields () {
       let productNameInput = this.getMLNameInput();
       let currentProductNameValue = productNameInput ? productNameInput.value : "";
       if (this.isAutoCompleteOn() && !currentProductNameValue) {
         await this.setDefaultProductName();
       }
       // get current values
       let fields = this.getFieldData();
       // get status containers
       let summary = this.getSummaryElements();
       let credentialOptionValue = this.getFieldValue('select#credentialSelector');
       // do validation and update status display
       let allFieldsValid = true;
       try {
         if (!Util.validateValue(summary.nameContainer, this.getProductName().toUpperCase())) { allFieldsValid = false; }
         if (!Util.validateValue(summary.credentialContainer,
                                 fields["mw-credential-id"],
                                 CredentialInfo.convertOptionValue(credentialOptionValue).displayName)) {
           allFieldsValid = false;
         }
         if (!Util.validateValue(summary.nicknameContainer, fields["mw-name"])) { allFieldsValid = false; }
         if (!Util.validateValue(summary.releaseContainer, fields.release)) { allFieldsValid = false; }
         if (!Util.validateValue(summary.cloudPlatformContainer, fields.cloudProvider)) { allFieldsValid = false; }
         if (!Util.validateValue(summary.osNameContainer, fields.operatingSystem)) { allFieldsValid = false; }
         if (!Util.validateValue(summary.cloudLocationContainer, fields["mw-cloud-location"])) { allFieldsValid = false; }
       } catch (error) {
         Util.consoleLogError("validateAllFields", error);
         allFieldsValid = false;
       }
       return allFieldsValid;
     }

     getFieldValue (selector) {
       let fieldValue;
       if (selector && typeof selector === 'string') {
         let element = document.querySelector(selector);
         if (element) {
           fieldValue = element.value;
         }
       }
       return fieldValue;
     }

     setFieldValue (selector, value) {
      if (selector && typeof selector === 'string') {
        let element = document.querySelector(selector);
        if (element) {
          element.value = value;
        }
      }
    }

    saveStepValues () {
      this.savedStepValues = this.getStepValues();
    }

    getStepValues () {
      const stepValues = { visible: {}, hidden: {} };
      const fieldSelectors = [
        "input#mlNameInput",
        "select#releaseSelector",
        "select#cloudChoice",
        "select#credentialSelector",
        "select#osChoice",
        "select#cloudLocationSelector",
        "select#descriptionSelector"
      ];
      const fieldContainerSelector = "div.stepComponentContainer";
      for (const selector of fieldSelectors) {
        const element = this.el.querySelector(selector);
        const elementId = selector.split('#')[1];
        if (element) {
          let fieldValue = element.value;
          if (selector === 'select#credentialSelector' && fieldValue) {
            fieldValue = fieldValue.split(':')[2];
          }
          const container = element.closest(fieldContainerSelector);
          if (container && container.style.display === 'none') {
            stepValues.hidden[elementId] = fieldValue;
          } else {
            stepValues.visible[elementId] = fieldValue;
          }
        }
      }
      return stepValues;
    }

     getCredInfoFieldFromSelectedOption (selector, fieldName) {
       let credentialId;
       if (selector && typeof selector === 'string' && fieldName && typeof fieldName === 'string') {
         let credentialOptionValue = this.getFieldValue(selector);
         if (credentialOptionValue) {
           try {
             let credInfo = CredentialInfo.convertOptionValue(credentialOptionValue);
             if (credInfo && (fieldName in credInfo)) {
               credentialId = credInfo[fieldName];
             }
           } catch (error) {
             Util.consoleLogError("getCredInfoFieldFromSelectedOption", error);
             credentialId = undefined;
           }
         }
       }
       return credentialId;
     }

     getFieldData () {
       let selectedRuleId = this.getFieldValue("div#descriptionContainer.stepComponentContainer select#descriptionSelector");
       if (!selectedRuleId && this.getSelectedRuleId()) {
         selectedRuleId = this.getSelectedRuleId();
       }
       let fields = {
         "mw-name": this.getFieldValue('div#nameContainer > input[name="mlNameInput"]'),
         release: this.getFieldValue('div#selectReleaseContainer > select'),
         cloudProvider: this.getFieldValue('select#cloudChoice'),
         "mw-credential-id": this.getCredInfoFieldFromSelectedOption('select#credentialSelector', "credentialId"),
         operatingSystem: this.getFieldValue('select#osChoice'),
         "mw-cloud-location": this.getFieldValue('div#cloudLocationSelectorContainer > select'),
         ruleId: selectedRuleId,
         "mw-subscription-id": this.getCredInfoFieldFromSelectedOption('select#credentialSelector', "credentialId")
       };
       return fields;
     }

     getStepDataAsRuleInfo () {
       let data = this.getFieldData();
       // all fields are valid here
       let stepData = new RuleInfo();
       stepData.setCloudLocations(data["mw-cloud-location"]);
       stepData.setNetworkType("");
       stepData.setCredentialType("");
       stepData.setDescription("");
       stepData.setOS(data.operatingSystem);
       stepData.setProduct(this.getProductName());
       stepData.setRelease(data.release);
       stepData.setCloudPlatform(data.cloudProvider);
       stepData.setFeatureHighlights("");
       stepData.addDetails(true);
       if (data.ruleId) {
         stepData.id = data.ruleId;
       }
       return stepData;
     }

     selectedReleaseRequiresLicense () {
       let licenseRequired = false;
       let release = this.getFieldValue('div#selectReleaseContainer > select');
       if (release) {
         licenseRequired = !RuleManager.isEntitledToUseRelease(release);
       }
       return licenseRequired;
     }

     doLicenseCheck () {
       let licenseRequired = this.selectedReleaseRequiresLicense();
       let warningContainer = this.getLicenseWarningContainer();
       if (warningContainer && licenseRequired && !this.isAutoCompleteOn()) {
         warningContainer.style.display = "block";
       } else {
         warningContainer.style.display = "none";
       }
     }

     onSelectionChanged (event) {
       let sourceId = event.target.id;
       let choice = event.target.value;
       if (!this.isAutoCompleteOn()) {
         this.onInvalidField();
       }
       if (sourceId === 'releaseSelector') {
         this.doLicenseCheck();
       }
       if (choice) {
         this.getConfigInfoSummary().setSummaryTargetValue(sourceId, choice);
       }
     }

    render () {
      let templateParameters = {};  // new object
      templateParameters.nameLabel = I18NStringResource.cmlWizardNameLabel;
      templateParameters.nameFinePrintText = I18NStringResource.cmlWizardNameFinePrint;
      templateParameters.nameMaxLength = I18NStringResource.cmlWizardNameMaxLength;
      templateParameters.releaseLabel = I18NStringResource.cmlWizardReleaseLabel;
      templateParameters.releaseFinePrintText = I18NStringResource.cmlWizardReleaseFinePrint;
      templateParameters.releasePromptText = I18NStringResource.cmlWizardReleasePrompt;
      templateParameters.selectCloudPlatformLabel = I18NStringResource.cmlWizardStep1SelectCloudPlatformLabel;
      templateParameters.selectCredentialLabel = I18NStringResource.cmlWizardStep1SelectCredentialLabel;
      templateParameters.selectOSLabel = I18NStringResource.cmlWizardStep1SelectOSLabel;
      templateParameters.selectCloudLocationLabel = I18NStringResource.cmlWizardStep1RegionSelectionLabel;
      templateParameters.chooseDescriptionLabel = I18NStringResource.cmlWizardChooseDescriptionLabel;
      templateParameters.chooseDescriptionText = I18NStringResource.cmlWizardChooseDescriptionText;
      let htmlResult = this.template(templateParameters);
      this.$el.empty().html(htmlResult);
      const nameValidator = document.createElement('inline-validation');
      nameValidator.setAttribute("elementid", "mlNameInput");
      const nameContainer = this.el.querySelector('div#nameContainer');
      if (nameContainer) {
        nameContainer.appendChild(nameValidator);
      }
    }

    async initializeStep () {
     // Wait for the rules to load
     try {
       await this.getRuleManager().initialize();
       // initialize release selection
       await this.initializeReleaseSelection();
       // Add the dynamic content
       // re-initialize our view listeners.
       // This will drop all current listeners and than add the new list of listeners
       this.delegateEvents();
       this.initializeInputToSummaryFieldMap();
       this.initializeStep1SequenceMap();
     } catch (error) {
       this.displayUIOnAutoCompleteError(error);
     }
   }

   initializeCloudChoiceSelector (data, defaultValue) {
     this.initializeSelectElement('select#cloudChoice', data, defaultValue, ComputeValueFormatter.getCloudProvider);
   }

   initializeOSChoiceSelector (data, defaultValue) {
     this.initializeSelectElement('select#osChoice', data, defaultValue, ComputeValueFormatter.getOperatingSystem);
   }

   initializeSelectElement (elementSelector, data, defaultValue, valueFmtFn) {
     if (!(elementSelector && typeof elementSelector === 'string')) {
       throw new TypeError("Invalid elementSelector argument");
     }
     let selector = this.el.querySelector(elementSelector);
     if (!selector) {
       throw new Error(`Unable to find "${elementSelector}"`);
     }
     if (!(data && Array.isArray(data))) {
       throw new TypeError("Invalid data argument");
     }
     if (!(valueFmtFn && typeof valueFmtFn === 'function')) {
       throw new TypeError("Invalid valueFmtFn argument");
     }
     while (selector.lastChild && selector.lastChild.value) {
       selector.lastChild.remove();
     }
     let madeSelection = false;

     let dataList = [];
     for (let i = 0; i < data.length; i++) {
       let value = data[i];
       let displayValue;
       try {
         displayValue = valueFmtFn(value);
       } catch (error) {
         Util.consoleLogError("initializeSelectElemen", error);
         displayValue = value;
       }
       dataList.push({key:value, desc:displayValue})
     }
     dataList.sort(Util.sortMultipleAttr("desc"));

     for (const item of dataList) {
      // Option(text, value, defaultSelected, selected);
      let option = new Option(item.desc, item.key);
      if ( defaultValue && (item.key === defaultValue) ) {
        option.selected = true;
        madeSelection = true;
      }
      selector.add(option, undefined);
    }
     if (!madeSelection && data.length) {
       madeSelection = Util.selectFirstNonPromptOption(selector);
     }
     if (madeSelection) {
       $(selector).change();
     }
     selector.disabled = (selector.options.length===2);
     if (selector.disabled) {
       selector.classList.add("disabled");
     } else {
       selector.classList.remove("disabled");
     }
     this.checkForAndHideEmptySelectorElements(selector);
   }

   async getValidDefaultRule () {
    const currentDefaultRule = this.getDefaultRule();
    const currentPlatform = currentDefaultRule ? currentDefaultRule.cloud_provider : "";
    if (!currentPlatform || !this.getRuleManager().hasCredentialFor(currentPlatform)) {
      this.setDefaultRule(null);
    }
    if (!this.getDefaultRule()) {
      let credTypeId;
      if (this.getRuleManager().hasCredentialFor('aws')) {
        if (this.getProductName() === "online_server") {
          credTypeId = CCWA_Constants.ALL_TOGETHER_NOW_CREDENTIAL_TYPE_ID;
        } else if (this.getProductName() === "cfe_demo") {
          credTypeId =  CCWA_Constants.AMAZON_AWS_CFE_DEMOS_CREDENTIAL_ID;
        } else {
          credTypeId = CCWA_Constants.AMAZON_AWS_CREATE_ROLE_CREDENTIAL_ID;
        }
      } else if (this.getRuleManager().hasCredentialFor('azure')) {
        credTypeId = CCWA_Constants.MICROSOFT_AZURE_CREATE_REFRESH_TOKEN_CREDENTIAL_ID;
      }

      const defaultRule = await this.getRuleManager().getRulesWithDefaults(
        this.getProductName(),
        null,
        credTypeId
      );
      this.setDefaultRule(defaultRule);
    }
   }

   async initializeReleaseSelection () {
     await this.getValidDefaultRule();
     const searchCriteria = new RuleInfo();
     let defaultRelease = this.getDefaultRule().release;

     searchCriteria.setProduct(this.getRuleManager().getProduct());
     // the rules are static and are search in the same order so, the results should be in the same order.
     let data = await this.getRuleManager().searchRules(searchCriteria, false);
     if (data && Array.isArray(data) && data.length) {
       data = data.sort().reverse();
     }

     // filter out any rule that the user does not have a credential to use
     const unsupportedRuleIds = [];
     for (const rule of data) {
      if (!this.getRuleManager().hasCredentialFor(rule.cloudPlatform)) {
        unsupportedRuleIds.push(rule.id);
      }
     }
     data = data.filter((rule) => !unsupportedRuleIds.includes(rule.id) );

     // Make a unique set of release values from the result.
     // Set uses "===" for object equality; Because 2 objects, even with the same values,
     // have different references so, they're consider unique.
     // To work around that, convert option to string and compare strings for uniqueness.
     // Then take that string Set and convert to equivalent Object Set.
     const uniqueReleasesJson = new Set();
     const uniqueReleases = new Set();
     for (const rule of data) {
       const optionData = {
         value: rule.release,
         displayText: rule.release,
         status: RuleInfo._EOLStatusNames[RuleInfo.StatusIntActive]
       }
       if (rule.eol) {
         optionData.status = rule.eol.Status;
         if (rule.eol.Status === "SUNSET") {
           optionData.deprecateDateSeconds = rule.eol.DeprecateOnDate.seconds;
           optionData.sunsetDateSeconds = rule.eol.SunsetOnDate.seconds;
         } else if (rule.eol.Status === "DEPRECATED") {
           optionData.displayText += I18NStringResource.deprecatedSuffix;
           optionData.deprecateDateSeconds = rule.eol.DeprecateOnDate.seconds;
           optionData.sunsetDateSeconds = rule.eol.SunsetOnDate.seconds;
         }
       }
       uniqueReleasesJson.add(JSON.stringify(optionData));
     }
     for (const json of uniqueReleasesJson) {
       const optionData = JSON.parse(json);
       uniqueReleases.add(optionData);
     }
     const orderedReleases = Array.from(uniqueReleases);
     orderedReleases.sort((a, b) => {
       if (a.value < b.value) { return -1; }
       if (a.value > b.value) { return 1; }
       return 0;
     }).reverse();

     let orderReleasesIncludesDefaultRelease = false;
     for (const r of orderedReleases) {
       if (r.value === defaultRelease) {
         orderReleasesIncludesDefaultRelease = true;
         break;
       }
     }
     if (!orderReleasesIncludesDefaultRelease) {
      defaultRelease = orderedReleases[0].value;
     }

     if (!orderedReleases || orderedReleases.length === 0) {
      throw new Error('No release data found');
     }

     let releaseSelectElement = this.getReleaseSelect();
     if (releaseSelectElement) {
       for (let optionData of orderedReleases) {
         // Option(text, value, defaultSelected, selected);
         let text = optionData.displayText;
         if (!RuleManager.isEntitledToUseRelease(optionData.value)) {
           text += " " + I18NStringResource.notEntitled;
         }
         let option = new Option(text, optionData.value);
         option.dataset.status = "Active";
         if (optionData.status === "DEPRECATED") {
           option.dataset.status = "Deprecated";
           option.dataset.deprecateDate = Util.formatDateYMD(new Date(optionData.deprecateDateSeconds * 1000));
           option.dataset.sunsetDate = Util.formatDateYMD(new Date(optionData.sunsetDateSeconds * 1000));
         } else if (optionData.status === "SUNSET") {
           option.dataset.status = "Sunsetted";
           option.dataset.deprecateDate = Util.formatDateYMD(new Date(optionData.deprecateDateSeconds * 1000));
           option.dataset.sunsetDate = Util.formatDateYMD(new Date(optionData.sunsetDateSeconds * 1000));
           // do not show sunsetted releases on the release dropdown
           continue;
         }
         releaseSelectElement.add(option, undefined);
       }
       if (!Util.selectHasNonPromptOption(releaseSelectElement)) {
         throw new Error("No release options");
       }
       // If there's a default release value, use it. Otherwise,
       // select the first entitled release.
       // If there are none, select the first, non-prompt option, because
       // the array is already sorted and all entries are non-entitled.
       let defaultReleaseSelected = false;
       if (defaultRelease ) {
         defaultReleaseSelected = Util.selectOptionIndexByValue(releaseSelectElement, defaultRelease);
         // must use jQuery event because of Backbone listeners
         $(releaseSelectElement).change();
       }
       if (!defaultReleaseSelected) {
         if (RuleManager.isEntitledReleasePresent()) {
           for (let i = 0; i < releaseSelectElement.options.length; i++) {
             let release = releaseSelectElement.options[i].value;
             if (RuleManager.isEntitledToUseRelease(release)) {
               releaseSelectElement.selectedIndex = i;
               break;
             }
           }
         } else if (Util.selectFirstNonPromptOption(releaseSelectElement)) {
           // must use jQuery event because of Backbone listeners
           $(releaseSelectElement).change();
         }
       }
       releaseSelectElement.disabled = (releaseSelectElement.options.length===2);
        if (releaseSelectElement.disabled) {
          releaseSelectElement.classList.add("disabled");
        } else {
          releaseSelectElement.classList.remove("disabled");
        }
        this.checkForAndHideEmptySelectorElements(releaseSelectElement);
     }
   }

   displayUIOnAutoCompleteError (error) {
     this.triggerAutoCompleteErrorEvent(error);
   }

   static getResourceNameFromProduct (product) {
     if (!product || typeof product !== 'string' ) {
       throw new TypeError("Invalid product argument");
     }
     let productDetails = SupportedProducts.getSupportedProducts()[product.toLowerCase()];
     let productName;
     if (productDetails && productDetails.defaultProductName) {
      productName = productDetails.defaultProductName;
     } else {
      productName = product;
     }
     const baseName = `${productName}`;
     return baseName;
   }

  }

  return CreateMATLABWizardStep1;
});
