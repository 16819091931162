/* jshint esversion: 8 */
define ([

], function(

) {

  class CacheRegistry {
    constructor () {
      this.cacheRegistry = new Map();
      this.dataCache = new Map();
    }

    getCacheRegistry () { return this.cacheRegistry; }
    getDataCache () { return this.dataCache; }

    registerCache (cacheId, cacheEnabled = true) {
      let registered = false;
      if (cacheId && typeof cacheId === 'string') {
        if (!this.cacheIsRegistered(cacheId)) {
          this.getCacheRegistry().set(cacheId, cacheEnabled);
          this.getDataCache().set(cacheId, new Map());
          registered = true;
        }
      }
      return registered;
    }

    clearCacheRegistration (cacheId) {
      let registrationCleared = false;
      if (cacheId && typeof cacheId === 'string' && this.getCacheRegistry().has(cacheId)) {
        this.getDataCache().delete(cacheId);
        this.getCacheRegistry().delete(cacheId);
        registrationCleared = true;
      }
      return registrationCleared;
    }

    deregisterCache (cacheId) {
      let deregistered = false;
      if (cacheId && typeof cacheId === 'string' && this.getCacheRegistry().has(cacheId)) {
        deregistered = this.clearCacheRegistration(cacheId);
      }
      return deregistered;
    }

    cacheIsRegistered (cacheId) { return (
      cacheId && typeof cacheId === 'string'
      && this.getCacheRegistry().has(cacheId));
    }

    enableCache (cacheId) {
      let cacheEnabled = false;
      if (this.cacheIsRegistered(cacheId)) {
        this.getCacheRegistry().set(cacheId, true);
        cacheEnabled = true;
      }
      return cacheEnabled;
    }

    disableCache (cacheId) {
      let cacheDisabled = false;
      if (this.cacheIsRegistered(cacheId)) {
        this.getCacheRegistry().set(cacheId, false);
        cacheDisabled = true;
      }
      return cacheDisabled;
    }

    cacheIsEnabled (cacheId) {
      let cacheEnabled = false;
      if (this.cacheIsRegistered(cacheId)) {
        cacheEnabled = (this.getCacheRegistry().get(cacheId) === true);
      }
      return cacheEnabled;
    }

    cacheIsDisabled (cacheId) {
      let cacheDisabled = true;
      if (this.cacheIsRegistered(cacheId)) {
        cacheDisabled = (this.getCacheRegistry().get(cacheId) === false);
      }
      return cacheDisabled;
    }

    set (cacheId, data) {
      let dataSet = false;
      if ( !(cacheId && typeof cacheId === 'string' && this.cacheIsRegistered(cacheId))) {
        throw new TypeError("Invalid cacheId argument");
      }
      if (this.cacheIsEnabled(cacheId)) {
        this.getDataCache().set(cacheId, data);
        dataSet = true;
      }
      return dataSet;
    }

    get (cacheId) {
      if ( !(cacheId && typeof cacheId === 'string' && this.cacheIsRegistered(cacheId))) {
        throw new TypeError("Invalid cacheId argument");
      }
      if (this.cacheIsDisabled(cacheId)) {
        return;
      }
      return this.getDataCache().get(cacheId);
    }

    delete (cacheId) {
      if ( !(cacheId && typeof cacheId === 'string' && this.cacheIsRegistered(cacheId))) {
        throw new TypeError("Invalid cacheId argument");
      }
      this.getDataCache().delete(cacheId);
      // because top-level cache contains map of sub-caches, we need a map.
      this.getDataCache().set(cacheId, new Map());
    }
  }

  return CacheRegistry;
});
