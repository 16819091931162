/* jshint esversion: 8 */
define ([

], function() {

  const DO_NOTHING = function () {};

  class SequenceMapElement {
    constructor (key) {
      if (!key || typeof key !== 'string') {
        throw new TypeError("Invalid key argument");
      }
      // create private instance members
      Object.defineProperty(this, "keyValue", {
        enumerable: false,
        value: key,
        writable: false
      });
      Object.defineProperty(this, "setDefaultsMethod", {
        enumerable: false,
        value: DO_NOTHING,
        writable: true
      });
      Object.defineProperty(this, "getAdjacentDataMethod", {
        enumerable: false,
        value: DO_NOTHING,
        writable: true
      });
      Object.defineProperty(this, "adjacentSelectorsMethod", {
        enumerable: false,
        value: DO_NOTHING,
        writable: true
      });
      Object.defineProperty(this, "nextSequenceStepValue", {
        enumerable: false,
        value: "",
        writable: true
      });
    }

    // public setters
    set setDefaults (func) {
      this.setDefaultsMethod = func;
    }
    set getAdjacentData (func) {
      this.getAdjacentDataMethod = func;
    }
    set adjacentSelectors (func) {
      this.adjacentSelectorsMethod = func;
    }
    set nextSequenceStep (nextStep) {
      this.nextSequenceStepValue = nextStep;
    }

    // public getters
    get key () { return this.keyValue; }
    get setDefaults () { return this.setDefaultsMethod; }
    get getAdjacentData () { return this.getAdjacentDataMethod; }
    get adjacentSelectors () { return this.adjacentSelectorsMethod; }
    get nextSequenceStep () { return this.nextSequenceStepValue; }

    static isValid (sequenceMapElement) {
      let isValid = false;
      if (sequenceMapElement instanceof SequenceMapElement) {
        isValid = (
          (sequenceMapElement.key && typeof sequenceMapElement.key === 'string') &&
          (sequenceMapElement.setDefaults && typeof sequenceMapElement.setDefaults === 'function') &&
          (sequenceMapElement.getAdjacentData && typeof sequenceMapElement.getAdjacentData === 'function') &&
          (sequenceMapElement.adjacentSelectors && typeof sequenceMapElement.adjacentSelectors === 'function') &&
          (!sequenceMapElement.nextSequenceStep || (sequenceMapElement.nextSequenceStep && typeof sequenceMapElement.nextSequenceStep === 'string'))
        );

      }
      return isValid;
    }

  }


  return SequenceMapElement;
});
