define([
    "util",
    "config",
    "service/cloudCenterDataService",
    "dao/cloudCenterDao",
], function (
    Util,
    GCONFIG,
    CloudCenterDataService,
    CloudCenterDao,
) {

    function abstractMethod() { throw new Error("Abstract method."); }

    class MachineTypeWithFinder {

        constructor(args) {
            if (this.constructor === MachineTypeWithFinder) {
                throw new Error("Cannot instantiate abstract class.");
            }
            if (!args || typeof args !== "object") {
                throw new TypeError("Invalid args argument");
            }

            this._root = args.root;

            this.getCurrentCredentialId = args.methods.core.getCurrentCredentialId
            this.getCurrentLocation = args.methods.core.getCurrentLocation

            this.updateSavedStep1Value = args.methods.core.updateSavedStep1Value
            this.updateSavedStep2ValueOverride = args.methods.core.updateSavedStep2ValueOverride
            this.onStep1Change = args.methods.core.onStep1Change

            this.refreshRules = args.methods.core.refreshRules
            this.getRules = args.methods.core.getRules

            this.getCSSClassFromLabelText = args.methods.core.getCSSClassFromLabelText  // this is from cloudcenterElement.js

            this.getDefaults = args.methods.core.getDefaults
            this.updateUI = args.methods.core.updateUI
            this.logClick = args.methods.core.logClick
            this.debouncedOptionsLoaded = args.methods.core.debouncedOptionsLoaded
            this.toggleApplyButton = args.methods.core.toggleApplyButton
            this.applyChoices = args.methods.core.applyChoices


            this.vpcid = args.props.vpcid
            this.vpclabel = args.props.vpclabel
            this.vpcpoptext = args.props.vpcpoptext
            this.vpcplaceholder = args.props.vpcplaceholder
            this.vpcvalue = args.props.vpcvalue
            this.subnetid = args.props.subnetid
            this.subnetlabel = args.props.subnetlabel
            this.subnetpoptext = args.props.subnetpoptext
            this.subnetplaceholder = args.props.subnetplaceholder
            this.subnetvalue = args.props.subnetvalue
            this.machinetypeid = args.props.machinetypeid
            this.machinetypelabel = args.props.machinetypelabel
            this.machinetypepoptext = args.props.machinetypepoptext
            this.machinetypeplaceholder = args.props.machinetypeplaceholder
            this.machinetypevalue = args.props.machinetypevalue

            this.isRadioSelected = false;

            this.timestamp = args.timestamp
            this.currentPage = args.currentPage
            this.credentialTypeId = args.credentialTypeId;
            this.rulesId = args.rulesId;

            this.initalLoad = true;

            const cloudCenterDao = new CloudCenterDao(GCONFIG);
            this._dataService = new CloudCenterDataService({ dao: cloudCenterDao });
        }

        getSelectedData() { abstractMethod(); }
        selectionRequiresStep1Change() { abstractMethod(); }
        updateStepsAndNavigate() { abstractMethod(); }
        render() { abstractMethod(); }

        disableAll(listOfObjects, msg) {
            if (!listOfObjects || typeof listOfObjects !== 'object' || !listOfObjects.length) {
                return
            }
            for (let obj of listOfObjects) {
                if (msg && typeof msg === 'string') {
                    obj.innerHTML = '';
                    obj.append(new Option(msg, ''));
                }
                obj.disabled = true;
                obj.classList.add('disabled');
            }
        }
        root() {
            return this._root;
        }

        setRadioSelected(isSelected) {
            this.isRadioSelected = isSelected;
            this.selectionRequiresStep1Change();
        }
        active() {
            return this.isRadioSelected === true;
        }

        setInitialLoad(initialLoad) {
            this.initalLoad = initialLoad;
        }

        isInitialLoad() {
            return this.initalLoad;
        }

        getDataService() {
            return this._dataService;
        }

        async getSupportedInstanceTypesAsOptions(allAvailableInstancesInSubnet) {
            let options = [];
            let supportedInstances = await this.listSupportedMachineTypes();
            let supportedInstanceIDs = {};
            let supportedInstanceDescs = {};
            let supportedDescs = [];

            for (const instance of supportedInstances) {
                supportedInstanceIDs[instance.id] = instance.desc; // for comparing to subnet list
                supportedInstanceDescs[instance.desc] = instance.id; // for building sorted list
            }






            let instanceData = [];
            let instanceMaxHeading = {};
            let sortedKeys = ["Name", "Machine"]
            let hasStorage = false;


            for (const instance of allAvailableInstancesInSubnet.msg) {
                const desc = supportedInstanceIDs[instance];
                if (desc) {

                    //remove trailing ) and split on , to get parts
                    let parts = desc.substr(0, desc.length - 1).split(",");
                    let nameAndType = parts.shift().split("(");
                    let sortableType = nameAndType.pop().trim();
                    let name = nameAndType.join("(").trim();

                    let instanceInfo = {
                        SortableType: sortableType,
                    }
                    for (const [key, value] of Object.entries({ Name: name, Machine: instance })) {
                        if (!instanceMaxHeading[key] || instanceMaxHeading[key] < value.length) {
                            instanceMaxHeading[key] = value.length;
                        }
                        instanceInfo[key] = value;
                    }

                    for (let i = 0; i < parts.length; i++) {
                        let trimmedPart = parts[i].trim();
                        let partInfo = trimmedPart.split(" ");
                        const leftDataPoint = partInfo[0].trim();
                        const rightDataPoint = partInfo.slice(1).join(" ").trim()

                        if (leftDataPoint.includes("x") && leftDataPoint.includes("B")) { //storage is the final piece
                            hasStorage = true;
                            for (const [key, value] of Object.entries({ "Storage Size": leftDataPoint, "Storage Type": rightDataPoint })) {
                                if (!instanceMaxHeading[key] || instanceMaxHeading[key] < value.length) {
                                    instanceMaxHeading[key] = value.length;
                                }
                                instanceInfo[key] = value;
                                if (!instanceMaxHeading[key] || instanceMaxHeading[key] < value.length) {
                                    instanceMaxHeading[key] = value.length;
                                }
                            }
                            break;
                        }

                        const paramId = rightDataPoint.substr(0, 1).toUpperCase() + rightDataPoint.substr(1);
                        const paramValue = leftDataPoint
                        instanceInfo[paramId] = paramValue;
                        if (!instanceMaxHeading[paramId]) {
                            sortedKeys.push(paramId);
                            instanceMaxHeading[paramId] = paramValue.length
                        } else if (instanceMaxHeading[rightDataPoint] < paramValue.length) {
                            instanceMaxHeading[paramId] = paramValue.length;
                        }
                    }
                    instanceData.push(instanceInfo);
                    supportedDescs.push(desc); // for sort order
                }
            }
            if (hasStorage) {
                sortedKeys.push("Storage Size", "Storage Type");
                for (const key of sortedKeys) {
                    if (instanceMaxHeading[key] < key.length) {
                        instanceMaxHeading[key] = key.length;
                    }
                }
            }

            let desc = ""
            const spaceChar = '\u00A0'

            for (const key of sortedKeys) {
                if (desc) {
                    desc += " | "
                }
                desc += key.padEnd(instanceMaxHeading[key], spaceChar);
            }
            const opt = new Option(desc, "");
            opt.style.fontFamily = "monospace";
            opt.disabled = true
            opt.textContent = opt.textContent.replace(/'\u00A0'/g, '&nbsp;')
            options.push(opt);

            instanceData.sort(Util.sortMultipleAttr("Name", "SortableType"))
            for (const instance of instanceData) {
                desc = ""
                for (const key of sortedKeys) {
                    if (desc) {
                        desc += " | "
                    }
                    let val = instance[key];
                    if (!val) {
                        val = "";
                    }
                    switch (key) {
                        case "Storage Type":
                        case "Machine":
                        case "Name":
                            desc += val.padEnd(instanceMaxHeading[key], spaceChar);
                            break
                        default:
                            desc += val.padStart(instanceMaxHeading[key], spaceChar);

                    }

                }
                const opt = new Option(desc, instance.Machine);
                opt.style.fontFamily = "monospace";
                opt.textContent = opt.textContent.replace(/'\u00A0'/g, '&nbsp;')
                options.push(opt);
            }


            // supportedDescs.sort();
            // for (const desc of supportedDescs) {
            //     const id = supportedInstanceDescs[desc];
            //     const opt = new Option(desc, id);
            //     options.push(opt);
            // }
            return options;
        }

        async listSupportedMachineTypes() {
            // this needs ot be sync, not async, so it should be loaded at step 2 load and not on click
            if (this._supportedMachines) {
                return this._supportedMachines;
            }

            if (!this.getRules()) {
                await this.refreshRules();
            }

            this._supportedMachines = [];
            for (const param of this.getRules().params.body.params) {
                if (param.id === this.machinetypeid) {
                    const settingsKey = param.constraints.setting_include;
                    let machineList = this.getRules().params[settingsKey];
                    let machines = [];
                    for (const [id, desc] of Object.entries(machineList)) {
                        machines.push({ id: id, desc: desc });
                    }
                    machines.sort(Util.sortMultipleAttr("desc"))
                    this._supportedMachines = machines;
                    break
                }
            }
            return this._supportedMachines;

        }

    }
    return MachineTypeWithFinder;
});
