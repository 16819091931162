/*jshint esversion: 6 */
define([], function() {

  /**
   ** Add entry to override default error message for specific operation.
   ** The operation name must be unique and whatever you like.
   ** inverse means NOT.  For example inverse = true and errorCode = "ILLEGAL_MOVE_TARGET" means "where errorCode !== "ILLEGAL_MOVE_TARGET""
   ** hasSubstitutionTokens = true if the string expects to do token substitution.
   ** SPECIAL VALUES:  "NO_ERROR_CODE" - Used for regular javascript Errors, which do not have a "errorCode" property.
   ** "ACTION_ABORTED" - Used when the browser operation is cancelled
   **
   ** Examples:
   **  {errorCode: "RESOURCE_ALREADY_EXISTS", inverse: false, operationName: "fileUpload.displayErrorOnDialog", msgData: { stringId: "simpleUploadResourceAlreadyExists", hasSubstitutionTokens: false }},
   **  {errorCode: "RESOURCE_DOES_NOT_EXIST", inverse: true, operationName: "actionHandler.performSingleDelete", msgData: { stringId: "actionHandlerSingleDelectFailed", hasSubstitutionTokens: true }},
   **
   */
  const ERROR_MSG_OVERRIDE_DATA = [

  ];
  return ERROR_MSG_OVERRIDE_DATA;
});
