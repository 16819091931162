
define([
    "dojo/_base/declare"
], function (declare) {

    return declare([], {

        constructor: function (args) {
            this.name = "";
            this.context = {};
            this.triggerHandler = args.triggerHandler || function () {}; // safeMixin won't
                                                                         // do functions :(
            declare.safeMixin(this, args);
        },

        enable: function () {
        },

        disable: function () {
        }
    });
});