/* Copyright 2011 The MathWorks, Inc. */

define([
    "dojo/_base/window",
    "dojo/window",
    "dojo/dom-construct",
    "dojo/has",
    "dojo/i18n!./l10n/nls/unsupportedErrorPage",
    "./detect/css",
    "./detect/object"
], function (win, winUtils, domConstruct, has, errorl10n) {
    var browserCheckFunctions = {};
    browserCheckFunctions.isBrowserValid = function () {
        var isValid = true;

        /**
         * Redirect if we detect any of the following conditions:
         * - no DOM
         * - browser running in quirks mode
         * - no CSS
         * - no CSS border radius support
         * - no CSS text overflow support
         * - no Object.create() support
         */
        if (!has("dom") || has("quirks")) {
            isValid = false;
        } else if (!has("css-enabled")) {
            isValid = false;
        } else if (!has("css-border-radius")) {
            isValid = false;
        } else if (!has("css-text-overflow")) {
            isValid = false;
        } else if (!has("object-create")) {
            isValid = false;
        }
        return isValid;
    };

    browserCheckFunctions.displayErrorAsOverlay = function () {
        var fadeDiv = domConstruct.create("div", {style: {
            position: "absolute",
            left: "0%",
            top: "0%",
            "background-color": "grey",
            "-moz-opacity": "0.7",
            "opacity": ".70",
            filter: "alpha(opacity=70)",
            width: "100%",
            height: "100%",
            "zIndex": "90"
        }, id: "fadeDiv"}, win.body());

        var div = domConstruct.create("div", {style: {
            position: "relative",
            margin: "auto",
            "margin-top": "12.35%",
            width: "400px",
            height: "300px",
            padding: "10px",
            border: "2px solid black",
            "background-color": "#ffffff",
            "z-index": "100",
            "font-family": "Sans-serif"
        }, id: "unsupportedBrowser", "class": "content_module"}, win.body());
        div.innerHTML = errorl10n.unsupportedError;
    };

    return {
        load: function (urlToRedirect, require, loaded) {

            var isBrowserValid = browserCheckFunctions.isBrowserValid();
            if (!isBrowserValid) {
                if (urlToRedirect) {
                    win.global.location = urlToRedirect;
                } else {
                    browserCheckFunctions.displayErrorAsOverlay();
                }
            } else {
                loaded();
            }
        }
    };
});
